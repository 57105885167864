import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "aiAnsweringOverviewTitle": {
      "id": "ai_answering_overview.title",
      "defaultMessage": "AI Answering"
    },
    "aiAnsweringOverviewTitleTooltipDataUpdate": {
      "id": "ai_answering_overview.title.tooltip_data_update",
      "defaultMessage": "Data last updated on {lastUpdated}. Showing data from {lastWeek} - {yesterday}"
    },
    "aiAnsweringOverviewTitleTooltip": {
      "id": "ai_answering_overview.title.tooltip",
      "defaultMessage": "Last updated: {lastUpdated}"
    },
    "aiAnsweringOverviewLockedTitle": {
      "id": "ai_answering_overview.locked_title",
      "defaultMessage": "Turn every call into an opportunity and never miss a call from your most important customer...all of them!"
    },
    "aiAnsweringOverviewLockedDescription": {
      "id": "ai_answering_overview.locked_description",
      "defaultMessage": "Reduce stress on your team and reclaim the power of your phone. With answering powered by artificial intelligence, the simple questions that keep your phone line tied up can now be handled without interrupting your in-person hospitality."
    },
    "aiAnsweringOverviewLockedDescriptionAlt": {
      "id": "ai_answering_overview.locked_description_alt",
      "defaultMessage": "Get a round-the-clock employee who answers your phone 24/7, 365 days a year for just $0.47 cents/hour."
    },
    "aiAnsweringOverviewLockedEmbeddedVideoTitle": {
      "id": "ai_answering_overview.locked_embedded_video_title",
      "defaultMessage": "Popmenu AI Answering"
    },
    "aiAnsweringOverviewLockedCaseStudyTitle": {
      "id": "ai_answering_overview.locked.case_study.title",
      "defaultMessage": "Case Study"
    },
    "aiAnsweringOverviewLockedCaseStudySubtitle": {
      "id": "ai_answering_overview.locked.case_study.subtitle",
      "defaultMessage": "Better Customer Service"
    },
    "aiAnsweringOverviewLockedCaseStudyDescription": {
      "id": "ai_answering_overview.locked.case_study.description",
      "defaultMessage": "Learn how restaurant owners have used our AI-powered technology to improve the guest experience, reduce labor costs, and drive revenue."
    },
    "aiAnsweringOverviewLockedCaseStudyButton": {
      "id": "ai_answering_overview.locked.case_study.button",
      "defaultMessage": "Read now"
    },
    "aiAnsweringOverviewLockedPopmenuTipsTitle": {
      "id": "ai_answering_overview.locked.popmenu_tips.title",
      "defaultMessage": "Popmenu Tips"
    },
    "aiAnsweringOverviewLockedPopmenuTipsSubtitle": {
      "id": "ai_answering_overview.locked.popmenu_tips.subtitle",
      "defaultMessage": "Diners Like Automated Answering"
    },
    "aiAnsweringOverviewLockedPopmenuTipsDescription": {
      "id": "ai_answering_overview.locked.popmenu_tips.description",
      "defaultMessage": "U.S. consumer survey showed that 74% of diners are happy to have questions answered by an automated system, and 49% are less likely to dine at or order from a restaurant if the phone isn't answered."
    },
    "aiAnsweringOverviewLockedPopmenuFeaturesTitle": {
      "id": "ai_answering_overview.locked.popmenu_features.title",
      "defaultMessage": "Popmenu Features"
    },
    "aiAnsweringOverviewLockedPopmenuFeaturesSubtitle": {
      "id": "ai_answering_overview.locked.popmenu_features.subtitle",
      "defaultMessage": "Make a Great First Impression"
    },
    "aiAnsweringOverviewLockedPopmenuFeaturesDescription": {
      "id": "ai_answering_overview.locked.popmenu_features.description",
      "defaultMessage": "Easily customize how you greet your guests, respond to their most frequently asked questions, take messages and escalate more complex conversations back to your team."
    },
    "aiAnsweringOverviewLockedPopmenuFeaturesButton": {
      "id": "ai_answering_overview.locked.popmenu_features.button",
      "defaultMessage": "See how"
    },
    "aiAnsweringOverviewTotalWeeklyCalls": {
      "id": "ai_answering_overview.total_weekly_calls",
      "defaultMessage": "Total weekly calls"
    },
    "aiAnsweringOverviewTotalCalls": {
      "id": "ai_answering_overview.total_calls",
      "defaultMessage": "Total calls"
    },
    "aiAnsweringOverviewResolutionRate": {
      "id": "ai_answering_overview.resolution_rate",
      "defaultMessage": "Resolution Rate"
    },
    "aiAnsweringOverviewAnsweringTimeSaved": {
      "id": "ai_answering_overview.answering_time_saved",
      "defaultMessage": "AI Answering Time Saved"
    },
    "aiAnsweringOverviewAverageCallDuration": {
      "id": "ai_answering_overview.average_call_duration",
      "defaultMessage": "Average call duration"
    },
    "aiAnsweringOverviewVolumePerHourGraphTitle": {
      "id": "ai_answering_overview.volume_per_hour_graph.title",
      "defaultMessage": "Call volume per hour"
    },
    "aiAnsweringOverviewVolumePerDayOfWeekGraphTitle": {
      "id": "ai_answering_overview.volume_per_day_of_week_graph.title",
      "defaultMessage": "Calls by day of week"
    },
    "aiAnsweringOverviewCallOutcomesTitle": {
      "id": "ai_answering_overview.top_call_outcomes.title",
      "defaultMessage": "Top call outcomes"
    },
    "aiAnsweringOverviewEmptyState": {
      "id": "ai_answering_overview.empty_state",
      "defaultMessage": "We're gathering your results. This can sometimes take as long as a day. Check back later!"
    },
    "aiAnsweringOverviewErrorState": {
      "id": "ai_answering_overview.error_state",
      "defaultMessage": "Oops looks something isn't right. We are unable to load data. Try reloading your browser."
    },
  }
);
export default defaultMessages;
