
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "blogPostsIndex": {
      "id": "blog_posts.index",
      "defaultMessage": "Blog"
    },
    "blogPostsEdit": {
      "id": "blog_posts.edit",
      "defaultMessage": "Edit Post"
    },
    "blogPostsShow": {
      "id": "blog_posts.show",
      "defaultMessage": "View Post"
    },
    "blogPostsNew": {
      "id": "blog_posts.new",
      "defaultMessage": "New Post"
    },
    "blogPostsImport": {
      "id": "blog_posts.import",
      "defaultMessage": "Import Posts"
    },
    "blogPostsSettings": {
      "id": "blog_posts.settings",
      "defaultMessage": "Blog Settings"
    }
  }
);
export default defaultMessages;

