
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "followerTagsNew": {
      "id": "follower_tags.new",
      "defaultMessage": "New Follower Tag"
    },
    "followerTagsEdit": {
      "id": "follower_tags.edit",
      "defaultMessage": "Edit Follower Tag"
    },
    "followerTagsDestroyConfirm": {
      "id": "follower_tags.destroy_confirm",
      "defaultMessage": "Are you sure you want to delete this tag?"
    },
    "followerTagsDestroyConfirmTitle": {
      "id": "follower_tags.destroy_confirm_title",
      "defaultMessage": "Confirm Delete"
    },
    "followerTagsDestroy": {
      "id": "follower_tags.destroy",
      "defaultMessage": "Delete Follower Tag"
    },
    "followerTagsDestroyed": {
      "id": "follower_tags.destroyed",
      "defaultMessage": "Follower Tag successfully deleted."
    },
    "followerTagsIndex": {
      "id": "follower_tags.index",
      "defaultMessage": "Follower Tags"
    },
    "followerTagsSettings": {
      "id": "follower_tags.settings",
      "defaultMessage": "Folloer Tag Settings"
    }
  }
);
export default defaultMessages;

