
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "formsImageDescriptionTooltip": {
      "id": "forms.image_description_tooltip",
      "defaultMessage": "Add a short photo description for the visually impaired"
    },
    "formsCancel": {
      "id": "forms.cancel",
      "defaultMessage": "Cancel"
    },
    "formsLearnMore": {
      "id": "forms.learn_more",
      "defaultMessage": "Learn More"
    },
    "formsResponses": {
      "id": "forms.responses",
      "defaultMessage": "Form responses"
    },
    "advancedFormsResponses": {
      "id": "advanced_forms.responses",
      "defaultMessage": "Advanced Form Responses"
    },
    "advancedFormsViewFile": {
      "id": "advanced_forms.view_file",
      "defaultMessage": "View file"
    },
    "advancedFormsNoFile": {
      "id": "advanced_forms.no_file",
      "defaultMessage": "No file uploaded"
    },
    "advancedFormsNoAnswer": {
      "id": "advanced_forms.no_answer",
      "defaultMessage": "n/a"
    },
    "formsBlocked": {
      "id": "forms.blocked",
      "defaultMessage": "Blocked"
    },
    "formsLinkUrlTooltip": {
      "id": "forms.link_url_tooltip",
      "defaultMessage": "Add a link to your image"
    },
    "formsSubmit": {
      "id": "forms.submit",
      "defaultMessage": "Save"
    },
    "formsAnother": {
      "id": "forms.another",
      "defaultMessage": "Save & Add Another"
    },
    "formsBack": {
      "id": "forms.back",
      "defaultMessage": "Back"
    },
    "formsClose": {
      "id": "forms.close",
      "defaultMessage": "Close"
    },
    "formsConfirm": {
      "id": "forms.confirm",
      "defaultMessage": "Confirm"
    },
    "formsContinue": {
      "id": "forms.continue",
      "defaultMessage": "Continue"
    },
    "formsDone": {
      "id": "forms.done",
      "defaultMessage": "Done"
    },
    "formsGetStarted": {
      "id": "forms.get_started",
      "defaultMessage": "Get Started"
    },
    "formsYou": {
      "id": "forms.you",
      "defaultMessage": "You"
    },
    "formsCreate": {
      "id": "forms.create",
      "defaultMessage": "Create"
    },
    "formsNext": {
      "id": "forms.next",
      "defaultMessage": "Next"
    },
    "formsSaveContinue": {
      "id": "forms.save_continue",
      "defaultMessage": "Save & Continue"
    },
    "formsSend": {
      "id": "forms.send",
      "defaultMessage": "Send"
    },
    "formsStatus": {
      "id": "forms.status",
      "defaultMessage": "Status"
    },
    "formsRecommendation": {
      "id": "forms.recommendation",
      "defaultMessage": "See Recommendation"
    },
    "formsReset": {
      "id": "forms.reset",
      "defaultMessage": "Reset"
    },
    "formsAddImage": {
      "id": "forms.add_image",
      "defaultMessage": "Add Image"
    },
    "formsInternalNote": {
      "id": "forms.internal.note",
      "defaultMessage": "Internal Note"
    },
    "formsAddNote": {
      "id": "forms.add.note",
      "defaultMessage": "Add a note"
    },
    "formsAddNoteTextAreaGroup": {
      "id": "forms.add.note.text.area.group",
      "defaultMessage": "Add a note..."
    },
    "formsMarkAsResolved": {
      "id": "forms.mark.as.resolved",
      "defaultMessage": "Mark as resolved"
    },
    "ErrorUpdatingForm": {
      "id": "forms.error",
      "defaultMessage": "Error updating the entry:"
    },
  }
);
export default defaultMessages;
