
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "libraryModalDone": {
      "id": "library_modal.done",
      "defaultMessage": "Done"
    },

    "libraryModalAdd": {
      "id": "library_modal.add",
      "defaultMessage": "Add"
    }
  }
);
export default defaultMessages;

