import { defineMessages } from 'react-intl';

const defaultMessages = defineMessages(
  {
    "rootSalesDemoCreationInProgress": {
      "id": "root_sales_demo.creation_in_progress",
      "defaultMessage": "Sales demo restaurant is being created, hold on...",
    },
    "rootSalesDemoCreationSuccess": {
      "id": "root_sales_demo.creation_success",
      "defaultMessage": "Sales demo restaurant created successfully",
    },
    "rootSalesDemoSourceRestaurant": {
      "id": "root_sales_demo.source_restaurant",
      "defaultMessage": "Template restaurant",
    },
    "rootSalesDemoName": {
      "id": "root_sales_demo.name",
      "defaultMessage": "Name",
    },
    "rootSalesDemoPageTitle": {
      "id": "root_sales_demo.page_title",
      "defaultMessage": "Root - Sales Demo Restaurants",
    },
    "rootSalesDemoCreateModalTitle": {
      "id": "root_sales_demo.create_modal_title",
      "defaultMessage": "Create Sales Demo Restaurant",
    },
  },
);
export default defaultMessages;
