
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "blockedTermDestroyConfirm": {
      "id": "blocked_term.destroy_confirm",
      "defaultMessage": "Are you sure you want to delete this root blocked term?"
    },
    "blockedTermDestroyConfirmTitle": {
      "id": "blocked_term.destroy_confirm_title",
      "defaultMessage": "Confirm Delete"
    },
    "blockedTermIndex": {
      "id": "blocked_term.index",
      "defaultMessage": "Root Blocked Terms"
    },
    "blockedTermNew": {
      "id": "blocked_term.new",
      "defaultMessage": "Add Root Block Term"
    },
    "blockedTermEdit": {
      "id": "blocked_term.edit",
      "defaultMessage": "Edit Root Block Term"
    },
    "blockedTermDestroy": {
      "id": "blocked_term.destroy",
      "defaultMessage": "Delete Root Block Term"
    }
  }
);
export default defaultMessages;

