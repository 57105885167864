
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "rootThemesIndex": {
      "id": "root_themes.index",
      "defaultMessage": "Root Themes & Templates"
    },
    "rootThemesNew": {
      "id": "root_themes.new",
      "defaultMessage": "Add Root Theme"
    },
    "rootThemesEdit": {
      "id": "root_themes.edit",
      "defaultMessage": "Edit Root Theme"
    }
  }
);
export default defaultMessages;

