import React from 'react';

import Divider from '../forms/Divider';
import TermsContainer from '../../../consumer/shared/TermsContainer';
import TermsNav from './TermsNav';

const AccessibilityContainer = () => (
  <React.Fragment>
    <TermsNav />
    <Divider />
    <TermsContainer termsType="clientAccessibility" />
  </React.Fragment>
);

export default AccessibilityContainer;
