
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "popsPop": {
      "id": "pops.pop",
      "defaultMessage": "Pop"
    },
    "popsPops": {
      "id": "pops.pops",
      "defaultMessage": "Pops"
    },
    "popsCreated": {
      "id": "pops.created",
      "defaultMessage": "You popped an item!"
    },
    "popsCreatedError": {
      "id": "pops.created_error",
      "defaultMessage": "There was an error popping the item."
    },
    "popsDestroyed": {
      "id": "pops.destroyed",
      "defaultMessage": "You unpopped an item."
    },
    "popsDestroyedError": {
      "id": "pops.destroyed_error",
      "defaultMessage": "There was an error unpopping the item."
    },
    "popsDestroyedAlready": {
      "id": "pops.destroyed_already",
      "defaultMessage": "You already unpopped that item."
    },
    "popsMyPopsTitle": {
      "id": "pops.my_pops.title",
      "defaultMessage": "My Pops"
    }
  }
);
export default defaultMessages;

