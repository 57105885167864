import React, { useCallback, useEffect, useMemo } from 'react';
import { useMutation, useQuery } from '~/lazy_apollo/client';
import { Redirect, useHistory, withRouter } from 'react-router-dom';
import { Grid, Paper, Typography } from '@popmenu/common-ui';
import { FormattedMessage } from 'react-intl';
import { compose } from '@shakacode/recompose';
import PropTypes from 'prop-types';
import userAdminRestaurantsQuery from '../../libs/gql/queries/users/userAdminRestaurantsQuery.gql';
import Loading from '../../shared/Loading';
import BasicList from '../../admin/shared/BasicList';
import { classNames, makeStyles } from '../../utils/withStyles';
import styles from './styles';
import Popmenu2020SVG from '../../assets/svg/popmenu_2020.svg';
import createOAuthIntegrationMutation from '../../libs/gql/mutations/oauth_integration/createOAuthIntegrationMutation.gql';
import { toCamelCase } from '../../utils/strings';
import { toQueryStringObject } from '../../utils/urls';

const useStyles = makeStyles(styles);
const INTEGRATION_PARAMS = {
  clover: ['client_id', 'code', 'merchant_id'],
};

const OAuthRestaurantSelection = ({ isAuthenticated, location, match }) => {
  const classes = useStyles();
  const history = useHistory();

  const { data, loading } = useQuery(
    userAdminRestaurantsQuery,
    {
      fetchPolicy: 'cache-and-network',
    },
  );
  const [mutateOAuthIntegration, { loading: creatingOauthIntegration }] = useMutation(createOAuthIntegrationMutation);

  const restaurantsLoading = loading || !data || !data.currentSession || !data.currentSession.user;
  const restaurants = useMemo(() => data?.currentSession?.user?.adminRestaurants.filter(r => r.status !== 'status_disabled'), [data]);

  const createOAuthIntegration = useCallback((restaurantId) => {
    const acceptedParams = INTEGRATION_PARAMS[match.params.accountType] || [];
    const cleanedParams = Object.fromEntries(
      Object.entries(toQueryStringObject(location.search))
        .filter(([key]) => acceptedParams.includes(key))
        .map(([key, value]) => [toCamelCase(key), value]),
    );

    mutateOAuthIntegration({
      variables: {
        input: {
          accountType: match.params.accountType,
          params: cleanedParams,
          restaurantId,
        },
      },
    }).then((response) => {
      if (response && response.data && response.data.createOauthIntegration && response.data.createOauthIntegration.success) {
        history.replace(response.data.createOauthIntegration.redirectPath);
        // Clear params from hash after submitting
        window.history.replaceState(null, null, ' ');
      }
    });
  }, [history, location.search, match.params.accountType, mutateOAuthIntegration]);

  const singleRestaurant = restaurants && restaurants.length === 1 && restaurants[0];
  useEffect(() => {
    // If we know we only have 1 restaurant, we can skip the selection
    if (singleRestaurant) {
      createOAuthIntegration(singleRestaurant.id);
    }
  }, [createOAuthIntegration, singleRestaurant]);

  if (!isAuthenticated) {
    const redirectPath = encodeURIComponent(`${location.pathname}${location.search}${location.hash}`);
    return <Redirect to={`/?redirect=${redirectPath}`} />;
  }

  return (
    <Grid container justifyContent="center" alignItems="center" className={classNames(classes.container, 'food-grid-wrapper')}>
      <Grid item xs={6}>
        {(restaurantsLoading || creatingOauthIntegration) && <Loading />}
        {(!restaurantsLoading && !creatingOauthIntegration) && (
          <React.Fragment>
            {(restaurants && restaurants.length < 1) && (
              <FormattedMessage id="restaurants.empty" defaultMessage="No restaurants found!" />
            )}
            {(restaurants && restaurants.length > 1) && (
              <Paper legacyStyles className={classes.paper}>
                <Popmenu2020SVG className={classes.popLogo} />
                <Typography className={classes.message}>
                  <FormattedMessage
                    id="restaurants.select_integration"
                    defaultMessage="Select a restaurant to integrate"
                  />
                </Typography>
                <BasicList>
                  {restaurants.map(({ id, logoUrl, name }) => (
                    <BasicList.ItemLink
                      key={id}
                      onClick={() => {
                        if (creatingOauthIntegration) {
                          return;
                        }

                        createOAuthIntegration(id);
                      }}
                    >
                      <BasicList.ItemAvatar alt={name} src={logoUrl} variant="square" fallback="none" background="#0000" fit="contain" />
                      <BasicList.ItemText
                        primary={name}
                      />
                    </BasicList.ItemLink>
                  ))}
                </BasicList>
              </Paper>
            )}
          </React.Fragment>
        )}
      </Grid>
    </Grid>
  );
};

OAuthRestaurantSelection.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    hash: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      accountType: PropTypes.string,
    }),
  }).isRequired,
};

export default compose(
  withRouter,
)(OAuthRestaurantSelection);
