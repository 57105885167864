import React, { useEffect } from 'react';

import { initAnalytics } from '~/utils/eventable';
import { usePopmenuConfig } from '../../../utils/withPopmenuConfig';
import LeadForm from '../LeadForm';
import UserSignUpForm from '../UserSignUpForm';
import sessionLeadQuery from '../../../libs/gql/queries/salesforce/sessionLeadQuery.gql';

import PopmenuLoading from '../../shared/PopmenuLoading';
import Query from '../../../shared/Query';

const SignUpContainer = () => {
  const popmenuConfig = usePopmenuConfig();
  useEffect(() => {
    initAnalytics({ adminApp: true, popmenuConfig });
  }, [popmenuConfig]);
  const searchParams = new URLSearchParams(document.location.search);
  const isOauth = searchParams.get('oauth');

  return (
    <Query query={sessionLeadQuery}>
      {({ data, loading }) => {
        if (loading || !data || !data.currentSession) {
          return <PopmenuLoading />;
        }
        const { salesforceLead } = data.currentSession;
        return (
          <React.Fragment>
            {isOauth || salesforceLead ? (
              <UserSignUpForm
                email={salesforceLead?.email}
                facebookOauthDisabled={popmenuConfig.facebookOauthDisabled}
                restaurantSlug={salesforceLead?.restaurant?.slug}
              />
            ) : (
              <LeadForm />
            )}
          </React.Fragment>
        );
      }}
    </Query>
  );
};

export default SignUpContainer;
