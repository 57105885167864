import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Query } from '~/lazy_apollo/client/react/components';
import { compose } from '@shakacode/recompose';

import { initAnalytics } from '~/utils/eventable';
import messageByTokenQuery from '../../libs/gql/queries/messages/messageByTokenQuery.gql';

import Loading from '../../shared/Loading';
import MessagePreview from './MessagePreview';
import { withPopmenuConfig } from '../../utils/withPopmenuConfig';
import { popmenuConfigShape } from '../../utils/popmenuConfigShape';

class MessagePreviewContainer extends PureComponent {
  componentDidMount() {
    initAnalytics({ adminApp: true, popmenuConfig: this.props.popmenuConfig });
  }

  render() {
    return (
      <Query
        query={messageByTokenQuery}
        variables={{ token: this.props.match.params.token }}
      >
        {({ data, loading }) => {
          if (loading || !data || !data.messageByToken) {
            return <Loading size="lg" />;
          }
          return (
            <MessagePreview
              messageHtmlUrl={data.messageByToken.messageHtmlUrl}
              messageContentHtml={data.messageByToken.messageContentHtml}
              windowHeightPercent={1.0}
            />
          );
        }}
      </Query>
    );
  }
}

MessagePreviewContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string,
    }),
  }).isRequired,
  popmenuConfig: popmenuConfigShape.isRequired,
};

export default compose(
  withPopmenuConfig,
  withRouter,
)(MessagePreviewContainer);
