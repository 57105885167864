
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "messageGroupsTabsAnnually": {
      "id": "message_packs.tabs.annually",
      "defaultMessage": "Annually",
    },
    "messageGroupsTabsMonthly": {
      "id": "message_packs.tabs.monthly",
      "defaultMessage": "Monthly",
    },
    "messageGroupsTabsOneTime": {
      "id": "message_packs.tabs.one_time",
      "defaultMessage": "One Time",
    },
    "messageGroupsTabsWeekly": {
      "id": "message_packs.tabs.weekly",
      "defaultMessage": "Weekly",
    },
  }
);
export default defaultMessages;

