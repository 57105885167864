
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "restaurantMembersDestroyConfirm": {
      "id": "restaurant_members.destroy_confirm",
      "defaultMessage": "Are you sure you want to remove this member?"
    },
    "restaurantMembersDestroyConfirmTitle": {
      "id": "restaurant_members.destroy_confirm_title",
      "defaultMessage": "Confirm Removal"
    },
    "restaurantMembersDestroy": {
      "id": "restaurant_members.destroy",
      "defaultMessage": "Remove Member"
    }
  }
);
export default defaultMessages;

