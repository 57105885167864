
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "messagesSelectedAudienceExclude": {
      "id": "messages.selected_audience_exclude",
      "defaultMessage": "Exclude"
    },
    "messagesSelectedAudienceExcludeLocations": {
      "id": "messages.selected_audience_exclude_locations",
      "defaultMessage": "Exclude locations"
    },
    "messagesSelectedAudienceTimezones": {
      "id": "messages.audience_timezones",
      "defaultMessage": "Included location(s) posting time"
    },
    "messagesSelectedAudienceTitle": {
      "id": "messages.audience_title",
      "defaultMessage": "Audience"
    },
    "messagesBackToEmailMessages": {
      "id": "messages.back_to_email_messages",
      "defaultMessage": "Back to email messages"
    },
    "messagesNew": {
      "id": "messages.new",
      "defaultMessage": "New Message"
    },
    "messagesEdit": {
      "id": "messages.edit",
      "defaultMessage": "Edit Message"
    },
    "messagesTabsAudienceTab": {
      "id": "messages.tabs.audience_tab",
      "defaultMessage": "Audience"
    },
    "messagesTabsAudienceSegmentTab": {
      "id": "messages.tabs.audience_segment_tab",
      "defaultMessage": "Audience & Segments"
    },
    "messagesTabsContentsTab": {
      "id": "messages.tabs.contents_tab",
      "defaultMessage": "Contents"
    },
    "messagesTabsMessageTab": {
      "id": "messages.tabs.message_tab",
      "defaultMessage": "Message"
    },
    "messagesTabsSmsMessageTab": {
      "id": "messages.tabs.sms_message_tab",
      "defaultMessage": "SMS Message"
    },
    "messagesTabsMmsMessageTab": {
      "id": "messages.tabs.mms_message_tab",
      "defaultMessage": "MMS Message"
    },
    "messagesTabsSettingsTab": {
      "id": "messages.tabs.settings_tab",
      "defaultMessage": "Settings"
    },
    "messagesDestroyConfirm": {
      "id": "messages.destroy_confirm",
      "defaultMessage": "Are you sure you want to cancel this Mass Message?"
    },
    "messagesLimitAlmostReachedTitle": {
      "id": "messages.limit_almost_reached_title",
      "defaultMessage": "You’re reaching the message limit for your account. There are {count} messages left."
    },
    "messagesLimitReachedTitle": {
      "id": "messages.limit_reached_title",
      "defaultMessage": "You have exceeded the message limit for your account."
    },
    "messagesLimitReachedDescription": {
      "id": "messages.limit_reached_description",
      "defaultMessage": "Your package includes {count} {type} emails per month. Upgrade to unlock unlimited {type} emails."
    },
    "messagesDestroyConfirmTitle": {
      "id": "messages.destroy_confirm_title",
      "defaultMessage": "Cancel Mass Message?"
    },
    "messagesIndex": {
      "id": "messages.index",
      "defaultMessage": "Messages"
    },
    "messagesDestroy": {
      "id": "messages.destroy",
      "defaultMessage": "Cancel Message"
    },
    "messagesUnsubscribers": {
      "id": "messages.unsubscribers",
      "defaultMessage": "{count} unsubscribed"
    },
    "messagesCollectingData": {
      "id": "messages.collecting_data",
      "defaultMessage": "Hang tight, we’re collecting data and building your overview"
    },
    "messagesProcessingDelay": {
      "id": "messages.processing_delay",
      "defaultMessage": "It can take about 5 mins after sending a message to complete processing."
    },
    "messagesCheckLater": {
      "id": "messages.check_later",
      "defaultMessage": "Check back later to see results"
    },
    "messagesOfferUrlFilterError": {
      "id": "messages.offer_url_filter_error",
      "defaultMessage": "Please add your offer value by selecting an offer using the offers icon"
    }
  }
);
export default defaultMessages;

