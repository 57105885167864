import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "cateringTitle": {
      "id": "catering.title",
      "defaultMessage": "Catering"
    },
    "cateringDescription": {
      "id": "catering.description",
      "defaultMessage": 'Help simplify catering orders for both you and your ' +
                        'guests by receiving them directly through online ' +
                        'ordering on your website.'
    }
  }
);

export default defaultMessages;
