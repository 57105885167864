
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "customFormsIndex": {
      "id": "custom_forms.index",
      "defaultMessage": "Forms"
    },
    "customFormsTitle": {
      "id": "custom_forms.title",
      "defaultMessage": "Forms"
    },
    "customFormsTooltip": {
      "id": "custom_forms.tooltip",
      "defaultMessage": "Edit Form"
    },
    "customFormsExportTitle": {
      "id": "custom_forms.export.title",
      "defaultMessage": "Export Form Responses"
    },
    "customFormsExportCsv": {
      "id": "custom_forms.export.csv",
      "defaultMessage": "Export to CSV"
    },
    "customFormsExportRecent": {
      "id": "custom_forms.export.recent",
      "defaultMessage": "Recent Exports..."
    },
    "customFormsExportBy": {
      "id": "custom_forms.export.by",
      "defaultMessage": "By {name} ({date})"
    },
    "customFormsExportDownload": {
      "id": "custom_forms.export.download",
      "defaultMessage": "Download Export"
    }
  }
);
export default defaultMessages;

