import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "leadsCompany": {
      "id": "leads.company",
      "defaultMessage": "Restaurant Name"
    },
    "leadsEmail": {
      "id": "leads.email",
      "defaultMessage": "Email"
    },
    "leadsFullName": {
      "id": "leads.full_name",
      "defaultMessage": "Full Name"
    },
    "leadsPhone": {
      "id": "leads.phone",
      "defaultMessage": "Phone"
    },
    "leadsState": {
      "id": "leads.state",
      "defaultMessage": "Which state is your restaurant located in?"
    },
  }
);
export default defaultMessages;
