import React from 'react';
import { ListItem, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { List } from '@popmenu/admin-ui';

import type { UserUnsubscribedRestaurantsProps } from './interfaces';

const UserUnsubscribedRestaurants = ({ user }: UserUnsubscribedRestaurantsProps) => (
  <React.Fragment>
    {user.isUnsubscribed ? (
      <Typography>
        <FormattedMessage id="user_unsubscribed.all_restaurants" defaultMessage="You have been unsubscribed from all restaurants" />
      </Typography>
    ) : (
      <React.Fragment>
        <Typography>
          <FormattedMessage id="user_unsubscribed" defaultMessage="You have been unsubscribed from the following restaurants:" />
          <List>
            {user.emailUnsubscribedRestaurants.map(restaurant => <ListItem>{restaurant.name}</ListItem>)}
          </List>
        </Typography>
      </React.Fragment>
    )}
  </React.Fragment>
);

export default UserUnsubscribedRestaurants;
