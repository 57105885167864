
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "approvableApprove": {
      "id": "approvable.approve",
      "defaultMessage": "Approve"
    },
    "approvableApproved": {
      "id": "approvable.approved",
      "defaultMessage": "Approved"
    },
    "approvableReject": {
      "id": "approvable.reject",
      "defaultMessage": "Reject"
    },
    "approvableRejected": {
      "id": "approvable.rejected",
      "defaultMessage": "Rejected"
    },
    "approvableTransfer": {
      "id": "approvable.transfer",
      "defaultMessage": "Transfer"
    },
    "approvableUndo": {
      "id": "approvable.undo",
      "defaultMessage": "Undo"
    },
    "approvablePending": {
      "id": "approvable.pending",
      "defaultMessage": "Pending"
    }
  }
);
export default defaultMessages;

