
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "onboardingAddBrandColor": {
      "id": "onboarding.add_brand_color",
      "defaultMessage": "Add Color",
    },
    "onboardingBrandColor": {
      "id": "onboarding.brand_color",
      "defaultMessage": "Color",
    },
    "onboardingComplete": {
      "id": "onboarding.complete",
      "defaultMessage": "Complete"
    },
    "onboardingCompleted": {
      "id": "onboarding.completed",
      "defaultMessage": "Completed"
    },
    "onboardingContent": {
      "id": "onboarding.content",
      "defaultMessage": "Content"
    },
    "onboardingContentAbout": {
      "id": "onboarding.content_about",
      "defaultMessage": "About Us"
    },
    "onboardingContentBlog": {
      "id": "onboarding.content_blog",
      "defaultMessage": "Blog"
    },
    "onboardingContentCatering": {
      "id": "onboarding.content_catering",
      "defaultMessage": "Catering"
    },
    "onboardingContentContactUs": {
      "id": "onboarding.content_contact_us",
      "defaultMessage": "Contact Us"
    },
    "onboardingContentEventsCalendar": {
      "id": "onboarding.content_events_calendar",
      "defaultMessage": "Events"
    },
    "onboardingContentExternalOrdering": {
      "id": "onboarding.content_external_ordering",
      "defaultMessage": "External Ordering & Delivery Services"
    },
    "onboardingContentGiftCard": {
      "id": "onboarding.content_gift_card",
      "defaultMessage": "Digital Gift Cards"
    },
    "onboardingContentHiring": {
      "id": "onboarding.content_hiring",
      "defaultMessage": "Hiring"
    },
    "onboardingContentLiveBeerList": {
      "id": "onboarding.content_live_beer_list",
      "defaultMessage": "Live Beer List"
    },
    "onboardingContentOlo": {
      "id": "onboarding.content_olo",
      "defaultMessage": "Online Ordering"
    },
    "onboardingContentOnlineStore": {
      "id": "onboarding.content_online_store",
      "defaultMessage": "Online Store"
    },
    "onboardingContentPhotoGallery": {
      "id": "onboarding.content_photo_gallery",
      "defaultMessage": "Photo Gallery"
    },
    "onboardingContentPressPage": {
      "id": "onboarding.content_press_page",
      "defaultMessage": "Press"
    },
    "onboardingContentPrivateEvent": {
      "id": "onboarding.content_private_event",
      "defaultMessage": "Private Events"
    },
    "onboardingContentPromotions": {
      "id": "onboarding.content_promotions",
      "defaultMessage": "Promotions"
    },
    "onboardingContentReservation": {
      "id": "onboarding.content_reservation",
      "defaultMessage": "Reservations"
    },
    "onboardingDomain": {
      "id": "onboarding.domain",
      "defaultMessage": "Domain"
    },
    "onboardingDomainTitle": {
      "id": "onboarding.domain_title",
      "defaultMessage": "Domain Support"
    },
    "onboardingEditMenu": {
      "id": "onboarding.edit_menu",
      "defaultMessage": "Edit Menu"
    },
    "onboardingEditFile": {
      "id": "onboarding.edit_file",
      "defaultMessage": "Edit File"
    },
    "onboardingFileName": {
      "id": "onboarding.file_name",
      "defaultMessage": "File Name"
    },
    "onboardingFileNameTooltip": {
      "id": "onboarding.file_name_tooltip",
      "defaultMessage": "Edit File Name"
    },
    "onboardingFontSaved": {
      "id": "onboarding.font_saved",
      "defaultMessage": "Fonts saved!"
    },
    "onboardingIsOloMenu": {
      "id": "onboarding.is_olo_menu",
      "defaultMessage": "Available for Popmenu Ordering",
    },
    "onboardingLinkPlaceholder": {
      "id": "onboarding.link_placeholder",
      "defaultMessage": "Type or paste URL"
    },
    "onboardingLogo": {
      "id": "onboarding.logo",
      "defaultMessage": "Logo"
    },
    "onboardingMenu": {
      "id": "onboarding.menus",
      "defaultMessage": "Menus"
    },
    "onboardingMenuSaved": {
      "id": "onboarding.menu_saved",
      "defaultMessage": "Menu saved!"
    },
    "onboardingMenuCategoryName": {
      "id": "onboarding.menu_category_name",
      "defaultMessage": "Type of Menu"
    },
    "onboardingModalStyleSummary": {
      "id": "onboarding.modal_style",
      "defaultMessage": "Site Styles"
    },
    "onboardingModalContent": {
      "id": "onboarding.modal_content",
      "defaultMessage": "Site Content"
    },
    "onboardingModalMenu": {
      "id": "onboarding.modal_menu",
      "defaultMessage": "Menus"
    },
    "onboardingMonday": {
      "id": "onboarding.monday",
      "defaultMessage": "Monday Integration"
    },
    "onboardingNotePlaceholder": {
      "id": "onboarding.note_placeholder",
      "defaultMessage": "Please add any special notes or requests about your file(s)."
    },
    "onboardingNote": {
      "id": "onboarding.note",
      "defaultMessage": "Note"
    },
    "onboardingNoteSaved": {
      "id": "onboarding.note_saved",
      "defaultMessage": "Note saved!"
    },
    "onboardingNoteSent": {
      "id": "onboarding.note_sent",
      "defaultMessage": "Note sent!"
    },
    "onboardingNotStarted": {
      "id": "onboarding.not_started",
      "defaultMessage": "Not Started",
    },
    "onboardingPending": {
      "id": "onboarding.pending",
      "defaultMessage": "Pending",
    },
    "onboardingPhotos": {
      "id": "onboarding.photos",
      "defaultMessage": "Photos"
    },
    "onboardingGoals": {
      "id": "onboarding.goals",
      "defaultMessage": "Goals"
    },
    "onboardingBusinessGoals": {
      "id": "onboarding.business_goals",
      "defaultMessage": "Business Goals"
    },
    "onboardingNoBusinessGoalsSelected": {
      "id": "onboarding.no_business_goals_selected",
      "defaultMessage": "No Businnes Goals Selected"
    },
    "onboardingRestaurant": {
      "id": "onboarding.restaurant",
      "defaultMessage": "Restaurant"
    },
    "onboardingStatus": {
      "id": "onboarding.status",
      "defaultMessage": "Status"
    },
    "onboardingSend": {
      "id": "onboarding.send",
      "defaultMessage": "Send"
    },
    "onboardingSettings": {
      "id": "onboarding.settings",
      "defaultMessage": "Onboarding Settings"
    },
    "onboardingStarted": {
      "id": "onboarding.started",
      "defaultMessage": "Started"
    },
    "onboardingSave": {
      "id": "onboarding.save",
      "defaultMessage": "Save"
    },
    "onboardingSaveFont": {
      "id": "onboarding.save_font",
      "defaultMessage": "Save Font"
    },
    "onboardingSaveNote": {
      "id": "onboarding.save_note",
      "defaultMessage": "Save Note"
    },
    "onboardingRequestNewImport": {
      "id": "onboarding.request_new_import",
      "defaultMessage": "Request New Import"
    },
    "onboardingStepContent": {
      "id": "onboarding.step_content",
      "defaultMessage": "Choose Site Content"
    },
    "onboardingStepDomain": {
      "id": "onboarding.step_domain",
      "defaultMessage": "Domain Setup"
    },
    "onboardingStepInfo": {
      "id": "onboarding.step_info",
      "defaultMessage": "Add Restaurant Info"
    },
    "onboardingStepInfoLocationTitle": {
      "id": "onboarding.step_info_location_title",
      "defaultMessage": "Location Info"
    },
    "onboardingStepInfoRestaurantTitle": {
      "id": "onboarding.step_info_restaurant_title",
      "defaultMessage": "Restaurant Info"
    },
    "onboardingStepLogo": {
      "id": "onboarding.step_logo",
      "defaultMessage": "Upload Logo"
    },
    "onboardingStepMenu": {
      "id": "onboarding.step_menu",
      "defaultMessage": "Add Menu"
    },
    "onboardingStepPhoto": {
      "id": "onboarding.step_photo",
      "defaultMessage": "Upload Photos"
    },
    "onboardingStepStyle": {
      "id": "onboarding.step_style",
      "defaultMessage": "Discover Site Styles"
    },
    "onboardingStyles": {
      "id": "onboarding.styles",
      "defaultMessage": "Styles"
    },
    "onboardingStyleNotePlaceholder": {
      "id": "onboarding.style_note_placeholder",
      "defaultMessage": "Please add any special notes or requests about your site styles."
    },
    "onboardingSummary": {
      "id": "onboarding.summary",
      "defaultMessage": "Summary"
    },
    "onboardingTabsGoogleFontsTab": {
      "id": "onboarding.tabs.google_fonts_tab",
      "defaultMessage": "Google Fonts"
    },
    "onboardingTabsImportTab": {
      "id": "onboarding.tabs.import_tab",
      "defaultMessage": "Imports"
    },
    "onboardingTabsUploadTab": {
      "id": "onboarding.tabs.upload_tab",
      "defaultMessage": "Files"
    },
    "onboardingTabsNoteTab": {
      "id": "onboarding.tabs.note_tab",
      "defaultMessage": "Note"
    },
    "onboardingTabsSummaryTab": {
      "id": "onboarding.tabs.summary_tab",
      "defaultMessage": "Summary"
    },
    "onboardingTabsUrlTab": {
      "id": "onboarding.tabs.links_tab",
      "defaultMessage": "Links"
    },
    "onboardingYourRoleStep": {
      "id": "onboarding.step.your_role",
      "defaultMessage": "Your Role"
    },
    "onboardingBusinessGoalsStep": {
      "id": "onboarding.step.business_goals",
      "defaultMessage": "Business Goals"
    },
    "onboardingBusinessGoalsStepAdditional": {
      "id": "onboarding.step.business_goals_additional",
      "defaultMessage": "This will help us to suggest the right tools for your business."
    },
    "onboardingBusinessGoalsStepDescribe": {
      "id": "onboarding.step.business_goals_describe",
      "defaultMessage": "What does your business need help with?"
    },
    "onboardingBusinessGoalsStepOption1": {
      "id": "onboarding.step.business_goals_opt_1",
      "defaultMessage": "A better looking website"
    },
    "onboardingBusinessGoalsStepOption2": {
      "id": "onboarding.step.business_goals_opt_2",
      "defaultMessage": "Increasing dine-in orders"
    },
    "onboardingBusinessGoalsStepOption3": {
      "id": "onboarding.step.business_goals_opt_3",
      "defaultMessage": "Increasing online orders"
    },
    "onboardingBusinessGoalsStepOption4": {
      "id": "onboarding.step.business_goals_opt_4",
      "defaultMessage": "Consolidating technology solutions"
    },
    "onboardingBusinessGoalsStepOption5": {
      "id": "onboarding.step.business_goals_opt_5",
      "defaultMessage": "Using technology to make up for a shortage of staff"
    },
    "onboardingBusinessGoalsStepOption6": {
      "id": "onboarding.step.business_goals_opt_6",
      "defaultMessage": "Gaining more followers"
    },
    "onboardingBusinessGoalsStepOption7": {
      "id": "onboarding.step.business_goals_opt_7",
      "defaultMessage": "Start taking reservations"
    },
    "onboardingBusinessGoalsStepOption8": {
      "id": "onboarding.step.business_goals_opt_8",
      "defaultMessage": "Other"
    },
    "onboardingAccountStep": {
      "id": "onboarding.step.account_details",
      "defaultMessage": "Account Details"
    },
    "onboardingRestaurantVibeStep": {
      "id": "onboarding.step.restaurant_vibe",
      "defaultMessage": "Restaurant Vibe"
    },
    "onboardingRestaurantVibeStepDescribe": {
      "id": "onboarding.step.restaurant_vibe_describe",
      "defaultMessage": "How would you describe your restaurant?"
    },
    "onboardingRestaurantVibeStepAdditional": {
      "id": "onboarding.step.restaurant_vibe_additional",
      "defaultMessage": "These additional details will show up on your website and help guests when online ordering."
    },
    "onboardingRestaurantVibeStepServiceType": {
      "id": "onboarding.step.restaurant_vibe_service_type",
      "defaultMessage": "What is your service type?"
    },
    "onboardingRestaurantVibeStepAttire": {
      "id": "onboarding.step.restaurant_vibe_attire",
      "defaultMessage": "What is your attire?"
    },
    "onboardingRestaurantVibeStepPriceRange": {
      "id": "onboarding.step.restaurant_vibe_price_range",
      "defaultMessage": "What is your price range?"
    },
    "onboardingTemplateStep": {
      "id": "onboarding.step.choose_template",
      "defaultMessage": "Choose a Template"
    },
    "onboardingTemplateStepButtonText": {
      "id": "onboarding.step.choose_template.button",
      "defaultMessage": "I’ll do this later"
    },
    "onboardingTemplateStepPrompt": {
      "id": "onboarding.step.choose_template.prompt",
      "defaultMessage": "Choose a website template for your restaurant"
    },
    "onboardingTemplateStepSubPrompt": {
      "id": "onboarding.step.choose_template.sub_prompt",
      "defaultMessage": "These designs aren’t final, we just want to see what you like! We’ll further customize the look of your website to match your brand."
    },
    "onboardingTemplateStepPreviewTemplate": {
      "id": "onboarding.step.choose_template.preview_template",
      "defaultMessage": "Preview Template"
    },
    "onboardingTemplateStepSelectTemplate": {
      "id": "onboarding.step.choose_template.select_template",
      "defaultMessage": "Select this template"
    },
    "onboardingTemplateStepTemplateSelected": {
      "id": "onboarding.step.choose_template.template_selected",
      "defaultMessage": "Template Selected"
    },
    "onboardingBrandStep": {
      "id": "onboarding.step.import_brand",
      "defaultMessage": "Import your Brand"
    },
    "onboardingBrandStepButton": {
      "id": "onboarding.step.brand_step.button",
      "defaultMessage": "I'll do this later"
    },
    "onboardingBrandStepHeading": {
      "id": "onboarding.step.brand_step.heading",
      "defaultMessage": "Import your brand"
    },
    "onboardingBrandStepSubeading": {
      "id": "onboarding.step.brand_step.subheading",
      "defaultMessage": "Enter your website URL and social media and we'll import your logo, images, brand colors and fonts to get a head start on building your website."
    },
    "onboardingBrandStepWebsite": {
      "id": "onboarding.step.brand_step.website",
      "defaultMessage": "Website"
    },
    "onboardingBrandStepNoWebsite": {
      "id": "onboarding.step.brand_step.no_website",
      "defaultMessage": "No Website"
    },
    "onboardingBrandStepNoLinks": {
      "id": "onboarding.step.brand_step.no_links",
      "defaultMessage": "No Links"
    },
    "onboardingBrandStepCurrentWebsite": {
      "id": "onboarding.step.brand_step.current_website",
      "defaultMessage": "Your current website URL"
    },
    "onboardingBrandStepSocialHandles": {
      "id": "onboarding.step.brand_step.social_handles",
      "defaultMessage": "Social Handles"
    },
    "onboardingBrandStepSocialMedia": {
      "id": "onboarding.step.brand_step.social_media",
      "defaultMessage": "Social Media"
    },
    "onboardingBrandStepInstagram": {
      "id": "onboarding.step.brand_step.instagram",
      "defaultMessage": "Instagram"
    },
    "onboardingBrandStepFacebook": {
      "id": "onboarding.step.brand_step.facebook",
      "defaultMessage": "Facebook"
    },
    "onboardingBrandStepCloudDrive": {
      "id": "onboarding.step.brand_step.cloud_drive",
      "defaultMessage": "Cloud Drive"
    },
    "onboardingBrandStepCloudDriveLinks": {
      "id": "onboarding.step.brand_step.cloud_drive_links",
      "defaultMessage": "Cloud Drive Links"
    },
    "onboardingBrandStepCloudDriveLinksDesctiption": {
      "id": "onboarding.step.brand_step.cloud_drive_links_description",
      "defaultMessage": "This can be Google Drive, Dropbox, etc."
    },
    "onboardingYourNotesStep": {
      "id": "onboarding.step.notes",
      "defaultMessage": "Notes"
    },
    "onboardingYourNotesStepSkipp": {
      "id": "onboarding.step.notes_skip",
      "defaultMessage": "Skip"
    },
    "onboardingYourNotesStepHeading": {
      "id": "onboarding.step.notes.heading",
      "defaultMessage": "Anything else we should know?"
    },
    "onboardingYourNotesStepSubHeading": {
      "id": "onboarding.step.notes.subheading",
      "defaultMessage": "Add anything else you’d like us to know about your brand and what you’d like to see in your Popmenu website below."
    },
    "onboardingYourNotesStepFieldTitle": {
      "id": "onboarding.step.notes.field_title",
      "defaultMessage": "Notes (optional)"
    },
    "onboardingFinishStepGreatJob": {
      "id": "onboarding.step.finish_step_great_job",
      "defaultMessage": "Great Job!"
    },
    "onboardingWelcomeName": {
      "id": "onboarding.welcome_name",
      "defaultMessage": "Welcome {name}!"
    },
    "onboardingWelcomeFirstLine": {
      "id": "onboarding.welcome_first_line",
      "defaultMessage": "Our goal at Popmenu is to help you grow your business by partnering with you. We’ll need a few things from you before we can start building your site."
    },
    "onboardingWelcomeSecondLine": {
      "id": "onboarding.welcome_second_line",
      "defaultMessage": "We’ll save your work along the way even if you have to step away.",
    },
    "onboardingYourRoleStepWhatsRole": {
      "id": "onboarding.step.your_role.whats_role",
      "defaultMessage": "What’s your role?"
    },
    "onboardingYourRoleStepSubDescription": {
      "id": "onboarding.step.your_role.sub_description",
      "defaultMessage": "This won’t affect your permissions, we just want to know more about you."
    },
    "onboardingYourRoleStepOwner": {
      "id": "onboarding.step.your_role.owner",
      "defaultMessage": "Owner"
    },
    "onboardingYourRoleStepManager": {
      "id": "onboarding.step.your_role.manager",
      "defaultMessage": "Manager"
    },
    "onboardingYourRoleStepMarketer": {
      "id": "onboarding.step.your_role.marketer",
      "defaultMessage": "Marketer"
    },
    "onboardingYourRoleStepWebDesigner": {
      "id": "onboarding.step.your_role.web_designer",
      "defaultMessage": "Web Designer"
    },
    "onboardingYourRoleStepFrontHouse": {
      "id": "onboarding.step.your_role.front_house",
      "defaultMessage": "Front of House"
    },
    "onboardingYourRoleStepOther": {
      "id": "onboarding.step.your_role.other",
      "defaultMessage": "Other"
    },
    "onboardingAccountStepHeading": {
      "id": "onboarding.step.account.heading",
      "defaultMessage": "Confirm or update your restaurant details"
    },
    "onboardingAccountStepSubheading": {
      "id": "onboarding.step.account.subheading",
      "defaultMessage": "We've imported your restaurant details for your convenience. Take a moment to confirm the information we have is correct."
    },
    "onboardingAccountStepMainContactPerson": {
      "id": "onboarding.step.account.main_contact_person",
      "defaultMessage": "Main Contact Person"
    },
    "onboardingAccountStepEmail": {
      "id": "onboarding.step.account.email",
      "defaultMessage": "Email"
    },
    "onboardingAccountStepPhone": {
      "id": "onboarding.step.account.phone",
      "defaultMessage": "Contact Phone Number"
    },
    "onboardingAccountStepYearEstablished": {
      "id": "onboarding.step.account.year_established",
      "defaultMessage": "Year Established"
    },
    "onboardingAccountStepDescription": {
      "id": "onboarding.step.account.description",
      "defaultMessage": "Please enter a brief description for your restaurant"
    },
    "onboardingFinishWoohoo": {
      "id": "onboarding.finish_woohoo",
      "defaultMessage": "Woohoo, looking good!"
    },
    "onboardingFinishSecondLine": {
      "id": "onboarding.finish_second_line",
      "defaultMessage": "Our team will start working on your site build."
    },
  }
);
export default defaultMessages;
