
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "dishLibraryDishes": {
      "id": "dish_library.items",
      "defaultMessage": "Items"
    },
    "dishLibrarySearchItems": {
      "id": "dish_library.search_items",
      "defaultMessage": "Search items"
    }
  }
);
export default defaultMessages;

