import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "itemsNew": {
      "id": "items.new",
      "defaultMessage": "New Item"
    },
    "itemsSettingsTabsAppearsOn": {
      "id": "items.settings.tabs.appears",
      "defaultMessage": "Appears On..."
    },
    "itemsSettingsTabsModifiers": {
      "id": "items.settings.tabs.modifiers",
      "defaultMessage": "Modifiers"
    },
    "itemsSettingsTabsGeneral": {
      "id": "items.settings.tabs.general",
      "defaultMessage": "General"
    },
    "itemsSettingsTabsHistory": {
      "id": "items.settings.tabs.history",
      "defaultMessage": "History"
    },
    "itemsSettingsTabsHtml": {
      "id": "items.settings.tabs.html",
      "defaultMessage": "Content"
    },
    "itemsSettingsTabsCrossSelling": {
      "id": "items.settings.tabs.cross-selling",
      "defaultMessage": "Cross-Selling"
    },
    "itemsSettingsTabsReviews": {
      "id": "items.settings.tabs.reviews",
      "defaultMessage": "Reviews"
    },
    "itemsSettingsTabsSettings": {
      "id": "items.settings.tabs.settings",
      "defaultMessage": "Settings"
    },
    "itemsSettingsTabsStats": {
      "id": "items.settings.tabs.stats",
      "defaultMessage": "Stats"
    },
    "itemsSettingsTabsSquare": {
      "id": "items.settings.tabs.menu_sync",
      "defaultMessage": "Sync"
    },
    "itemsSettingsTitle": {
      "id": "items.settings.title",
      "defaultMessage": "{name} Settings"
    },
    "itemsDestroy": {
      "id": "items.destroy",
      "defaultMessage": "Delete Item"
    },
    "itemsDestroyConfirm": {
      "id": "items.destroy_confirm",
      "defaultMessage": "Are you sure you want to delete this item? The item will be removed from all menus, and all related pops and reviews will be lost."
    },
    "itemsDestroyConfirmTitle": {
      "id": "items.destroy_confirm_title",
      "defaultMessage": "Delete Item"
    },
    "itemsEmptyIndex": {
      "id": "items.empty_index",
      "defaultMessage": "Add an item to begin building your menus."
    },
    "itemsSharesTitle": {
      "id": "items.shares.title",
      "defaultMessage": "Share"
    },
    "itemsSharesMessage": {
      "id": "items.shares.message",
      "defaultMessage": "New Mass Message"
    },
    "itemsSharesSocial": {
      "id": "items.shares.social",
      "defaultMessage": "New Social Post"
    },
    "itemsSaveConfirmMessage": {
      "id": "items.save_confirm_message",
      "defaultMessage": "Are you sure you want to apply the settings for this items to all menus?"
    },
    "itemsSaveConfirmTitle": {
      "id": "items.save_confirm_title",
      "defaultMessage": "Apply Item Settings"
    },
    "itemsItemTags": {
      "id": "items.item_tags",
      "defaultMessage": "Item Tags"
    },
    "itemsIndex": {
      "id": "items.index",
      "defaultMessage": "Item List"
    },
    "itemsItem": {
      "id": "items.item",
      "defaultMessage": "Item"
    },
    "itemsShare": {
      "id": "items.share",
      "defaultMessage": "Share this item!"
    },
    "itemsPopsCount": {
      "id": "items.pops_count",
      "defaultMessage": "({count}) "
    },
    "itemsReviewsCount": {
      "id": "items.reviews_count",
      "defaultMessage": "({count}) "
    },
    "itemsReviews": {
      "id": "items.reviews",
      "defaultMessage": "Reviews ({count})"
    },
    "itemsAdminShow": {
      "id": "items.admin_show",
      "defaultMessage": "View Item"
    },
    "itemsCloseConfirmMessage": {
      "id": "items.close_confirm_message",
      "defaultMessage": "You have unsaved changes. Are you sure you want to exit?"
    },
    "itemsCreated": {
      "id": "items.created",
      "defaultMessage": "Item successfully added!"
    },
    "itemsCreatedError": {
      "id": "items.created_error",
      "defaultMessage": "There was an error adding the item."
    },
    "itemsEdit": {
      "id": "items.edit",
      "defaultMessage": "Edit Item"
    },
    "itemsUpdated": {
      "id": "items.updated",
      "defaultMessage": "Item successfully updated!"
    },
    "itemsUpdatedError": {
      "id": "items.updated_error",
      "defaultMessage": "There was an error updating the item."
    },
    "itemsUpdatedStatus": {
      "id": "items.updated_status",
      "defaultMessage": "Update In Progress"
    },
    "itemsDestroyed": {
      "id": "items.destroyed",
      "defaultMessage": "Item successfully deleted."
    },
    "itemsDestroyedError": {
      "id": "items.destroyed_error",
      "defaultMessage": "There was an error deleting the item."
    },
    "dishesMergeQueuedMessage": {
      "id": "dishes.merge.queued_message",
      "defaultMessage": "Dish merge has been queued for processing."
    },
    "dishesMergeTitle": {
      "id": "dishes.merge.title",
      "defaultMessage": "Merge Dish"
    },
    "dishesMergeSelectDish": {
      "id": "dishes.merge.select_dish",
      "defaultMessage": "Select a Dish"
    },
    "dishesMergeNoDishes": {
      "id": "dishes.merge.no_dishes",
      "defaultMessage": "Search for a dish to merge"
    },
    "dishesMergeCompareDish": {
      "id": "dishes.merge.compare_dish",
      "defaultMessage": "Compare Dish"
    },
    "dishesMergeConfirmation": {
      "id": "dishes.merge.confirmation",
      "defaultMessage": "Confirm Merge"
    },
    "dishesMergeConfirm": {
      "id": "dishes.merge.finish_merge",
      "defaultMessage": "Merge"
    },
    "itemsUpdating": {
      "id": "items.updating",
      "defaultMessage": "Updating"
    },
    "itemsUpdatedItem": {
      "id": "items.updated_item",
      "defaultMessage": "Updated"
    },
    "itemsExtrasOutOfStock": {
      "id": "items.extras.out_of_stock",
      "defaultMessage": "Out of Stock Modifiers"
    },
    "itemsTotalReviews": {
      "id": "items.total_reviews",
      "defaultMessage": "Total Reviews"
    },
    "itemsTotalPops": {
      "id": "items.total_pops",
      "defaultMessage": "Total Pops"
    },
    "itemsOutOfStockUntil": {
      "id": "items.out_of_stock_until",
      "defaultMessage": "Until {date}"
    },
    "itemsUpdateInProgress": {
      "id": "items.update_in_progress",
      "defaultMessage": "Item update in progress!"
    },
    "itemsUpdateInProgressMessage": {
      "id": "items.update_in_progress_message",
      "defaultMessage": "Changes being made to selected items on various menus and locations. You can close out of this window or wait for it to close automatically."
    },
    "itemsNotFound": {
      "id": "items.not_found",
      "defaultMessage": "No items found."
    },
  }
);
export default defaultMessages;
