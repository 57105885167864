import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "dineInTitle": {
      "id": "dine_in.title",
      "defaultMessage": "Contactless Dine-In"
    },
    "cateringDescription": {
      "id": "dine_in.description",
      "defaultMessage": 'Dine-in features allow guests to scan a QR code, browse ' +
                        'your menu, open a tab, fire orders to the kitchen and ' +
                        'pay for their meal—all from their phone. Give the gift ' +
                        'of speed, ease and convenience to your guests, as well ' +
                        'as a more streamlined and efficient process to your staff.',
    },
    "dineInCloseTabModalCloseTab": {
      "id": "dine_in.close_tab_modal.close_tab",
      "defaultMessage": "Close Tab"
    },
    "dineInCloseTabModalPaymentPrompt": {
      "id": "dine_in.close_tab_modal.payment_prompt",
      "defaultMessage": "Would you like to close this tab and capture the payment?"
    },
    "dineInCloseTabModalNoPaymentPrompt": {
      "id": "dine_in.close_tab_modal.no_payment_prompt",
      "defaultMessage": "There is no payment method attached to this tab, are you sure you want to close it?"
    },
    "dineInCloseTabModalPaymentDescriptionWithAutoTip": {
      "id": "dine_in.close_tab_modal.payment_description_with_auto_tip",
      "defaultMessage": "Total: {total} (Includes {tip_percentage}% Auto-Tip of {tip_amount})"
    },
    "dineInCloseTabModalPaymentDescription": {
      "id": "dine_in.close_tab_modal.payment_description_with_auto_tip",
      "defaultMessage": "Total: {total}"
    },
    "dineInCloseTabModalNoPaymentDescription": {
      "id": "dine_in.close_tab_modal.no_payment_description",
      "defaultMessage": "No payment will be captured"
    }
  }
);

export default defaultMessages;
