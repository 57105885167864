
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "SharedComponentsColorPickerContrastRatio": {
      "id": "shared_components.colorpicker.contrast_ratio",
      "defaultMessage": "{contrastRatio} on {friendlyName} background"
    },
    "SharedComponentsColorPickerAdaCompliance": {
      "id": "shared_components.colorpicker.ada_compliance",
      "defaultMessage": "Color chosen does not pass ADA compliance testing"
    }
  },
);
export default defaultMessages;

