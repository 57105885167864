
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "photosNewContribute": {
      "id": "photos.new_contribute",
      "defaultMessage": "Add Your Photo"
    }
  }
);
export default defaultMessages;

