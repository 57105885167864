
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "rootSiteTemplatesNew": {
      "id": "root_site_templates.new",
      "defaultMessage": "Add Site Template"
    },
    "rootSiteTemplatesEdit": {
      "id": "root_site_templates.edit",
      "defaultMessage": "Edit Site Template"
    },
    "rootSiteTemplatesThumbnail": {
      "id": "root_site_templates.thumbnail",
      "defaultMessage": "Thumbnail"
    }
  }
);
export default defaultMessages;

