
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "restaurantEmbedsDestroy": {
      "id": "restaurant_embeds.destroy",
      "defaultMessage": "Remove"
    },
    "restaurantEmbedsDestroyConfirm": {
      "id": "restaurant_embeds.destroy_confirm",
      "defaultMessage": "Are you sure want to remove this embed?"
    },
    "restaurantEmbedsDestroyConfirmTitle": {
      "id": "restaurant_embeds.destroy_confirm_title",
      "defaultMessage": "Confirm Removal"
    },
    "restaurantEmbedsPreview": {
      "id": "restaurant_embeds.preview",
      "defaultMessage": "Preview"
    },
    "restaurantEmbedsShow": {
      "id": "restaurant_embeds.show",
      "defaultMessage": "Embed Code"
    },
    "restaurantEmbedsNew": {
      "id": "restaurant_embeds.new",
      "defaultMessage": "New Embed"
    },
    "restaurantEmbedsIndex": {
      "id": "restaurant_embeds.index",
      "defaultMessage": "Embeds"
    }
  }
);
export default defaultMessages;

