
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "offersActivate": {
      "id": "offers.activate",
      "defaultMessage": "Activate"
    },
    "offersTypeNone": {
      "id": "offers.types.general",
      "defaultMessage": "General Offer"
    },
    "offersTypeOnce": {
      "id": "offers.types.bxgy",
      "defaultMessage": "Buy This, Get That"
    },
    "offersTypeFreeWith": {
      "id": "offers.types.free_with_purchase",
      "defaultMessage": "Free Gift with Purchase"
    },
    "offersTypeFreeWithout": {
      "id": "offers.types.free_without_purchase",
      "defaultMessage": "Free Gift without Purchase"
    },
    "offersApply": {
      "id": "offers.apply",
      "defaultMessage": "What do you want this offer to apply to?"
    },
    "offersNamePlaceholder": {
      "id": "offers.name_placeholder",
      "defaultMessage": "e.g. Free Appetizer"
    },
    "offersContentPlaceholder": {
      "id": "offers.content_placeholder",
      "defaultMessage": "e.g. Get $10 off when you place an order of at least $50"
    },
    "offersCreateOrderingOffer": {
      "id": "offers.create_ordering_offer",
      "defaultMessage": "Create Online Offer"
    },
    "offersContent": {
      "id": "offers.content",
      "defaultMessage": "Offer Content"
    },
    "offersBackToList": {
      "id": "offers.back_to_list",
      "defaultMessage": "Back to List"
    },
    "offersCreateDineinOffer": {
      "id": "offers.create_dinein_offer",
      "defaultMessage": "Create Dine-In Offer"
    },
    "offersDetails": {
      "id": "offers.details",
      "defaultMessage": "Offer Details"
    },
    "offersBxgy": {
      "id": "offers.bxgy",
      "defaultMessage": "Choose Items"
    },
    "offersCreate": {
      "id": "offers.create",
      "defaultMessage": "Create Offer"
    },
    "offersFormat": {
      "id": "offers.format",
      "defaultMessage": "Offer Format"
    },
    "offersIndex": {
      "id": "offers.index",
      "defaultMessage": "Offers"
    },
    "offersNew": {
      "id": "offers.new",
      "defaultMessage": "Add Offer"
    },
    "offersNewDineIn": {
      "id": "offers.new_dine_in",
      "defaultMessage": "On Premise Dine-in Offer"
    },
    "offersNewOrderingOffer": {
      "id": "offers.new_ordering_offer",
      "defaultMessage": "Add Online Offer"
    },
    "offersSummary": {
      "id": "offers.summary",
      "defaultMessage": "Offer Summary"
    },
    "offersChooseItem": {
      "id": "offers.choose_item",
      "defaultMessage": "Choose Item"
    },
    "offersRedeem": {
      "id": "offers.redeem",
      "defaultMessage": "Redeem Offer"
    },
    "offersEdit": {
      "id": "offers.edit",
      "defaultMessage": "Edit Offer"
    },
    "offersDestroyConfirm": {
      "id": "offers.destroy_confirm",
      "defaultMessage": "Are you sure you want to remove this offer? Existing codes will be invalidated."
    },
    "offersDestroyConfirmTitle": {
      "id": "offers.destroy_confirm_title",
      "defaultMessage": "Confirm Removal"
    },
    "offersDestroy": {
      "id": "offers.destroy",
      "defaultMessage": "Remove Offer"
    },
    "offersScheduleType": {
      "id": "offers.schedule.types",
      "defaultMessage": "Schedule"
    },
    "offersScheduleStartDate": {
      "id": "offers.schedule.start_date",
      "defaultMessage": "Start Date"
    },
    "offersScheduleStartDateHelperText": {
      "id": "offers.schedule.start_date_helper_text",
      "defaultMessage": "Start date will use the offer creation date if left blank"
    },
    "offersScheduleEndDate": {
      "id": "offers.schedule.end_date",
      "defaultMessage": "End Date"
    },
    "offersScheduleEndDateHelperText": {
      "id": "offers.schedule.end_date_helper_text",
      "defaultMessage": "You must manually disable the offer if no end date is selected"
    },
    "offersScheduleTypeNone": {
      "id": "offers.schedule.types.none",
      "defaultMessage": "No schedule"
    },
    "offersScheduleTypeDaily": {
      "id": "offers.schedule.types.daily",
      "defaultMessage": "Recurring daily"
    },
    "offersScheduleTypeWeekly": {
      "id": "offers.schedule.types.weekly",
      "defaultMessage": "Recurring weekly"
    },
    "offersScheduleTypeBiWeekly": {
      "id": "offers.schedule.types.biweekly",
      "defaultMessage": "Recurring bi-weekly"
    },
    "offersScheduleTypeMonthly": {
      "id": "offers.schedule.types.monthly",
      "defaultMessage": "Recurring monthly"
    },
    "offersScheduleRecurrencyFromTime": {
      "id": "offers.schedule.recurrency.from_time",
      "defaultMessage": "Start hour"
    },
    "offersScheduleRecurrencyUntilTime": {
      "id": "offers.schedule.recurrency.until_time",
      "defaultMessage": "End hour"
    },
    "offersCreateBxgyOffer": {
      "id": "offers.create_bxgy_offer",
      "defaultMessage": "Create BXGY Offer"
    },
    "marketingOffersActivate": {
      "id": "marketing_offers.activate",
      "defaultMessage": "Activate"
    },
    "marketingOffersAttachOffer": {
      "id": "marketing_offers.attach_offer",
      "defaultMessage": "Attach Offer"
    },
    "marketingOffersMarketOffer": {
      "id": "marketing_offers.promote_offer",
      "defaultMessage": "Offer Promotion"
    },
    "marketingOffersDeactivate": {
      "id": "marketing_offers.promotion_deactivate",
      "defaultMessage": "Deactivate Promotion Offer"
    },
    "marketingOffersContent": {
      "id": "marketing_offers.promotion_content",
      "defaultMessage": "Offer Promotion Content"
    },
    "marketingOffersPreview": {
      "id": "marketing_offers.promotion_preview",
      "defaultMessage": "Offer Promotion Preview"
    },
    "marketingOffersSummary": {
      "id": "marketing_offers.summary",
      "defaultMessage": "Offer Promotion Summary"
    },
    "offerModalSearchOffers": {
      "id": "offer_modal.search_offers",
      "defaultMessage": "Search Offers"
    },
  }
);
export default defaultMessages;

