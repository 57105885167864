
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "marketingTabsBuilderTab": {
      "id": "marketing.tabs.builder_tab",
      "defaultMessage": "Campaign Builder"
    },
    "marketingTabsReportsTab": {
      "id": "marketing.tabs.reports_tab",
      "defaultMessage": "Reports"
    },
    "marketingCampaignName": {
      "id": "marketing.campaign_name",
      "defaultMessage": "Campaign Name"
    },
  }
);
export default defaultMessages;

