
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "aiMarketingDashboardTitle": {
      "id": "ai_marketing.dashboard.title",
      "defaultMessage": "AI Marketing Planner"
    },
    "aiMarketingDashboardNew": {
      "id": "ai_marketing.dashboard.new",
      "defaultMessage": "✨New"
    },
    "aiMarketingDashboardNeedsApproval": {
      "id": "ai_marketing.dashboard.needs_approval",
      "defaultMessage": "{approvalCount} need{s} approval"
    },
    "aiMarketingDashboardApprovalCompleted": {
      "id": "ai_marketing.dashboard.all_approved",
      "defaultMessage": "All approved"
    },
    "aiMarketingDashboardAllReviewed": {
      "id": "ai_marketing.dashboard.all_reviewed",
      "defaultMessage": "All reviewed"
    },
    "aiMarketingDashboardContentCounter": {
      "id": "ai_marketing.dashboard.content_counter",
      "defaultMessage": "{messageSquareIcon}{socialPostsCount} post{socialPostsCountS}, {mailIcon}{messageGroupsCount} email{messageGroupsCountS}, {smartphoneIcon}{textMessageGroupsCount} text{textMessageGroupsCountS}"
    },
    "aiMarketingDashboardReviewContent": {
      "id": "ai_marketing.dashboard.review_content",
      "defaultMessage": "Review Content"
    },
    "aiMarketingDashboardSeePlanDetails": {
      "id": "ai_marketing.dashboard.see_plan_details",
      "defaultMessage": "See Plan Details"
    },
    "aiMarketingDashboardProcessed": {
      "id": "ai_marketing.dashboard.processed",
      "defaultMessage": "{processedCount} processed"
    },
    "aiMarketingContentStepperScheduleDatePassed": {
      "id": "ai_marketing.content_stepper.schedule_date_passed",
      "defaultMessage": "Schedule date has passed. Select a new date to approve this content."
    },
    "aiMarketingContentStepperTextMessageGroupHeader": {
      "id": "ai_marketing.content_stepper.text_message_group_header",
      "defaultMessage": "Text Message"
    },
    "aiMarketingContentStepperTextMessageGroupSubheader": {
      "id": "ai_marketing.content_stepper.text_message_group_subheader",
      "defaultMessage": "Confirm schedule date and content body"
    },
    "aiMarketingIntroStepperIntro1NextButton": {
      "id": "ai_marketing.intro_stepper.intro_1.next_button",
      "defaultMessage": "Next: How reviewing content works"
    },
    "aiMarketingIntroStepperIntro2NextButton": {
      "id": "ai_marketing.intro_stepper.intro_2.finish_button",
      "defaultMessage": "Next: Start content review"
    },
    "aiMarketingStepperBackButton": {
      "id": "ai_marketing.stepper.back_button",
      "defaultMessage": "Back"
    },
    "aiMarketingStepperCancelButton": {
      "id": "ai_marketing.stepper.cancel_button",
      "defaultMessage": "Skip for later"
    },
    "aiMarketingStepperFinishButton": {
      "id": "ai_marketing.stepper.finish_button",
      "defaultMessage": "Finish"
    },
    "aiMarketingStepperApproveButton": {
      "id": "ai_marketing.stepper.approve_button",
      "defaultMessage": "Approve"
    },
    "aiMarketingStepperFinishApproveButton": {
      "id": "ai_marketing.stepper.finish_approve_button",
      "defaultMessage": "Approve & finish"
    },
    "aiMarketingStepperNextButton": {
      "id": "ai_marketing.stepper.next_button",
      "defaultMessage": "Next"
    },
    "aiMarketingStepperNextApproveButton": {
      "id": "ai_marketing.stepper.next_approve_button",
      "defaultMessage": "Approve & next content"
    },
    "aiMarketingStepperSkipButton": {
      "id": "ai_marketing.stepper.skip_button",
      "defaultMessage": "Skip for later"
    },
    "aiMarketingStepperFinishSummaryButton": {
      "id": "ai_marketing.stepper.finish_summary_button",
      "defaultMessage": "Confirm and schedule"
    },
    "aiMarketingStepperRejectButton": {
      "id": "ai_marketing.stepper.reject_button",
      "defaultMessage": "Reject"
    },
    "aiMarketingStepperRejectSuccess": {
      "id": "ai_marketing.stepper.reject_success",
      "defaultMessage": "This content has been rejected successfully."
    },
    "aiMarketingAppliedFilterStatusApproved": {
      "id": "ai_marketing.applied_filter.status.approved",
      "defaultMessage": "Approved"
    },
    "aiMarketingAppliedFilterStatusDraft": {
      "id": "ai_marketing.applied_filter.status.draft",
      "defaultMessage": "Needs Approval"
    },
    "aiMarketingAppliedFilterStatusRejected": {
      "id": "ai_marketing.applied_filter.status.rejected",
      "defaultMessage": "Rejected"
    },
    "aiMarketingAppliedFilterStatusProcessed": {
      "id": "ai_marketing.applied_filter.status.processed",
      "defaultMessage": "Published"
    },
    "aiMarketingAppliedFilterContentTypeMessageGroup": {
      "id": "ai_marketing.applied_filter.content_type.message_group",
      "defaultMessage": "Email Message"
    },
    "aiMarketingAppliedFilterContentTypeSocialPost": {
      "id": "ai_marketing.applied_filter.content_type.social_post",
      "defaultMessage": "Social Post"
    },
    "aiMarketingAppliedFilterContentTypeTextMessageGroup": {
      "id": "ai_marketing.applied_filter.content_type.text_message_group",
      "defaultMessage": "Text Message"
    },
    "aiMarketingAppliedFilterIsCreatedByAiFalse": {
      "id": "ai_marketing.applied_filter.is_created_by_ai.false",
      "defaultMessage": "User"
    },
    "aiMarketingAppliedFilterIsCreatedByAiTrue": {
      "id": "ai_marketing.applied_filter.is_created_by_ai.true",
      "defaultMessage": "AI Marketing"
    },
  }
);
export default defaultMessages;
