// This component is admin-only. Headings order checks may be disabled. See AccessibleHeading.jsx
/* eslint-disable regex/invalid */
import React from 'react';
import { Box, Icon, Typography } from '@popmenu/common-ui';

import MagicLinkSVG from '../../assets/svg/magic_link.svg';

/* eslint-disable react/jsx-no-literals */
const SetupLinkContainer = () => (
  <React.Fragment>
    <Box textAlign="center">
      <Icon icon={MagicLinkSVG} style={{ height: '160px', width: '160px' }} />
    </Box>
    <Typography align="center" gutterBottom variant="h4">
      {'Email Sent!'}
    </Typography>
    <Typography align="center" gutterBottom>
      {"We're excited to have you at Popmenu!"}
    </Typography>
    <Typography align="center">
      {'Look out for another email to confirm your contact info and set up your account.'}
    </Typography>
  </React.Fragment>
);
/* eslint-enable react/jsx-no-literals */

export default SetupLinkContainer;
