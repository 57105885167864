import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Query } from '~/lazy_apollo/client/react/components';
import { Helmet } from 'react-helmet';

import { PopmenuConfigContext } from '~/utils/withPopmenuConfig';
import { AbilityContext } from '~/utils/withAbility';
import { toggleClass } from '../utils/dom';
import initAbility from '../utils/initAbility';
import { withIntl } from '../utils/withIntl';
import { DarkThemeProvider, ThemeProvider } from '../utils/withStyles';
import { CurrentSessionContextProvider } from '../shared/CurrentSessionProvider';
import adminSessionQuery from '../libs/gql/queries/users/adminSessionQuery.gql';

import AdminParentContainer from './AdminContainer/AdminParentContainer.imports-loadable';
import GenericContainer from './GenericContainer/GenericContainer';
import MessagePreviewContainer from './messages/MessagePreviewContainer';
import OAuthRestaurantSelection from '../oauth/OAuthRestaurantSelection';
import PopmenuLoading from './shared/PopmenuLoading';
import SignUpContainer from './sign-up/SignUpContainer';
import StatusPrompt from './shared/StatusPrompt';
import VersionCheck from './shared/version_check';
import { AdminPageChangeHandler } from './AdminPageChangeHandler';

// Set theme class on body
const AdminBackgroundToggle = ({ session }) => {
  useLayoutEffect(() => {
    if (typeof document !== 'undefined' && document.body) {
      toggleClass(document.body, 'pm-dark-theme', !!(session && session.user && session.user.adminPaletteType === 'admin_palette_dark'));
    }
  });
  return null;
};

const AdminSwitch = props => (
  <Query query={adminSessionQuery} pollInterval={180000}>
    {({ data, refetch }) => {
      if (!data || !data.currentSession) {
        return <PopmenuLoading />;
      }
      console.log(`[POPMENU] Popmenu Version ${data.popmenuConfig.popmenuVersion}, Session ${data.currentSession.id}, User ${data.currentSession.user ? data.currentSession.user.id : '(none)'}`);
      const session = data.currentSession;
      const { facebookOauthDisabled } = data.popmenuConfig;

      // Routes that should be always be displayed outside the main AdminContainer
      const genericRoutes = [
        '/accessibility',
        '/authenticate',
        '/client-terms',
        '/dmca-policy',
        '/do-not-sell-my-personal-info',
        '/invites/:token',
        '/link',
        '/ncr/sign-up',
        '/privacy',
        '/profile',
        '/reset/:token',
        '/setup',
        '/setup-link',
        '/sign-up',
        '/subscriptions/:unsubscribeToken',
        '/unsubscribed/:unsubscribeToken',
        '/terms',
      ];

      // Fallback to generic container for ALL routes if user is unauthenticated
      const isAuthenticated = !!session.user && (session.isAuthyVerified || !session.isAuthyRequired);
      let defaultContainer = null;
      if (isAuthenticated) {
        defaultContainer = <AdminParentContainer />;
      } else {
        defaultContainer = <GenericContainer refetch={refetch} facebookOauthDisabled={facebookOauthDisabled} />;
      }

      // Override theme provider with user admin theme palette setting
      let UserThemeProvider;
      if (session && session.user && session.user.adminPaletteType === 'admin_palette_dark') {
        UserThemeProvider = DarkThemeProvider;
      } else {
        UserThemeProvider = ThemeProvider;
      }

      const searchParams = new URLSearchParams(document.location.search);
      let returnTo = searchParams.get('return_to');

      const authyOk = (session.isAuthyRequired && session.isAuthyVerified) || !session.isAuthyRequired;

      if (session.user && authyOk && returnTo) {
        returnTo = atob(unescape(returnTo));
        window.location.assign(returnTo);
      }

      if (session.user && !authyOk && returnTo && !window.location.pathname.includes('authenticate')) {
        window.location.assign(`/authenticate/?return_to=${returnTo}`);
      }

      return (
        <UserThemeProvider>
          <PopmenuConfigContext.Provider value={data.popmenuConfig}>
            <CurrentSessionContextProvider value={session}>
              <AbilityContext.Provider value={initAbility(session)}>
                <React.Fragment>
                  <Helmet
                    defaultTitle={props.t('admin.title_suffix')}
                    titleTemplate={`%s - ${props.t('admin.title_suffix')}`}
                  />
                  <AdminPageChangeHandler branchIoKey={data.popmenuConfig.branchIoKey} />
                  <Switch>
                    <Route path="/ncr/sign-up">
                      <SignUpContainer />
                    </Route>
                    <Route path="/sign-up">
                      {session.user ? <Redirect to="/" /> : <SignUpContainer />}
                    </Route>
                    <Route path="/messages/:token">
                      <MessagePreviewContainer />
                    </Route>
                    {genericRoutes.map(route => (
                      <Route key={route} path={route}>
                        <GenericContainer refetch={refetch} facebookOauthDisabled={facebookOauthDisabled} />
                      </Route>
                    ))}
                    <Route path="/oauth/:accountType">
                      <OAuthRestaurantSelection isAuthenticated={isAuthenticated} />
                    </Route>
                    <Route path="/" exact>
                      <GenericContainer refetch={refetch} facebookOauthDisabled={facebookOauthDisabled} />
                    </Route>
                    <Route path="/:restaurantSlug">
                      {defaultContainer}
                    </Route>
                    <Route path="/">
                      {defaultContainer}
                    </Route>
                  </Switch>
                  <StatusPrompt />
                  <VersionCheck />
                  <AdminBackgroundToggle session={session} />
                </React.Fragment>
              </AbilityContext.Provider>
            </CurrentSessionContextProvider>
          </PopmenuConfigContext.Provider>
        </UserThemeProvider>
      );
    }}
  </Query>
);

AdminSwitch.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withIntl(AdminSwitch);
