
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "listsNew": {
      "id": "lists.new",
      "defaultMessage": "Create New List"
    },
    "listsNewHeader": {
      "id": "lists.new_header",
      "defaultMessage": "Finalize Your List"
    },
    "listsCreate": {
      "id": "lists.create",
      "defaultMessage": "Save"
    },
    "listsCreatedError": {
      "id": "lists.created_error",
      "defaultMessage": "There was an error creating the list."
    },
    "listsUpdatedError": {
      "id": "lists.updated_error",
      "defaultMessage": "There was an error saving the list."
    },
    "listsSave": {
      "id": "lists.save",
      "defaultMessage": "Confirm"
    },
    "listsCancel": {
      "id": "lists.cancel",
      "defaultMessage": "Cancel"
    },
    "listsName": {
      "id": "lists.name",
      "defaultMessage": "List Title"
    },
    "listsNamePlaceholder": {
      "id": "lists.name_placeholder",
      "defaultMessage": "Name your list"
    },
    "listsMessage": {
      "id": "lists.message",
      "defaultMessage": "Message (optional)"
    },
    "listsMessagePlaceholder": {
      "id": "lists.message_placeholder",
      "defaultMessage": "Short description of your list"
    },
    "listsShare": {
      "id": "lists.share",
      "defaultMessage": "Share this List!"
    }
  }
);
export default defaultMessages;

