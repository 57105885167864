import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "contentBuilderAddContent": {
      "id": "content_builder.add_content",
      "defaultMessage": "Add Content"
    },
    "contentBuilderRemoveContentMessage": {
      "id": "content_builder.remove_content_message",
      "defaultMessage": "Are you sure you'd like to remove this content?"
    },
    "contentBuilderRemoveContent": {
      "id": "content_builder.remove_content",
      "defaultMessage": "Remove Content"
    },
    "contentBuilderDynamicContentDish": {
      "id": "content_builder.dynamic_content.dish",
      "defaultMessage": "This section will automatically display an item."
    },
    "contentBuilderDynamicContentFeatured": {
      "id": "content_builder.dynamic_content.featured",
      "defaultMessage": "This section will automatically display featured items."
    },
    "contentBuilderDynamicContentOffer": {
      "id": "content_builder.dynamic_content.offer",
      "defaultMessage": "this section will automatically display an offer."
    },
    "contentBuilderDividerStylesDashed": {
      "id": "content_builder.divider_styles.dashed",
      "defaultMessage": "Dashed"
    },
    "contentBuilderDividerStylesDotted": {
      "id": "content_builder.divider_styles.dotted",
      "defaultMessage": "Dotted"
    },
    "contentBuilderDividerStylesSolid": {
      "id": "content_builder.divider_styles.solid",
      "defaultMessage": "Solid"
    },
    "contentBuilderSizesMedium": {
      "id": "content_builder.sizes.medium",
      "defaultMessage": "Medium"
    },
    "contentBuilderSizesLarge": {
      "id": "content_builder.sizes.large",
      "defaultMessage": "Large"
    },
    "contentBuilderSizesSmall": {
      "id": "content_builder.sizes.small",
      "defaultMessage": "Small"
    },
    "contentBuilderCustomButtonsEdit": {
      "id": "content_builder.custom_buttons.edit",
      "defaultMessage": "Edit Button"
    },
    "contentBuilderCustomButtonGiftCardLink": {
      "id": "content_builder.custom_buttons.gift_card_link",
      "defaultMessage": "Order Digital Gift Card Today"
    },
    "contentBuilderCustomButtonsMenuLink": {
      "id": "content_builder.custom_buttons.menu_link",
      "defaultMessage": "Menu"
    },
    "contentBuilderCustomButtonsOnlineOrderingLink": {
      "id": "content_builder.custom_buttons.online_ordering_link",
      "defaultMessage": "Online Order"
    },
    "contentBuilderCustomButtonsReservationLink": {
      "id": "content_builder.custom_buttons.reservation_link",
      "defaultMessage": "Reservation"
    },
  }
);
export default defaultMessages;

