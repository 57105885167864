import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '../../utils/withStyles';
import signInStyles from '../../assets/jss/shared/signInStyles';

import SocialButton from './SocialButton';

class SocialControls extends React.PureComponent {
  render() {
    return (
      <div className={this.props.classes.socialButtons}>
        {!this.props.facebookOauthDisabled && (
          <div>
            <SocialButton
              baseLocale="social_accounts.social_types.facebook_type"
              socialType="facebook_type"
              submit={this.props.submit}
              tokenName="facebook_type"
            />
          </div>
        )}
        <div>
          <SocialButton
            baseLocale="social_accounts.social_types.google_type"
            socialType="google_type"
            submit={this.props.submit}
            tokenName="google_type"
          />
        </div>
      </div>
    );
  }
}

SocialControls.propTypes = {
  classes: PropTypes.object.isRequired,
  facebookOauthDisabled: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
};

export default withStyles(signInStyles)(SocialControls);
