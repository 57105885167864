import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "advancedFormsDashboardTitle": {
      "id": "advanced_forms.dashboard.title",
      "defaultMessage": "Forms"
    },
    "advancedFormsDownloadAllFormResponses": {
      "id": "advanced_forms.export_all_responses.title",
      "defaultMessage": "Export All Form Responses"
    },
    "advancedFormsExportCancel": {
      "id": "advanced_forms.export.cancel",
      "defaultMessage": "Cancel"
    },
    "advancedFormsExportExportToCsv": {
      "id": "advanced_forms.export.export_to_csv",
      "defaultMessage": "Export to CSV"
    },
    "advancedFormsExportComplete": {
      "id": "advanced_forms.export.complete",
      "defaultMessage": "Form responses were sent to your email."
    },
    "advancedFormsExportDone": {
      "id": "advanced_forms.export.done",
      "defaultMessage": "Done"
    },
    "advancedFormsExportDateDropdownTitle": {
      "id": "advanced_forms.export.date_dropdown_title",
      "defaultMessage": "Date range"
    },
    "advancedFormsFormsTab": {
      "id": "advanced_forms.form_list.tabs.forms",
      "defaultMessage": "Forms"
    },
    "advancedFormsBlockedTab": {
      "id": "advanced_forms.form_list.tabs.blocked",
      "defaultMessage": "Blocked"
    },
    "advancedFormsListDownloadToolTip": {
      "id": "advanced_forms.form_list.export.tooltip",
      "defaultMessage": "Download Form"
    },
    "advancedFormsEditToolTip": {
      "id": "advanced_forms.form_list.edit.tooltip",
      "defaultMessage": "Edit Form"
    },
    "advancedFormsDetailsTitle": {
      "id": "advanced_forms.form_details.title",
      "defaultMessage": "Form Details"
    },
    "advancedFormsBackButton": {
      "id": "advanced_forms.form_details.back_button",
      "defaultMessage": "Back to Forms"
    },
    "advancedFormsDetailsResponsesTab": {
      "id": "advanced_forms.form_details.responses_tab",
      "defaultMessage": "Back to Forms"
    },
    "advancedFormsDetailsNotificationsTab": {
      "id": "advanced_forms.form_details.notifications_tab",
      "defaultMessage": "Back to Forms"
    },
    "advancedFormsDownloadFormResponses": {
      "id": "advanced_forms.export_responses.title",
      "defaultMessage": "Export Form Responses"
    },
    "advancedFormsEditForm": {
      "id": "advanced_forms.edit_form.title",
      "defaultMessage": "Edit Form"
    },
    "advancedFormsDetailsNotificationTableHeader": {
      "id": "advanced_forms.details.notification_table.header",
      "defaultMessage": "Notifications"
    },
    "advancedFormsDetailsNotificationTableSubHeader": {
      "id": "advanced_forms.details.notification_table.subheader",
      "defaultMessage": "These users will be notified of any form submissions from this form."
    },
    "advancedFormsDetailsResponsesSubheader": {
      "id": "advanced_forms.details.responses.subheader",
      "defaultMessage": "responses"
    },
    "advancedFormsDetailsLocationSubheader": {
      "id": "advanced_forms.details.location.subheader",
      "defaultMessage": "All locations"
    },
    "advancedFormsDetailsLocationSubheader": {
      "id": "advanced_forms.details.untitled_form_name.header",
      "defaultMessage": "Untitled"
    },
    "advancedFormEntriesDefaultSender": {
      "id": "advanced_form.details.default_sender",
      "defaultMessage": "Form Response"
    }
  }
);
export default defaultMessages;
