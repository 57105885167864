import { defineMessages } from 'react-intl'
const defaultMessages = defineMessages(
  {
    "marketingOffersAnnouncementContent": {
      "id": "marketing_offers.announcement_content",
      "defaultMessage": "Announcement Content"
    },
    "marketingOffersAttachOffer": {
      "id": "marketing_offers.attach_offer",
      "defaultMessage": "Attach Offer"
    },
    "marketingOffersBecomeVipDescription": {
      "id": "marketing_offers.become_vip_description",
      "defaultMessage": "Promote This Offer on Become a VIP Modal"
    },
    "marketingOffersChooseSmartMessage": {
      "id": "marketing_offers.choose_smart_message",
      "defaultMessage": "Choose Smart Message"
    },
    "marketingOffersChooseAutomatedEmail": {
      "id": "marketing_offers.choose_automated_email",
      "defaultMessage": "Choose Automated Email"
    },
    "marketingOffersModalNo": {
      "id": "marketing_offers.modal.no",
      "defaultMessage": "No"
    },
    "marketingOffersModalYes": {
      "id": "marketing_offers.modal.yes",
      "defaultMessage": "Yes"
    },
    "marketingOffersModalDescription": {
      "id": "marketing_offers.modal.description",
      "defaultMessage": "Would you like to promote this new offer in the Become a VIP Modal?"
    },
    "marketingOffersBxgyDefaultTitle": {
      "id": "marketing_offers.bxgy_default_title",
      "defaultMessage": "Get a free item with your first order"
    },
    "marketingOffersDefaultOfferDefaultTitle": {
      "id": "marketing_offers.default_offer.default_title",
      "defaultMessage": "Get {amount} off your first order"
    },
    "marketingOffersBxgyOfferDefaultDescription": {
      "id": "marketing_offers.bxgy_offer.default_description",
      "defaultMessage": "Become a VIP, get a free item with your first order and receive updates on future discounts, loyalty and more!"
    },
    "marketingOffersDefaultOfferDefaultDescription": {
      "id": "marketing_offers.default_offer.default_description",
      "defaultMessage": "Become a VIP, get {amount} off your first order and receive updates on future discounts, loyalty and more!"
    },
    "modelsMarketingOfferPromoteDisplayBanner": {
      "id": "marketing_offer.promote.display_banner",
      "defaultMessage": "Banner"
    },
    "modelsMarketingOfferPromoteDisplayModal": {
      "id": "marketing_offer.promote.display_modal",
      "defaultMessage": "Pop Up"
    },
    "marketingOffersModalHeader": {
      "id": "marketing_offers.modal.header",
      "defaultMessage": "Offer Promotion: Become VIP Modal"
    },
    "marketingOfferStepperSmartMessage": {
      "id": "marketing_offer.stepper.smart_message",
      "defaultMessage": "Smart Messages"
    },
    "marketingOfferStepperAutomatedEmails": {
      "id": "marketing_offer.stepper.automated_emails",
      "defaultMessage": "Automated Emails"
    },
    "marketingOfferStepperSelectSmartMessage": {
      "id": "marketing_offer.stepper.select_smart_message",
      "defaultMessage": "Select Smart Message"
    },
    "marketingOfferStepperSelectAutomatedEmail": {
      "id": "marketing_offer.stepper.select_automated_email",
      "defaultMessage": "Select Automated Email"
    },
    "marketingOfferStepperExistingOfferTitle": {
      "id": "marketing_offer.stepper.existing_offer_title",
      "defaultMessage": "Existing Offer Attached"
    },
    "marketingOfferStepperExistingOfferSmartMessage": {
      "id": "marketing_offer.stepper.existing_offer.smart_message",
      "defaultMessage": "There is already an offer attached to this Smart Message. Would you like to replace it with this one?"
    },
    "marketingOfferStepperExistingOfferAutomatedEmail": {
      "id": "marketing_offer.stepper.existing_offer.automated_email",
      "defaultMessage": "There is already an offer attached to this Automated Email. Would you like to replace it with this one?"
    },
    "marketingOfferStepperContentTitle": {
      "id": "marketing_offer.stepper.content_title",
      "defaultMessage": "Title"
    },
    "marketingOfferStepperContentTitleAnnouncement": {
      "id": "marketing_offer.stepper.content_title_announcement",
      "defaultMessage": "Heading"
    },
    "marketingOfferStepperContentDescription": {
      "id": "marketing_offer.stepper.content_description",
      "defaultMessage": "Description"
    },
    "marketingOfferStepperContentDescriptionAnnouncement": {
      "id": "marketing_offer.stepper.content_description_announcement",
      "defaultMessage": "Content"
    },
    "marketingOfferStopMarketingTitle": {
      "id": "marketing_offer.stop_promoting.title",
      "defaultMessage": "Stop Promoting Offer"
    },
  }
);
export default defaultMessages;
