import React from 'react';
import { Icon, Paper, Typography } from '@popmenu/common-ui';
import { Error as ErrorIcon, Warning } from '@popmenu/web-icons';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { compose, mapProps } from '@shakacode/recompose';
import { withPopmenuConfig } from '../../../utils/withPopmenuConfig';
import { classNames, withStyles } from '../../../utils/withStyles';
import styles from './styles';

class StatusPrompt extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      online: typeof navigator === 'undefined' || typeof navigator.onLine !== 'boolean' || navigator.onLine,
      popmenuVersion: props.popmenuVersion,
    };
    this.handleConnectionChange = this.handleConnectionChange.bind(this);
  }

  componentDidMount() {
    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.handleConnectionChange);
    window.removeEventListener('offline', this.handleConnectionChange);
  }

  handleConnectionChange() {
    const online = typeof navigator === 'undefined' || typeof navigator.onLine !== 'boolean' || navigator.onLine;
    this.setState({ online });
  }

  // Display prompt to refresh the page if global Popmenu version is updated
  render() {
    if (!this.state.online) {
      return (
        <div className={this.props.classes.root}>
          <Paper
            className={classNames(this.props.classes.paper, this.props.classes.paperDanger)}
            elevation={9}
          >
            <Typography align="center" className={this.props.classes.typographyDanger} variant="h6">
              <Icon icon={Warning} inline /> <FormattedMessage id="admin.status_prompt.reconnecting" defaultMessage="Reconnecting..." />
            </Typography>
            <Typography align="center" className={this.props.classes.typographyDanger} variant="body2">
              <FormattedMessage id="admin.status_prompt.changes_connection_restored" defaultMessage="Changes will not be saved until your connection is restored." />
            </Typography>
          </Paper>
        </div>
      );
    }
    if (this.props.popmenuVersion !== this.state.popmenuVersion) {
      const usingWebBuilder = document.querySelector('.iframe-consumer-preview');
      return (
        <div className={classNames(this.props.classes.root, usingWebBuilder && this.props.classes.webBuilder)}>
          <Paper
            className={this.props.classes.paper}
            elevation={9}
            onClick={() => window.location.reload(true)}
          >
            <Typography align="center" className={this.props.classes.typography} variant="h6">
              <Icon icon={ErrorIcon} inline /> <FormattedMessage id="admin.status_prompt.update_ready" defaultMessage="Update ready!" />
            </Typography>
            <Typography align="center" className={this.props.classes.typography} variant="body2">
              <FormattedMessage id="admin.status_prompt.reload_page" defaultMessage="Simply reload the page to get" />
              <br />
              <FormattedMessage id="admin.status_prompt.latest_goodness" defaultMessage="the latest goodness." />
            </Typography>
          </Paper>
        </div>
      );
    }
    return null;
  }
}

StatusPrompt.defaultProps = {
  popmenuVersion: null,
};

StatusPrompt.propTypes = {
  classes: PropTypes.object.isRequired,
  popmenuVersion: PropTypes.string,
};

export default compose(
  withStyles(styles),
  withPopmenuConfig,
  mapProps(({ popmenuConfig, ...props }) => ({
    ...props,
    popmenuVersion: popmenuConfig.popmenuVersion,
  })),
)(StatusPrompt);
