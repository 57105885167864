import {
  TOGGLE_IFRAME_RENDER,
  SET_LOADING_PREVIEW,
  SET_SELECTED_NAV_LINK,
  SET_SELECTED_PAGE,
  SET_PREVIEW_SELECTED_SECTION,
  SET_ACTIVE_SECTION,
  SET_ADD_SECTION_OPENED,
  SET_FORM_ERROR,
} from './WebBuilderActions';

const initialState = {
  activeSection: null,
  addSectionOpened: false,
  formError: null,
  loadingPreview: true,
  pageUrl: null,
  previewSelectedSection: null,
  selectedNavLink: null,
  selectedPageId: null,
  toggleRender: 0,
};

const WebBuilderReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_IFRAME_RENDER:
      return {
        ...state,
        toggleRender: state.toggleRender === 0 ? 1 : 0,
      };

    case SET_LOADING_PREVIEW:
      return {
        ...state,
        loadingPreview: action.loadingPreview,
      };

    case SET_SELECTED_NAV_LINK:
      return {
        ...state,
        selectedNavLink: action.selectedNavLink,
      };

    case SET_SELECTED_PAGE:
      return {
        ...state,
        pageUrl: action.pageUrl,
        selectedPageId: action.selectedPageId,
      };

    case SET_PREVIEW_SELECTED_SECTION:
      return {
        ...state,
        ...action.data,
      };
    case SET_ACTIVE_SECTION:
      return {
        ...state,
        activeSection: action.section,
      };
    case SET_ADD_SECTION_OPENED:
      return {
        ...state,
        addSectionOpened: action.payload,
      };
    case SET_FORM_ERROR:
      return {
        ...state,
        formError: action.formError,
      };

    default:
      return state;
  }
};

export default WebBuilderReducer;
