
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "rootGroupsDestroyConfirm": {
      "id": "root_groups.destroy_confirm",
      "defaultMessage": "Are you sure you want to remove this segment?"
    },
    "rootGroupsDestroyConfirmTitle": {
      "id": "root_groups.destroy_confirm_title",
      "defaultMessage": "Confirm Removal"
    },
    "rootGroupsIndex": {
      "id": "root_groups.index",
      "defaultMessage": "Root Segments"
    },
    "rootGroupsNew": {
      "id": "root_groups.new",
      "defaultMessage": "Add Segment"
    },
    "rootGroupsEdit": {
      "id": "root_groups.edit",
      "defaultMessage": "Edit Segment"
    },
    "rootGroupsDestroy": {
      "id": "root_groups.destroy",
      "defaultMessage": "Remove Segment"
    },
    "rootGroupsSync": {
      "id": "root_groups.sync",
      "defaultMessage": "Segment refresh has been queued! It may take several minutes to complete."
    },
    "rootGroupsSyncTooltip": {
      "id": "root_groups.sync_tooltip",
      "defaultMessage": "Refresh segments with a Sysco location"
    },
    "rootGroupsAggregateReportTitle": {
      "id": "root_groups.aggregate_report.title",
      "defaultMessage": "Google Analytics Traffic"
    },
    "rootGroupsAggregateReportStatColumnsNewUsers": {
      "id": "root_groups.aggregate_report.stat_columns.new_users",
      "defaultMessage": "New Users"
    },
    "rootGroupsAggregateReportStatColumnsPageViews": {
      "id": "root_groups.aggregate_report.stat_columns.page_views",
      "defaultMessage": "Page Views"
    },
    "rootGroupsAggregateReportStatColumnsSessions": {
      "id": "root_groups.aggregate_report.stat_columns.sessions",
      "defaultMessage": "Sessions"
    },
    "rootGroupsAggregateReportStatColumnsUsers": {
      "id": "root_groups.aggregate_report.stat_columns.users",
      "defaultMessage": "Users"
    },
    "rootGroupsAggregateReportStatTypesAvg": {
      "id": "root_groups.aggregate_report.stat_types.avg",
      "defaultMessage": "Average"
    },
    "rootGroupsAggregateReportStatTypesTotal": {
      "id": "root_groups.aggregate_report.stat_types.total",
      "defaultMessage": "Total"
    },
    "rootGroupsTabsAnalyticsTab": {
      "id": "root_groups.tabs.analytics_tab",
      "defaultMessage": "Analytics"
    },
    "rootGroupsTabsConciergeTab": {
      "id": "root_groups.tabs.concierge_tab",
      "defaultMessage": "Boost Report"
    },
    "rootGroupsTabsRestaurantsTab": {
      "id": "root_groups.tabs.restaurants_tab",
      "defaultMessage": "Restaurants"
    },
    "rootGroupsMembersNew": {
      "id": "root_groups.members.new",
      "defaultMessage": "Add Restaurant"
    },
    "rootGroupsMembersReport": {
      "id": "root_groups.members.report",
      "defaultMessage": "Report"
    },
    "rootGroupsAggregateReportGroupBysDay": {
      "id": "root_groups.aggregate_report.group_bys.day",
      "defaultMessage": "Day"
    },
    "rootGroupsAggregateReportGroupBysWeek": {
      "id": "root_groups.aggregate_report.group_bys.week",
      "defaultMessage": "Week"
    },
    "rootGroupsAggregateReportGroupBysMonth": {
      "id": "root_groups.aggregate_report.group_bys.month",
      "defaultMessage": "Month"
    },
    "rootGroupsAggregateReportGroupBysYear": {
      "id": "root_groups.aggregate_report.group_bys.year",
      "defaultMessage": "year"
    },
    "rootGroupsAggregateReportQuickDatesLastMonth": {
      "id": "root_groups.aggregate_report.quick_dates.last_month",
      "defaultMessage": "Last Month"
    },
    "rootGroupsAggregateReportQuickDatesLastQuarter": {
      "id": "root_groups.aggregate_report.quick_dates.last_quarter",
      "defaultMessage": "Last Quarter"
    },
    "rootGroupsAggregateReportQuickDatesLastYear": {
      "id": "root_groups.aggregate_report.quick_dates.last_year",
      "defaultMessage": "Last Year"
    },
    "rootGroupsAggregateReportQuickDatesMtd": {
      "id": "root_groups.aggregate_report.quick_dates.mtd",
      "defaultMessage": "Month to Date"
    },
    "rootGroupsAggregateReportQuickDatesQuarter": {
      "id": "root_groups.aggregate_report.quick_dates.quarter",
      "defaultMessage": "Quarter to Date"
    },
    "rootGroupsAggregateReportQuickDatesYtd": {
      "id": "root_groups.aggregate_report.quick_dates.ytd",
      "defaultMessage": "Year to Date"
    }
  }
);
export default defaultMessages;

