
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "billingCouponsEdit": {
      "id": "billing_coupons.edit",
      "defaultMessage": "Edit Coupon"
    },
    "billingCouponsNew": {
      "id": "billing_coupons.new",
      "defaultMessage": "Add Coupon"
    },
    "billingCouponsIsRestrictedTooltip": {
      "id": "billing_coupons.is_restricted_tooltip",
      "defaultMessage": "Restricted coupons cannot be selected by root-sales users"
    },
    "billingCouponsIsValidTooltip": {
      "id": "billing_coupons.is_valid_tooltip",
      "defaultMessage": "Disabled coupons cannot be selected by any users"
    }
  }
);
export default defaultMessages;

