import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { compose } from '@shakacode/recompose';
import { Button, Icon, Typography } from '@popmenu/common-ui';
import { Mail } from '@popmenu/web-icons';

import { createEvent } from '~/utils/eventable';
import { withIntl } from '../../utils/withIntl';
import { withStyles } from '../../utils/withStyles';
import signInUserMutation from '../../libs/gql/mutations/users/signInUserMutation.gql';
import signInStyles from '../../assets/jss/shared/signInStyles';

import BasicForm, { SubmitGroup, TextFieldGroup } from '../../admin/shared/forms/BasicForm';
import SocialControls from './SocialControls';
import { AH, AHLevelProvider } from '../../consumer/shared/AccessibleHeading';

const SignInForm = (props) => {
  const renderLinks = () => (
    <div className={props.classes.signInLinks}>
      {props.openForgotModal && (
        <Button
          color="inherit"
          data-cy="sign_in_forgot_password"
          onClick={() => {
            createEvent({
              eventableType: 'BecomeVipV2',
              eventType: 'sign_in_forgot_password',
            });
            props.openForgotModal();
          }}
          variant="text"
        >
          <FormattedMessage id="sessions.forgot" defaultMessage="Forgot password?" />
        </Button>
      )}
    </div>
  );

  const renderForm = () => (
    <BasicForm
      aria-labelledby="sign-in-title"
      defaultValues={{
        email: props.email,
      }}
      id="sign-in-form"
      labelPosition="top"
      mutate={{
        mutation: signInUserMutation,
        onCompleted: () => {
          if (typeof props.afterSignIn === 'function') {
            props.afterSignIn();
          }
        },
        toVariables: variables => ({
          ...variables,
          restaurantId: props.restaurantId,
        }),
      }}
      onSubmit={({ facebookCode, googleCode }) => {
        if (facebookCode) {
          createEvent({
            eventableType: 'BecomeVipV2',
            eventType: 'sign_in_modal_facebook',
          });
        } else if (googleCode) {
          createEvent({
            eventableType: 'BecomeVipV2',
            eventType: 'sign_in_modal_google',
          });
        } else {
          createEvent({
            eventableType: 'BecomeVipV2',
            eventType: 'sign_in_modal_button',
          });
        }
      }}
    >
      {({ submitManual }) => (
        <React.Fragment>
          <TextFieldGroup
            field="email"
            placeholder={props.t('users.email')}
            style={props.style}
            type="email"
            variant={props.variant}
          />
          <TextFieldGroup
            field="password"
            placeholder={props.t('users.password')}
            style={props.style}
            type="password"
            variant={props.variant}
            data-tour-id="sign-in-form-password"
          />

          <SubmitGroup block color="primary" icon={false} justify="center" size="lg" title="Sign In" />

          <div className={props.classes.socialSeparatorContainer}>
            <div className={props.classes.socialSeparator} aria-hidden="true">
              <div className={props.classes.socialSeparatorLine} />
            </div>
            <Typography align="center" className={props.classes.socialSeparatorText}>
              <FormattedMessage id="or" defaultMessage="or" />
            </Typography>
          </div>

          <div className={props.classes.signInLinks}>
            <div>
              <Button
                role="link"
                aria-label={props['aria-label']}
                className={props.classes.signInLinkButton}
                onClick={() => {
                  createEvent({
                    eventableType: 'BecomeVipV2',
                    eventType: 'sign_in_magic_link_button',
                  });
                  props.openForgotModal();
                }}
                size="large"
                textTransform="none"
                variant="outlined"
              >
                <Icon icon={Mail} className={props.classes.buttonIcon} />
                <FormattedMessage id="sessions.sign_in_magic_link" defaultMessage=" Email me a Magic Link" />
              </Button>
            </div>

            <SocialControls
              facebookOauthDisabled={props.facebookOauthDisabled}
              submit={(e, variables) => submitManual(variables)}
            />
          </div>
        </React.Fragment>
      )}
    </BasicForm>
  );

  return (
    (props.onlyForm ? (
      <React.Fragment>
        {renderForm()}
        {renderLinks()}

      </React.Fragment>
    ) : (
      <React.Fragment>
        <AH typography align="center" className={props.classes.signInTitle} id="sign-in-title" variant="h3">
          <FormattedMessage id="sessions.sign_in" defaultMessage="Sign In" />
        </AH>
        <AHLevelProvider>
          {renderForm()}
          {renderLinks()}
        </AHLevelProvider>
      </React.Fragment>
    ))
  );
};

SignInForm.defaultProps = {
  afterSignIn: null,
  email: null,
  onlyForm: false,
  openForgotModal: null,
  restaurantId: null,
  style: null,
  variant: 'standard',
};

SignInForm.propTypes = {
  afterSignIn: PropTypes.func,
  email: PropTypes.string,
  facebookOauthDisabled: PropTypes.bool.isRequired,
  onlyForm: PropTypes.bool,
  openForgotModal: PropTypes.func,
  restaurantId: PropTypes.number,
  style: PropTypes.object,
  t: PropTypes.func.isRequired,
  variant: PropTypes.string,
};

export default compose(
  withStyles(signInStyles),
  withIntl,
)(SignInForm);
