import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { compose, withProps } from '@shakacode/recompose';
import { Paper } from '@popmenu/common-ui';
import { classNames, withStyles } from '../../utils/withStyles';
import genericStyles from '../../assets/jss/admin/genericStyles';
import { currentSessionShape, withCurrentSession } from '../../shared/CurrentSessionProvider';
import Popmenu2020SVG from '../../assets/svg/popmenu_2020.svg';

const GenericContainerWrapper = ({ children, classes, isAuthenticationRoute, isLargePanel }) => (
  <div className={classNames(classes.panelWrapper, 'food-grid-wrapper')}>
    <div className={classNames(classes.panelWrapperInner, 'food-grid-inner')}>
      <Paper
        className={classNames(
          isAuthenticationRoute ? classes.panelMedium : null,
          isLargePanel ? classes.panelLarge : null,
          classes.panel,
        )}
        elevation={6}
      >
        <Link to="/" className={classes.panelLogoLink}>
          <Popmenu2020SVG className={classes.panelLogo} />
        </Link>
        {children}
      </Paper>
    </div>
  </div>
);

GenericContainerWrapper.defaultProps = {
  children: null,
};

GenericContainerWrapper.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  currentSession: currentSessionShape.isRequired,
  isAuthenticationRoute: PropTypes.bool.isRequired,
  isLargePanel: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default compose(
  withRouter,
  withCurrentSession,
  withStyles(genericStyles),
  withProps(props => ({
    ...props,
    isAuthenticationRoute: props.location.pathname === '/authenticate' && props.currentSession.user,
    isLargePanel: [
      '/accessibility',
      '/dmca-policy',
      '/do-not-sell-my-personal-info',
      '/privacy',
      '/terms',
    ].indexOf(props.location.pathname) > -1,
  })),
)(GenericContainerWrapper);
