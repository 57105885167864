
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "navBilling": {
      "id": "nav.billing",
      "defaultMessage": "Billing"
    },
    "navClientAccessibility": {
      "id": "nav.client_accessibility",
      "defaultMessage": "Website Accessibility"
    },
    "navClientTerms": {
      "id": "nav.client_terms",
      "defaultMessage": "Client Terms of Service"
    },
    "navDMCAPolicy": {
      "id": "nav.dmca_policy",
      "defaultMessage": "DMCA Policy"
    },
    "navGoogleMyBusiness": {
      "id": "nav.google_my_business",
      "defaultMessage": "Google Business Profile"
    },
    "navLocations": {
      "id": "nav.locations",
      "defaultMessage": "Locations"
    },
    "navMenu": {
      "id": "nav.menu",
      "defaultMessage": "Menus"
    },
    "navPops": {
      "id": "nav.pops",
      "defaultMessage": "My Pops"
    },
    "navRestaurants": {
      "id": "nav.restaurants",
      "defaultMessage": "Restaurants"
    },
    "navRootBillingPlans": {
      "id": "nav.root_billing_plans",
      "defaultMessage": "Root Products"
    },
    "navRootBillingCoupons": {
      "id": "nav.root_billing_coupons",
      "defaultMessage": "Root Coupons"
    },
    "navSettings": {
      "id": "nav.settings",
      "defaultMessage": "Settings"
    },
    "navSignOut": {
      "id": "nav.sign_out",
      "defaultMessage": "Sign Out"
    },
    "navWelcome": {
      "id": "nav.welcome",
      "defaultMessage": "Welcome, {name}!"
    },
  }
);
export default defaultMessages;

