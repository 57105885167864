
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "aiPopmenuRecommends": {
      "id": "ai.popmenu_recommends",
      "defaultMessage": "Popmenu 🤖 recommends"
    }
  }
);
export default defaultMessages;

