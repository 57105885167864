
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "tablesActions": {
      "id": "tables.actions",
      "defaultMessage": "Actions"
    },
    "tablesViews": {
      "id": "tables.views",
      "defaultMessage": "Views"
    },
    "tablesSearch": {
      "id": "tables.search",
      "defaultMessage": "Search {entry_name}..."
    },
    "tablesError": {
      "id": "tables.error",
      "defaultMessage": "Sorry, there was an error loading the {model}s."
    },
    "tablesEmpty": {
      "id": "tables.empty",
      "defaultMessage": "No {model}s found."
    },
    "tablesOrderingOfferInactiveEmpty": {
      "id": "tables.ordering_offer.inactive_empty",
      "defaultMessage": "Your Online Offers drafts will be displayed here."
    },
    "tablesOrderingOfferHistoryEmpty": {
      "id": "tables.ordering_offer.history_empty",
      "defaultMessage": "Your Online Offers history will be displayed here."
    },
    "tablesOrderingOfferLiveEmpty": {
      "id": "tables.ordering_offer.live_empty",
      "defaultMessage": "Your live Online Offers will be displayed here."
    },
    "tablesMarketingOfferEmpty": {
      "id": "tables.marketing_offer.empty",
      "defaultMessage": "Your live Offer Promotions will be displayed here."
    }
  }
);
export default defaultMessages;

