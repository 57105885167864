
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "billingCreditCardsSuccess": {
      "id": "billing_credit_cards.success",
      "defaultMessage": "New credit card successfully added"
    },
    "billingCreditCardsSelf": {
      "id": "billing_credit_cards.self",
      "defaultMessage": "Credit Card"
    },
    "billingCreditCardsExpiration": {
      "id": "billing_credit_cards.expiration",
      "defaultMessage": "Expires"
    },
    "billingCreditCardsNickName": {
      "id": "billing_credit_cards.nick_name",
      "defaultMessage": "Nickname"
    },
    "billingCreditCardsReplace": {
      "id": "billing_credit_cards.replace",
      "defaultMessage": "Replace"
    },
    "billingCreditCardsSummaryExpiry": {
      "id": "billing_credit_cards.summary_expiry",
      "defaultMessage": "{month}/{year}"
    },
    "billingCreditCardsSummaryLast4": {
      "id": "billing_credit_cards.summary_last4",
      "defaultMessage": "{brand} ending in {last4}"
    },
    "billingCreditCardsDestroy": {
      "id": "billing_credit_cards.destroy",
      "defaultMessage": "Remove"
    },
    "billingCreditCardsDestroyConfirm": {
      "id": "billing_credit_cards.destroy_confirm",
      "defaultMessage": "Are you sure you want to remove this credit card?"
    },
    "billingCreditCardsDestroyConfirmTitle": {
      "id": "billing_credit_cards.destroy_confirm_title",
      "defaultMessage": "Confirm Removal"
    },
    "billingCreditCardsEdit": {
      "id": "billing_credit_cards.edit",
      "defaultMessage": "Edit"
    },
    "billingCreditCardsSelves": {
      "id": "billing_credit_cards.selves",
      "defaultMessage": "Credit Cards"
    },
    "billingCreditCardsBrand": {
      "id": "billing_credit_cards.brand",
      "defaultMessage": "Brand"
    },
    "billingCreditCardsNew": {
      "id": "billing_credit_cards.new",
      "defaultMessage": "Add Credit Card"
    },
    "billingCreditCardsSubscription": {
      "id": "billing_credit_cards.subscription",
      "defaultMessage": "Subscriptions"
    },
    "billingCreditCardsUpdateConfirm": {
      "id": "billing_credit_cards.update_confirm",
      "defaultMessage": "Are you sure you want to set this card as the default payment?"
    },
    "billingCreditCardsUpdateConfirmTitle": {
      "id": "billing_credit_cards.update_confirm_title",
      "defaultMessage": "Confirm Update"
    }
  }
);
export default defaultMessages;

