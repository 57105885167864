import {
  SET_HAS_NEW_RELEASE,
  SET_RELEASE_FETCH_AT,
  SET_SHOULD_FETCH_RELEASE,
} from './NewsNotificationActions';

const initialState = {
  hasNewRelease: false,
  releaseFetchAt: null,
  shouldFetchRelease: false,
};

const NewsNotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HAS_NEW_RELEASE:
      return {
        ...state,
        hasNewRelease: action.hasNewRelease,
      };

    case SET_RELEASE_FETCH_AT:
      return {
        ...state,
        releaseFetchAt: action.releaseFetchAt,
      };

    case SET_SHOULD_FETCH_RELEASE:
      return {
        ...state,
        shouldFetchRelease: action.shouldFetchRelease,
      };

    default:
      return state;
  }
};

export default NewsNotificationReducer;
