
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "rootSectionTemplatesNew": {
      "id": "root_section_templates.new",
      "defaultMessage": "Add Section Template"
    },
    "rootSectionTemplatesEdit": {
      "id": "root_section_templates.edit",
      "defaultMessage": "Edit Section Template"
    },
    "rootSectionTemplatesThumbnail": {
      "id": "root_section_templates.thumbnail",
      "defaultMessage": "Thumbnail"
    }
  }
);
export default defaultMessages;

