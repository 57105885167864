import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "rootThemeTemplatesNew": {
      "id": "root_theme_templates.new",
      "defaultMessage": "Add Theme Template"
    },
    "rootThemeTemplatesEdit": {
      "id": "root_theme_templates.edit",
      "defaultMessage": "Edit Theme Template"
    },
    "rootThemeTemplatesSelected": {
      "id": "root_theme_templates.selected",
      "defaultMessage": "Selected template:"
    },
    "rootThemeTemplatesChange": {
      "id": "root_theme_templates.change",
      "defaultMessage": "Change Template"
    }
  }
);
export default defaultMessages;

