
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "rootNotesDestroyConfirm": {
      "id": "root_notes.destroy_confirm",
      "defaultMessage": "Are you sure you want to delete this note?"
    },
    "rootNotesDestroyConfirmTitle": {
      "id": "root_notes.destroy_confirm_title",
      "defaultMessage": "Confirm Delete"
    },
    "rootNotesDestroy": {
      "id": "root_notes.destroy",
      "defaultMessage": "Delete Note"
    }
  }
);
export default defaultMessages;

