import {
  SET_LAST_VIEWED_HISTORY_OFFSET,
  SET_NOTIFYING_CURBSIDE_GUEST,
  SET_READY_FOR_CAPTURE,
  SET_SELECTED_CART_BUCKET,
  SET_SELECTED_EVENT_ID,
  SET_SELECTED_LOCATION_ID,
} from './OrderingActions';

const initialState = {
  historyOffset: 0,
  notifyingCurbsideGuest: false,
  readyForCapture: false,
  selectedCartBucket: 'new_cart_bucket',
  selectedEventId: null,
  selectedLocationId: null,
};

const OrderingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LAST_VIEWED_HISTORY_OFFSET:
      return {
        ...state,
        historyOffset: action.historyOffset,
      };

    case SET_NOTIFYING_CURBSIDE_GUEST:
      return {
        ...state,
        notifyingCurbsideGuest: action.notifyingCurbsideGuest,
      };

    case SET_READY_FOR_CAPTURE:
      return {
        ...state,
        readyForCapture: action.readyForCapture,
      };

    case SET_SELECTED_CART_BUCKET:
      return {
        ...state,
        selectedCartBucket: action.selectedCartBucket,
      };

    case SET_SELECTED_EVENT_ID:
      return {
        ...state,
        selectedEventId: action.selectedEventId,
      };

    case SET_SELECTED_LOCATION_ID:
      return {
        ...state,
        selectedLocationId: action.selectedLocationId,
      };

    default:
      return state;
  }
};

export default OrderingReducer;
