
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "textMessageGroupsDestroy": {
      "id": "text_message_groups.destroy",
      "defaultMessage": "Delete Text Message"
    },
    "textMessageGroupsDestroyConfirmTitle": {
      "id": "text_message_groups.destroy_confirm_title",
      "defaultMessage": "Confirm Delete"
    },
    "textMessageGroupsDestroyConfirm": {
      "id": "text_message_groups.destroy_confirm",
      "defaultMessage": "Are you sure you want to delete this mass text message?"
    },
    "textMessageGroupsFailedTooFast": {
      "id": "text_message_groups.failed.too_fast",
      "defaultMessage": "Message failed. No more than one mass text message can be sent in 7 days."
    },
    "textMessageGroupsNew": {
      "id": "text_message_groups.new",
      "defaultMessage": "New Text Message"
    },
  }
);
export default defaultMessages;

