export const SET_ORDERING_OFFER_ID = 'SET_ORDERING_OFFER_ID';

export function setOrderingOfferId(orderingOfferId) {
  return (dispatch) => {
    dispatch({
      orderingOfferId,
      type: SET_ORDERING_OFFER_ID,
    });
  };
}
