import React from 'react';
import PropTypes from 'prop-types';
import { compose, mapProps } from '@shakacode/recompose';
import { withRouter } from 'react-router-dom';

import { withPopmenuConfig } from '../../../utils/withPopmenuConfig';

class VersionCheck extends React.PureComponent {
  constructor(props) {
    super(props);
    this.currentVersion = props.popmenuVersion;
  }

  // Automatically reload the page after user navigation when new version is available
  componentDidMount() {
    this.unlisten = this.props.historyListen(() => {
      if (this.currentVersion !== this.props.popmenuVersion) {
        window.location.reload();
      }
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    return null;
  }
}

VersionCheck.defaultProps = {
  popmenuVersion: null,
};

VersionCheck.propTypes = {
  historyListen: PropTypes.func.isRequired,
  popmenuVersion: PropTypes.string,
};

export default compose(
  withPopmenuConfig,
  mapProps(({ popmenuConfig, ...props }) => ({
    ...props,
    popmenuVersion: popmenuConfig.popmenuVersion,
  })),
  withRouter,
  mapProps(({ history, location, match, ...props }) => ({
    ...props,
    historyListen: history.listen,
  })),
)(VersionCheck);
