import {
  RESET_BUILDER_CONTENTS,
  SET_BUILDER_CONTENTS,
} from './BuilderActions';

const initialState = {
  builderContents: {
    messageGroupBuilder: [],
  },
};

const mapBuilderStateToProps = (state = initialState, action) => {
  switch (action.type) {
    case RESET_BUILDER_CONTENTS:
      return {
        builderContents: {
          messageGroupBuilder: [],
        },
      };
    case SET_BUILDER_CONTENTS:
      return {
        ...state,
        builderContents: action.builderContents,
      };
  }

  return state;
};

export default mapBuilderStateToProps;
