
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "menusAddSection": {
      "id": "menus.add_section",
      "defaultMessage": "Add Section"
    },
    "menusEdit": {
      "id": "menus.edit",
      "defaultMessage": "Settings"
    },
    "menusNew": {
      "id": "menus.new",
      "defaultMessage": "New Menu"
    },
    "menusSelectCategories": {
      "id": "menus.select_categories",
      "defaultMessage": "Select Categories for Menu"
    },
    "menusLocations": {
      "id": "menus.locations",
      "defaultMessage": "Locations"
    },
    "menusMenuAndLocationCount": {
      "id": "menus.menu_and_location_count",
      "defaultMessage": "{menu_count} {menu_tense} | {location_count} {location_tense}"
    },
    "menusDisplayExtraGroupsTooltip": {
      "id": "menus.display_extra_groups_tooltip",
      "defaultMessage": "Displays modifier groups on the menu"
    },
    "menusImportHeaderMultiple": {
      "id": "menus.import.header.multiple",
      "defaultMessage": "Menus Attached"
    },
    "menusImportHeaderSingle": {
      "id": "menus.import.header.single",
      "defaultMessage": "Menu Attached"
    },
    "menusImportInstructionsPlaceholder": {
      "id": "menus.import.instructions.placeholder",
      "defaultMessage": "Special Instructions"
    },
    "menusImportLinkButton": {
      "id": "menus.import.link.button",
      "defaultMessage": "Attach"
    },
    "menusImportLinkPlaceholder": {
      "id": "menus.import.link.placeholder",
      "defaultMessage": "Type or paste the URL to the menu"
    },
    "menusImportNavFailed": {
      "id": "menus.import.nav.failed",
      "defaultMessage": "Import Failed"
    },
    "menusImportNavProcessing": {
      "id": "menus.import.nav.processing",
      "defaultMessage": "Import Processing"
    },
    "menusImportSuccessTitle": {
      "id": "menus.import.success.title",
      "defaultMessage": "Menu import is processing."
    },
    "menusImportSuccessDescription": {
      "id": "menus.import.success.description",
      "defaultMessage": "The menu import process typically takes between 2-3 business days. Keep checking back to view your completed menus!"
    },
    "menusImportSourceRemoveButton": {
      "id": "menus.import.source.remove_button",
      "defaultMessage": "Remove"
    },
    "menusImportSubmitButton": {
      "id": "menus.import.submit.button",
      "defaultMessage": "Import Menus"
    },
    "menusImportTabAttachMenu": {
      "id": "menus.import.tabs.attach_menu_tab",
      "defaultMessage": "Attach File"
    },
    "menusImportTabLinkMenu": {
      "id": "menus.import.tabs.link_menu_tab",
      "defaultMessage": "Link to Menu"
    },
    "menusImportTitle": {
      "id": "menus.import.title",
      "defaultMessage": "Attach or Link to Menus for Import"
    },
    "menusIsEnabledTooltip": {
      "id": "menus.is_enabled_tooltip",
      "defaultMessage": "When a menu is NOT enabled, it will be excluded from all pages, embeds, and search results. Disabled menus are also excluded from online ordering."
    },
    "menusItemsAreYouSureDisable": {
      "id": "menus.items.are_you_sure_disable",
      "defaultMessage": "Are you sure you want to disable this item?"
    },
    "menusItemsAreYouSureEnable": {
      "id": "menus.items.are_you_sure_enable",
      "defaultMessage": "Are you sure you want to enable this item?"
    },
    "menusItemsAreYouSureRemove": {
      "id": "menus.items.are_you_sure_remove",
      "defaultMessage": "Are you sure you want to remove this item?"
    },
    "menusItemsConfirm": {
      "id": "menus.items.confirm",
      "defaultMessage": "Confirm"
    },
    "menusItemsConfirmRemoval": {
      "id": "menus.items.confirm_removal",
      "defaultMessage": "Confirm Removal"
    },
    "menusItemsDisable": {
      "id": "menus.items.disable",
      "defaultMessage": "Disable"
    },
    "menusItemsEnable": {
      "id": "menus.items.enable",
      "defaultMessage": "Enable"
    },
    "menusItemsNew": {
      "id": "menus.items.new",
      "defaultMessage": "Add Item"
    },
    "menusItemsRemove": {
      "id": "menus.items.remove",
      "defaultMessage": "Remove Item"
    },
    "menusItemsRemoved": {
      "id": "menus.items.removed",
      "defaultMessage": "Removed"
    },
    "menusItemsToggleItem": {
      "id": "menus.items.toggle_item",
      "defaultMessage": "Toggle Item"
    },
    "menusItemsUpdated": {
      "id": "menus.items.updated",
      "defaultMessage": "Updated"
    },
    "menusPickerTitle": {
      "id": "menus.picker.title",
      "defaultMessage": "How do you want to build your menu?"
    },
    "menusPickerBuildDescription": {
      "id": "menus.picker.build.description",
      "defaultMessage": "For those who want to add sections and items manually."
    },
    "menusPickerBuildTitle": {
      "id": "menus.picker.build.title",
      "defaultMessage": "Build from Scratch"
    },
    "menusPickerImportDescription": {
      "id": "menus.picker.import.description",
      "defaultMessage": "For those who have menu files or can link to online menus."
    },
    "menusPickerImportTitle": {
      "id": "menus.picker.import.title",
      "defaultMessage": "Import Menu"
    },
    "menusPickerSquareDescription": {
      "id": "menus.picker.square.description",
      "defaultMessage": "Use your Square POS menu data and link all items to the POS."
    },
    "menusPickerCloverDescription": {
      "id": "menus.picker.clover.description",
      "defaultMessage": "Use your Clover POS menu data and link all items to the POS."
    },
    "menusPickerSquareTitle": {
      "id": "menus.picker.square.title",
      "defaultMessage": "Square"
    },
    "menusPickerCloverTitle": {
      "id": "menus.picker.clover.title",
      "defaultMessage": "Clover"
    },
    "menusPickerNcrSilverDescription": {
      "id": "menus.picker.ncr_silver.description",
      "defaultMessage": "Use your NCR Silver POS menu data and link all items to the POS."
    },
    "menusPickerNcrSilverTitle": {
      "id": "menus.picker.ncr_silver.title",
      "defaultMessage": "NCR Silver"
    },
    "menusPickerAlohaDescription": {
      "id": "menus.picker.aloha.description",
      "defaultMessage": "Use your NCR Aloha POS menu data and link all items to the POS."
    },
    "menusPickerAlohaTitle": {
      "id": "menus.picker.aloha.title",
      "defaultMessage": "NCR Aloha"
    },
    "menusPickerMicrosTitle": {
      "id": "menus.picker.micros.title",
      "defaultMessage": "Oracle Micros Symphony"
    },
    "menusPickerMicrosDescription": {
      "id": "menus.picker.micros.description",
      "defaultMessage": "Use your Oracle POS menu data and link all items to the POS."
    },
    "menusSectionsNew": {
      "id": "menus.sections.new",
      "defaultMessage": "Add Section"
    },
    "menusSectionsEdit": {
      "id": "menus.sections.edit",
      "defaultMessage": "Edit Section"
    },
    "menusFeaturedSectionNew": {
      "id": "menus.featured_section.new",
      "defaultMessage": "Add Featured Section"
    },
    "menusFeaturedSectionSave": {
      "id": "menus.featured_section.save",
      "defaultMessage": "Save"
    },
    "menusFeaturedSectionEmptyState": {
      "id": "menus.featured_section.empty_state",
      "defaultMessage": "You don’t have any featured items yet. Add the items you want to feature."
    },
    "menusSubsectionsNew": {
      "id": "menus.subsections.new",
      "defaultMessage": "Add Subsection"
    },
    "menusSubsectionsEdit": {
      "id": "menus.subsections.edit",
      "defaultMessage": "Edit Subsection"
    },
    "menusDestroy": {
      "id": "menus.destroy",
      "defaultMessage": "Delete Menu"
    },
    "menusDestroyConfirm": {
      "id": "menus.destroy_confirm",
      "defaultMessage": "Are you sure you want to delete this menu?"
    },
    "menusDestroyConfirmTitle": {
      "id": "menus.destroy_confirm_title",
      "defaultMessage": "Delete Menu"
    },
    "menusDestroyDisabled": {
      "id": "menus.destroy_disabled",
      "defaultMessage": "All menu sections must be removed before deleting this menu."
    },
    "menusCopy": {
      "id": "menus.copy",
      "defaultMessage": "Copy Menu"
    },
    "menusCopyConfirm": {
      "id": "menus.copy_confirm",
      "defaultMessage": "Are you sure you want to copy this menu?"
    },
    "menusCopyConfirmTitle": {
      "id": "menus.copy_confirm_title",
      "defaultMessage": "Confirm Copy Menu"
    },
    "menusApplyAllTitle": {
      "id": "menus.apply_all_title",
      "defaultMessage": "Confirm Apply All"
    },
    "menusApplyAllConfirm": {
      "id": "menus.apply_all_confirm",
      "defaultMessage": "Are you sure you want to forcibly sync this menu to all locations? All location customizations will be lost. It may take several minutes to copy all sections/items."
    },
    "menusEmpty": {
      "id": "menus.empty",
      "defaultMessage": "No sections!"
    },
    "menusDownloadPdf": {
      "id": "menus.download_pdf",
      "defaultMessage": "Export to PDF"
    },
    "menusMaster": {
      "id": "menus.master",
      "defaultMessage": "Base"
    },
    "menusMenu": {
      "id": "menus.menu",
      "defaultMessage": "Menu"
    },
    "menusMenus": {
      "id": "menus.menus",
      "defaultMessage": "Menus"
    },
    "menusIndex": {
      "id": "menus.index",
      "defaultMessage": "Overview"
    },
    "menusIndexSearchPlaceholder": {
      "id": "menus.index.search_placeholder",
      "defaultMessage": "Search menus..."
    },
    "menusConnect": {
      "id": "menus.connect",
      "defaultMessage": "Connect To Page"
    },
    "menusCreated": {
      "id": "menus.created",
      "defaultMessage": "Menu successfully created!"
    },
    "menusCreatedError": {
      "id": "menus.created_error",
      "defaultMessage": "There was an error creating the menu."
    },
    "menusUpdated": {
      "id": "menus.updated",
      "defaultMessage": "Menu successfully updated!"
    },
    "menusUpdatedError": {
      "id": "menus.updated_error",
      "defaultMessage": "There was an error updating the menu."
    },
    "menusDestroyed": {
      "id": "menus.destroyed",
      "defaultMessage": "Menu successfully deleted."
    },
    "menusDestroyedError": {
      "id": "menus.destroyed_error",
      "defaultMessage": "There was an error deleting the menu."
    },
    "menusHistoryTitle": {
      "id": "menus.history.title",
      "defaultMessage": "{name} History"
    },
    "menusSyncHistoryCreatedAt": {
      "id": "menus.sync_history.created_at",
      "defaultMessage": "Synced At"
    },
    "menusSyncHistoryToastMenuGroupTypeTitle": {
      "id": "menus.sync_history.toast_menu_group_type_title",
      "defaultMessage": "Menu groups"
    },
    "menusSyncHistoryToastItemTypeTitle": {
      "id": "menus.sync_history.toast_item_type_title",
      "defaultMessage": "Items"
    },
    "menusSyncHistoryDetailsTitle": {
      "id": "menus.sync_history.details_title",
      "defaultMessage": "Details:"
    },
    "menusSyncHistoryStatusTitle": {
      "id": "menus.sync_history.status_title",
      "defaultMessage": "Status:"
    },
    "menusSyncHistoryPosMenuNameTitle": {
      "id": "menus.sync_history.pos_menu_name_title",
      "defaultMessage": "Menu name:"
    },
    "menusSyncHistoryCompleted": {
      "id": "menus.sync_history.completed",
      "defaultMessage": "Completed at {finishedAt}"
    },
    "menusSyncHistoryInProgress": {
      "id": "menus.sync_history.in_progress",
      "defaultMessage": "In Progress"
    },
    "menusSyncHistoryFailed": {
      "id": "menus.sync_history.failed",
      "defaultMessage": "Failed. Started at {startedAt}"
    },
    "menusSyncHistoryPosMenuName": {
      "id": "menus.sync_history.pos_menu_name",
      "defaultMessage": "Toast Menu Name"
    },
    "menusSyncHistorySyncList": {
      "id": "menus.sync_history.sync_list",
      "defaultMessage": "Sync List"
    },
    "menusSyncHistoryNoHistory": {
      "id": "menus.sync_history.no_history",
      "defaultMessage": "No sync history yet"
    },
    "menusSyncHistoryToastModifierGroupType": {
      "id": "menus.sync_history.toast_modifier_group_type",
      "defaultMessage": "Modifier Groups"
    },
    "menusSyncHistoryToastModifierOptionType": {
      "id": "menus.sync_history.toast_modifier_option_type",
      "defaultMessage": "Modifier Options"
    },
    "menusSyncBackToMenu": {
      "id": "menus.sync.back_to_menu",
      "defaultMessage": "Back to Menu"
    },
    "menusSyncToast": {
      "id": "menus.sync.toast",
      "defaultMessage": "Toast"
    },
    "menusSyncOff": {
      "id": "menus.sync.off",
      "defaultMessage": "Menu sync off"
    },
    "menusSyncToggle": {
      "id": "menus.sync.toggle",
      "defaultMessage": "Menu syncing is {menuSyncingValue}"
    },
    "menusSyncOffLong": {
      "id": "menus.sync.off_long",
      "defaultMessage": "Menu syncing is currently off"
    },
    "menusSyncLastSynced": {
      "id": "menus.sync.last_synced_at",
      "defaultMessage": "Last synched {syncedDate} at {syncedTime}"
    },
    "menuItemsUpdated": {
      "id": "menus.items_updated",
      "defaultMessage": "Menu items updated!"
    },
    "menuItemsNotUpdated": {
      "id": "menus.items_not_updated",
      "defaultMessage": "Error updating menu items!"
    },
    "menuFeaturedSectionItemsUpdated": {
      "id": "menus.featured_section_items_updated",
      "defaultMessage": "Featured section items updated!"
    },
    "menuFeaturedSectionItemsNotUpdated": {
      "id": "menus.featured_section_items_not_updated",
      "defaultMessage": "Error updating featured section items!"
    },
    "menusSectionUpdated": {
      "id": "menus.section_updated",
      "defaultMessage": "Sections updated!"
    },
    "menusSectionNotUpdated": {
      "id": "menus.section_not_updated",
      "defaultMessage": "Error updating sections!"
    },
    "menusSearchThisMenu": {
      "id": "menus.search_this_menu",
      "defaultMessage": "Search this menu"
    },
    "menusSearchThisMenuPlaceholder": {
      "id": "menus.search_this_menu_placeholder",
      "defaultMessage": "Search this menu..."
    },
    "menusNoCoustomizedSettings": {
      "id": "menus.no_customized_settings",
      "defaultMessage": "No Customized Settings"
    },
    "menusSyncConfirm": {
      "id": "menus.sync_confirm",
      "defaultMessage": "Syncing this menu will update all sections, subsections, items and modifiers."
    },
    "menusSyncConfirmTitle": {
      "id": "menus.sync_confirm_title",
      "defaultMessage": "Confirm Menu Sync"
    },
    "menusCompareAll": {
      "id": "menus.compare_all",
      "defaultMessage": "Compare All"
    },
    "menusSyncModal": {
      "id": "menus.sync",
      "defaultMessage": "Manage Syncing for {scopeName}"
    },
    "menusSyncShort": {
      "id": "menus.sync_short",
      "defaultMessage": "Menu Sync"
    },
    "menusSyncSuccess": {
      "id": "menus.sync_success",
      "defaultMessage": "Synced"
    },
    "menusSyncFailed": {
      "id": "menus.sync_failed",
      "defaultMessage": "Sync failed"
    },
    "menusSyncFailedLong": {
      "id": "menus.sync_failed_long",
      "defaultMessage": "Menu is not synced"
    },
    "menusSyncAll": {
      "id": "menus.sync_all",
      "defaultMessage": "Apply to all Locations"
    },
    "menusSyncSubtitle": {
      "id": "menus.sync_subtitle",
      "defaultMessage": "Syncing {menuName} @ {menuLocation}"
    },
    "menusSyncItemType": {
      "id": "menus.sync_item_type",
      "defaultMessage": "Syncing {itemType}:"
    },
    "menusSyncItemEmpty": {
      "id": "menus.sync_empty",
      "defaultMessage": "Select a {itemType} to begin 3rd party sync"
    },
    "menusSyncItemEmptyForm": {
      "id": "menus.sync_item_empty_form",
      "defaultMessage": "No Services were found for this location. Please check your OrderNerd portal for service options."
    },
    "menusSyncSectionName": {
      "id": "menus.sync_section_name",
      "defaultMessage": "Section Name"
    },
    "menusSyncSubtitleLong": {
      "id": "menus.sync_subtitle_long",
      "defaultMessage": "Syncing “{modifierGroupName}” modifier group for {menuItemName} on {menuName} @ {menuLocation}"
    },
    "menusSyncWarningPaused": {
      "id": "menus.sync_warning_paused",
      "defaultMessage": "Menu Sync Paused"
    },
    "menusSyncWarningEnabled": {
      "id": "menus.sync_warning_enabled",
      "defaultMessage": "Menu Sync Enabled"
    },
    "menusSyncWarningOn": {
      "id": "menus.sync_warning_on",
      "defaultMessage": "OrderNerd Sync is incomplete"
    },
    "menusSyncCompleteOn": {
      "id": "menus.sync_complete_on",
      "defaultMessage": "OrderNerd Sync is complete"
    },
    "menusSyncReSync": {
      "id": "menus.sync_resync",
      "defaultMessage": "Re-Sync Menu"
    },
    "menusBeginOrder": {
      "id": "menu.begin_order",
      "defaultMessage": "Select a menu to start an order"
    },
  }
);
export default defaultMessages;

