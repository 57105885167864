export default theme => ({
  '@global': {
    body: {
      backgroundColor: '#fff',
    },
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(5, 1),
  },
  logo: {
    display: 'block',
    marginBottom: theme.spacing(3),
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '260px',
  },
});
