import React, { useCallback, useContext } from 'react';
import type { Ability } from '@casl/ability';

type CanFunction = (action: string, subject: unknown) => boolean;

export const AbilityContext = /* @__PURE__ */ React.createContext<Ability | undefined>(undefined);

export const useAbility = (): CanFunction => {
  const ability = useContext(AbilityContext);
  return useCallback((action: string, subject) => ability?.can(action, subject) ?? false, [ability]);
};

interface WithAbilityProps {
  can: CanFunction;
}

export function withAbility<TProps extends WithAbilityProps>(Component: React.ComponentType<TProps>) {
  // eslint-disable-next-line react/function-component-definition
  return function ComponentWithAbility(props: Omit<TProps, keyof WithAbilityProps>) {
    return (
      <AbilityContext.Consumer>
        {ability => (
          <Component
            {...props as TProps}
            can={(action, subject) => ability?.can(action, subject) ?? false}
          />
        )}
      </AbilityContext.Consumer>
    );
  };
}
