import { defineMessages } from 'react-intl';
export default defineMessages({
  "orderNerdName": {
    "id": "order_nerd.name",
    "defaultMessage": "OrderNerd",
  },
  "orderNerdModalDetailsName": {
    "id": "order_nerd.modal_details.name",
    "defaultMessage": "OrderNerd"
  },
  "orderNerdModalDetailsTitle": {
    "id": "order_nerd.modal_details.title",
    "defaultMessage": "It’s time to clear the clutter and streamline your operations"
  },
  "orderNerdModalDetailsSubtitle": {
    "id": "order_nerd.modal_details.subtitle",
    "defaultMessage": "Popmenu is even more powerful with OrderNerd."
  },
  "orderNerdModalDetailsDescription": {
    "id": "order_nerd.modal_details.description",
    "defaultMessage": "Ready to toss your tablets? OrderNerd makes it easier to manage your " +
    "third-party delivery partners by putting all your online orders in one place, creating " +
    "a better experience for your staff and your guests."
  },
  "orderNerdModalDetailsFootnoteTitle": {
    "id": "order_nerd.modal_details.footnote.title",
    "defaultMessage": "Key advantages of OrderNerd:"
  },
  "orderNerdModalDetailsFootnoteIconOneTitle": {
    "id": "order_nerd.modal_details.footnote.icon_one_title",
    "defaultMessage": "One simple app for all your third-party delivery orders"
  },
  "orderNerdModalDetailsFootnoteIconTwoTitle": {
    "id": "order_nerd.modal_details.footnote.icon_two_title",
    "defaultMessage": "Declutter counters of multiple tablets and cords"
  },
  "orderNerdModalDetailsFootnoteIconThreeTitle": {
    "id": "order_nerd.modal_details.footnote.icon_three_title",
    "defaultMessage": "Consolidated sales reporting for all partners"
  },
  "orderNerdModalDetailsCta": {
    "id": "order_nerd.modal_details.cta",
    "defaultMessage": "Start a conversation with us"
  },
  "orderNerdModalDetailsCancel": {
    "id": "order_nerd.modal_details.cancel",
    "defaultMessage": "Cancel"
  },
  "orderNerdModalTitle": {
    "id": "order_nerd.modal.title",
    "defaultMessage": "Want to learn more about OrderNerd By Popmenu to see if it's a great fit for your restaurant?"
  },
  "orderNerdModalSubtitle": {
    "id": "order_nerd.modal.subtitle",
    "defaultMessage": "Click Submit Request below and a member of our solutions team will reach out soon!"
  },
});
