
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "advisor": {
      "id": "advisor",
      "defaultMessage": "ADVISOR"
    },
    "advisorIndex": {
      "id": "advisor.index",
      "defaultMessage": "Advisor"
    },
    "advisorCreateAnEvent": {
      "id": "advisor.create_marketing_content",
      "defaultMessage": "Create marketing content"
    },
    "advisorEmail": {
      "id": "advisor.email",
      "defaultMessage": "Email"
    },
    "advisorText": {
      "id": "advisor.text",
      "defaultMessage": "Text message"
    },
    "advisorSocialPost": {
      "id": "advisor.social_post",
      "defaultMessage": "Social post"
    },
    "advisorMessagePack": {
      "id": "advisor.message_pack",
      "defaultMessage": "Message pack"
    },
    "advisorUpcomingEvents": {
      "id": "advisor.upcoming_events",
      "defaultMessage": "Upcoming events"
    },
    "advisorNoCardMessage": {
      "id": "advisor.no_cards_message",
      "defaultMessage": "You don’t have any recommendations right now. Check back later for more."
    },
    "advisorNoRootMessagePackMessage": {
      "id": "advisor.no_root_message_pack_message",
      "defaultMessage": "Sorry, we could not create a pre-made offer for you but we can start you off with a blank template."
    }
  }
);
export default defaultMessages;
