import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "modelsDoorDashEventCategory": {
      "id": "models.door_dash.event_category",
      "defaultMessage": "Event"
    },
    "modelsDoorDashDasherStatus": {
      "id": "models.door_dash.dasher_status",
      "defaultMessage": "Driver Status"
    },
    "modelsDoorDashDeliveryStatus": {
      "id": "models.door_dash.delivery_status",
      "defaultMessage": "Delivery Status"
    },
    "modelsDoorDashUpdatedAt": {
      "id": "models.door_dash.updated_at",
      "defaultMessage": "Updated"
    },
    "modelsDoorDashEventCategoryDeliveryCreated": {
      "id": "models.door_dash.event_category.delivery_created",
      "defaultMessage": "Delivery Created"
    },
    "modelsDoorDashEventCategoryDasherConfirmed": {
      "id": "models.door_dash.event_category.dasher_confirmed",
      "defaultMessage": "Driver Confirmed"
    },
    "modelsDoorDashEventCategoryDriverBatched": {
      "id": "models.door_dash.event_category.driver_batched",
      "defaultMessage": "Driver Batched"
    },
    "modelsDoorDashEventCategoryDasherEnrouteToPickup": {
      "id": "models.door_dash.event_category.dasher_enroute_to_pickup",
      "defaultMessage": "Driver Enroute To Pickup"
    },
    "modelsDoorDashEventCategoryDasherConfirmedStoreArrival": {
      "id": "models.door_dash.event_category.dasher_confirmed_store_arrival",
      "defaultMessage": "Driver Confirmed Store Arrival"
    },
    "modelsDoorDashEventCategoryDasherPickedUp": {
      "id": "models.door_dash.event_category.dasher_picked_up",
      "defaultMessage": "Driver Picked Up"
    },
    "modelsDoorDashEventCategoryDasherEnrouteToDropoff": {
      "id": "models.door_dash.event_category.dasher_enroute_to_dropoff",
      "defaultMessage": "Driver Enroute To Dropoff"
    },
    "modelsDoorDashEventCategoryDasherConfirmedConsumerArrival": {
      "id": "models.door_dash.event_category.dasher_confirmed_consumer_arrival",
      "defaultMessage": "Driver Confirmed Consumer Arrival"
    },
    "modelsDoorDashEventCategoryDasherDroppedOff": {
      "id": "models.door_dash.event_category.dasher_dropped_off",
      "defaultMessage": "Driver Dropped Off"
    },
    "modelsDoorDashEventCategoryDeliveryPendingReturn": {
      "id": "models.door_dash.event_category.delivery_pending_return",
      "defaultMessage": "Delivery Pending Return"
    },
    "modelsDoorDashEventCategoryDasherEnrouteToReturn": {
      "id": "models.door_dash.event_category.dasher_enroute_to_return",
      "defaultMessage": "Driver Enroute To Return"
    },
    "modelsDoorDashEventCategoryDasherConfirmedReturnArrival": {
      "id": "models.door_dash.event_category.dasher_confirmed_return_arrival",
      "defaultMessage": "Driver Confirmed Return Arrival"
    },
    "modelsDoorDashEventCategoryDasherDroppedOffReturn": {
      "id": "models.door_dash.event_category.dasher_dropped_off_return",
      "defaultMessage": "Driver Dropped Off Return"
    },
    "modelsDoorDashEventCategoryDeliveryCancelled": {
      "id": "models.door_dash.event_category.delivery_cancelled",
      "defaultMessage": "Delivery Cancelled"
    },
    "modelsDoorDashEventCategoryDeliveryAttempted": {
      "id": "models.door_dash.event_category.delivery_attempted",
      "defaultMessage": "Delivery Attempted"
    },
    "modelsDoorDashEventCategoryDasherSuppliedParkingDetails": {
      "id": "models.door_dash.event_category.dasher_supplied_parking_details",
      "defaultMessage": "Driver Supplied Parking Details"
    },
    "modelsDoorDashEventCategoryDeliveryPostedTipReceived": {
      "id": "models.door_dash.event_category.delivery_post_tip_received",
      "defaultMessage": "Delivery Tip"
    },
    "modelsDoorDashDeliveryStatusScheduled": {
      "id": "models.door_dash.delivery_status.scheduled",
      "defaultMessage": "Scheduled"
    },
    "modelsDoorDashDeliveryStatusAssigned": {
      "id": "models.door_dash.delivery_status.assigned",
      "defaultMessage": "Assigned"
    },
    "modelsDoorDashDeliveryStatusPickedUp": {
      "id": "models.door_dash.delivery_status.picked_up",
      "defaultMessage": "Picked Up"
    },
    "modelsDoorDashDeliveryStatusDelivered": {
      "id": "models.door_dash.delivery_status.delivered",
      "defaultMessage": "Delivered"
    },
    "modelsDoorDashDeliveryStatusPendingReturn": {
      "id": "models.door_dash.delivery_status.pending_return",
      "defaultMessage": "Pending Return"
    },
    "modelsDoorDashDeliveryStatusReturned": {
      "id": "models.door_dash.delivery_status.returned",
      "defaultMessage": "Returned"
    },
    "modelsDoorDashDeliveryStatusCancelled": {
      "id": "models.door_dash.delivery_status.cancelled",
      "defaultMessage": "Cancelled"
    },
    "modelsDoorDashDeliveryStatusCountNotDeliver": {
      "id": "models.door_dash.delivery_status.could_not_deliver",
      "defaultMessage": "Could Not Deliver"
    },
    "modelsDoorDashDasherStatusUnassigned": {
      "id": "models.door_dash.dasher_status.unassigned",
      "defaultMessage": "Unassigned"
    },
    "modelsDoorDashDasherStatusAccepted": {
      "id": "models.door_dash.dasher_status.accepted",
      "defaultMessage": "Accepted"
    },
    "modelsDoorDashDasherStatusEnrouteToPickUp": {
      "id": "models.door_dash.dasher_status.enroute_to_pickup",
      "defaultMessage": "Enroute To Pickup"
    },
    "modelsDoorDashDasherStatusArrivedAtStore": {
      "id": "models.door_dash.dasher_status.arrived_at_store",
      "defaultMessage": "Arrived At Store"
    },
    "modelsDoorDashDasherStatusPickedUp": {
      "id": "models.door_dash.dasher_status.picked_up",
      "defaultMessage": "Picked Up"
    },
    "modelsDoorDashDasherStatusEnrouteToDropoff": {
      "id": "models.door_dash.dasher_status.enroute_to_dropoff",
      "defaultMessage": "Enroute To Dropoff"
    },
    "modelsDoorDashDasherStatusArrivedAtConsumer": {
      "id": "models.door_dash.dasher_status.arrived_at_consumer",
      "defaultMessage": "Arrived At Consumer"
    },
    "modelsDoorDashDasherStatusDroppedOff": {
      "id": "models.door_dash.dasher_status.dropped_off",
      "defaultMessage": "Dropped Off"
    },
    "modelsDoorDashDasherStatusEnrouteToReturn": {
      "id": "models.door_dash.dasher_status.enroute_to_return",
      "defaultMessage": "Enroute To Return"
    },
    "modelsDoorDashDasherStatusArrivedAtReturn": {
      "id": "models.door_dash.dasher_status.arrived_at_return",
      "defaultMessage": "Arrived At Return"
    },
    "modelsDoorDashDasherStatusPendingReturn": {
      "id": "models.door_dash.dasher_status.pending_return",
      "defaultMessage": "Pending Return"
    },
    "modelsDoorDashDasherStatusDroppedOffReturn": {
      "id": "models.door_dash.dasher_status.dropped_off_return",
      "defaultMessage": "Dropped Off Return"
    },
    "modelsDoorDashDasherStatusCountNotDeliver": {
      "id": "models.door_dash.dasher_status.could_not_deliver",
      "defaultMessage": "Could Not Deliver"
    },
  }
);
export default defaultMessages;

