
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "posAttachedSquareItemTitle": {
      "id": "pos.attached_square_item_title",
      "defaultMessage": "Attached Item"
    },
    "posAttachedSquareVariationTitle": {
      "id": "pos.attached_square_variation_title",
      "defaultMessage": "Attached Variation"
    },
    "posSquareItemNotAttached": {
      "id": "pos.square_item_not_attached",
      "defaultMessage": "Item missing Attached Item"
    },
    "posInconsistentLocations": {
      "id": "pos.inconsistent_locations",
      "defaultMessage": "Square Variation and Menu Location have different Square Locations"
    },
    "posWrongProductType": {
      "id": "pos.wrong_product_type",
      "defaultMessage": "Product type is wrong. Square Item can be attached to Regular product type only"
    },
    "posNoSingleVariationAttached": {
      "id": "pos.variation_not_attached",
      "defaultMessage": "Item or Size has no Square Variation attached"
    },
    "posWrongVariationPricingType": {
      "id": "pos.wrong_variation_pricing_type",
      "defaultMessage": "Attached Square Variation has wrong Pricing type. Only Fixed pricing is supported"
    },
    "posInconsistentItemPrice": {
      "id": "pos.inconsistent_item_price",
      "defaultMessage": "Attached Square Variation price and Item price don't match"
    },
    "posCustomPriceTypeNotSupported": {
      "id": "pos.custom_price_type",
      "defaultMessage": "Custom price type is not supported"
    },
    "posDuplicateItemName": {
      "id": "pos.duplicate_item_name",
      "defaultMessage": "Another Square Item with the same name exists and is not synced"
    },
    "posAttachedModifierListHasWarnings": {
      "id": "pos.attached_modifier_list_has_warnings",
      "defaultMessage": "One of the attached Shared Modifier Groups has warnings"
    },
    "posSquareSyncError": {
      "id": "pos.square_sync_error",
      "defaultMessage": "Square sync error"
    },
    "posWrongUnit": {
      "id": "pos.wrong_unit",
      "defaultMessage": "Attached Square Variation has wrong Unit. Only default (Per Item) Unit type is supported"
    },
    "posToastInvalidVisibility": {
      "id": "pos.toast_invalid_visibility",
      "defaultMessage": "Attached Toast Item is not available for online ordering"
    },
    "posToastRequiredNestedModifiersBlocking": {
      "id": "pos.toast_required_nested_modifiers_blocking",
      "defaultMessage": "Attached Toast Modifier Group has required nested modifiers (blocking its usage)"
    },
    "posToastRequiredNestedModifiersNonBlocking": {
      "id": "pos.toast_required_nested_modifiers_non_blocking",
      "defaultMessage": "Attached Toast Modifier Group has required nested modifiers (non-blocking its usage)"
    },
    "posToastNonRequiredNestedModifiers": {
      "id": "pos.toast_non_required_nested_modifiers",
      "defaultMessage": "Attached Toast Modifier Group has non-required nested modifiers"
    },
    "posToastInvalidUnitOfMeasure": {
      "id": "pos.toast_invalid_unit_of_measure",
      "defaultMessage": "Attached Toast Item has an incompatible unit of measure"
    },
    "posToastSizeModifierNotAttached": {
      "id": "pos.toast_size_modifier_not_attached",
      "defaultMessage": "Size has no Toast Modifier attached or not all Toast Item Modifiers are attached"
    },
    "posToastInconsistentItemPrice": {
      "id": "pos.toast_inconsistent_item_price",
      "defaultMessage": "Attached Toast Item/Modifier price and Item/Size price don't match or zero"
    },
    "posToastInvalidPricingStrategy": {
      "id": "pos.toast_invalid_pricing_strategy",
      "defaultMessage": "Attached Toast Item has an incompatible pricing strategy"
    },
    "posToastInconsistentModifierGroups": {
      "id": "pos.toast_inconsistent_modifier_groups",
      "defaultMessage": "Attached Toast Modifier Groups and Shared Modifier Groups don't match"
    },
    "posToastInconsistentRequiredModifierGroups": {
      "id": "pos.toast_inconsistent_required_modifier_groups",
      "defaultMessage": "Attached Required Toast Modifier Groups and Shared Extra Groups don't match"
    },
    "posToastBlockingCyclicModifiers": {
      "id": "pos.toast_blocking_cyclic_modifiers",
      "defaultMessage": "Attached Toast item has a modifier that is nested into itself (blocking usage)"
    },
    "posToastNonBlockingCyclicModifiers": {
      "id": "pos.toast_non_blocking_cyclic_modifiers",
      "defaultMessage": "Attached Toast item has a modifier that is nested into itself (non-blocking usage)"
    },
    "posToastAttachedNotVisibleModifierGroup": {
      "id": "pos.toast_attached_not_visible_modifier_group",
      "defaultMessage": "Toast Modifier Group with invalid visibility present"
    },
    "posToastAttachedModifierGroupHasBlockingWarnings": {
      "id": "pos.toast_attached_modifier_group_has_blocking_warnings",
      "defaultMessage": "One of the attached Shared Modifier Groups has blocking warnings"
    },
    "posToastAttachedModifierGroupHasNonBlockingWarnings": {
      "id": "pos.toast_attached_modifier_group_has_non_blocking_warnings",
      "defaultMessage": "One of the attached Shared Modifier Groups has non-blocking warnings"
    },
    "posModifierNotAttached": {
      "id": "pos.modifier_not_attached",
      "defaultMessage": "Modifier has no Square Modifier attached"
    },
    "posInconsistentModifierPrice": {
      "id": "pos.inconsistent_modifier_price",
      "defaultMessage": "Attached Square Modifier price and Modifier price don't match"
    },
    "posInconsistentSelectionType": {
      "id": "pos.inconsistent_selection_type",
      "defaultMessage": "Attached Square Modifier List and Modifier Group maximum selection settings don't match"
    },
    "posAbsentAtSomeLocations": {
      "id": "pos.absent_at_some_locations",
      "defaultMessage": "Attached Square Modifier List is absent at some used Square Locations"
    },
    "posSharedExtraGroupNotAttached": {
      "id": "pos.shared_extra_group_not_attached",
      "defaultMessage": "Modifier Group missing Attached Modifier Group"
    },
    "posNoExtras": {
      "id": "pos.no_extras",
      "defaultMessage": "Shared Modifier Group has no Modifiers"
    },
    "posToastModifierNotAttached": {
      "id": "pos.toast_modifier_not_attached",
      "defaultMessage": "Modifier has no Toast Modifier attached"
    },
    "posToastInconsistentModifierPrice": {
      "id": "pos.toast_inconsistent_modifier_price",
      "defaultMessage": "Attached Toast Modifier price and Modifier price don't match"
    },
    "posToastInvalidModifierPricingStrategy": {
      "id": "pos.toast_invalid_modifier_group_pricing_strategy",
      "defaultMessage": "Attached Toast Modifier Group pricing strategy is not supported"
    },
    "posToastPreModifierGroupAttached": {
      "id": "pos.toast_pre_modifier_group_attached",
      "defaultMessage": "Attached Toast Modifier Group has Pre-Modifier Group attached which is not supported"
    },
    "posToastInconsistentSelectionSettings": {
      "id": "pos.toast_inconsistent_selection_settings",
      "defaultMessage": "Attached Toast Modifier Group and Modifier Group maximum selection settings don't match"
    },
    "posProviderMenuSyncInProgress": {
      "id": "pos.provider_menu_sync_in_progress",
      "defaultMessage": "Provider sync in progress..."
    },
    "posToastItemSyncEnabled": {
      "id": "pos.toast_item_sync_enabled",
      "defaultMessage": "Toast Menu Item Sync Enabled"
    },
    "posToastModifierSyncEnabled": {
      "id": "pos.toast_modifier_sync_enabled",
      "defaultMessage": "Toast Modifier Sync Enabled"
    },
    "posNcrSilverModifierSyncEnabled": {
      "id": "pos.ncr_silver_modifier_sync_enabled",
      "defaultMessage": "NCR Silver Modifier Sync Enabled"
    },
    "posSquareItemSyncEnabled": {
      "id": "pos.square_item_sync_enabled",
      "defaultMessage": "Square Menu Item Sync Enabled"
    },
    "posSquareModifierSyncEnabled": {
      "id": "pos.square_modifier_sync_enabled",
      "defaultMessage": "Square Modifier Sync Enabled"
    },
    "posNcrSilverItemSyncEnabled": {
      "id": "pos.ncr_silver_item_sync_enabled",
      "defaultMessage": "Ncr Silver Menu Item Sync Enabled"
    },
    "posDeliverectProductSyncEnabled": {
      "id": "pos.deliverect_item_sync_enabled",
      "defaultMessage": "Deliverect Product Sync Enabled"
    },
    "posModifierGroupCreatedBySync": {
      "id": "pos.modifier_group_created_by_sync",
      "defaultMessage": "Created by Menu Sync"
    },
    "posModifierGroupHasSyncWarnings": {
      "id": "pos.modifier_group_has_sync_warnings",
      "defaultMessage": "Modifier Group Sync Warnings"
    },
    "posNoToastRestaurantFeeServiceCharge": {
      "id": "pos.no_toast_restaurant_fee_service_charge",
      "defaultMessage": "Toast service charge must be set up before Pickup can be enabled."
    },
    "posNoToastRestaurantDeliveryServiceCharge": {
      "id": "pos.no_toast_restaurant_delivery_service_charge",
      "defaultMessage": "Toast delivery service charge must be set up before Delivery can be enabled."
    },
    "posNoDineInForLocation": {
      "id": "pos.no_dine_in_for_location",
      "defaultMessage": "Contactless Dine-In is not yet supported."
    },
    "posDeliveryHoursSynchedByDiningOption": {
      "id": "pos.delivery_hours_synched_by_dining_option",
      "defaultMessage": "Delivery Hours is using the associated dining option behavior schedule."
    },
    "posOrderingHoursSynchedByDiningOption": {
      "id": "pos.ordering_hours_synched_by_dining_option",
      "defaultMessage": "Pickup Hours is using the associated dining option behavior schedule."
    },
    "posToastOnlineHours": {
      "id": "pos.toast_online_hours",
      "defaultMessage": "Toast Online Ordering Hours"
    },
    "posToastOnlineScheduleTitle": {
      "id": "pos.toast_online_schedule.title",
      "defaultMessage": "Toast Online Ordering Schedule"
    },
    "posToastOnlineScheduleDescription": {
      "id": "pos.toast_online_schedule.description",
      "defaultMessage": "Pickup and Delivery hours will be overridden with Toast online ordering schedule if they are present."
    },
    "posToastOnlineScheduleAlertDisabledTitle": {
      "id": "pos.toast_online_schedule.alert.disabled.title",
      "defaultMessage": "Toast Online Ordering Settings"
    },
    "posToastOnlineScheduleAlertDisabledBody": {
      "id": "pos.toast_online_schedule.alert.disabled.body",
      "defaultMessage": "Allow your guests to schedule orders in case you want to use Toast Schedule."
    },
    "posToastPickUpAndDeliverySettingsTitle": {
      "id": "pos.toast_pickup_and_delivery_settings.title",
      "defaultMessage": "Unavailable - Order Throttling is enabled"
    },
    "posToastPickUpAndDeliverySettingsBody": {
      "id": "pos.toast_pickup_and_delivery_settings.body",
      "defaultMessage": "This feature can not be used when Order Throttling is turned on."
    },
    "posToastOnlineScheduleAlertSelectDiningOptionBody": {
      "id": "pos.toast_online_schedule.alert.select_dining_option.body",
      "defaultMessage": "Takeout or Delivery dining option should be specified."
    },
    "posToastOnlineScheduleToggle": {
      "id": "pos.toast_online_schedule.toggle",
      "defaultMessage": "Override Popmenu hours with Toast schedule"
    },
    "posItemSyncedAt": {
      "id": "pos.item_synced_at",
      "defaultMessage": "Provider item sync - Completed {syncedAt}"
    },
    "posToastSummarySyncedAt": {
      "id": "pos.toast_summary.synced_at",
      "defaultMessage": "Synced on {date}"
    },
    "posToastSummaryNotSynced": {
      "id": "pos.toast_summary.not_synced",
      "defaultMessage": "Not synced"
    },
    "posToastSummaryDeliveryMessage": {
      "id": "pos.toast_summary.delivery_message",
      "defaultMessage": "Service Charge with name of \"Popmenu Delivery\" and Open Price must be configured within Toast before accepting delivery orders."
    },
    "posToastSummaryFeeMessage": {
      "id": "pos.toast_summary.fee_message",
      "defaultMessage": "Service Charge with name of \"Popmenu\" and Open Price must be configured within Toast before accepting orders."
    },
    "posToastSummaryAffectedItemsCountPlural": {
      "id": "pos.toast_summary.affected_items_count.plural",
      "defaultMessage": "{count} items affected."
    },
    "posToastSummaryAffectedItemsCountSingular": {
      "id": "pos.toast_summary.affected_items_count.singular",
      "defaultMessage": "{count} item affected."
    },
    "posToastSummaryOrderingPartnersMissingItemsPlural": {
      "id": "pos.toast_summary.ordering_partners_missing_items.plural",
      "defaultMessage": "{count} items affected."
    },
    "posToastSummaryOrderingPartnersMissingItemsSingular": {
      "id": "pos.toast_summary.ordering_partners_missing_items.singular",
      "defaultMessage": "{count} item affected."
    },
    "posToastSummaryOrderingPartnersMissingMenuGroupsPlural": {
      "id": "pos.toast_summary.ordering_partners_missing_menu_groups.plural",
      "defaultMessage": "{count} menu groups affected."
    },
    "posToastSummaryOrderingPartnersMissingMenuGroupsSingular": {
      "id": "pos.toast_summary.ordering_partners_missing_menu_groups.singular",
      "defaultMessage": "{count} menu group affected."
    },
    "posToastSummaryOrderingPartnersMissingMenusPlural": {
      "id": "pos.toast_summary.ordering_partners_missing_menus.plural",
      "defaultMessage": "{count} menus affected."
    },
    "posToastSummaryOrderingPartnersMissingMenusSingular": {
      "id": "pos.toast_summary.ordering_partners_missing_menus.singular",
      "defaultMessage": "{count} menu affected."
    },
    "posToastSummaryZeroMenus": {
      "id": "pos.toast_summary.zero_menus",
      "defaultMessage": "All groups in toast menu {name} have visibility set to not show on Popmenu."
    },
    "posToastSummaryZeroMenuGroups": {
      "id": "pos.toast_summary.zero_menu_groups",
      "defaultMessage": "All items in toast menu group {name} have visibility set to not show on Popmenu."
    },
    "posToastDueTitle": {
      "id": "pos.toast_due.title",
      "defaultMessage": "Toast Prep/Delivery Times"
    },
    "posToastDueDescription": {
      "id": "pos.toast_due.description",
      "defaultMessage": "Popmenu prep time and delay will be overridden by Toast prep time."
    },
    "posToastDueToggle": {
      "id": "pos.toast_due.toggle",
      "defaultMessage": "Override Popmenu prep time and delay"
    },
    "posToastDueOnComplete": {
      "id": "pos.toast_due.on_complete",
      "defaultMessage": "Toast prep times {status} for {locationName}!"
    },
    "posToastOnlineScheduleOnComplete": {
      "id": "pos.toast_online_schedule.on_complete",
      "defaultMessage": "Toast online ordering schedule {status} for {locationName}!"
    },
    "posToastDisconnectConfirmMessage": {
      "id": "pos.toast_disconnect.confirm_massage",
      "defaultMessage": "Are you sure you want to disconnect your Toast restaurant from this location?"
    },
    "posToastDisconnectConfirmTitle": {
      "id": "pos.toast_disconnect.confirm_title",
      "defaultMessage": "Disconnect Toast Restaurant"
    },
    "posToastDisconnectButton": {
      "id": "pos.toast_disconnect.button",
      "defaultMessage": "Disconnect Toast Restaurant"
    },
    "posToastQuoteTimes": {
      "id": "pos.toast_quote_times",
      "defaultMessage": "POS Sync Enabled"
    },
    "posPickupDueSyncedByDiningOption": {
      "id": "pos.pickup_due_synced_by_dining_option",
      "defaultMessage": "Default Time to Prepare is synced from Toast 'Take out' quote time."
    },
    "posDeliveryDueSyncedByDiningOption": {
      "id": "pos.delivery_due_synced_by_dining_option",
      "defaultMessage": "Default Delivery Time is synced from Toast 'Delivery' quote time."
    },
    "posBillingPaymentMethodIsNotSetTitle": {
      "id": "pos.billing.payment_method_is_not_set_title",
      "defaultMessage": "Billing for Online Ordering Fees is Enabled"
    },
    "posBillingPaymentMethodIsNotSetWarning": {
      "id": "pos.billing.payment_method_is_not_set_warning",
      "defaultMessage": "Online ordering fees will continue to accrue and will be billed once payment method is provided. Please select your preferred payment method as soon as possible."
    },
    "posBillingUseBankAccount": {
      "id": "pos.billing.use_bank_account",
      "defaultMessage": "Use bank account"
    },
    "posBillingUseCreditCard": {
      "id": "pos.billing.use_credit_card",
      "defaultMessage": "Use credit card"
    },
    "posBillingCreditCardField": {
      "id": "pos.billing.credit_card_field",
      "defaultMessage": "Credit card"
    },
    "posBillingBankAccountField": {
      "id": "pos.billing.bank_account_field",
      "defaultMessage": "Bank account"
    },
    "posBillingConfigSaved": {
      "id": "pos.billing.config_saved",
      "defaultMessage": "Billing config saved!"
    },
    "posNcrSilverAccountMenuSyncStarted": {
      "id": "pos.ncr_silver_account.menu_sync_started",
      "defaultMessage": "NCR Silver Syncing Started!"
    },
    "posAlohaAccountConnected": {
      "id": "pos.aloha_account.connected",
      "defaultMessage": "Aloha Account is connected to popmenu restaurant"
    },
    "posAlohaAccountNotConnected": {
      "id": "pos.aloha_account.not_connected",
      "defaultMessage": "Aloha Account isn't connected to any popmenu restaurant"
    },
    "posNcrPaymentAccountPageTitle": {
      "id": "pos.ncr_payment_account.page_title",
      "defaultMessage": "Root - NCR Payment Accounts"
    },
    "posNcrPaymentAccountReady": {
      "id": "pos.ncr_payment_account.ready",
      "defaultMessage": "NCR Payment Account is ready"
    },
    "posNcrPaymentAccountPending": {
      "id": "pos.ncr_payment_account.pending",
      "defaultMessage": "Waiting for NCR public key"
    },
    "posNcrPaymentAccountCreateModalTitle": {
      "id": "pos.ncr_payment_account.create_modal_title",
      "defaultMessage": "Connect Ncr Payment"
    },
    "posNcrPaymentAccountCreateModalCompanyId": {
      "id": "pos.ncr_payment_account.create_modal_company_id",
      "defaultMessage": "NCR Company ID"
    },
    "posNcrPaymentAccountCreateModalStoreNumber": {
      "id": "pos.ncr_payment_account.create_modal_store_number",
      "defaultMessage": "NCR Store Number"
    },
    "posNcrPaymentAccountCreateModalSubmit": {
      "id": "pos.ncr_payment_account.create_modal_submit",
      "defaultMessage": "Save and Generate Keys"
    },
    "posNcrPaymentAccountCreateModalSuccess": {
      "id": "pos.ncr_payment_account.create_modal_success",
      "defaultMessage": "NCR Payment Account was successfully created!"
    },
    "posNcrPaymentAccountUpdateModalTitle": {
      "id": "pos.ncr_payment_account.update_modal_title",
      "defaultMessage": "Connect Ncr Payment"
    },
    "posNcrPaymentAccountUpdateModalCompanyId": {
      "id": "pos.ncr_payment_account.update_modal_company_id",
      "defaultMessage": "NCR Company ID"
    },
    "posNcrPaymentAccountUpdateModalStoreNumber": {
      "id": "pos.ncr_payment_account.update_modal_store_number",
      "defaultMessage": "NCR Store Number"
    },
    "posNcrPaymentAccountUpdateModalPopmenuKey": {
      "id": "pos.ncr_payment_account.update_modal_popmenu_key",
      "defaultMessage": "Popmenu Public Key"
    },
    "posNcrPaymentAccountUpdateModalCopyPopmenuKey": {
      "id": "pos.ncr_payment_account.update_modal_copy_popmenu_key",
      "defaultMessage": "Copy the key"
    },
    "posNcrPaymentAccountUpdateModalCopyPopmenuKeyDone": {
      "id": "pos.ncr_payment_account.update_modal_copy_popmenu_key_done",
      "defaultMessage": "Popmenu public key copied to clipboard"
    },
    "posNcrPaymentAccountUpdateModalNcrKey": {
      "id": "pos.ncr_payment_account.update_modal_ncr_key",
      "defaultMessage": "NCR Public Key"
    },
    "posNcrPaymentAccountUpdateModalSuccess": {
      "id": "pos.ncr_payment_account.update_modal_success",
      "defaultMessage": "NCR Payment Account was successfully updated!"
    },
  }
);
export default defaultMessages;

