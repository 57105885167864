import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { compose } from '@shakacode/recompose';

import { withIntl } from '../../utils/withIntl';
import { withSnackbar } from '../../utils/withSnackbar';
import updateUserPasswordMutation from '../../libs/gql/mutations/users/updateUserPasswordMutation.gql';
import { passwordValidator } from '../../utils/forms';

import BasicForm, { SubmitGroup, TextFieldGroup } from '../../admin/shared/forms/BasicForm';

class ResetForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      updated: false,
    };
  }

  render() {
    if (this.state.updated) {
      return <Redirect to="/" />;
    }
    return (
      <BasicForm
        mutate={{
          mutation: updateUserPasswordMutation,
          onCompleted: () => {
            this.props.showSnackbar('You can now sign in with your new password!');
            this.setState({ updated: true });
          },
          toVariables: variables => ({
            ...variables,
            token: this.props.match.params.token,
          }),
        }}
      >
        <TextFieldGroup
          field="password"
          title={this.props.t('users.password_new')}
          type="password"
          helperText={this.props.t('models.user.password_helper_text')}
          validate={passwordValidator}
        />
        <TextFieldGroup
          field="passwordConfirmation"
          title={this.props.t('users.password_new_confirm')}
          type="password"
          validate={passwordValidator}
        />
        <SubmitGroup justify="center" title={this.props.t('users.update_password')} />
      </BasicForm>
    );
  }
}

ResetForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string,
    }),
  }).isRequired,
  showSnackbar: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  withSnackbar,
  withIntl,
  withRouter,
)(ResetForm);
