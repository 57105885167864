
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "liveDomainsCreate": {
      "id": "live_domains.create",
      "defaultMessage": "Enter your existing domain to begin full-site hosting setup."
    },
    "liveDomainsDnsSnapshotsSummary": {
      "id": "live_domains.dns_snapshots.summary",
      "defaultMessage": "Existing DNS records have been imported to ensure email services and other subdomains continue to run once nameservers have been pointed to Popmenu.\n\nWe may not have found every existing record, so double-check the list below to be sure."
    },
    "liveDomainsRecordTypeHintsARecordType": {
      "id": "live_domains.record_type_hints.a_record_type",
      "defaultMessage": "An IPv4 address. Enter multiple addresses on separate lines.\n\nExample:\n192.0.2.235\n198.51.100.234"
    },
    "liveDomainsRecordTypeHintsAaaaRecordType": {
      "id": "live_domains.record_type_hints.aaaa_record_type",
      "defaultMessage": "An IPv6 address. Enter multiple addresses on separate lines.\n\nExample:\n2001:0db8:85a3:0:0:8a2e:0370:7334 \nfe80:0:0:0:202:b3ff:fe1e:8329"
    },
    "liveDomainsRecordTypeHintsCnameRecordType": {
      "id": "live_domains.record_type_hints.cname_record_type",
      "defaultMessage": "An alternate domain name to resolve to.\n\nExample:\nwww.example.com"
    },
    "liveDomainsRecordTypeHintsMxRecordType": {
      "id": "live_domains.record_type_hints.mx_record_type",
      "defaultMessage": "A priority and a domain name that specifies a mail server. Enter multiple values on separate lines.\nFormat: [priority] [mail server host name]\n\nExample:\n10 mailserver.example.com.\n20 mailserver2.example.com."
    },
    "liveDomainsRecordTypeHintsNsRecordType": {
      "id": "live_domains.record_type_hints.ns_record_type",
      "defaultMessage": "Alternative nameservers for a subdomain.\n\nExample:\nns1.amazon.com."
    },
    "liveDomainsRecordTypeHintsSpfRecordType": {
      "id": "live_domains.record_type_hints.spf_record_type",
      "defaultMessage": "An SPF record. Enter multiple values on separate lines. Enclose values in quotation marks.\n\nExample:\n\"v=spf1 ip4:192.168.0.1/16-all\""
    },
    "liveDomainsRecordTypeHintsSrvRecordType": {
      "id": "live_domains.record_type_hints.srv_record_type",
      "defaultMessage": "An SRV record. Enter multiple values on separate lines.\nFormat: [priority] [weight] [port] [server host name]\n\nExample:\n1 10 5269 xmpp-server.example.com.\n2 12 5060 sip-server.example.com."
    },
    "liveDomainsRecordTypeHintsTxtRecordType": {
      "id": "live_domains.record_type_hints.txt_record_type",
      "defaultMessage": "A text record. Enter multiple values on separate lines. Enclose values in quotation marks.\n\nExample:\n\"Sample Text Entry\"\n\"MYKEY=value\""
    }
  }
);
export default defaultMessages;

