
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "billingSetupAccount": {
      "id": "billing_setup.account",
      "defaultMessage": "Account"
    },
    "billingSetupAddPayment": {
      "id": "billing_setup.add_payment",
      "defaultMessage": "Add payment"
    },
    "billingSetupAddPaymentMethod": {
      "id": "billing_setup.add_payment_method",
      "defaultMessage": "Add payment method"
    },
    "billingSetupBank": {
      "id": "billing_setup.bank",
      "defaultMessage": "Bank Account"
    },
    "billingSetupCard": {
      "id": "billing_setup.card",
      "defaultMessage": "Credit Card"
    },
    "billingSetupCurrentPaymentMethod": {
      "id": "billing_setup.current_payment",
      "defaultMessage": "Current Payment"
    },
    "billingSetupIndie": {
      "id": "billing_setup.indie",
      "defaultMessage": "Indie"
    },
    "billingSetupIndieConfirmationMessage": {
      "id": "billing_setup.indie_confirmation_message",
      "defaultMessage": "The selected plan will be used new Indie clients, existing clients will clients will not be affected."
    },
    "billingSetupIsPrimaryPayment": {
      "id": "billing_setup.is_primary_payment",
      "defaultMessage": "Use as primary payment method"
    },
    "billingSetupPaymentForAllSubscriptions": {
      "id": "billing_setup.apply_to_all_subscriptions",
      "defaultMessage": "Apply to all subscriptions"
    },
    "billingSetupNewCard": {
      "id": "billing_setup.new_card",
      "defaultMessage": "Credit or debit card"
    },
    "billingSetupNewPaymentMethod": {
      "id": "billing_setup.new_payment",
      "defaultMessage": "New Payment"
    },
    "billingSetupNoPayment": {
      "id": "billing_setup.no_payment",
      "defaultMessage": "No payment method found"
    },
    "billingSetupOnetime": {
      "id": "billing_setup.onetime",
      "defaultMessage": "One-Time"
    },
    "billingSetupOnetimeCharge": {
      "id": "billing_setup.onetime_charge",
      "defaultMessage": "Add One-time Charge"
    },
    "billingSetupOnetimeChargeCount": {
      "id": "billing_setup.onetime_charge_count",
      "defaultMessage": "Quantity"
    },
    "billingSetupOnetimeConfirm": {
      "id": "billing_setup.onetime_confirm",
      "defaultMessage": "Are you sure you want to create this charge? One-time charges will be invoiced immediately."
    },
    "billingSetupOnetimeConfirmTitle": {
      "id": "billing_setup.onetime_confirm_title",
      "defaultMessage": "Confirm Charge"
    },
    "billingSetupPayment": {
      "id": "billing_setup.payment",
      "defaultMessage": "Payment"
    },
    "billingSetupProductName": {
      "id": "billing_setup.product_name",
      "defaultMessage": "Product Name"
    },
    "billingSetupRecurring": {
      "id": "billing_setup.recurring",
      "defaultMessage": "Recurring"
    },
    "billingSetupReplaceConfirmation": {
      "id": "billing_setup.replace_confirmation",
      "defaultMessage": "The new payment method will be used for the following subscription"
    },
    "billingSetupStripeUrl": {
      "id": "billing_setup.stripe_url",
      "defaultMessage": "View on Stripe"
    },
    "billingSetupSubscription": {
      "id": "billing_setup.subscription",
      "defaultMessage": "Subscription"
    },
    "billingSetupTitle": {
      "id": "billing_setup.title",
      "defaultMessage": "Billing"
    },
    "billingSetupUpdateInfo": {
      "id": "billing_setup.update_info",
      "defaultMessage": "We’re making changes to this page. To update your Payment Method information, send us a message at {billingEmail}."
    },
    "billingSetupBillingPortalAccess": {
      "id": "billing_setup.billing_portal_access",
      "defaultMessage": "Billing Portal Access"
    },
    "billingSetupManageBillingAccounts": {
      "id": "billing_setup.manage_billing_accounts",
      "defaultMessage": "Manage billing accounts"
    },
    "billingSetupPayOutstandingInvoices": {
      "id": "billing_setup.pay_outstanding_invoices",
      "defaultMessage": "Pay outstanding invoices"
    },
    "billingSetupPaySuccess": {
      "id": "billing_setup.Payment_success",
      "defaultMessage": "Payment successful!"
    },
    "billingSetupPayDeclined": {
      "id": "billing_setup.Payment_declined",
      "defaultMessage": "Payment Declined"
    },
    "billingSetupAddNewLocation": {
      "id": "billing_setup.add_new_location",
      "defaultMessage": "Add new location"
    },
    "billingSetupAddNewProducts": {
      "id": "billing_setup.add_new_products",
      "defaultMessage": "Add new Products"
    },
  }
);
export default defaultMessages;
