
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "rootIntegrationsGoogleSettingsAccount": {
      "id": "root.integrations.google_settings.account",
      "defaultMessage": "Account"
    },
    "rootIntegrationsGoogleSettingsAccountCreatingMessage": {
      "id": "root.integrations.google_settings.account_creating_message",
      "defaultMessage": "Google account is being set up. Please check back later."
    },
    "rootIntegrationsGoogleSettingsAnalyticsAccountCreated": {
      "id": "root.integrations.google_settings.analytics_account_created",
      "defaultMessage": "Analytics Account Created"
    },
    "rootIntegrationsGoogleSettingsCampaign": {
      "id": "root.integrations.google_settings.campaign",
      "defaultMessage": "Campaign"
    },
    "rootIntegrationsGoogleSettingsCampaignSaved": {
      "id": "root.integrations.google_settings.campaign_saved",
      "defaultMessage": "Campaign setting has been saved!"
    },
    "rootIntegrationsGoogleSettingsConversionScript": {
      "id": "root.integrations.google_settings.conversion_script",
      "defaultMessage": "Conversion Script"
    },
    "rootIntegrationsGoogleSettingsDateTime": {
      "id": "root.integrations.google_settings.date_time",
      "defaultMessage": "Date Time"
    },
    "rootIntegrationsGoogleSettingsDisclaimer": {
      "id": "root.integrations.google_settings.disclaimer",
      "defaultMessage": "The account setting will take precedent over campaign if both are selected. We are migrating to account based reporting. Campaign based reporting will be deprecated in the future."
    },
    "rootIntegrationsGoogleSettingsFalse": {
      "id": "root.integrations.google_settings.false",
      "defaultMessage": "No"
    },
    "rootIntegrationsGoogleSettingsMessage": {
      "id": "root.integrations.google_settings.message",
      "defaultMessage": "Message"
    },
    "rootIntegrationsGoogleSettingsSave": {
      "id": "root.integrations.google_settings.save",
      "defaultMessage": "Save Settings"
    },
    "rootIntegrationsGoogleSettingsSaveCampaign": {
      "id": "root.integrations.google_settings.save_campaign",
      "defaultMessage": "Save Campaign"
    },
    "rootIntegrationsGoogleSettingsSelectAccount": {
      "id": "root.integrations.google_settings.select_account",
      "defaultMessage": "Select an account"
    },
    "rootIntegrationsGoogleSettingsSelectCampaign": {
      "id": "root.integrations.google_settings.select_campaign",
      "defaultMessage": "Select a campaign"
    },
    "rootIntegrationsGoogleSettingsSelf": {
      "id": "root.integrations.google_settings.self",
      "defaultMessage": "Settings"
    },
    "rootIntegrationsGoogleSettingsSiteMapSubmitted": {
      "id": "root.integrations.google_settings.site_map_submitted",
      "defaultMessage": "Site Map Submitted"
    },
    "rootIntegrationsGoogleSettingsSiteVerified": {
      "id": "root.integrations.google_settings.site_verified",
      "defaultMessage": "Site Verified"
    },
    "rootIntegrationsGoogleSettingsStatus": {
      "id": "root.integrations.google_settings.status",
      "defaultMessage": "Status"
    },
    "rootIntegrationsGoogleSettingsTrackingScript": {
      "id": "root.integrations.google_settings.tracking_script",
      "defaultMessage": "Tracking Script"
    },
    "rootIntegrationsGoogleSettingsTrue": {
      "id": "root.integrations.google_settings.true",
      "defaultMessage": "Yes"
    },
    "rootIntegrationsEnable": {
      "id": "root.integrations.enable",
      "defaultMessage": "Enable"
    },
    "rootStatsBillingSubscriptionsHeader": {
      "id": "root.stats.billing_subscriptions.header",
      "defaultMessage": "Subscriptions"
    },
    "rootTasksCreate": {
      "id": "root.tasks.create",
      "defaultMessage": "Create Task"
    },
    "rootTasksTitle": {
      "id": "root.tasks.title",
      "defaultMessage": "Tasks"
    },
    "rootStatsBillingSubscriptionsUpcomingInvoiceTotal": {
      "id": "root.stats.billing_subscriptions.upcoming_invoice_total",
      "defaultMessage": "Upcoming Invoices Total"
    },
    "rootIntegrationsGoogleName": {
      "id": "root.integrations.google.name",
      "defaultMessage": "Google Admin"
    },
    "rootIntegrationsGoogleConnect": {
      "id": "root.integrations.google.connect",
      "defaultMessage": "Connect Google Admin Account"
    },
    "rootIntegrationsGoogleConnectMessage": {
      "id": "root.integrations.google.connect_message",
      "defaultMessage": "- Domain verification and sitemap management\n- Additional visitor statistics and analytics"
    },
    "rootIntegrationsGoogleScope": {
      "id": "root.integrations.google.scope",
      "defaultMessage": "profile email https://www.googleapis.com/auth/analytics https://www.googleapis.com/auth/analytics.edit https://www.googleapis.com/auth/analytics.provision https://www.googleapis.com/auth/siteverification https://www.googleapis.com/auth/webmasters https://www.googleapis.com/auth/adwords"
    },
    "rootIntegrationsGoogleAccessType": {
      "id": "root.integrations.google.access_type",
      "defaultMessage": "offline"
    },
    "rootIntegrationsGoogleSignInPath": {
      "id": "root.integrations.google.sign_in_path",
      "defaultMessage": "/sign-in/google"
    },
    "rootIntegrationsGoogleSignInVariable": {
      "id": "root.integrations.google.sign_in_variable",
      "defaultMessage": "googleCode"
    },
    "rootIntegrationsGooglePrompt": {
      "id": "root.integrations.google.prompt",
      "defaultMessage": "consent"
    },
    "rootIntegrationsNewDescription": {
      "id": "root.integrations.new_description",
      "defaultMessage": "Connect a platform to begin integration"
    },
    "rootIntegrationsDestroy": {
      "id": "root.integrations.destroy",
      "defaultMessage": "Remove Integration"
    },
    "rootIntegrationsDestroyConfirm": {
      "id": "root.integrations.destroy_confirm",
      "defaultMessage": "Are you sure you want to remove this integration?"
    },
    "rootIntegrationsDestroyConfirmTitle": {
      "id": "root.integrations.destroy_confirm_title",
      "defaultMessage": "Confirm Removal"
    },
    "rootIntegrationsEdit": {
      "id": "root.integrations.edit",
      "defaultMessage": "Manage Integration"
    },
    "rootIntegrationsConnect": {
      "id": "root.integrations.connect",
      "defaultMessage": "Connect"
    },
    "rootIntegrationsTitle": {
      "id": "root.integrations.title",
      "defaultMessage": "Root Integrations"
    },
  }
);
export default defaultMessages;

