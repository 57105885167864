import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "indieOnboardingNeedHelp": {
      "id": "indie_onboarding.need_help",
      "defaultMessage": "Need help or have questions?"
    },
    "indieOnboardingEmailUs": {
      "id": "indie_onboarding.email_us",
      "defaultMessage": "Email us!"
    },
    "indieOnboardingSupportEmail": {
      "id": "indie_onboarding.support_email",
      "defaultMessage": "support@popmenu.com"
    },
    "indieOnboardingQuestionMark": {
      "id": "indie_onboarding.question_mark",
      "defaultMessage": "?"
    },
  }
);
export default defaultMessages;
