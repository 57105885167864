import type { StyleRules, Theme } from '@material-ui/core/styles';

const genericStyles = (theme: Theme) => ({
  emptyMessage: {
    margin: theme.spacing(8, 0, 6),
    textAlign: 'center',
  },
  moreButton: {
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  panel: {
    [theme.breakpoints.up('md')]: {
      margin: `${theme.spacing(8)}px auto !important`,
    },
    [theme.breakpoints.up('sm')]: {
      margin: `${theme.spacing(2)}px auto !important`,
      padding: theme.spacing(8, 4, 4, 4),
    },
    border: '1px solid #CCC',
    boxShadow: '0 4px 24px rgba(0, 0, 0, .1)',
    margin: `${theme.spacing(1)}px auto !important`,
    maxWidth: '420px',
    minWidth: '300px',
    padding: theme.spacing(4),
    width: '95%',
  },
  panelLarge: {
    maxWidth: '900px',
  },
  panelLinks: {
    '& a': {
      textDecoration: 'none',
    },
    '& button': {
      marginTop: theme.spacing(2),
    },
    textAlign: 'center',
  },
  panelLogo: {
    display: 'block',
    height: '60px',
    margin: '0 auto',
    maxWidth: '100%',
  },
  panelLogoLink: {
    display: 'block',
    margin: `0 auto ${theme.spacing(4)}px auto`,
  },
  panelLogoLinkAuth: {
    display: 'block',
    margin: `0 auto ${theme.spacing(8)}px auto`,
  },
  panelMedium: {
    maxWidth: '600px',
  },
  panelWrapper: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
  },
  panelWrapperInner: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  searchWrapper: {
    marginBottom: theme.spacing(2),
  },
  sliderFormContainer: {
    '@media (min-width: 1600px)': {
      padding: theme.spacing(15, 8),
    },
    border: 'none',
    boxShadow: 'none',
    height: '100vh',
    margin: '0 !important',
    maxWidth: '600px',
    padding: theme.spacing(15, 4),
    width: '40%',
  },
  sliderWrapper: {
    display: 'block',
    height: '100vh',
    maxWidth: '100%',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
  },
  squareAvatar: {
    '& img': {
      objectFit: 'contain',
    },
    borderRadius: '0',
    objectFit: 'contain',
  },
  termsLink: {
    fontSize: '0.875em',
  },
  termsLinks: {
    borderTop: '1px solid #E5E6E7',
    marginBottom: theme.spacing(-2),
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(2),
  },
  welcomeText: {
    marginBottom: theme.spacing(3),
  },
} as const satisfies StyleRules);

export default genericStyles;
