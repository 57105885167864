import 'regenerator-runtime/runtime';
import 'whatwg-fetch';
import React, { useEffect } from 'react';
import ReactOnRails from 'react-on-rails';

import AdminApp from '../admin/AdminApp';
import { onHydrationFinished } from '../utils/onHydrationFinished';

const App = (props) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onHydrationFinished(false), []);
  return React.createElement(AdminApp, props);
};

ReactOnRails.register({
  AdminApp: App,
});
