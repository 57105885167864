import { defineMessages } from 'react-intl';

const defaultMessages = defineMessages(
  {
    "supportUtilityTitle": {
      "id": "support_utility.title",
      "defaultMessage": "Support Utility"
    },
    // support utils > menu > woflow import messages
    "supportUtilityMenuWoflowTitle": {
      "id": "support_utility.menu.woflow.title",
      "defaultMessage": "Create Menu from Woflow Import"
    },
    "supportUtilityMenuWoflowDescription": {
      "id": "support_utility.menu.woflow.description",
      "defaultMessage": "This service will create a menu from a file found in Website/Files."
    },
    "supportUtilityMenuWoflowSelectLabel": {
      "id": "support_utility.menu.woflow.select_label",
      "defaultMessage": "Custom Files"
    },
    "supportUtilityMenuWoflowSelectPlaceholder": {
      "id": "support_utility.menu.woflow.select_placeholder",
      "defaultMessage": "Select a Custom File"
    },
    "supportUtilityMenuWoflowConfirmMessage": {
      "id": "support_utility.menu.woflow.confirm_message",
      "defaultMessage": "Are you sure you want to create a menu for file {fileName}?"
    },
    "supportUtilityMenuWoflowConfirmTitle": {
      "id": "support_utility.menu.woflow.confirm_title",
      "defaultMessage": "This will create a new menu"
    },
    "supportUtilityMenuWoflowConfirmSuccess": {
      "id": "support_utility.menu.woflow.confirm_success",
      "defaultMessage": "menu creation in progress...(this may take a while)"
    },
    "supportUtilityMenuWoflowConfirmContent": {
      "id": "support_utility.menu.woflow.confirm_content",
      "defaultMessage": "Create Menu From File"
    },
  }
);

export default defaultMessages;
