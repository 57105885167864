
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "rootExtraGroupsIndex": {
      "id": "root_extra_groups.index",
      "defaultMessage": "Root - Modifier Group Templates"
    },
    "rootExtraGroupsCopy": {
      "id": "root_extra_groups.copy",
      "defaultMessage": "Copy Modifier Group Template"
    },
    "rootExtraGroupsEdit": {
      "id": "root_extra_groups.edit",
      "defaultMessage": "Edit Modifier Group Template"
    },
    "rootExtraGroupsNew": {
      "id": "root_extra_groups.new",
      "defaultMessage": "New Modifier Group Template"
    },
    "rootExtraGroupsConfirmTitle": {
      "id": "root_extra_groups.destroy_confirm_title",
      "defaultMessage": "Confirm delete"
    },
    "rootExtraGroupConfirm": {
      "id": "root_extra_groups.destroy_confirm",
      "defaultMessage": "Are you sure you want to delete this modifier group template?"
    },
    "rootExtraGroupsDestroy": {
      "id": "root_extra_groups.destroy",
      "defaultMessage": "Delete Modifier Group Template"
    }
  }
);
export default defaultMessages;

