
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "billingBankAccountsSelf": {
      "id": "billing_bank_accounts.self",
      "defaultMessage": "Bank Account"
    },
    "billingBankAccountsSelves": {
      "id": "billing_bank_accounts.selves",
      "defaultMessage": "Bank Accounts"
    },
    "billingBankAccountsBankName": {
      "id": "billing_bank_accounts.bank_name",
      "defaultMessage": "Bank Name"
    },
    "billingBankAccountsDestroy": {
      "id": "billing_bank_accounts.destroy",
      "defaultMessage": "Remove"
    },
    "billingBankAccountsDestroyConfirm": {
      "id": "billing_bank_accounts.destroy_confirm",
      "defaultMessage": "Are you sure you want to remove this bank account?"
    },
    "billingBankAccountsDestroyConfirmTitle": {
      "id": "billing_bank_accounts.destroy_confirm_title",
      "defaultMessage": "Confirm Removal"
    },
    "billingBankAccountsEdit": {
      "id": "billing_bank_accounts.edit",
      "defaultMessage": "Edit"
    },
    "billingBankAccountsNew": {
      "id": "billing_bank_accounts.new",
      "defaultMessage": "Add Bank Account"
    },
    "billingBankAccountsNickName": {
      "id": "billing_bank_accounts.nick_name",
      "defaultMessage": "Nickname"
    },
    "billingBankAccountsReplace": {
      "id": "billing_bank_accounts.replace",
      "defaultMessage": "Replace"
    },
    "billingBankAccountsStatus": {
      "id": "billing_bank_accounts.status",
      "defaultMessage": "Status"
    },
    "billingBankAccountsSubscription": {
      "id": "billing_bank_accounts.subscription",
      "defaultMessage": "Subscriptions"
    },
    "billingBankAccountsSuccess": {
      "id": "billing_bank_accounts.success",
      "defaultMessage": "New bank account successfully added"
    },
    "billingBankAccountsSummaryBank": {
      "id": "billing_bank_accounts.summary_bank",
      "defaultMessage": "{bank_name} ending in {last4}"
    },
    "billingBankAccountsUpdateConfirm": {
      "id": "billing_bank_accounts.update_confirm",
      "defaultMessage": "Are you sure you want to set this bank as the default payment?"
    },
    "billingBankAccountsUpdateConfirmTitle": {
      "id": "billing_bank_accounts.update_confirm_title",
      "defaultMessage": "Confirm Update"
    }
  }
);
export default defaultMessages;

