
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "dashboardsCommunicationCalendar": {
      "id": "dashboards.communication.calendar",
      "defaultMessage": "Calendar"
    },
    "dashboardsCommunicationDraft": {
      "id": "dashboards.communication.draft",
      "defaultMessage": "Drafts"
    },
    "dashboardsCommunicationEmpty": {
      "id": "dashboards.communication.empty",
      "defaultMessage": "Schedule Messages and Social Posts\nto further engage your followers."
    },
    "dashboardsCommunicationHistory": {
      "id": "dashboards.communication.history",
      "defaultMessage": "History"
    },
    "dashboardsCommunicationUpcoming": {
      "id": "dashboards.communication.upcoming",
      "defaultMessage": "Upcoming"
    },
    "dashboardsCommunicationPerTimeTitle": {
      "id": "dashboards.communication.basic_menu.perTime",
      "defaultMessage": "Per Time"
    },
    "dashboardsCommunicationFilterByTitle": {
      "id": "dashboards.communication.basic_menu.filterBy",
      "defaultMessage": "Filter By"
    },
    "dashboardsCommunicationTimeAllTime": {
      "id": "dashboards.communication.time_options.allTime",
      "defaultMessage": "All Time"
    },
    "dashboardsCommunicationTimeThisMonth": {
      "id": "dashboards.communication.time_options.thisMonth",
      "defaultMessage": "This Month"
    },
    "dashboardsCommunicationTimeLastMonth": {
      "id": "dashboards.communication.time_options.lastMonth",
      "defaultMessage": "Last Month"
    },
    "dashboardsCommunicationTimeThreeMonths": {
      "id": "dashboards.communication.time_options.threeMonths",
      "defaultMessage": "Last 3 Months"
    },
    "dashboardsCommunicationTimeSixMonths": {
      "id": "dashboards.communication.time_options.sixMonths",
      "defaultMessage": "Last 6 Months"
    },
    "dashboardsCommunicationTimeTwelveMonths": {
      "id": "dashboards.communication.time_options.twelveMonths",
      "defaultMessage": "Last 12 Months"
    },
    "dashboardsConciergeTabsAdsTab": {
      "id": "dashboards.concierge.tabs.ads_tab",
      "defaultMessage": "Google Ads"
    },
    "dashboardsConciergeAdsPerformance": {
      "id": "dashboards.concierge.ads_performance",
      "defaultMessage": "Ads Performance"
    },
    "dashboardsConciergeClicks": {
      "id": "dashboards.concierge.clicks",
      "defaultMessage": "Clicks"
    },
    "dashboardsConciergeConversions": {
      "id": "dashboards.concierge.conversions",
      "defaultMessage": "Conversions"
    },
    "dashboardsConciergeCost": {
      "id": "dashboards.concierge.cost",
      "defaultMessage": "Cost"
    },
    "dashboardsConciergeEmptyTitle": {
      "id": "dashboards.concierge.empty_title",
      "defaultMessage": "Report data not found."
    },
    "dashboardsConciergeHighestPerformingAd": {
      "id": "dashboards.concierge.highest_performing_ad",
      "defaultMessage": "Highest Performing Ad"
    },
    "dashboardsConciergeImpressions": {
      "id": "dashboards.concierge.impressions",
      "defaultMessage": "Impressions"
    },
    "dashboardsConciergeImpressionsTooltip": {
      "id": "dashboards.concierge.impressions.tooltip",
      "defaultMessage": "The number of times your post was displayed on screen"
    },
    "dashboardsConciergeKeywordsPerformance": {
      "id": "dashboards.concierge.keywords_performance",
      "defaultMessage": "Keywords Performance"
    },
    "dashboardsConciergeReportsFor": {
      "id": "dashboards.concierge.reports_for",
      "defaultMessage": "Reports for"
    },
    "dashboardsConciergeTabsAnalyticsTab": {
      "id": "dashboards.concierge.tabs.analytics_tab",
      "defaultMessage": "Google Analytics"
    },
    "dashboardsConciergeCity": {
      "id": "dashboards.concierge.city",
      "defaultMessage": "City"
    },
    "dashboardsConciergeSessions": {
      "id": "dashboards.concierge.sessions",
      "defaultMessage": "Sessions"
    },
    "dashboardsConciergeTrafficByChannel": {
      "id": "dashboards.concierge.traffic_by_channel",
      "defaultMessage": "Traffic by Channel"
    },
    "dashboardsConciergeTrafficByCity": {
      "id": "dashboards.concierge.traffic_by_city",
      "defaultMessage": "Traffic by City"
    },
    "dashboardsConciergeTrafficByDevice": {
      "id": "dashboards.concierge.traffic_by_device",
      "defaultMessage": "Traffic by Device Type"
    },
    "dashboardsConciergeUniqueVisitors": {
      "id": "dashboards.concierge.unique_visitors",
      "defaultMessage": "Unique Site Visitors"
    },
    "dashboardsConciergeVisitorSessionLength": {
      "id": "dashboards.concierge.visitor_session_length",
      "defaultMessage": "Average visitor session length"
    },
    "dashboardsConciergeCaptionsAdsImpressions": {
      "id": "dashboards.concierge.captions.ads_impressions",
      "defaultMessage": "Impressions: "
    },
    "dashboardsConciergeCaptionsAdsImpressionsDescription": {
      "id": "dashboards.concierge.captions.ads_impressions_description",
      "defaultMessage": "This is the number of times that your ad was displayed on a search results page."
    },
    "dashboardsConciergeCaptionsClicks": {
      "id": "dashboards.concierge.captions.clicks",
      "defaultMessage": "Clicks: "
    },
    "dashboardsConciergeCaptionsClicksDescription": {
      "id": "dashboards.concierge.captions.clicks_description",
      "defaultMessage": "Number of times your ad was clicked on by a potential customer, taking them to your website over other sites on the search results page. With your Boost package, we'll get you to at least 100 clicks every month, but the number will fluctuate beyond that."
    },
    "dashboardsConciergeCaptionsClickThroughRate": {
      "id": "dashboards.concierge.captions.click_through_rate",
      "defaultMessage": "CTR (Click through rate): "
    },
    "dashboardsConciergeCaptionsClickThroughRateDescription": {
      "id": "dashboards.concierge.captions.click_through_rate_description",
      "defaultMessage": "The ratio of users who click on a specific link to the number of total users who view a page, email, or advertisement. The higher the rate, the more successful the keywords."
    },
    "dashboardsConciergeCaptionsDirectSearch": {
      "id": "dashboards.concierge.captions.direct_search",
      "defaultMessage": "Direct Search: "
    },
    "dashboardsConciergeCaptionsDirectSearchDescription": {
      "id": "dashboards.concierge.captions.direct_search_description",
      "defaultMessage": "Traffic generated by typing a URL or clicking a link."
    },
    "dashboardsConciergeCaptionsEmail": {
      "id": "dashboards.concierge.captions.email",
      "defaultMessage": "Email: "
    },
    "dashboardsConciergeCaptionsEmailDescription": {
      "id": "dashboards.concierge.captions.email_description",
      "defaultMessage": "Traffic generated via emails."
    },
    "dashboardsConciergeCaptionsEngagedUsers": {
      "id": "dashboards.concierge.captions.engaged_users",
      "defaultMessage": "Engaged Users: "
    },
    "dashboardsConciergeCaptionsEngagedUsersDescription": {
      "id": "dashboards.concierge.captions.engaged_users_description",
      "defaultMessage": "The number of times an user reacted to your post."
    },
    "dashboardsConciergeCaptionsFavorites": {
      "id": "dashboards.concierge.captions.favorites",
      "defaultMessage": "Favorites: "
    },
    "dashboardsConciergeCaptionsFavoritesDescription": {
      "id": "dashboards.concierge.captions.favorites_description",
      "defaultMessage": "The number of times your post was favorited."
    },
    "dashboardsConciergeCaptionsImpressions": {
      "id": "dashboards.concierge.captions.impressions",
      "defaultMessage": "Impressions: "
    },
    "dashboardsConciergeCaptionsImpressionsDescription": {
      "id": "dashboards.concierge.captions.impressions_description",
      "defaultMessage": "The number of times your post was displayed on screen."
    },
    "dashboardsConciergeCaptionsOrganicSearch": {
      "id": "dashboards.concierge.captions.organic_search",
      "defaultMessage": "Organic Search: "
    },
    "dashboardsConciergeCaptionsOrganicSearchDescription": {
      "id": "dashboards.concierge.captions.organic_search_description",
      "defaultMessage": "Traffic that arrives at your site through a search engine through un-paid results."
    },
    "dashboardsConciergeCaptionsPaidSearch": {
      "id": "dashboards.concierge.captions.paid_search",
      "defaultMessage": "Paid Search: "
    },
    "dashboardsConciergeCaptionsPaidSearchDescription": {
      "id": "dashboards.concierge.captions.paid_search_description",
      "defaultMessage": "Traffic generated through PPC campaigns in search results."
    },
    "dashboardsConciergeCaptionsReactions": {
      "id": "dashboards.concierge.captions.reactions",
      "defaultMessage": "Reactions: "
    },
    "dashboardsConciergeCaptionsReactionsDescription": {
      "id": "dashboards.concierge.captions.reactions_description",
      "defaultMessage": "The number of times an user reacted to your post."
    },
    "dashboardsConciergeCaptionsReferral": {
      "id": "dashboards.concierge.captions.referral",
      "defaultMessage": "Referral: "
    },
    "dashboardsConciergeCaptionsReferralDescription": {
      "id": "dashboards.concierge.captions.referral_description",
      "defaultMessage": "Traffic that arrives at your site through clicking a link from another site, excluding search engines."
    },
    "dashboardsConciergeCaptionsRetweets": {
      "id": "dashboards.concierge.captions.retweets",
      "defaultMessage": "Retweets: "
    },
    "dashboardsConciergeCaptionsRetweetsDescription": {
      "id": "dashboards.concierge.captions.retweets_description",
      "defaultMessage": "The number of times your post was retweeted."
    },
    "dashboardsConciergeCaptionsSocial": {
      "id": "dashboards.concierge.captions.social",
      "defaultMessage": "Social: "
    },
    "dashboardsConciergeCaptionsSocialDescription": {
      "id": "dashboards.concierge.captions.social_description",
      "defaultMessage": "Traffic that arrives at your site through a social network (Facebook, Twitter, etc.)."
    },
    "dashboardsConciergeTabsEmailMarketingTab": {
      "id": "dashboards.concierge.tabs.email_marketing_tab",
      "defaultMessage": "Email Marketing"
    },
    "dashboardsConciergeHighestPerformingEmails": {
      "id": "dashboards.concierge.highest_performing_emails",
      "defaultMessage": "Highest Performing Emails"
    },
    "dashboardsConciergeTabsMilestonesTab": {
      "id": "dashboards.concierge.tabs.milestones_tab",
      "defaultMessage": "Engagement"
    },
    "dashboardsConciergeThisMonth": {
      "id": "dashboards.concierge.this_month",
      "defaultMessage": "This Month"
    },
    "dashboardsConciergeMilestonesTypesReportTypeFollowers": {
      "id": "dashboards.concierge.milestones.types.report_type_followers",
      "defaultMessage": "Follower"
    },
    "dashboardsConciergeMilestonesTypesReportTypeLikePops": {
      "id": "dashboards.concierge.milestones.types.report_type_like_pops",
      "defaultMessage": "Like"
    },
    "dashboardsConciergeMilestonesTypesReportTypeLovePops": {
      "id": "dashboards.concierge.milestones.types.report_type_love_pops",
      "defaultMessage": "Love"
    },
    "dashboardsConciergeMilestonesTypesReportTypePageViews": {
      "id": "dashboards.concierge.milestones.types.report_type_page_views",
      "defaultMessage": "Page View"
    },
    "dashboardsConciergeMilestonesTypesReportTypePops": {
      "id": "dashboards.concierge.milestones.types.report_type_pops",
      "defaultMessage": "Pop"
    },
    "dashboardsConciergeMilestonesTypesReportTypeTryPops": {
      "id": "dashboards.concierge.milestones.types.report_type_try_pops",
      "defaultMessage": "Remind to try"
    },
    "dashboardsConciergeMilestonesTypesReportTypeReviews": {
      "id": "dashboards.concierge.milestones.types.report_type_reviews",
      "defaultMessage": "Review"
    },
    "dashboardsConciergeMilestonesTypesReportTypeUsers": {
      "id": "dashboards.concierge.milestones.types.report_type_users",
      "defaultMessage": "Visitor"
    },
    "dashboardsConciergeMilestonesPluralTypesReportTypeFollowers": {
      "id": "dashboards.concierge.milestones.plural_types.report_type_followers",
      "defaultMessage": "Followers"
    },
    "dashboardsConciergeMilestonesPluralTypesReportTypeLikePops": {
      "id": "dashboards.concierge.milestones.plural_types.report_type_like_pops",
      "defaultMessage": "Likes"
    },
    "dashboardsConciergeMilestonesPluralTypesReportTypeLovePops": {
      "id": "dashboards.concierge.milestones.plural_types.report_type_love_pops",
      "defaultMessage": "Loves"
    },
    "dashboardsConciergeMilestonesPluralTypesReportTypePageViews": {
      "id": "dashboards.concierge.milestones.plural_types.report_type_page_views",
      "defaultMessage": "Page Views"
    },
    "dashboardsConciergeMilestonesPluralTypesReportTypePops": {
      "id": "dashboards.concierge.milestones.plural_types.report_type_pops",
      "defaultMessage": "Pops"
    },
    "dashboardsConciergeMilestonesPluralTypesReportTypeTryPops": {
      "id": "dashboards.concierge.milestones.plural_types.report_type_try_pops",
      "defaultMessage": "Remind to try"
    },
    "dashboardsConciergeMilestonesPluralTypesReportTypeReviews": {
      "id": "dashboards.concierge.milestones.plural_types.report_type_reviews",
      "defaultMessage": "Reviews"
    },
    "dashboardsConciergeMilestonesPluralTypesReportTypeUsers": {
      "id": "dashboards.concierge.milestones.plural_types.report_type_users",
      "defaultMessage": "Visitors"
    },
    "dashboardsConciergeTabsRemindersTab": {
      "id": "dashboards.concierge.tabs.reminders_tab",
      "defaultMessage": "Reminders"
    },
    "dashboardsConciergeContactUsAt": {
      "id": "dashboards.concierge.contact_us_at",
      "defaultMessage": "Please contact us at "
    },
    "dashboardsConciergeContactUsEmail": {
      "id": "dashboards.concierge.contact_us_email",
      "defaultMessage": "boost@popmenu.com"
    },
    "dashboardsConciergeContactUsUpdates": {
      "id": "dashboards.concierge.contact_us_updates",
      "defaultMessage": " for menu updates, upcoming event promotions, and other restaurant happenings!"
    },
    "dashboardsConciergeHolidayHours": {
      "id": "dashboards.concierge.holiday_hours",
      "defaultMessage": "Holiday Hours"
    },
    "dashboardsConciergeHolidayMenus": {
      "id": "dashboards.concierge.holiday_menus",
      "defaultMessage": "Holiday Menus"
    },
    "dashboardsConciergeHolidayRemindersPrefix": {
      "id": "dashboards.concierge.holiday_reminders_prefix",
      "defaultMessage": "- Consider adding "
    },
    "dashboardsConciergeHolidayRemindersSuffix": {
      "id": "dashboards.concierge.holiday_reminders_suffix",
      "defaultMessage": " if applicable."
    },
    "dashboardsConciergePendingReviewsPrefix": {
      "id": "dashboards.concierge.pending_reviews_prefix",
      "defaultMessage": "You have"
    },
    "dashboardsConciergePendingReviewsSuffix": {
      "id": "dashboards.concierge.pending_reviews_suffix",
      "defaultMessage": "to reject or approve."
    },
    "dashboardsConciergePendingReviews": {
      "id": "dashboards.concierge.pending_reviews",
      "defaultMessage": " pending reviews"
    },
    "dashboardsConciergeContactUs": {
      "id": "dashboards.concierge.contact_us",
      "defaultMessage": "Contact Us"
    },
    "dashboardsConciergeReportDate": {
      "id": "dashboards.concierge.report_date",
      "defaultMessage": "Report Date"
    },
    "dashboardsConciergeTitle": {
      "id": "dashboards.concierge.title",
      "defaultMessage": "Boost Report"
    },
    "dashboardsConciergeTabsSocialPostsTab": {
      "id": "dashboards.concierge.tabs.social_posts_tab",
      "defaultMessage": "Top Social Posts"
    },
    "dashboardsConciergeSocialTypesFacebookType": {
      "id": "dashboards.concierge.social_types.facebook_type",
      "defaultMessage": "Facebook"
    },
    "dashboardsConciergeSocialTypesInstagramType": {
      "id": "dashboards.concierge.social_types.instagram_type",
      "defaultMessage": "Instagram"
    },
    "dashboardsConciergeSocialTypesTwitterType": {
      "id": "dashboards.concierge.social_types.twitter_type",
      "defaultMessage": "Twitter"
    },
    "dashboardsConciergeDate": {
      "id": "dashboards.concierge.date",
      "defaultMessage": "Date"
    },
    "dashboardsConciergeEngagedUsers": {
      "id": "dashboards.concierge.engaged_users",
      "defaultMessage": "Engaged Users"
    },
    "dashboardsConciergeEngagement": {
      "id": "dashboards.concierge.engagement",
      "defaultMessage": "Engagement"
    },
    "dashboardsConciergeFavorites": {
      "id": "dashboards.concierge.favorites",
      "defaultMessage": "Favorites"
    },
    "dashboardsConciergeHighestPerformingPosts": {
      "id": "dashboards.concierge.highest_performing_posts",
      "defaultMessage": "Highest Performing Posts"
    },
    "dashboardsConciergePost": {
      "id": "dashboards.concierge.post",
      "defaultMessage": "Post"
    },
    "dashboardsConciergeReactions": {
      "id": "dashboards.concierge.reactions",
      "defaultMessage": "Reactions"
    },
    "dashboardsConciergeRetweets": {
      "id": "dashboards.concierge.retweets",
      "defaultMessage": "Retweets"
    },
    "dashboardsPerformanceTitle": {
      "id": "dashboards.performance.title",
      "defaultMessage": "Performance Snapshot (all locations)"
    },
    "dashboardsPerformanceWebsite": {
      "id": "dashboards.performance.website",
      "defaultMessage": "Website"
    },
    "dashboardsPerformanceNewReviews": {
      "id": "dashboards.performance.new_reviews",
      "defaultMessage": "New Reviews"
    },
    "dashboardsPerformanceDishLikes": {
      "id": "dashboards.performance.dish_likes",
      "defaultMessage": "Dish Likes"
    },
    "dashboardsPerformanceTotalSiteVisits": {
      "id": "dashboards.performance.total_site_visits",
      "defaultMessage": "Total Site Visits"
    },
    "dashboardsPerformanceLastSevenDays": {
      "id": "dashboards.performance.last_seven_days",
      "defaultMessage": "Last 7 Days"
    },
    "dashboardsPerformanceNoDataFound": {
      "id": "dashboards.performance.no_data_found",
      "defaultMessage": "Sorry, no data was found yet."
    },
    "dashboardsPerformanceTotalVisits": {
      "id": "dashboards.performance.total_visits",
      "defaultMessage": "Total Visits"
    },
    "dashboardsPerformanceReturningVisits": {
      "id": "dashboards.performance.returning_visits",
      "defaultMessage": "Returning Visits"
    },
    "dashboardsPerformanceTrendLineSummary": {
      "id": "dashboards.performance.trend_line.summary",
      "defaultMessage": " {trendType} {roundedChange}% over the last week"
    },
    "dashboardsPerformanceOnlineOrderingCallToAction": {
      "id": "dashboards.performance.online_ordering.call_to_action",
      "defaultMessage": "Set Up Online Ordering"
    },
    "dashboardsWebsiteOverviewPopmenuMarketing": {
      "id": "dashboards.website_overview.popmenu_marketing",
      "defaultMessage": "Popmenu Marketing"
    },
    "dashboardsPublishingIndex": {
      "id": "dashboards.publishing.index",
      "defaultMessage": "3rd-Party Publishing"
    },
    "dashboardsPublishingDisabledButton": {
      "id": "dashboards.publishing.disabled_button",
      "defaultMessage": "Learn About Concierge"
    },
    "dashboardsPublishingDisabledMessage": {
      "id": "dashboards.publishing.disabled_message",
      "defaultMessage": "Upgrade to the Boost Package to unlock automated publishing to popular third-parties."
    },
    "dashboardsPublishingPublishingInfo": {
      "id": "dashboards.publishing.publishing_info",
      "defaultMessage": "Before publishing, confirm all restaurant and location information is accurate and make any needed changes.\n\nOnce a location is confirmed, it will be published to supported third-parties, and ongoing changes will be published automatically.\n\nTime-to-publish for each third-party may vary from hours to several business days."
    },
    "dashboardsPublishingRestaurantInfo": {
      "id": "dashboards.publishing.restaurant_info",
      "defaultMessage": "This basic restaurant information is shared among all locations."
    },
    "dashboardsPublishingLocationInfo": {
      "id": "dashboards.publishing.location_info",
      "defaultMessage": "This info is specific to publishing this location, merged with the basic restaurant information above."
    },
    "dashboardsPublishingLocationConfirmTitle": {
      "id": "dashboards.publishing.location_confirm_title",
      "defaultMessage": "Confirm Publish"
    },
    "dashboardsPublishingLocationConfirmMessage": {
      "id": "dashboards.publishing.location_confirm_message",
      "defaultMessage": "Are you sure you want to publish this location? Ensure that all restaurant and location data is correct."
    },
    "dashboardsPublishingLocationConfirmMessageCompleted": {
      "id": "dashboards.publishing.location_confirm_message_completed",
      "defaultMessage": "Your changes have been published. Please allow a few minutes for changes to show up on Google."
    },
    "dashboardsPublishingLocationConfirmButton": {
      "id": "dashboards.publishing.location_confirm_button",
      "defaultMessage": "Publish Now"
    },
    "dashboardsPublishingListingsTitle": {
      "id": "dashboards.publishing.listings_title",
      "defaultMessage": "Listings"
    },
    "dashboardsPublishingListingsLink": {
      "id": "dashboards.publishing.listings_link",
      "defaultMessage": "View Listing"
    },
    "dashboardsTasksAccept": {
      "id": "dashboards.tasks.accept",
      "defaultMessage": "Accept"
    },
    "dashboardsTasksReject": {
      "id": "dashboards.tasks.reject",
      "defaultMessage": "Reject"
    },
    "dashboardsTasksPostReview": {
      "id": "dashboards.tasks.post_review",
      "defaultMessage": "Post Review"
    },
    "dashboardsTasksAccepted": {
      "id": "dashboards.tasks.accepted",
      "defaultMessage": "accepted"
    },
    "dashboardsTasksAcceptedSuccessMessage": {
      "id": "dashboards.tasks.accepted_success_message",
      "defaultMessage": "The review is now posted on your website"
    },
    "dashboardsTasksReviewWhatsNext": {
      "id": "dashboards.tasks.review_whats_next",
      "defaultMessage": "what's next (optional)"
    },
    "dashboardsTasksReviewShare": {
      "id": "dashboards.tasks.review_share",
      "defaultMessage": "Share on social accounts"
    },
    "dashboardsTasksReviewPostMessage": {
      "id": "dashboards.tasks.review_post_message",
      "defaultMessage": "Post this review with a pre-filled template"
    },
    "dashboardsTasksEmptyMessage": {
      "id": "dashboards.tasks.empty_message",
      "defaultMessage": "You're all caught up!"
    },
    "dashboardsTasksSelves": {
      "id": "dashboards.tasks.selves",
      "defaultMessage": "Tasks"
    },
    "dashboardsListsPopularTitle": {
      "id": "dashboards.lists.popular.title",
      "defaultMessage": "Popular Items"
    },
    "dashboardsListsPopularViews": {
      "id": "dashboards.lists.popular.views",
      "defaultMessage": "{count} views"
    },
    "dashboardsNoticesEmptyMessage": {
      "id": "dashboards.notices.empty_message",
      "defaultMessage": "You're all caught up!"
    },
    "dashboardsNoticesSelves": {
      "id": "dashboards.notices.selves",
      "defaultMessage": "Notices"
    },
    "dashboardsShow": {
      "id": "dashboards.show",
      "defaultMessage": "Dashboard"
    },
    "dashboardsNoticesMilestonesPretextDefault": {
      "id": "dashboards.notices.milestones.pretext.default",
      "defaultMessage": "You have received"
    },
    "dashboardsNoticesMilestonesPretextReportTypeDislikePops": {
      "id": "dashboards.notices.milestones.pretext.report_type_dislike_pops",
      "defaultMessage": "You have received"
    },
    "dashboardsNoticesMilestonesPretextReportTypeFollowers": {
      "id": "dashboards.notices.milestones.pretext.report_type_followers",
      "defaultMessage": "You have reached"
    },
    "dashboardsNoticesMilestonesPretextReportTypeLikePops": {
      "id": "dashboards.notices.milestones.pretext.report_type_like_pops",
      "defaultMessage": "You have received"
    },
    "dashboardsNoticesMilestonesPretextReportTypeLovePops": {
      "id": "dashboards.notices.milestones.pretext.report_type_love_pops",
      "defaultMessage": "You have received"
    },
    "dashboardsNoticesMilestonesPretextReportTypePageViews": {
      "id": "dashboards.notices.milestones.pretext.report_type_page_views",
      "defaultMessage": "You have reached"
    },
    "dashboardsNoticesMilestonesPretextReportTypePops": {
      "id": "dashboards.notices.milestones.pretext.report_type_pops",
      "defaultMessage": "You have received"
    },
    "dashboardsNoticesMilestonesPretextReportTypeReviews": {
      "id": "dashboards.notices.milestones.pretext.report_type_reviews",
      "defaultMessage": "You have received"
    },
    "dashboardsNoticesMilestonesPretextReportTypeTryPops": {
      "id": "dashboards.notices.milestones.pretext.report_type_try_pops",
      "defaultMessage": "You have received"
    },
    "dashboardsNoticesMilestonesPretextReportTypeUsers": {
      "id": "dashboards.notices.milestones.pretext.report_type_users",
      "defaultMessage": "You have reached"
    },
    "dashboardsTasksSmartMessageTaskManageMessages": {
      "id": "dashboards.tasks.smart_message_task.manage_messages",
      "defaultMessage": "Manage Smart Messages"
    },
    "dashboardsTasksConnectSocialMediaTaskConnectSocialMedia": {
      "id": "dashboards.tasks.connect_social_media_task.connect_social_media",
      "defaultMessage": "Connect Social Accounts"
    },
    "dashboardsTasksReply": {
      "id": "dashboards.tasks.reply",
      "defaultMessage": "Reply"
    },
    "dashboardsStatsStatTypesEventPinterestShareTitle": {
      "id": "dashboards.stats.stat_types.event_pinterest_share.title",
      "defaultMessage": "Pinterest Shares"
    },
    "dashboardsStatsStatTypesPopTitle": {
      "id": "dashboards.stats.stat_types.pop.title",
      "defaultMessage": "Pops"
    },
    "dashboardsStatsStatTypesEventReserveAttemptTitle": {
      "id": "dashboards.stats.stat_types.event_reserve_attempt.title",
      "defaultMessage": "Reserve Embed Clicks"
    },
    "dashboardsStatsStatTypesEventTwitterShareTitle": {
      "id": "dashboards.stats.stat_types.event_twitter_share.title",
      "defaultMessage": "Twitter Shares"
    },
    "dashboardsStatsStatTypesEventShareAttemptTitle": {
      "id": "dashboards.stats.stat_types.event_share_attempt.title",
      "defaultMessage": "Share Attempts"
    },
    "dashboardsStatsStatTypesEventPopAttemptTitle": {
      "id": "dashboards.stats.stat_types.event_pop_attempt.title",
      "defaultMessage": "Pop Attempts"
    },
    "dashboardsStatsStatTypesFollowerTitle": {
      "id": "dashboards.stats.stat_types.follower.title",
      "defaultMessage": "Followers"
    },
    "dashboardsStatsStatTypesEventLocationCustomTabTitle": {
      "id": "dashboards.stats.stat_types.event_location_custom_tab.title",
      "defaultMessage": "Location Custom Tab Clicks"
    },
    "dashboardsStatsStatTypesEventReserveTabTitle": {
      "id": "dashboards.stats.stat_types.event_reserve_tab.title",
      "defaultMessage": "Reserve Tab Clicks"
    },
    "dashboardsStatsStatTypesEventRestaurantCustomTabTitle": {
      "id": "dashboards.stats.stat_types.event_restaurant_custom_tab.title",
      "defaultMessage": "Restaurant Custom Tab Clicks"
    },
    "dashboardsStatsStatTypesEventFacebookShareTitle": {
      "id": "dashboards.stats.stat_types.event_facebook_share.title",
      "defaultMessage": "Facebook Shares"
    },
    "dashboardsStatsStatTypesEventCallAttemptTitle": {
      "id": "dashboards.stats.stat_types.event_call_attempt.title",
      "defaultMessage": "Reserve Call Clicks"
    },
    "dashboardsStatsStatTypesVisitMenuTitle": {
      "id": "dashboards.stats.stat_types.visit_menu.title",
      "defaultMessage": "Menu Views"
    },
    "dashboardsStatsStatTypesReviewTitle": {
      "id": "dashboards.stats.stat_types.review.title",
      "defaultMessage": "Reviews"
    },
    "dashboardsStatsStatTypesEventReviewAttemptTitle": {
      "id": "dashboards.stats.stat_types.event_review_attempt.title",
      "defaultMessage": "Review Attempts"
    },
    "dashboardsStatsStatTypesVisitItemTitle": {
      "id": "dashboards.stats.stat_types.visit_dish.title",
      "defaultMessage": "Item Views"
    },
    "dashboardsStatsStatTypesEventSmsShareTitle": {
      "id": "dashboards.stats.stat_types.event_sms_share.title",
      "defaultMessage": "SMS Shares"
    },
    "dashboardsEvents": {
      "id": "dashboards.events",
      "defaultMessage": "Recent Activity"
    },
    "dashboardsGooglePublishing": {
      "id": "dashboards.google_publishing",
      "defaultMessage": "Google Publishing"
    },
    "dashboardsWebsiteProgressTitle": {
      "id": "dashboards.website_progress.title",
      "defaultMessage": "Website Progress"
    },
    "dashboardsUpgrade": {
      "id": "dashboards.upgrade",
      "defaultMessage": "Upgrade"
    },
    "dashboardsLearnMore": {
      "id": "dashboards.learn_more",
      "defaultMessage": "Learn More"
    },
    "dashboardsWelcome": {
      "id": "dashboards.welcome",
      "defaultMessage": "Welcome"
    },
    "dashboardsLimitedInfoCardCaptureLabel": {
      "id": "dashboards.limited.info_card.capture.label",
      "defaultMessage": "CAPTURE"
    },
    "dashboardsLimitedInfoCardDashboardCaptureBody": {
      "id": "dashboards.limited.info_card.dashboard.capture.body",
      'defaultMessage': 'Identify top-performing emails, texts, and social posts that are bringing in more guests and sales.'
    },
    "dashboardsLimitedInfoCardMarketingCaptureBody": {
      "id": "dashboards.limited.info_card.marketing.capture.body",
      'defaultMessage': 'Get to know your guests through their favorite dishes, reviews, and order history—so you can personalize emails and texts and drive more $$$.'
    },
    "dashboardsLimitedInfoCardOrderingCaptureBody": {
      "id": "dashboards.limited.info_card.ordering.capture.body",
      'defaultMessage': 'Capture more revenue—and all the juicy details on what guests like—with every order. So your marketing gets smarter and more tailored.'
    },
    "dashboardsLimitedInfoCardAiAnsweringCaptureBody": {
      "id": "dashboards.limited.info_card.ai_answering.capture.body",
      'defaultMessage': 'Automatically send links to guests  for reservations, online orders, and your waitlist, and escalate priority calls.'
    },
    "dashboardsLimitedInfoCardWebsiteCaptureBody": {
      "id": "dashboards.limited.info_card.website.capture.body",
      'defaultMessage': 'Boost website traffic and capture guest contact info the moment they land—so you can invite them back and build loyalty.'
    },
    'dashboardsLimitedInfoCardMenuCaptureBody': {
      'id': 'dashboards.limited.info_card.menu.capture.body',
      'defaultMessage': 'Attract more guests with an interactive menu and website, online ordering, waitlisting, and more. Plus, learn what guests like and grow your contact list with automated prompts.'
    },
    "dashboardsLimitedInfoCardConnectLabel": {
      "id": "dashboards.limited.info_card.connect.label",
      "defaultMessage": "CONNECT"
    },
    "dashboardsLimitedInfoCardDashboardConnectBody": {
      "id": "dashboards.limited.info_card.dashboard.connect.body",
      'defaultMessage': 'Track how marketing activities are impacting website performance, orders, and more...so you can cook up more revenue.'
    },
    "dashboardsLimitedInfoCardMarketingConnectBody": {
      "id": "dashboards.limited.info_card.marketing.connect.body",
      'defaultMessage': 'Stay connected with guests with AI-generated social content that is unique to your restaurant and posts directly to your favorite channels.'
    },
    "dashboardsLimitedInfoCardOrderingConnectBody": {
      "id": "dashboards.limited.info_card.ordering.connect.body",
      'defaultMessage': 'Drive repeat business with automated offers and loyalty rewards that are triggered by guest behavior.'
    },
    "dashboardsLimitedInfoCardAiAnsweringConnectBody": {
      "id": "dashboards.limited.info_card.ai_answering.connect.body",
      'defaultMessage': 'Free up staff to focus on in-house service while calls are expertly handled with custom greetings and responses.'
    },
    "dashboardsLimitedInfoCardWebsiteConnectBody": {
      "id": "dashboards.limited.info_card.website.connect.body",
      'defaultMessage': 'Create buzz around your brand and automate personalized messages to guests based on their behavior.'
    },
    'dashboardsLimitedInfoCardMenuConnectBody': {
      'id': 'dashboards.limited.info_card.menu.connect.body',
      'defaultMessage': 'Grow sales with easy marketing tools: automated emails, AI-driven social posts, texts, pre-built marketing campaigns, and more.'
    },
    "dashboardsLimitedInfoCardConsolidateLabel": {
      "id": "dashboards.limited.info_card.consolidate.label",
      "defaultMessage": "CONSOLIDATE"
    },
    "dashboardsLimitedInfoCardDashboardConsolidateBody": {
      "id": "dashboards.limited.info_card.dashboard.consolidate.body",
      'defaultMessage': 'See how online ordering factors into your total revenue with insights into order volume, peak times, top-selling dishes, and more.'
    },
    "dashboardsLimitedInfoCardMarketingConsolidateBody": {
      "id": "dashboards.limited.info_card.marketing.consolidate.body",
      'defaultMessage': 'Manage guest info, create content, send communications, and track marketing impact on revenue in real time—all in one place.'
    },
    "dashboardsLimitedInfoCardOrderingConsolidateBody": {
      "id": "dashboards.limited.info_card.ordering.consolidate.body",
      'defaultMessage': 'Enjoy hassle-free direct ordering that\'s seamlessly connected to your website, POS, and marketing tools. Plus, delivery without costly commissions.'
    },
    "dashboardsLimitedInfoCardAiAnsweringConsolidateBody": {
      "id": "dashboards.limited.info_card.ai_answering.consolidate.body",
      'defaultMessage': 'Reduce workloads and labor costs while providing faster responses to guests. Plus, track how AI Answering is impacting revenue.'
    },
    "dashboardsLimitedInfoCardWebsiteConsolidateBody": {
      "id": "dashboards.limited.info_card.website.consolidate.body",
      'defaultMessage': 'Ensure menu and website updates automatically populate on Google so guests have all the delicious details.'
    },
    'dashboardsLimitedInfoCardMenuConsolidateBody': {
      'id': 'dashboards.limited.info_card.menu.consolidate.body',
      'defaultMessage': 'Save time and improve operations with fewer tools. Tap into our OpenTable, POS,and DoorDash integrations and manage everything on-the-go with the Popmenu for Owners app.'
    },
    "dashboardsLimitedEmbedVideoTitle": {
      "id": "dashboards.limited.menu.embed_video.title",
      "defaultMessage": "How to Build a Menu For OpenTable"
    },
    "dashboardsLimitedDashboardEmbedVideoTitle": {
      "id": "dashboards.limited.dashboard.embed_video.title",
      "defaultMessage": "Popmenu Restaurant Software"
    },
    "dashboardsLimitedMarketingEmbedVideoTitle": {
      "id": "dashboards.limited.marketing.embed_video.title",
      "defaultMessage": "Popmenu for OpenTable Clients | Marketing"
    },
    "dashboardsLimitedOrderingEmbedVideoTitle": {
      "id": "dashboards.limited.ordering.embed_video.title",
      "defaultMessage": "Popmenu for OpenTable Clients | Online Ordering"
    },
    "dashboardsLimitedAiAnsweringEmbedVideoTitle": {
      "id": "dashboards.limited.ai_answering.embed_video.title",
      "defaultMessage": "Popmenu Answering"
    },
    "dashboardsLimitedWebsiteEmbedVideoTitle": {
      "id": "dashboards.limited.website.embed_video.title",
      "defaultMessage": "Popmenu for OpenTable Clients | Website"
    },
    "dashboardsPerfomanceOpenRateMessages": {
      "id": "dashboards.performance.maketing_open_rate.open_rate_messages",
      "defaultMessage": "This is a combination of your Smart Messages, Mass Messages and Text Messages sent"
    },
    "dashboardsPerfomanceOpenRateMessages": {
      "id": "dashboards.performance.total_messages.email_key",
      "defaultMessage": "Mass Messages"
    },
    "dashboardsPerfomanceOpenRateMessages": {
      "id": "dashboards.performance.total_messages.sms_key",
      "defaultMessage": "Text Messages"
    },
    "dashboardsPerfomanceOpenRateMessages": {
      "id": "dashboards.performance.total_messages.smart_message_key",
      "defaultMessage": "Smart Message"
    },
    "dashboardsPerfomanceOpenRateAutomatedEmail": {
      "id": "dashboards.performance.total_messages.automated_email_key",
      "defaultMessage": "Automated Email"
    },
    "dashboardsBoostReportEmailMarketingMessages": {
      "id": "dashboards.boost_report.email_marketing.messages",
      "defaultMessage": "Messages"
    },
    "dashboardsBoostReportEmailMarketingOpenRate": {
      "id": "dashboards.boost_report.email_marketing.open_rate",
      "defaultMessage": "Open Rate"
    },
    "dashboardsBoostReportEmailMarketingClickRate": {
      "id": "dashboards.boost_report.email_marketing.click_rate",
      "defaultMessage": "Click Rate"
    },
    "dashboardsBoostReportEmailMarketingMessageSubject": {
      "id": "dashboards.boost_report.email_marketing.message_subject",
      "defaultMessage": "Message Subject"
    },
    "dashboardsBoostReportEmailMarketingSent": {
      "id": "dashboards.boost_report.email_marketing.sent",
      "defaultMessage": "Sent"
    }
  }

);
export default defaultMessages;
