
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "themeFontsDestroyConfirm": {
      "id": "theme_fonts.destroy_confirm",
      "defaultMessage": "Are you sure you want to remove this font?"
    },
    "themeFontsDestroyConfirmTitle": {
      "id": "theme_fonts.destroy_confirm_title",
      "defaultMessage": "Confirm Removal"
    },
    "themeFontsNew": {
      "id": "theme_fonts.new",
      "defaultMessage": "New Font"
    },
    "themeFontsEdit": {
      "id": "theme_fonts.edit",
      "defaultMessage": "Edit Font"
    },
    "themeFontsDestroy": {
      "id": "theme_fonts.destroy",
      "defaultMessage": "Remove Font"
    },
    "themeFontsTooBig": {
      "id": "theme_fonts.too_big",
      "defaultMessage": "Font size {size}Mb is larger than maximum recommended size of 100Kb"
    }
  }
);
export default defaultMessages;

