import { Typography } from '@popmenu/common-ui';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import { useUserUnsubscribedRestaurantsByUnsubscribeTokenQuery } from '~/libs/gql/queries/users/userUnsubscribedRestaurantsByUnsubscribeTokenQuery.generated';
import Loading from '~/shared/Loading';

import type { Params } from './interfaces';
import UserUnsubscribedRestaurants from './UserUnsubscribedRestaurants';

const PostUnsubscribeContainer = () => {
  const { unsubscribeToken } = useParams<Params>();

  const { data, loading } = useUserUnsubscribedRestaurantsByUnsubscribeTokenQuery({
    variables: {
      unsubscribeToken,
    },
  });

  if (loading || !data) {
    return <Loading />;
  }

  const { userByUnsubscribeToken } = data;

  return (
    <React.Fragment>
      <Typography align="center" variant="h5">
        <FormattedMessage id="post_unsubscribe.subscriptions" defaultMessage="Subscriptions" />
      </Typography>
      <br />
      <br />
      <UserUnsubscribedRestaurants user={userByUnsubscribeToken} />
    </React.Fragment>
  );
};

export default PostUnsubscribeContainer;
