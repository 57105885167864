
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "customPagesLimitReached": {
      "id": "custom_pages.limit",
      "defaultMessage": "Limit of custom pages has been reached."
    },
    "customPagesNew": {
      "id": "custom_pages.new",
      "defaultMessage": "Add Page"
    },
    "customPagesAdd": {
      "id": "custom_pages.add",
      "defaultMessage": "Add"
    },
    "customPagesSearchPages": {
      "id": "custom_pages.search_pages",
      "defaultMessage": "Search pages..."
    },
    "customPagesSortBy": {
      "id": "custom_pages.sort_by",
      "defaultMessage": "Sort By"
    },
    "customPagesNewQrCard": {
      "id": "custom_pages.new_qr_card",
      "defaultMessage": "Create QR Card"
    },
    "customPagesHostingWidget": {
      "id": "custom_pages.hosting_widget",
      "defaultMessage": "Ready to take full advantage of Popmenu? Upgrade and host your full site with us."
    },
    "customPagesSelectTemplateToBegin": {
      "id": "custom_pages.select_template_to_begin",
      "defaultMessage": "Select a template to begin building your site."
    },
    "customPagesSelectTemplate": {
      "id": "custom_pages.select_template",
      "defaultMessage": "Select a Template"
    },
    "customPagesPublishDraftError": {
      "id": "custom_pages.publish_draft_error",
      "defaultMessage": "There was an error publishing your draft, please try again later",
    },
    "customPagesPublishDraftSuccess": {
      "id": "custom_pages.publish_draft_success",
      "defaultMessage": "Your draft has been published",
    },
    "customPagesSelectThemedTemplate": {
      "id": "custom_pages.select_themed_template",
      "defaultMessage": "Select a Themed Template"
    },
    "customPagesSorryNoTemplates": {
      "id": "custom_pages.sorry_no_templates",
      "defaultMessage": "Sorry, no templates are available right now."
    },
    "customPagesPublishDraftIsRequired": {
      "id": "custom_pages.publish_draft.is_required",
      "defaultMessage": "is required",
    },
    "customPagesPublishDraftTheseFieldsRequired": {
      "id": "custom_pages.publish_draft.these_required_field",
      "defaultMessage": "These fields are required",
    },
    "customPagesPublishDraftValidateLink": {
      "id": "custom_pages.publish_draft.validate_link",
      "defaultMessage": "Click {link} to access the webbuilder and fill the text fields",
    },
    "customPagesPublishDraftHere": {
      "id": "custom_pages.publish_draft.here",
      "defaultMessage": "here",
    },
    "publishDraftsModalHeader": {
      "id": "custom_pages.publish_drafts_modal_header",
      "defaultMessage": "Publish All Draft Content?"
    },
    "publishDraftsModalContent": {
      "id": "custom_pages.publish_drafts_modal_content",
      "defaultMessage": "Are you sure you want to publish all draft content? Draft content includes: \n• All custom pages\n• All navigation links\n• All footer links"
    },
    "customPagesApplyTemplateConfirm": {
      "id": "custom_pages.apply_template_confirm",
      "defaultMessage": "Are you sure you want to apply this site template?"
    },
    "customPagesSectionsSectionTypesAdvancedFormSection": {
      "id": "custom_pages.sections.section_types.advanced_forms_section",
      "defaultMessage": "Advanced Forms."
    },
    "customPagesSectionsSectionTypesAnnouncementSection": {
      "id": "custom_pages.sections.section_types.announcement_section",
      "defaultMessage": "Latest restaurant announcement."
    },
    "customPagesSectionsSectionTypesCalendarEventSection": {
      "id": "custom_pages.sections.section_types.calendar_event_section",
      "defaultMessage": "Upcoming events for selected locations."
    },
    "customPagesSectionsSectionTypesCustomEmbedSection": {
      "id": "custom_pages.sections.section_types.custom_embed_section",
      "defaultMessage": "Custom embedded script."
    },
    "customPagesSectionsSectionTypesCustomSection": {
      "id": "custom_pages.sections.section_types.custom_section",
      "defaultMessage": "Custom  HTML or scripts."
    },
    "customPagesSectionsSectionTypesEcwidShopSection": {
      "id": "custom_pages.sections.section_types.ecwid_shop_section",
      "defaultMessage": "Ecwid online shop embed."
    },
    "customPagesSectionsSectionTypesPopmenuInstagramFeedSection": {
      "id": "custom_pages.sections.section_types.popmenu_instagram_feed_section",
      "defaultMessage": "Add a 3x3 grid of photos that stream from your Instagram business account."
    },
    "customPagesSectionsSectionTypesFacebookFeedSection": {
      "id": "custom_pages.sections.section_types.facebook_feed_section",
      "defaultMessage": "Facebook feed embed."
    },
    "customPagesSectionsSectionTypesFoodtecSection": {
      "id": "custom_pages.sections.section_types.foodtec_section",
      "defaultMessage": "Foodtec gift card form."
    },
    "customPagesSectionsSectionTypesFormSection": {
      "id": "custom_pages.sections.section_types.form_section",
      "defaultMessage": "Contact form with email notifications."
    },
    "customPagesSectionsSectionTypesFormsiteSection": {
      "id": "custom_pages.sections.section_types.formsite_section",
      "defaultMessage": "Formsite form embed."
    },
    "customPagesSectionsSectionTypesGiftCardSection": {
      "id": "custom_pages.sections.section_types.gift_card_section",
      "defaultMessage": "Allow your guests to buy gift cards to use with Online Ordering"
    },
    "customPagesSectionsSectionTypesGallerySection": {
      "id": "custom_pages.sections.section_types.gallery_section",
      "defaultMessage": "Image gallery with various display options."
    },
    "customPagesSectionsSectionTypesLocationSearchSection": {
      "id": "custom_pages.sections.section_types.location_search_section",
      "defaultMessage": "Searchable map of restaurant locations."
    },
    "customPagesSectionsSectionTypesLocationSection": {
      "id": "custom_pages.sections.section_types.location_section",
      "defaultMessage": "Location contact info and reservations."
    },
    "customPagesSectionsSectionTypesMenuSection": {
      "id": "custom_pages.sections.section_types.menu_section",
      "defaultMessage": "Restaurant menu or multiple menus."
    },
    "customPagesSectionsSectionTypesMapSection": {
      "id": "custom_pages.sections.section_types.map_section",
      "defaultMessage": "Simple map of restaurant locations."
    },
    "customPagesSectionsSectionTypesMultiColumnSection": {
      "id": "custom_pages.sections.section_types.multi_column_section",
      "defaultMessage": "Custom multi-content section of images and text."
    },
    "customPagesSectionsSectionTypesOnlineOrderingSection": {
      "id": "custom_pages.sections.section_types.online_ordering_section",
      "defaultMessage": "Online ordering form for pickup orders and catering requests."
    },
    "customPagesSectionsSectionTypesPowrInstagramFeedSection": {
      "id": "custom_pages.sections.section_types.powr_instagram_feed_section",
      "defaultMessage": "Instagram feed embed."
    },
    "customPagesSectionsSectionTypesPressSection": {
      "id": "custom_pages.sections.section_types.press_section",
      "defaultMessage": "Links to external news articles."
    },
    "customPagesSectionsSectionTypesReviewSection": {
      "id": "custom_pages.sections.section_types.review_section",
      "defaultMessage": "Latest approved reviews & review submission form."
    },
    "customPagesSectionsSectionTypesTextBoxSection": {
      "id": "custom_pages.sections.section_types.text_box_section",
      "defaultMessage": "Simple text box with optional image."
    },
    "customPagesSectionsSectionTypesTripleseatFormSection": {
      "id": "custom_pages.sections.section_types.tripleseat_form_section",
      "defaultMessage": "Tripleseat form embed."
    },
    "customPagesSectionsSectionTypesVideoSection": {
      "id": "custom_pages.sections.section_types.video_section",
      "defaultMessage": "Upload a custom video with automatically generated captions."
    },
    "customPagesSectionsSectionTypesYoutubeVideoSection": {
      "id": "custom_pages.sections.section_types.youtube_video_section",
      "defaultMessage": "YouTube video embed."
    },
    "customPagesDestroyConfirm": {
      "id": "custom_pages.destroy_confirm",
      "defaultMessage": "Are you sure you want to delete this page?"
    },
    "customPagesIndex": {
      "id": "custom_pages.index",
      "defaultMessage": "Pages"
    },
    "customPagesEdit": {
      "id": "custom_pages.edit",
      "defaultMessage": "Edit Page"
    },
    "customPagesDestroy": {
      "id": "custom_pages.destroy",
      "defaultMessage": "Delete Page"
    },
    "customPagesCopy": {
      "id": "custom_page.copy",
      "defaultMessage": "Copy Page"
    },
    "customPagesDestroyed": {
      "id": "custom_pages.destroyed",
      "defaultMessage": "Page successfully deleted."
    },
    "customPagesCreatedError": {
      "id": "custom_pages.created_error",
      "defaultMessage": "There was an error adding the page."
    },
    "customPagesUpdatedError": {
      "id": "custom_pages.updated_error",
      "defaultMessage": "There was an error updating the page."
    },
    "customPagesPreviewBanner": {
      "id": "custom_page.draft_preview",
      "defaultMessage": "Preview of draft content"
    },
    "customPagesPreviewBannerNoCustomCss": {
      "id": "custom_page.draft_preview_no_custom_css",
      "defaultMessage": "Preview of draft content: Custom CSS Disabled"
    },
    "customPagesPreviewBannerInvalid": {
      "id": "custom_page.draft_preview_invalid",
      "defaultMessage": "You are viewing the live version of this page"
    },
    "webBuilderIntroHeader": {
      "id": "web_builder_intro.header",
      "defaultMessage": "Introducing the new web builder",
    },
    "webBuilderIntroContent": {
      "id": "web_builder_intro.content",
      "defaultMessage": "Visually manage your website in our new web builder experience",
    },
    "webBuilderIntroButton": {
      "id": "web_builder_intro.button",
      "defaultMessage": "Go to New Web Builder",
    }
  }
);
export default defaultMessages;

