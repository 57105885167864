import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "loginCarouselSlidesCreate": {
      "id": "login_carousel_slides.create",
      "defaultMessage": "Add Carousel Slide"
    },
    "loginCarouselSlidesEdit": {
      "id": "login_carousel_slides.edit",
      "defaultMessage": "Edit Carousel Slide"
    },
    "loginCarouselSlidesIndex": {
      "id": "login_carousel_slides.index",
      "defaultMessage": "Login Page - Carousel Slides"
    }
  }
);

export default defaultMessages;