import { ApolloLink, Observable } from '~/lazy_apollo/client';
import { print } from 'graphql';

// Modified ActionCableLink from the graphql-ruby-client package
// https://github.com/rmosolgo/graphql-ruby/blob/3aaaf5a33cc2820ddeb53b5d7376eea42f8aa08f/javascript_client/src/subscriptions/ActionCableLink.ts
class ActionCableLink extends ApolloLink {
  constructor(options) {
    super();
    this.cable = options.cable; // ActionCable.createConsumer connection
    this.channelName = options.channelName || 'GraphQLChannel'; // Server-side channel class
    this.actionName = options.actionName || 'execute'; // Server-side channel method
    this.connectionParams = options.connectionParams || {};
    this.subscriptions = {};

    console.log(`[POPMENU] Init ActionCableLink ${options.cable.url}`);
  }

  // Interestingly, this link does _not_ call through to `next` because
  // instead, it sends the request to ActionCable.
  request(operation, _next) {
    const { cable } = this;
    return new Observable((observer) => {
      const channelId = Math.round(Date.now() + Math.random() * 100000).toString(16);
      const { actionName } = this;
      const subscription = cable.subscriptions.create(
        {
          channel: this.channelName,
          channelId,
          ...this.connectionParams,
        },
        {
          connected() {
            this.perform(
              actionName,
              {
                operationId: operation.operationId,
                operationName: operation.operationName,
                query: operation.query ? print(operation.query) : null,
                variables: operation.variables,
              },
            );
          },
          received(payload) {
            if (payload.result && (payload.result.data || payload.result.errors)) {
              observer.next(payload.result);
            }
            if (!payload.more) {
              observer.complete();
            }
          },
          unsubscribe() {
            // Manually call unsubscribe method via internal ActionCable command
            // https://github.com/rails/rails/blob/a55938782cd595db175440fbaf708da56d2ab3e8/actioncable/app/assets/javascripts/action_cable.js#L439
            cable.subscriptions.sendCommand({ identifier: this.identifier }, 'unsubscribe');
          },
        },
      );

      // Make the ActionCable subscription behave like an Apollo subscription
      return Object.assign(subscription, { closed: false });
    });
  }
}

export default ActionCableLink;
