
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "messageGroupsAutosaveNewChip": {
      "id": "message_groups.autosave_new_chip",
      "defaultMessage": "New"
    },
    "messageGroupsAutosaveNewChipDescription": {
      "id": "message_groups.autosave_new_chip_modal_description",
      "defaultMessage": "Mass email messages now have auto-save, so clicking “save draft” is a thing of the past! All mass mesages that are created and updated will now have their changes saved automatically."
    },
    "messageGroupsBodyError": {
      "id": "message_groups.body_error",
      "defaultMessage": "Please enter a message body"
    },
    "messageGroupsClosePreview": {
      "id": "message_groups.close_preview",
      "defaultMessage": "Close Preview"
    },
    "messageGroupsDraftSavedDate": {
      "id": "message_groups.draft_saved_date",
      "defaultMessage": "Draft saved {updatedAt}"
    },
    "messageGroupsEdit": {
      "id": "message_groups.edit",
      "defaultMessage": "Edit Mass Message"
    },
    "messageGroupsDefaultSubject": {
      "id": "message_groups.default_subject",
      "defaultMessage": "Draft subject"
    },
    "messageGroupsDefaultTeaser": {
      "id": "message_groups.default_teaser",
      "defaultMessage": "Draft Teaser"
    },
    "messageGroupsDraftMessages": {
      "id": "message_groups.draft_messages",
      "defaultMessage": "Draft Messages"
    },
    "messageGroupsImageText": {
      "id": "message_groups.image_text",
      "defaultMessage": "Email message icon"
    },
    "messageGroupsMessagePreview": {
      "id": "message_groups.message_preview",
      "defaultMessage": "Message Preview"
    },
    "messageGroupsNew": {
      "id": "message_groups.new",
      "defaultMessage": "New Mass Message"
    },
    "messageGroupsLiveMessages": {
      "id": "message_groups.live_messages",
      "defaultMessage": "Sent Messages"
    },
    "messageGroupsDestroyConfirmTitle": {
      "id": "message_groups.destroy_confirm_title",
      "defaultMessage": "Confirm Delete"
    },
    "messageGroupsDestroyConfirm": {
      "id": "message_groups.destroy_confirm",
      "defaultMessage": "Are you sure you want to delete this mass message?"
    },
    "messageGroupsBackMessage": {
      "id": "message_groups.back_message",
      "defaultMessage": "Would you like to save your work for later or discard the changes?"
    },
    "messageGroupsDiscard": {
      "id": "message_groups.discard",
      "defaultMessage": "Discard"
    },
    "messageGroupsCopyError": {
      "id": "message_groups.copy.error",
      "defaultMessage": "Error copying message, please try again"
    },
    "messageGroupsCopySuccess": {
      "id": "message_groups.copy.success",
      "defaultMessage": "Successfully copied message!"
    },
    "messageGroupsError": {
      "id": "message_groups.error",
      "defaultMessage": "Error sending message, please try again"
    },
    "messageGroupsErrorFormatting": {
      "id": "message_groups.error_formatting",
      "defaultMessage": "Message failed. Please check for formatting errors."
    },
   "messageGroupsErrorHosting": {
      "id": "message_groups.error_hosting",
      "defaultMessage": "Please configure your hosting settings before sending a message."
    },
    "messageGroupsSubmit": {
      "id": "message_groups.submit",
      "defaultMessage": "Send Message"
    },
    "messageGroupsDescription": {
      "id": "message_groups.description",
      "defaultMessage": "Mass messages send a one-time custom email to all of your followers."
    },
    "messageGroupsWarning": {
      "id": "message_groups.warning",
      "defaultMessage": "It may take several minutes to send messages to all followers"
    },
    "messageGroupsDestroy": {
      "id": "message_groups.destroy",
      "defaultMessage": "Delete Mass Message"
    },
    "messageGroupsSaveDraft": {
      "id": "message_groups.save_draft",
      "defaultMessage": "Save Draft"
    },
    "messageGroupsOverviewClickRate": {
      "id": "message_groups.overview.click_rate",
      "defaultMessage": "Click Rate"
    },
    "messageGroupsOverviewClickRateClicked": {
      "id": "message_groups.overview.click_rate_clicked",
      "defaultMessage": "{count} clicked"
    },
    "messageGroupsOverviewOpenRate": {
      "id": "message_groups.overview.open_rate",
      "defaultMessage": "Open Rate"
    },
    "messageGroupsOverviewOpenRateOpened": {
      "id": "message_groups.overview.open_rate_opened",
      "defaultMessage": "{count} opened"
    },
    "messageGroupsOverviewDeliveryRate": {
      "id": "message_groups.overview.delivery_rate",
      "defaultMessage": "Delivery Rate"
    },
    "messageGroupsOverviewDeliveryRateDelivered": {
      "id": "message_groups.overview.delivery_rate_delivered",
      "defaultMessage": "{count} delivered"
    },
    "messageGroupsOverviewUnsubscribed": {
      "id": "message_groups.overview.unsubscribed",
      "defaultMessage": "Unsubscribed"
    },
    "messageGroupsOverviewUnsubscribedUnsubscribed": {
      "id": "message_groups.overview.unsubscribed_unsubscribed",
      "defaultMessage": "{count} unsubscribed"
    },
    "messageGroupsPageTitle": {
      "id": "message_groups.page_title",
      "defaultMessage": "Email Messages"
    },
    "messageGroupsPostAgain": {
      "id": "message_groups.post_again",
      "defaultMessage": "Copy to New Message"
    },
    "messageGroupsPreview": {
      "id": "message_groups.preview",
      "defaultMessage": "Preview"
    },
    "messageGroupsPreviewSent": {
      "id": "message_groups.preview_sent",
      "defaultMessage": "Message preview sent! The preview may take a few minutes to be delivered."
    },
    "messageGroupsSaveChanges": {
      "id": "message_groups.save_changes",
      "defaultMessage": "Save Changes?"
    },
    "messageGroupsSaving": {
      "id": "message_groups.saving",
      "defaultMessage": "Saving..."
    },
    "messageGroupsSaved": {
      "id": "message_groups.saved",
      "defaultMessage": "Message saved!"
    },
    "messageGroupsSendPreview": {
      "id": "message_groups.send_preview",
      "defaultMessage": "Send Test Message"
    },
    "messageGroupsSent": {
      "id": "message_groups.sent",
      "defaultMessage": "Message sending. This may take a few minutes."
    },
    "messageGroupsSettings": {
      "id": "message_groups.settings",
      "defaultMessage": "Message Settings"
    },
    "messageGroupsDraftSubjectError": {
      "id": "message_groups.draft_subject_error",
      "defaultMessage": "Please enter a valid subject"
    },
    "messageGroupsSubjectError": {
      "id": "message_groups.subject_error",
      "defaultMessage": "Please enter a subject"
    },
    "messageGroupsIndustryAverageOpenRate": {
      "id": "message_groups.industry_average_open_rate",
      "defaultMessage": "The average open rate for the restaurant industry is approx {averageOpenRate}％"
    },
    "messageGroupsDoingGreat": {
      "id": "message_groups.doing_great",
      "defaultMessage": "Doing Great!"
    },
    "messageGroupsOpenRateAverage": {
      "id": "message_groups.open_rate_average",
      "defaultMessage": "Your open rate is {openHigherPercent}％ higher than industry average."
    },
    "messageGroupsEstimatedRevenueWidgetTitle": {
      "id": "message_groups.estimated_revenue_widget.title",
      "defaultMessage": "Total revenue from online orders",
    },
    "messageGroupsEstimatedRevenueWidgetSubtitle": {
      "id": "message_groups.estimated_revenue_widget.subtitle",
      "defaultMessage": "Est. revenue earned through this message from online orders placed on website",
    },
    "messageGroupsEstimatedRevenueWidgetOrders": {
      "id": "message_groups.estimated_revenue_widget.orders",
      "defaultMessage": "Total online orders",
    },
    "messageGroupsEstimatedRevenueWidgetTotalRevenue": {
      "id": "message_groups.estimated_revenue_widget.total_revenue",
      "defaultMessage": "Total revenue",
    },
    "messageGroupsEstimatedRevenueWidgetAverageRevenue": {
      "id": "message_groups.estimated_revenue_widget.average_revenue",
      "defaultMessage": "Avg. order revenue",
    },
    "messageGroupsRevenueBreakdownDirectRevenueDescription": {
      "id": "message_groups.revenue_breakdown.direct_revenue_description",
      "defaultMessage": "Direct revenue is recorded when recipients clicked on a link in this email that resulted in an online order. This is tracked via UTM codes.",
    },
    "messageGroupsRevenueBreakdownAttributableRevenueDescription": {
      "id": "message_groups.revenue_breakdown.attributable_revenue_description",
      "defaultMessage": "Attributable revenue is recorded when a guest who has opened this email places an online order within 10 days of opening the email, but the order is not necessarily the result of a direct click on a link from the email.",
    },
    "messageGroupsRevenueBreakdownTotalRevenueDescription": {
      "id": "message_groups.revenue_breakdown.total_revenue_description",
      "defaultMessage": "Total revenue is the sum of these two revenue types, showing the potential impact of this message on your overall online ordering revenue. This may not account for in-person orders.",
    },
    "messageGroupsRevenueBreakdownOrdersDescription": {
      "id": "message_groups.revenue_breakdown.orders_description",
      "defaultMessage": "Total number of online orders from both direct & attributable revenue types.",
    },
    "messageGroupsRevenueBreakdownAverageRevenueDescription": {
      "id": "message_groups.revenue_breakdown.average_revenue_description",
      "defaultMessage": "Total revenue divided by total number of online orders for an average order amount per online order.",
    },
    "messageGroupsRevenueBreakdownTypesOfRevenue": {
      "id": "message_groups.revenue_breakdown.types_of_revenue",
      "defaultMessage": "Types of revenue",
    },
    "messageGroupsRevenueBreakdownTitle": {
      "id": "message_groups.revenue_breakdown.title",
      "defaultMessage": "How is est. revenue from messages calculated?",
    },
  }
);
export default defaultMessages;
