import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { compose } from '@shakacode/recompose';
import { Redirect, withRouter } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import debounce from 'lodash/debounce';
import { Button } from '@popmenu/admin-ui';
import { Typography } from '@popmenu/common-ui';

import { formatNumber } from '../../utils/numbers';
import { withStyles } from '../../utils/withStyles';
import genericStyles from '../../assets/jss/admin/genericStyles';
import { withIntl } from '../../utils/withIntl';
import { currentUserShape, withCurrentUser } from '../../shared/CurrentSessionProvider';
import userAdminRestaurantsQuery from '../../libs/gql/queries/users/userAdminRestaurantsQuery.gql';
import userCreateRestaurantQuery from '../../libs/gql/queries/users/userCreateRestaurantQuery.gql';

import BasicList from '../shared/BasicList';
import BasicModal from '../shared/BasicModal';
import CreateRestaurantForm from './CreateRestaurantForm';
import Query from '../../shared/Query';
import SignOutButton from '../shared/SignOutButton';
import TextField from '../shared/forms/TextField';

const RestaurantListSkeleton = () => (
  <React.Fragment>
    <Skeleton height="32px" width="100%" />
    <Skeleton height="56px" width="100%" />
    <Skeleton height="56px" width="100%" />
    <Skeleton height="56px" width="100%" />
    <Skeleton height="56px" width="100%" />
    <Skeleton height="56px" width="100%" />
  </React.Fragment>
);

class RestaurantIndexContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      showCreateRestaurantModal: false,
    };
    this.closeCreateRestaurantModal = this.closeCreateRestaurantModal.bind(this);
    this.handleSearchTermChange = debounce(this.handleSearchTermChange.bind(this), 300);
    this.onRestaurantCreateCompleted = this.onRestaurantCreateCompleted.bind(this);
    this.openCreateRestaurantModal = this.openCreateRestaurantModal.bind(this);
    const containerSF = document.querySelector('.embeddedServiceSidebar');
    if (containerSF) {
      window.embedded_svc.liveAgentAPI.endChat();
    }
    window.embedded_svc = null;
  }

  onRestaurantCreateCompleted(data) {
    this.closeCreateRestaurantModal();
    if (data && data.createRestaurant && data.createRestaurant.slug) {
      this.props.history.push(`/${data.createRestaurant.slug}`);
    }
  }

  handleSearchTermChange(searchTerm) {
    this.setState({ searchTerm });
  }

  openCreateRestaurantModal() {
    this.setState({ showCreateRestaurantModal: true });
  }

  closeCreateRestaurantModal() {
    this.setState({ showCreateRestaurantModal: false });
  }

  buildHref({ slug }) {
    if (this.props.location.pathname.indexOf('/link') === 0) {
      // Extract path and remove trailing slash
      let path = `/${slug}${this.props.location.pathname.split('/link')[1] || ''}`.replace(/\/+$/, '');
      // Apply query string values
      if (this.props.location.search && this.props.location.search.length > 1) {
        path = `${path}${this.props.location.search}`;
      }
      return path;
    }
    return `/${slug}`;
  }

  renderPendingInvites() {
    if (this.props.currentUser.pendingInvites.length === 0) {
      return null;
    }
    return (
      <React.Fragment>
        <Typography variant="h5"><FormattedMessage id="restaurants.member_invites.pending" defaultMessage="Pending Invites" /></Typography>
        <BasicList>
          {this.props.currentUser.pendingInvites.map(({ id, token, restaurant }) => (
            <BasicList.ItemLink
              key={id}
              to={`/invites/${token}`}
            >
              <BasicList.ItemAvatar alt={restaurant.name} className={this.props.classes.squareAvatar} src={restaurant.logoUrl} />
              <BasicList.ItemText
                primary={restaurant.name}
                secondary="Pending Invite"
              />
            </BasicList.ItemLink>
          ))}
        </BasicList>
      </React.Fragment>
    );
  }

  renderList() {
    const searchTerm = (this.state.searchTerm || '').trim() || null;
    const isRoot = this.props.currentUser.isRootStaging || this.props.currentUser.isRoot || this.props.currentUser.isRootOpentable;
    const { pendingInvites } = this.props.currentUser;
    return (
      <React.Fragment>
        {isRoot && pendingInvites.length === 0 && (
          <div className={this.props.classes.searchWrapper}>
            <TextField
              onChange={e => this.handleSearchTermChange(e.target.value)}
              placeholder="Find a restaurant..."
              type="text"
              variant="standard"
            />
          </div>
        )}
        <Query
          fetchPolicy="cache-and-network"
          query={userAdminRestaurantsQuery}
          variables={{ searchTerm }}
        >
          {({ data, loading }) => {
            if (loading || !data || !data.currentSession || !data.currentSession.user) {
              return <RestaurantListSkeleton />;
            }

            // Remove cached restaurants that we know have been disabled
            const adminRestaurants = data.currentSession.user.adminRestaurants.filter(r => r.status !== 'status_disabled');

            // Empty state for no restaurants
            if (adminRestaurants.length === 0) {
              if (pendingInvites.length > 0) {
                return null;
              }
              return (
                <Typography className={this.props.classes.emptyMessage}>
                  <FormattedMessage id="restaurants.empty" defaultMessage="No restaurants found!" />
                </Typography>
              );
            }

            // Redirect to single restaurant
            // Only when there are no pending invites and not searching
            if (!isRoot && adminRestaurants.length === 1 && pendingInvites.length === 0 && !searchTerm) {
              return <Redirect to={this.buildHref(adminRestaurants[0])} />;
            }

            // Redirect to the last restaurant slug if was a NCR restaurant
            // and the onboardingInfo status is not is_dashboard
            const lastRestaurant = adminRestaurants.sort((a, b) => b.id - a.id)[0];
            if (!isRoot && lastRestaurant?.isNcr && lastRestaurant?.onboardingInfo?.indieStatus !== 'is_dashboard') {
              return <Redirect to={this.buildHref(lastRestaurant)} />;
            }

            return (
              <BasicList>
                {adminRestaurants.map(({ followersCount, id, logoUrl, name, slug }) => (
                  <BasicList.ItemLink
                    key={id}
                    to={this.buildHref({ slug })}
                  >
                    <BasicList.ItemAvatar alt={name} src={logoUrl} variant="square" fallback="none" background="#0000" fit="contain" />
                    <BasicList.ItemText
                      primary={name}
                      secondary={followersCount ? this.props.t('restaurants.followers_count', { count: formatNumber(followersCount || 0) }) : null}
                    />
                  </BasicList.ItemLink>
                ))}
              </BasicList>
            );
          }}
        </Query>
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderPendingInvites()}
        {this.renderList()}
        <Query
          fetchPolicy="cache-and-network"
          query={userCreateRestaurantQuery}
          variables={{ userId: this.props.currentUser.id }}
        >
          {({ data }) => (
            <React.Fragment>
              <div className={this.props.classes.panelLinks}>
                {data?.user?.policy?.createRestaurant && (
                  <div>
                    <Button
                      variant="secondary"
                      onClick={this.openCreateRestaurantModal}
                    >
                      <FormattedMessage id="restaurants.new" defaultMessage="Create Restaurant" />
                    </Button>
                  </div>
                )}
                <div>
                  <SignOutButton isAdmin />
                </div>
              </div>

              <BasicModal
                closeModal={this.closeCreateRestaurantModal}
                show={this.state.showCreateRestaurantModal}
                size="sm"
                title={this.props.t('restaurants.new')}
              >
                <CreateRestaurantForm
                  onCompleted={this.onRestaurantCreateCompleted}
                />
              </BasicModal>
            </React.Fragment>
          )}
        </Query>
      </React.Fragment>
    );
  }
}

RestaurantIndexContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  currentUser: currentUserShape.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  refetchCurrentUser: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  withRouter,
  withIntl,
  withCurrentUser,
  withStyles(genericStyles),
)(RestaurantIndexContainer);
