import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';
import { compose } from '@shakacode/recompose';
import { Button, Typography } from '@popmenu/common-ui';

import { formatPhone } from '../../utils/utils';
import { notBlankValidator } from '../../utils/forms';
import { currentSessionShape, withCurrentSession } from '../CurrentSessionProvider';
import confirmSessionAuthyMutation from '../../libs/gql/mutations/users/confirmSessionAuthyMutation.gql';
import enableUserAuthyMutation from '../../libs/gql/mutations/users/enableUserAuthyMutation.gql';
import sendUserAuthyCodeMutation from '../../libs/gql/mutations/users/sendUserAuthyCodeMutation.gql';

import BasicForm, { SubmitGroup, TextFieldGroup } from '../../admin/shared/forms/BasicForm';
import Grid from '../Grid';
import Mutation from '../Mutation';
import SignOutButton from '../../admin/shared/SignOutButton';
import { AH, AHLevelProvider } from '../../consumer/shared/AccessibleHeading';

class AuthyForm extends React.PureComponent {
  render() {
    const session = this.props.currentSession;
    const { user } = session;
    const authyApp = (
      <a href="https://authy.com/download/" rel="noreferrer" target="_blank">
        <FormattedMessage
          id="shared_components.sessions.authy_form.authy_link"
          defaultMessage="Authy app"
        />
      </a>
    );
    if (user && user.authyStatus === 'authy_disabled') {
      return (
        <React.Fragment>
          <AH typography align="center" gutterBottom variant="h6">
            <strong>
              <FormattedMessage
                id="shared_components.sessions.authy_form.enable_2fa"
                defaultMessage="Enable Two-Factor Authentication"
              />
            </strong>
          </AH>
          <AHLevelProvider>
            <Typography align="center" gutterBottom>
              <FormattedMessage
                id="shared_components.sessions.authy_form.confirm_2fa"
                defaultMessage="Two-factor authentication is currently disabled. Confirm your mobile phone and password to secure your Popmenu account."
              />
              <br /><br />
              <FormattedMessage
                id="shared_components.sessions.authy_form.2fa_description"
                defaultMessage="Enter the security code sent to your mobile phone or {authyApp}."
                values={{ authyApp }}
              />
              <br /><br />
              <a href="https://authy.com/what-is-2fa/" rel="noreferrer" target="_blank">
                <FormattedMessage
                  id="shared_components.sessions.authy_form.what_is_2fa"
                  defaultMessage="What is 2FA?"
                />
              </a>
              <br /><br />
            </Typography>
            <BasicForm
              defaultValues={{
                email: user.email,
                phone: formatPhone(user.phone),
                userId: user.id,
              }}
              mutate={{
                mutation: enableUserAuthyMutation,
                toVariables: ({ email, ...variables }) => variables,
              }}
            >
              <TextFieldGroup
                disabled
                field="email"
                title="Email"
              />
              <TextFieldGroup
                field="phone"
                title="Mobile Phone"
                type="tel"
                validate={notBlankValidator}
              />
              <TextFieldGroup
                field="password"
                title="Current Password"
                type="password"
                validate={notBlankValidator}
              />
              <SubmitGroup
                block
                size="lg"
                title="Send Code"
              />
            </BasicForm>
            <Typography align="center">
              <SignOutButton isAdmin />
            </Typography>
          </AHLevelProvider>
        </React.Fragment>
      );
    }
    if (user && (user.authyStatus === 'authy_pending' || !session.isAuthyVerified)) {
      return (
        <React.Fragment>
          <AH typography align="center" gutterBottom variant="h6">
            <strong>
              <FormattedMessage
                id="shared_components.sessions.authy_form.confirm_2fa"
                defaultMessage="Confirm Two-Factor Authentication"
              />
            </strong>
          </AH>
          <AHLevelProvider>
            <Typography align="center" gutterBottom>
              <FormattedMessage
                id="shared_components.sessions.authy_form.enter_code"
                defaultMessage="Enter the security code sent to your mobile phone or {authyApp}."
                values={{ authyApp }}
              />
              <br /><br />
            </Typography>
            <BasicForm mutation={confirmSessionAuthyMutation}>
              <TextFieldGroup
                autoComplete="one-time-code"
                field="authyCode"
                title="Code"
                validate={notBlankValidator}
              />
              <SubmitGroup
                block
                size="lg"
                title="Confirm"
              />
            </BasicForm>
            <Grid container justify="center">
              <Grid item>
                <Mutation mutation={sendUserAuthyCodeMutation}>
                  {(sendUserAuthyCode, { loading }) => (
                    <Button
                      loading={loading}
                      onClick={() => {
                        if (loading) {
                          return;
                        }
                        sendUserAuthyCode({ variables: { userId: user.id } });
                      }}
                      variant="text"
                    >
                      <FormattedMessage
                        id="shared_components.sessions.authy_form.send_another_code"
                        defaultMessage="Send Another Code"
                      />
                    </Button>
                  )}
                </Mutation>
              </Grid>
            </Grid>
            <Typography align="center">
              <SignOutButton isAdmin />
            </Typography>
          </AHLevelProvider>
        </React.Fragment>
      );
    }
    return <Redirect to="/" />;
  }
}

AuthyForm.propTypes = {
  currentSession: currentSessionShape.isRequired,
};

export default compose(
  withCurrentSession,
)(AuthyForm);
