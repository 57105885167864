import admin from '../messages/admin/admin'
import advancedForms from '../messages/admin/advanced_forms'
import advancedFormsShared from '../messages/shared/advanced_forms'
import advisor from '../messages/admin/advisor'
import ai from '../messages/admin/ai'
import aiMarketing from '../messages/admin/ai_marketing'
import answering from '../messages/admin/answering'
import aiAnsweringOverview from '../messages/admin/ai_answering_overview'
import answeringOnboarding from '../messages/admin/answering_onboarding'
import announcements from '../messages/admin/announcements'
import approvable from '../messages/admin/approvable'
import billingBankAccounts from '../messages/admin/billing_bank_accounts'
import billingCoupons from '../messages/admin/billing_coupons'
import billingCreditCards from '../messages/admin/billing_credit_cards'
import billingCustomers from '../messages/admin/billing_customers'
import billingInvoices from '../messages/admin/billing_invoices'
import billingPlans from '../messages/admin/billing_plans'
import billingProducts from '../messages/admin/billing_products'
import billingSetup from '../messages/admin/billing_setup'
import billingSubscriptions from '../messages/admin/billing_subscriptions'
import blockedTerm from '../messages/admin/blocked_term'
import blogPost from '../messages/admin/blog_post'
import blogPosts from '../messages/admin/blog_posts'
import calendarEvents from '../messages/admin/calendar_events'
import catering from '../messages/admin/catering';
import consumer from '../messages/admin/consumer'
import contentBuilder from '../messages/admin/content_builder'
import cuisineTypes from '../messages/admin/cuisine_types'
import customFiles from '../messages/admin/custom_files'
import customFormEntries from '../messages/admin/custom_form_entries'
import customForms from '../messages/admin/custom_forms'
import customPages from '../messages/admin/custom_pages'
import customTaxRates from '../messages/admin/custom_tax_rates'
import dashboards from '../messages/admin/dashboards'
import dineIn from '../messages/admin/dine_in';
import dishLibrary from '../messages/admin/dish_library'
import dishes from '../messages/admin/dishes'
import doorDash from '../messages/admin/door_dash'
import errors from '../messages/admin/errors'
import eventTags from '../messages/admin/event_tags'
import events from '../messages/admin/events'
import facebookCampaigns from '../messages/admin/facebook_campaigns'
import follow from '../messages/admin/follow'
import followerTags from '../messages/admin/follower_tags'
import followers from '../messages/admin/followers'
import forms from '../messages/admin/forms'
import frontOfHouse from '../messages/admin/front_of_house';
import generic from '../messages/shared/generic'
import help from '../messages/admin/help'
import indie_onboarding from '../messages/admin/indie_onboarding'
import leads from '../messages/admin/leads'
import library from '../messages/admin/library'
import lists from '../messages/admin/lists'
import liveDomains from '../messages/admin/live_domains'
import loginCarouselSlides from '../messages/admin/login_carousel_slides'
import marketing from '../messages/admin/marketing'
import marketingOffers from '../messages/admin/marketing_offers'
import masterMessageCampaigns from '../messages/admin/master_message_campaigns'
import mediaLibrary from '../messages/admin/media_library'
import menus from '../messages/admin/menus'
import messageCampaigns from '../messages/admin/message_campaigns'
import messageGroups from '../messages/admin/message_groups'
import messagePacks from '../messages/admin/message_packs'
import messages from '../messages/admin/messages'
import models from '../messages/admin/models'
import modifierGroups from '../messages/admin/modifier_groups'
import nav from '../messages/admin/nav'
import offers from '../messages/admin/offers'
import orderingOffers from '../messages/admin/ordering_offers'
import orderNerd from '../messages/admin/order_nerd';
import onboarding from '../messages/admin/onboarding'
import orders from '../messages/admin/orders'
import photos from '../messages/admin/photos'
import popmenu from '../messages/admin/popmenu'
import pops from '../messages/admin/pops'
import printLayouts from '../messages/admin/print_layouts'
import qrCard from '../messages/admin/qr_card'
import restaurantEmbeds from '../messages/admin/restaurant_embeds'
import restauranMemberInvites from '../messages/admin/restaurant_member_invites'
import restaurantMembers from '../messages/admin/restaurant_members'
import restaurantNavDropdowns from '../messages/admin/restaurant_nav_dropdowns'
import restaurantNavLinks from '../messages/admin/restaurant_nav_links'
import restaurants from '../messages/admin/restaurants'
import reviews from '../messages/admin/reviews'
import root from '../messages/admin/root'
import rootAnnouncements from '../messages/admin/root_announcements'
import rootDishTags from '../messages/admin/root_dish_tags'
import rootExtraGroup from '../messages/admin/root_extra_groups'
import rootGroups from '../messages/admin/root_groups'
import rootMessagePacks from '../messages/admin/root_message_packs'
import rootNotes from '../messages/admin/root_notes'
import rootSectionTemplates from '../messages/admin/root_section_templates'
import rootSectionTypes from '../messages/admin/root_section_types'
import rootSiteTemplates from '../messages/admin/root_site_templates'
import rootThemeTemplates from '../messages/admin/root_theme_templates'
import rootThemes from '../messages/admin/root_themes'
import rootToastRestaurants from '../messages/admin/root_toast_restaurant'
import sessions from '../messages/admin/sessions'
import sharedComponents from '../messages/admin/shared_components'
import sharedExtraGroups from '../messages/admin/shared_extra_groups'
import shares from '../messages/admin/shares'
import socialPosts from '../messages/admin/social_posts'
import posProvider from '../messages/admin/pos_provider'
import tables from '../messages/admin/tables'
import textMessageGroups from '../messages/admin/text_message_groups'
import themeFonts from '../messages/admin/theme_fonts'
import twilio from '../messages/admin/twilio';
import upgradeRequest from '../messages/admin/upgrade_request';
import users from '../messages/admin/users'
import webBuilder from '../messages/admin/web_builder'
import consumerShared from '../messages/shared/consumer'
import customFormEntriesShared from '../messages/shared/custom_form_entries'
import errorsShared from '../messages/shared/errors'
import followShared from '../messages/shared/follow'
import modelsShared from '../messages/shared/models'
import navShared from '../messages/shared/nav'
import popsShared from '../messages/shared/pops'
import reviewsShared from '../messages/shared/reviews'
import sessionsShared from '../messages/shared/sessions'
import socialAccounts from '../messages/shared/social_accounts'
import usersShared from '../messages/shared/users'
import rootSalesDemo from '../messages/admin/root_sales_demo';
import supportUtility from '../messages/admin/support_utility'

const adminMessages = {
  ...admin,
  ...advancedForms,
  ...advancedFormsShared,
  ...advisor,
  ...ai,
  ...aiMarketing,
  ...answering,
  ...aiAnsweringOverview,
  ...answeringOnboarding,
  ...announcements,
  ...approvable,
  ...billingBankAccounts,
  ...billingCoupons,
  ...billingCreditCards,
  ...billingCustomers,
  ...billingInvoices,
  ...billingPlans,
  ...billingProducts,
  ...billingSetup,
  ...billingSubscriptions,
  ...blockedTerm,
  ...blogPost,
  ...blogPosts,
  ...calendarEvents,
  ...catering,
  ...consumer,
  ...contentBuilder,
  ...cuisineTypes,
  ...customFiles,
  ...customFormEntries,
  ...customForms,
  ...customPages,
  ...customTaxRates,
  ...dashboards,
  ...dineIn,
  ...dishLibrary,
  ...dishes,
  ...doorDash,
  ...errors,
  ...eventTags,
  ...events,
  ...facebookCampaigns,
  ...follow,
  ...followerTags,
  ...followers,
  ...forms,
  ...frontOfHouse,
  ...generic,
  ...help,
  ...indie_onboarding,
  ...leads,
  ...library,
  ...lists,
  ...liveDomains,
  ...loginCarouselSlides,
  ...marketing,
  ...marketingOffers,
  ...masterMessageCampaigns,
  ...mediaLibrary,
  ...menus,
  ...messageCampaigns,
  ...messageGroups,
  ...messagePacks,
  ...messages,
  ...models,
  ...modifierGroups,
  ...nav,
  ...offers,
  ...orderingOffers,
  ...orderNerd,
  ...onboarding,
  ...orders,
  ...photos,
  ...popmenu,
  ...pops,
  ...posProvider,
  ...printLayouts,
  ...qrCard,
  ...restaurantEmbeds,
  ...restauranMemberInvites,
  ...restaurantMembers,
  ...restaurantNavDropdowns,
  ...restaurantNavLinks,
  ...restaurants,
  ...reviews,
  ...root,
  ...rootAnnouncements,
  ...rootDishTags,
  ...rootExtraGroup,
  ...rootGroups,
  ...rootMessagePacks,
  ...rootNotes,
  ...rootSalesDemo,
  ...rootSectionTemplates,
  ...rootSectionTypes,
  ...rootSiteTemplates,
  ...rootThemeTemplates,
  ...rootThemes,
  ...rootToastRestaurants,
  ...sessions,
  ...sharedComponents,
  ...sharedExtraGroups,
  ...shares,
  ...socialAccounts,
  ...socialPosts,
  ...tables,
  ...textMessageGroups,
  ...themeFonts,
  ...twilio,
  ...upgradeRequest,
  ...users,
  ...webBuilder,
  ...consumerShared,
  ...customFormEntriesShared,
  ...errorsShared,
  ...followShared,
  ...modelsShared,
  ...navShared,
  ...popsShared,
  ...reviewsShared,
  ...sessionsShared,
  ...usersShared,
  ...supportUtility,
}

export default adminMessages;
