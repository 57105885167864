// This component is admin-only. Headings order checks may be disabled. See AccessibleHeading.jsx
/* eslint-disable react/forbid-elements */
import React from 'react';

// HTML and styles shared with admin_react.html.erb
class PopmenuLoading extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      dots: 0,
    };
    this.dotsInterval = null;
  }

  componentDidMount() {
    this.dotsInterval = setInterval(() => {
      this.setState(({ dots }) => ({ dots: dots === 6 ? 0 : dots + 1 }));
    }, 1500);
  }

  componentWillUnmount() {
    clearInterval(this.dotsInterval);
  }

  render() {
    return (
      <div id="popmenu-loading" className="no-fade">
        <div className="icon-wrap">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" className="icon icon-back">
            <path d="M168.49,92.9H58.9a7,7,0,0,0,0,13.95H168.49a7,7,0,1,0,0-13.95Z" />
            <path d="M58.9,73H168.49a7,7,0,1,0,0-13.95H58.9A7,7,0,0,0,58.9,73Z" />
            <path d="M168.49,126.78H58.9a7,7,0,1,0,0,13.94H168.49a7,7,0,1,0,0-13.94Z" />
            <path d="M211.52,105.13c-4.72-.08-8.94,3.72-12.75,9a106.11,106.11,0,0,0-10.54,19.65c-2.6,6-4,10.54-4.13,14.47a15.28,15.28,0,0,0,3,10c-1.64,2.12-3.57,4.42-5.82,6.8a89.22,89.22,0,0,1-8.9,8.27c-.86.7-2.86,2.33-6,4.51a90.47,90.47,0,0,1-34,14.16c-1.62.31-3.3.57-5,.81a92.06,92.06,0,0,1-15.09.79l-8.75,10L98,209.94V192a91.9,91.9,0,0,1-10.85-2.77,90.39,90.39,0,0,1-12.24-5A91,91,0,0,1,108.38,11.82c2.66-.19,5.23-.26,7.87-.23s5.09.17,7.55.4a90.69,90.69,0,0,1,45.52,17.52A91.48,91.48,0,0,1,180.85,39.7c2.7,2.84,4.36,4.89,5.11,5.81h0a91,91,0,0,1,6.34,8.91,21.19,21.19,0,0,0-3.77,9.75,21.8,21.8,0,0,0,1.18,10.45,79.45,79.45,0,0,1,5,28h5a84.77,84.77,0,0,0-5.36-29.71,15.61,15.61,0,0,1-.45-9.67,12,12,0,0,1,5.51-7.44,9.94,9.94,0,0,1,9.42,0,16.08,16.08,0,0,1,7.64,8.85,108.27,108.27,0,0,1,6.86,38h5a113.11,113.11,0,0,0-7.19-39.75,23.72,23.72,0,0,0-8.7-11.4,15.43,15.43,0,0,0-11.21-2.76,101.43,101.43,0,0,0-7-9.84h0A101.67,101.67,0,1,0,66.53,191.93a99.87,99.87,0,0,0,20.61,8.43v38.56l30.24-34.7q3.51-.08,7-.4A101.59,101.59,0,0,0,189,172.32q3.41-3.6,6.43-7.51c3.31,1.29,7.11,1.26,11.33-1.28s8.86-7.64,12.65-16.42A53,53,0,0,0,223.54,131a40.33,40.33,0,0,0-1-13.66C220.48,109.58,216.16,105.2,211.52,105.13Z" />
            <path d="M218.08,102.58a102.67,102.67,0,0,0-6-34.65l-4.69,1.68a98,98,0,0,1,5.68,33Z" />
            <path d="M198.77,72.7a88.62,88.62,0,0,1,5.15,29.88h5A93.74,93.74,0,0,0,203.47,71Z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" className="icon icon-front">
            <path d="M168.49,92.9H58.9a7,7,0,0,0,0,13.95H168.49a7,7,0,1,0,0-13.95Z" />
            <path d="M58.9,73H168.49a7,7,0,1,0,0-13.95H58.9A7,7,0,0,0,58.9,73Z" />
            <path d="M168.49,126.78H58.9a7,7,0,1,0,0,13.94H168.49a7,7,0,1,0,0-13.94Z" />
            <path d="M211.52,105.13c-4.72-.08-8.94,3.72-12.75,9a106.11,106.11,0,0,0-10.54,19.65c-2.6,6-4,10.54-4.13,14.47a15.28,15.28,0,0,0,3,10c-1.64,2.12-3.57,4.42-5.82,6.8a89.22,89.22,0,0,1-8.9,8.27c-.86.7-2.86,2.33-6,4.51a90.47,90.47,0,0,1-34,14.16c-1.62.31-3.3.57-5,.81a92.06,92.06,0,0,1-15.09.79l-8.75,10L98,209.94V192a91.9,91.9,0,0,1-10.85-2.77,90.39,90.39,0,0,1-12.24-5A91,91,0,0,1,108.38,11.82c2.66-.19,5.23-.26,7.87-.23s5.09.17,7.55.4a90.69,90.69,0,0,1,45.52,17.52A91.48,91.48,0,0,1,180.85,39.7c2.7,2.84,4.36,4.89,5.11,5.81h0a91,91,0,0,1,6.34,8.91,21.19,21.19,0,0,0-3.77,9.75,21.8,21.8,0,0,0,1.18,10.45,79.45,79.45,0,0,1,5,28h5a84.77,84.77,0,0,0-5.36-29.71,15.61,15.61,0,0,1-.45-9.67,12,12,0,0,1,5.51-7.44,9.94,9.94,0,0,1,9.42,0,16.08,16.08,0,0,1,7.64,8.85,108.27,108.27,0,0,1,6.86,38h5a113.11,113.11,0,0,0-7.19-39.75,23.72,23.72,0,0,0-8.7-11.4,15.43,15.43,0,0,0-11.21-2.76,101.43,101.43,0,0,0-7-9.84h0A101.67,101.67,0,1,0,66.53,191.93a99.87,99.87,0,0,0,20.61,8.43v38.56l30.24-34.7q3.51-.08,7-.4A101.59,101.59,0,0,0,189,172.32q3.41-3.6,6.43-7.51c3.31,1.29,7.11,1.26,11.33-1.28s8.86-7.64,12.65-16.42A53,53,0,0,0,223.54,131a40.33,40.33,0,0,0-1-13.66C220.48,109.58,216.16,105.2,211.52,105.13Z" />
            <path d="M218.08,102.58a102.67,102.67,0,0,0-6-34.65l-4.69,1.68a98,98,0,0,1,5.68,33Z" />
            <path d="M198.77,72.7a88.62,88.62,0,0,1,5.15,29.88h5A93.74,93.74,0,0,0,203.47,71Z" />
          </svg>
        </div>
        <h1 className="text">{`Loading ${[...Array(this.state.dots).keys()].map(() => '.').join(' ')}`.trim()}</h1>
      </div>
    );
  }
}

export default PopmenuLoading;
