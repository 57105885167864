import React from 'react';
import loadable from '@loadable/component';
import retry from '../../utils/retry';
import PopmenuLoading from '../shared/PopmenuLoading';

const AdminParentContainer = loadable(() => retry(() => import('./AdminParentContainer')), {
  fallback: <PopmenuLoading />,
});

export default AdminParentContainer;
