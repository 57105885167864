
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "customFilesDestroyConfirm": {
      "id": "custom_files.destroy_confirm",
      "defaultMessage": "Are you sure you want to delete this file?"
    },
    "customFilesDestroyConfirmTitle": {
      "id": "custom_files.destroy_confirm_title",
      "defaultMessage": "Confirm Delete"
    },
    "customFilesIndex": {
      "id": "custom_files.index",
      "defaultMessage": "Files"
    },
    "customFilesNew": {
      "id": "custom_files.new",
      "defaultMessage": "Upload File"
    },
    "customFilesSubmit": {
      "id": "custom_files.submit",
      "defaultMessage": "Upload"
    },
    "customFilesDestroy": {
      "id": "custom_files.destroy",
      "defaultMessage": "Delete File"
    },
    "customFilesDestroyed": {
      "id": "custom_files.destroyed",
      "defaultMessage": "File successfully deleted."
    },
    "customFilesCreatedError": {
      "id": "custom_files.created_error",
      "defaultMessage": "There was an error uploading the file."
    },
    "customFilesNamePlaceholder": {
      "id": "custom_files.name_placeholder",
      "defaultMessage": "e.g., happy-hour-menu"
    }
  }
);
export default defaultMessages;

