import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import GenericContainerWrapper from './GenericContainerWrapper';

const GenericRoute = ({ children, path, renderContainer }) => {
  const route = (
    <Route path={path}>
      {children}
    </Route>
  );
  if (renderContainer) {
    return (
      <GenericContainerWrapper>
        {route}
      </GenericContainerWrapper>
    );
  }
  return route;
};
GenericRoute.defaultProps = {
  renderContainer: true,
};

GenericRoute.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string.isRequired,
  renderContainer: PropTypes.bool,
};

export default GenericRoute;
