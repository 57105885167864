
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "rootToastRestaurantSyncingStarted": {
      "id": "root_toast_restaurant.syncing_started",
      "defaultMessage": "Toast restaurants syncing started!"
    }
  }
);
export default defaultMessages;

