
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "rootDishTagsIndex": {
      "id": "root_dish_tags.index",
      "defaultMessage": "Root - Item Tags"
    },
    "rootDishTagsNew": {
      "id": "root_dish_tags.new",
      "defaultMessage": "Add Tag"
    },
    "rootDishTagsEdit": {
      "id": "root_dish_tags.edit",
      "defaultMessage": "Edit Tag"
    }
  }
);
export default defaultMessages;

