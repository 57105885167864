
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "mediaLibraryClickMessage": {
      "id": "media_library.click_message",
      "defaultMessage": "or click to select a file"
    },
    "mediaLibraryCreatedAt": {
      "id": "media_library.created_at",
      "defaultMessage": "Date"
    },
    "mediaLibraryDeleteFileMessage": {
      "id": "media_library.delete_file_message",
      "defaultMessage": "Are you sure you want to remove this file from the library?"
    },
    "mediaLibraryDeleteFileTitle": {
      "id": "media_library.delete_file_title",
      "defaultMessage": "Remove File"
    },
    "mediaLibraryDeleteImageMessage": {
      "id": "media_library.delete_image_message",
      "defaultMessage": "Are you sure you want to remove this photo from the library?"
    },
    "mediaLibraryDeleteImageTitle": {
      "id": "media_library.delete_image_title",
      "defaultMessage": "Remove Photo"
    },
    "mediaLibraryDeleteVideoMessage": {
      "id": "media_library.delete_video_message",
      "defaultMessage": "Are you sure you want to remove this video from the library?"
    },
    "mediaLibraryDeleteVideoTitle": {
      "id": "media_library.delete_video_title",
      "defaultMessage": "Remove Video"
    },
    "mediaLibraryDownloadImage": {
      "id": "media_library.download_image",
      "defaultMessage": "Download"
    },
    "mediaLibraryDownloadFile": {
      "id": "media_library.download_file",
      "defaultMessage": "Download"
    },
    "mediaLibraryDownloadVideo": {
      "id": "media_library.download_video",
      "defaultMessage": "Download"
    },
    "mediaLibraryDropBrandsMessage": {
      "id": "media_library.drop_brands_message",
      "defaultMessage": "Drop brand elements here"
    },
    "mediaLibraryDropContentsMessage": {
      "id": "media_library.drop_contents_message",
      "defaultMessage": "Drop content here"
    },
    "mediaLibraryDropFilesMessage": {
      "id": "media_library.drop_files_message",
      "defaultMessage": "Drop files here"
    },
    "mediaLibraryDropFontsMessage": {
      "id": "media_library.drop_fonts_message",
      "defaultMessage": "Drop font files here"
    },
    "mediaLibraryDropImagesMessage": {
      "id": "media_library.drop_images_message",
      "defaultMessage": "Drop photos here"
    },
    "mediaLibraryDropImportsMessage": {
      "id": "media_library.drop_imports_message",
      "defaultMessage": "Drop imports here"
    },
    "mediaLibraryDropMenusMessage": {
      "id": "media_library.drop_menus_message",
      "defaultMessage": "Drop menus here"
    },
    "mediaLibraryDropVideosMessage": {
      "id": "media_library.drop_videos_message",
      "defaultMessage": "Drop videos here"
    },
    "mediaLibraryAddPhotoDescription": {
      "id": "media_library.add_photo_description",
      "defaultMessage": "Add photo description"
    },
    "mediaLibraryModalSubHeader": {
      "id": "media_library.modal_sub_header",
      "defaultMessage": "Some of the photos have no description. Photo descriptions help visually impaired customers navigate your website."
    },
    "mediaLibraryAddToAiMarketing": {
      "id": "media_library.add_to_ai_marketing",
      "defaultMessage": "Add to AI Marketing",
    },
    "mediaLibraryEditImage": {
      "id": "media_library.edit_image",
      "defaultMessage": "Edit Photo"
    },
    "mediaLibraryEditVideo": {
      "id": "media_library.edit_video",
      "defaultMessage": "Edit Video"
    },
    "mediaLibraryImageLibrary": {
      "id": "media_library.image_library",
      "defaultMessage": "Photo Library"
    },
    "mediaLibraryOriginalFilename": {
      "id": "media_library.original_filename",
      "defaultMessage": "Name"
    },
    "mediaLibrarySearchFiles": {
      "id": "media_library.search_files",
      "defaultMessage": "Search files..."
    },
    "mediaLibrarySearchImages": {
      "id": "media_library.search_images",
      "defaultMessage": "Search photos..."
    },
    "mediaLibrarySearchVideos": {
      "id": "media_library.search_videos",
      "defaultMessage": "Search videos..."
    },
    "mediaLibrarySelectDish": {
      "id": "media_library.select_dish",
      "defaultMessage": "Add Item"
    },
    "mediaLibrarySelectDishes": {
      "id": "media_library.select_dishes",
      "defaultMessage": "Add Items"
    },
    "mediaLibrarySelectFile": {
      "id": "media_library.select_file",
      "defaultMessage": "Add File"
    },
    "mediaLibrarySelectFiles": {
      "id": "media_library.select_files",
      "defaultMessage": "Add Files"
    },
    "mediaLibrarySelectImage": {
      "id": "media_library.select_image",
      "defaultMessage": "Add Photo"
    },
    "mediaLibrarySelectImages": {
      "id": "media_library.select_images",
      "defaultMessage": "Add Photos"
    },
    "mediaLibrarySelectVideo": {
      "id": "media_library.select_video",
      "defaultMessage": "Add Video"
    },
    "mediaLibrarySelectVideos": {
      "id": "media_library.select_videos",
      "defaultMessage": "Add Videos"
    },
    "mediaLibrarySwapDish": {
      "id": "media_library.swap_dish",
      "defaultMessage": "Swap Item"
    },
    "mediaLibrarySwapDishes": {
      "id": "media_library.swap_dishes",
      "defaultMessage": "Swap Items"
    },
    "mediaLibrarySwapFile": {
      "id": "media_library.swap_file",
      "defaultMessage": "Swap File"
    },
    "mediaLibrarySwapFiles": {
      "id": "media_library.swap_files",
      "defaultMessage": "Swap Files"
    },
    "mediaLibrarySwapImage": {
      "id": "media_library.swap_image",
      "defaultMessage": "Swap Photo"
    },
    "mediaLibrarySwapImages": {
      "id": "media_library.swap_images",
      "defaultMessage": "Swap Photos"
    },
    "mediaLibrarySwapVideo": {
      "id": "media_library.swap_video",
      "defaultMessage": "Swap Video"
    },
    "mediaLibrarySwapVideos": {
      "id": "media_library.swap_videos",
      "defaultMessage": "Swap Videos"
    },
    "mediaLibraryVideoLimitMessage": {
      "id": "media_library.video.file_limit",
      "defaultMessage": "Video exceeds file limit"
    },
    "mediaLibraryVideoLibrary": {
      "id": "media_library.video_library",
      "defaultMessage": "Video Library"
    }
  }
);
export default defaultMessages;

