import React, { useEffect, useState } from 'react';
import type { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { useWindowSizeContext } from '~/shared/WindowSizeProvider';
import { findElement } from '~/utils/dom';
import { useBranchIo } from '../useBranchIo/useBranchIo';

interface AdminPageChangeHandlerProps {
  children?: ReactNode
  branchIoKey: string | null
}

export const AdminPageChangeHandler = ({ children = null, branchIoKey }:AdminPageChangeHandlerProps) => {
  const [pageName, setPageName] = useState<null | string>(null);
  const location = useLocation();
  const windowSizeContext = useWindowSizeContext();
  const { isMobile, triggerResize } = windowSizeContext;
  useBranchIo({ branchIoKey, isMobile, pageName });

  useEffect(() => {
    if (location.pathname !== pageName) {
      setTimeout(() => {
        // Scroll to the top of the page as most users expect
        window.scrollTo(0, 0);

        // Focus the main content for screenreaders
        const mainEl = findElement('main');
        if (mainEl) {
          mainEl.focus();
        }

        // Announce page change via aria-live region
        setPageName(location.pathname);

        // Trigger resize
        if (triggerResize) {
          triggerResize();
        }
      }, 10);
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 50);
      console.log(`[POPMENU] Navigated to ${location.pathname}`);
    }
  }, [location.pathname, pageName, triggerResize]);

  return (
    <React.Fragment>
      {children}
      <div
        aria-atomic="true"
        aria-live="polite"
        className="sr-only"
        role="status"
      >
        {pageName && `Navigated to ${pageName} page.`}
      </div>
    </React.Fragment>
  );
};
