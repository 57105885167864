
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "popmenu": {
      "id": "popmenu",
      "defaultMessage": "Popmenu"
    }
  }
);
export default defaultMessages;

