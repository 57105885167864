
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "restaurantsLocationPickerTitle": {
      "id": "restaurants.location_picker.title",
      "defaultMessage": "Select a Location"
    },
    "restaurantsMemberInvitesExpired": {
      "id": "restaurants.member_invites.expired",
      "defaultMessage": "Sorry, the invite has expired or has already been accepted."
    },
    "restaurantsMemberInvitesAccept": {
      "id": "restaurants.member_invites.accept",
      "defaultMessage": "Accept Invite"
    },
    "restaurantsItemTagsSettings": {
      "id": "restaurants.item_tags.settings",
      "defaultMessage": "Manage Item Tags"
    },
    "restaurantsSettingsIsMemberOnlyTooltip": {
      "id": "restaurants.settings.is_member_only_tooltip",
      "defaultMessage": "Only show page to signed-in team members"
    },
    "restaurantsDisplayDoorDashAtCheckout": {
      "id": "restaurants.display_doordash_at_checkout",
      "defaultMessage": "Display Doordash At Checkout"
    },
    "restaurantsDisplayRestaurantLocation": {
      "id": "restaurants.display_restaurant_location",
      "defaultMessage": "Display Locations"
    },
    "restaurantsDisplayRestaurantLocationHours": {
      "id": "restaurants.display_restaurant_location_hours",
      "defaultMessage": "Display Location Hours"
    },
    "restaurantsNew": {
      "id": "restaurants.new",
      "defaultMessage": "Create Restaurant"
    },
    "restaurantsDishTagsNew": {
      "id": "restaurants.item_tags.new",
      "defaultMessage": "New Item Tag"
    },
    "restaurantsDishTagsEdit": {
      "id": "restaurants.item_tags.edit",
      "defaultMessage": "Edit Item Tag"
    },
    "restaurantsEmpty": {
      "id": "restaurants.empty",
      "defaultMessage": "No restaurants found!"
    },
    "restaurantsMemberInvitesPending": {
      "id": "restaurants.member_invites.pending",
      "defaultMessage": "Pending Invites"
    },
    "restaurantsFollowersCountOne": {
      "id": "restaurants.followers_count.one",
      "defaultMessage": "{count} follower"
    },
    "restaurantsFollowersCountOther": {
      "id": "restaurants.followers_count.other",
      "defaultMessage": "{count} followers"
    },
    "restaurantsSettingsAllowAnonymousPopsTooltip": {
      "id": "restaurants.settings.allow_anonymous_pops_tooltip",
      "defaultMessage": "Allow anonymous pops from guests without signing up"
    },
    "restaurantsSettingsFeaturesHeading": {
      "id": "restaurants.settings.features_heading",
      "defaultMessage": "Features"
    },
    "restaurantsSettingsAllowAnonymousReviewsTooltip": {
      "id": "restaurants.settings.allow_anonymous_reviews_tooltip",
      "defaultMessage": "Allow anonymous reviews from guests without signing up"
    },
    "restaurantsSettingsCustomFollowPathTooltip": {
      "id": "restaurants.settings.custom_follow_path_tooltip",
      "defaultMessage": "If provided, the follow prompt will redirect to the given URL rather than opening the sign up form"
    },
    "restaurantsSettingsDisplayRootAnnouncementsTooltip": {
      "id": "restaurants.settings.display_root_announcements_tooltip",
      "defaultMessage": "Enabled Root Restaurant Announcements feature"
    },
    "restaurantsSettingsDisplayVipPopupTooltip": {
      "id": "restaurants.settings.display_vip_popup_tooltip",
      "defaultMessage": "Enable VIP popup"
    },
    "restaurantsSettingsIsIndieTooltip": {
      "id": "restaurants.settings.is_indie_enabled_tooltip",
      "defaultMessage": "For Indie clients, restricts the usage of certain features",
    },
    "restaurantsSettingsIsStandaloneAnsweringTooltip": {
      "id": "restaurants.settings.is_standalone_answering_tooltip",
      "defaultMessage": "For Standalone AI Answering clients, only enable answering related features",
    },
    "restaurantsSettingsStatusTooltip": {
      "id": "restaurants.settings.status_tooltip",
      "defaultMessage": "Only enabled restaurants are considered when calculating aggregate stats"
    },
    "restaurantsSettingsUseLastLocationTooltip": {
      "id": "restaurants.settings.use_last_location_tooltip",
      "defaultMessage": "Use the last location as a fallback for whenever favorite location is unavailable"
    },
    "restaurantsSettingsDescriptionTooltip": {
      "id": "restaurants.settings.description_tooltip",
      "defaultMessage": "Short description used for search results and other metadata (e.g., \"Family-owned eatery offering pasta & other classic Italian dishes in an informal setting\")"
    },
    "restaurantsSettingsFooterLogoTooltip": {
      "id": "restaurants.settings.footer_logo_tooltip",
      "defaultMessage": "Alternate logo displayed in website footer (defaults to main restaurant logo if not present)"
    },
    "restaurantsSettingsMailerDarkThemeLogoTooltip": {
      "id": "restaurants.settings.mailer_dark_theme_logo_tooltip",
      "defaultMessage": "Alternate logo used for dark themed email messages"
    },
    "restaurantsSettingsMailerLogoTooltip": {
      "id": "restaurants.settings.mailer_logo_tooltip",
      "defaultMessage": "Alternate logo displayed in email messages to followers (defaults to Logo)"
    },
    "restaurantsSettingsSmsLimitations": {
      "id": "restaurants.settings.sms_limitations",
      "defaultMessage": "Additional messages per month"
    },
    "restaurantsSettingsSmsLimitations": {
      "id": "restaurants.settings.mms_limitations",
      "defaultMessage": "Messages per month"
    },
    "restaurantsSettingsSmsLimitationsTitle": {
      "id": "restaurants.settings.sms_limitations_title",
      "defaultMessage": "Additional messages per month"
    },
    "restaurantsSettingsIsCurrencyDisplayOverrideEnabledTooltip": {
      "id": "restaurants.settings.is_currency_display_override_enabled_tooltip",
      "defaultMessage": "This toggle changes the currency icons used across a clients website when they use different currency than their Country location."
    },
    "restaurantsSettingsIsCurrencyDisplayOverrideEnabledTitle": {
      "id": "restaurants.settings.is_currency_display_override_enabled_title",
      "defaultMessage": "Enable Currency Display Override"
    },
    "restaurantsSettingsCurrencyDisplayOverrideTypePlaceholder": {
      "id": "restaurants.settings.currency_display_override_type_placeholder",
      "defaultMessage": "Select a currency"
    },
    "restaurantsSettingsCurrencyDisplayOverrideTypeTitle": {
      "id": "restaurants.settings.currency_display_override_type_title",
      "defaultMessage": "Currency"
    },
    "restaurantsSettingsCurrencyDisplayOverrideTypeLabelUsd": {
      "id": "restaurants_settings_currency_display_override_type_label_usd",
      "defaultMessage": "US Dollar - USD"
    },
    "restaurantsSettingsCurrencyDisplayOverrideTypeLabelCad": {
      "id": "restaurants_settings_currency_display_override_type_label_Cad",
      "defaultMessage": "Canadian Dollar - CAD"
    },
    "restaurantsSettingsCurrencyDisplayOverrideTypeLabelMxd": {
      "id": "restaurants_settings_currency_display_override_type_label_Mxd",
      "defaultMessage": "Mexican Peso - MXN"
    },
    "restaurantsSettingsCurrencyDisplayOverrideTypeLabelGbp": {
      "id": "restaurants_settings_currency_display_override_type_label_Gbp",
      "defaultMessage": "Great Britain Pound - GBP"
    },
    "restaurantsSettingsCurrencyDisplayOverrideTypeLabelEur": {
      "id": "restaurants_settings_currency_display_override_type_label_Eur",
      "defaultMessage": "EU Euro - EUR"
    },
    "restaurantsLocationsEdit": {
      "id": "restaurants.locations.edit",
      "defaultMessage": "Location Settings"
    },

    "restaurantsLocationsAdd": {
      "id": "restaurants.locations.add",
      "defaultMessage": "Add New Location"
    },

    "restaurantsLocationsAddRequestsSuccess": {
      "id": "restaurants.locations.add.requests.success",
      "defaultMessage": "Request successfully saved!"
    },

    "restaurantsLocationsAddDescriptionSingleLocation": {
      "id": "restaurants.locations.add.description.single",
      "defaultMessage": "Want to learn more about adding a new location to your platform? Click Submit below and our team will be reaching out to finalize your new location."
    },

    "restaurantsLocationsAddDescriptionMultLocation": {
      "id": "restaurants.locations.add.description.mult",
      "defaultMessage": "Ready to expand your restaurant? Add a new location for $229/mo. By clicking Submit, you’ll be sending this request to our Billing team and they will bill you $229/mo."
    },

    "restaurantsLocationsArchiveArchive": {
      "id": "restaurants.locations.archive.archive",
      "defaultMessage": "Archive Location"
    },
    "restaurantsLocationsArchiveConfirmTitle": {
      "id": "restaurants.locations.archive.confirm.title",
      "defaultMessage": "Confirm Archive"
    },
    "restaurantsLocationsArchiveConfirmMessage": {
      "id": "restaurants.locations.archive.confirm.message",
      "defaultMessage": "Are you sure you want to archive this location?"
    },
    "restaurantsSettingsGoogleMyBusinessEditAttributes": {
      "id": "restaurants.settings.google_my_business.edit_attributes",
      "defaultMessage": "Edit Attributes"
    },
    "restaurantsSettingsGoogleMyBusinessWebsiteUrl": {
      "id": "restaurants.settings.google_my_business.website_url",
      "defaultMessage": "Website URL"
    },
    "restaurantsSettingsGoogleMyBusinessCompleteVerification": {
      "id": "restaurants.settings.google_my_business.complete_verification",
      "defaultMessage": "Complete Verification"
    },
    "restaurantsSettingsGoogleMyBusinessVerificationPin": {
      "id": "restaurants.settings.google_my_business.verification_pin",
      "defaultMessage": "Verification Pin"
    },
    "restaurantsSettingsGoogleMyBusinessVerify": {
      "id": "restaurants.settings.google_my_business.verify",
      "defaultMessage": "Verify"
    },
    "restaurantsSettingsGoogleMyBusinessNewPost": {
      "id": "restaurants.settings.google_my_business.new_post",
      "defaultMessage": "New Post"
    },
    "restaurantsSettingsGoogleMyBusinessUpdatePost": {
      "id": "restaurants.settings.google_my_business.update_post",
      "defaultMessage": "Update Post"
    },
    "restaurantsSettingsGoogleMyBusinessPublishingAddress": {
      "id": "restaurants.settings.google_my_business.publishing.address",
      "defaultMessage": "Address"
    },
    "restaurantsSettingsGoogleMyBusinessPublishingAdditionalCategories": {
      "id": "restaurants.settings.google_my_business.publishing.additional_categories",
      "defaultMessage": "Additional Categories"
    },
    "restaurantsSettingsGoogleMyBusinessPublishingAttributes": {
      "id": "restaurants.settings.google_my_business.publishing.attributes",
      "defaultMessage": "Attributes"
    },
    "restaurantsSettingsGoogleMyBusinessPublishingLanguageCode": {
      "id": "restaurants.settings.google_my_business.publishing.language_code",
      "defaultMessage": "Language"
    },
    "restaurantsSettingsGoogleMyBusinessPublishingLocationName": {
      "id": "restaurants.settings.google_my_business.publishing.location_name",
      "defaultMessage": "Location Name"
    },
    "restaurantsSettingsGoogleMyBusinessPublishingPrimaryCategory": {
      "id": "restaurants.settings.google_my_business.publishing.primary_category",
      "defaultMessage": "Primary Category"
    },
    "restaurantsSettingsGoogleMyBusinessPublishingPrimaryPhone": {
      "id": "restaurants.settings.google_my_business.publishing.primary_phone",
      "defaultMessage": "Primary Phone"
    },
    "restaurantsSettingsGoogleMyBusinessPublishingProfile": {
      "id": "restaurants.settings.google_my_business.publishing.profile",
      "defaultMessage": "Description"
    },
    "restaurantsSettingsGoogleMyBusinessPublishingRegularHours": {
      "id": "restaurants.settings.google_my_business.publishing.regular_hours",
      "defaultMessage": "Regular Hours"
    },
    "restaurantsSettingsGoogleMyBusinessPublishingSpecialHours": {
      "id": "restaurants.settings.google_my_business.publishing.special_hours",
      "defaultMessage": "Special Hours"
    },
    "restaurantsSettingsGoogleMyBusinessPublishingStoreCode": {
      "id": "restaurants.settings.google_my_business.publishing.store_code",
      "defaultMessage": "Store Code"
    },
    "restaurantsSettingsGoogleMyBusinessPublishingWebsiteUrl": {
      "id": "restaurants.settings.google_my_business.publishing.website_url",
      "defaultMessage": "Website URL"
    },
    "restaurantsSettingsGoogleMyBusinessCreate": {
      "id": "restaurants.settings.google_my_business.create",
      "defaultMessage": "Add Location"
    },
    "restaurantsSettingsGoogleMyBusinessDestroy": {
      "id": "restaurants.settings.google_my_business.destroy",
      "defaultMessage": "Delete"
    },
    "restaurantsSettingsGoogleMyBusinessDestroyConfirm": {
      "id": "restaurants.settings.google_my_business.destroy_confirm",
      "defaultMessage": "Are you sure you want to delete this location?"
    },
    "restaurantsSettingsGoogleMyBusinessDestroyConfirmTitle": {
      "id": "restaurants.settings.google_my_business.destroy_confirm_title",
      "defaultMessage": "Delete Location?"
    },
    "restaurantsSettingsGoogleMyBusinessLastPublished": {
      "id": "restaurants.settings.google_my_business.last_published",
      "defaultMessage": "Last Published"
    },
    "restaurantsSettingsGoogleMyBusinessLinkLocation": {
      "id": "restaurants.settings.google_my_business.link_location",
      "defaultMessage": "Link Location"
    },
    "restaurantsSettingsGoogleMyBusinessLocationInfo": {
      "id": "restaurants.settings.google_my_business.location_info",
      "defaultMessage": "Location Info"
    },
    "restaurantsSettingsGoogleMyBusinessTitle": {
      "id": "restaurants.settings.google_my_business.title",
      "defaultMessage": "Google Business Profile"
    },
    "restaurantsSettingsGoogleMyBusinessUnlinkLocation": {
      "id": "restaurants.settings.google_my_business.unlink_location",
      "defaultMessage": "Unlink Location"
    },
    "restaurantsSettingsGoogleMyBusinessUnlinkLocationMessage": {
      "id": "restaurants.settings.google_my_business.unlink_location_message",
      "defaultMessage": "Are you sure you'd like to unlink this location?"
    },
    "restaurantsSettingsGoogleMyBusinessUnlinkLocationTitle": {
      "id": "restaurants.settings.google_my_business.unlink_location_title",
      "defaultMessage": "Unlink Location"
    },
    "restaurantsSettingsGoogleMyBusinessLocations": {
      "id": "restaurants.settings.google_my_business.locations",
      "defaultMessage": "Google Business Profile Locations"
    },
    "restaurantsSettingsGoogleMyBusinessInsights": {
      "id": "restaurants.settings.google_my_business.insights",
      "defaultMessage": "Insights"
    },
    "restaurantsSettingsGoogleMyBusinessAccounts": {
      "id": "restaurants.settings.google_my_business.accounts",
      "defaultMessage": "Google Business Profile Accounts"
    },
    "restaurantsSettingsGoogleMyBusinessActionType": {
      "id": "restaurants.settings.google_my_business.action_type",
      "defaultMessage": "Action Type"
    },
    "restaurantsSettingsGoogleMyBusinessActionUrl": {
      "id": "restaurants.settings.google_my_business.action_url",
      "defaultMessage": "Action URL"
    },
    "restaurantsSettingsGoogleMyBusinessAddPost": {
      "id": "restaurants.settings.google_my_business.add_post",
      "defaultMessage": "Add Post"
    },
    "restaurantsSettingsGoogleMyBusinessAddLocation": {
      "id": "restaurants.settings.google_my_business.add_location",
      "defaultMessage": "Add Location"
    },
    "restaurantsSettingsGoogleMyBusinessAlertType": {
      "id": "restaurants.settings.google_my_business.alert_type",
      "defaultMessage": "Alert Type"
    },
    "restaurantsSettingsGoogleMyBusinessAttributes": {
      "id": "restaurants.settings.google_my_business.attributes",
      "defaultMessage": "Attributes"
    },
    "restaurantsSettingsGoogleMyBusinessCouponCode": {
      "id": "restaurants.settings.google_my_business.coupon_code",
      "defaultMessage": "Coupon Code (Optional)"
    },
    "restaurantsSettingsGoogleMyBusinessCreatePost": {
      "id": "restaurants.settings.google_my_business.create_post",
      "defaultMessage": "Create Post"
    },
    "restaurantsSettingsGoogleMyBusinessDescription": {
      "id": "restaurants.settings.google_my_business.description",
      "defaultMessage": "Description"
    },
    "restaurantsSettingsGoogleMyBusinessEdit": {
      "id": "restaurants.settings.google_my_business.edit",
      "defaultMessage": "Edit"
    },
    "restaurantsSettingsGoogleMyBusinessEndDate": {
      "id": "restaurants.settings.google_my_business.end_date",
      "defaultMessage": "End Date"
    },
    "restaurantsSettingsGoogleMyBusinessEndTime": {
      "id": "restaurants.settings.google_my_business.end_time",
      "defaultMessage": "End Time"
    },
    "restaurantsSettingsGoogleMyBusinessEventDetails": {
      "id": "restaurants.settings.google_my_business.event_details",
      "defaultMessage": "Event Details"
    },
    "restaurantsSettingsGoogleMyBusinessEventTitle": {
      "id": "restaurants.settings.google_my_business.event_title",
      "defaultMessage": "Event Title"
    },
    "restaurantsSettingsGoogleMyBusinessLocation": {
      "id": "restaurants.settings.google_my_business.location",
      "defaultMessage": "Google Location"
    },
    "restaurantsSettingsGoogleMyBusinessLocalPosts": {
      "id": "restaurants.settings.google_my_business.local_posts",
      "defaultMessage": "Local Posts"
    },
    "restaurantsSettingsGoogleMyBusinessNoOptions": {
      "id": "restaurants.settings.google_my_business.no_options",
      "defaultMessage": "No verification options."
    },
    "restaurantsSettingsGoogleMyBusinessNoLocations": {
      "id": "restaurants.settings.google_my_business.no_locations",
      "defaultMessage": "No Google Locations."
    },
    "restaurantsSettingsGoogleMyBusinessOfferDetails": {
      "id": "restaurants.settings.google_my_business.offer_details",
      "defaultMessage": "Offer Details"
    },
    "restaurantsSettingsGoogleMyBusinessOfferLink": {
      "id": "restaurants.settings.google_my_business.offer_link",
      "defaultMessage": "Offer Redeeming Link (Optional)"
    },
    "restaurantsSettingsGoogleMyBusinessOfferTerms": {
      "id": "restaurants.settings.google_my_business.offer_terms",
      "defaultMessage": "Offer Terms and Conditions (Optional)"
    },
    "restaurantsSettingsGoogleMyBusinessOfferTitle": {
      "id": "restaurants.settings.google_my_business.offer_title",
      "defaultMessage": "Offer Title"
    },
    "restaurantsSettingsGoogleMyBusinessPendingVerifications": {
      "id": "restaurants.settings.google_my_business.pending_verifications",
      "defaultMessage": "Pending Verifications"
    },
    "restaurantsSettingsGoogleMyBusinessPendingVerificationsSelection": {
      "id": "restaurants.settings.google_my_business.pending_verifications_select",
      "defaultMessage": "Select Verification Method"
    },
    "restaurantsSettingsGoogleMyBusinessPostType": {
      "id": "restaurants.settings.google_my_business.post_type",
      "defaultMessage": "Post Type"
    },
    "restaurantsSettingsGoogleMyBusinessProcessed": {
      "id": "restaurants.settings.google_my_business.processed",
      "defaultMessage": "Processed"
    },
    "restaurantsSettingsGoogleMyBusinessProcessing": {
      "id": "restaurants.settings.google_my_business.processing",
      "defaultMessage": "Processing"
    },
    "restaurantsSettingsGoogleMyBusinessSelectLocation": {
      "id": "restaurants.settings.google_my_business.select_location",
      "defaultMessage": "Select Google location"
    },
    "restaurantsSettingsGoogleMyBusinessSchedule": {
      "id": "restaurants.settings.google_my_business.schedule",
      "defaultMessage": "Schedule"
    },
    "restaurantsSettingsGoogleMyBusinessScheduleDate": {
      "id": "restaurants.settings.google_my_business.schedule_date",
      "defaultMessage": "Schedule Date"
    },
    "restaurantsSettingsGoogleMyBusinessSendCode": {
      "id": "restaurants.settings.google_my_business.send_code",
      "defaultMessage": "Send Code"
    },
    "restaurantsSettingsGoogleMyBusinessStartDate": {
      "id": "restaurants.settings.google_my_business.start_date",
      "defaultMessage": "Start Date"
    },
    "restaurantsSettingsGoogleMyBusinessStartTime": {
      "id": "restaurants.settings.google_my_business.start_time",
      "defaultMessage": "Start Time"
    },
    "restaurantsSettingsGoogleMyBusinessUploadPhoto": {
      "id": "restaurants.settings.google_my_business.upload_photo",
      "defaultMessage": "Upload Photo"
    },
    "restaurantsSettingsGoogleMyBusinessVerification": {
      "id": "restaurants.settings.google_my_business.verification",
      "defaultMessage": "Verification"
    },
    "restaurantsSettingsGoogleMyBusinessVerificationMethods": {
      "id": "restaurants.settings.google_my_business.verification_methods",
      "defaultMessage": "Verification Methods"
    },
    "restaurantsSettingsGoogleMyBusinessTopicTypesAlertType": {
      "id": "restaurants.settings.google_my_business.topic_types.alert_type",
      "defaultMessage": "Alert"
    },
    "restaurantsSettingsGoogleMyBusinessTopicTypesEventType": {
      "id": "restaurants.settings.google_my_business.topic_types.event_type",
      "defaultMessage": "Event"
    },
    "restaurantsSettingsGoogleMyBusinessTopicTypesOfferType": {
      "id": "restaurants.settings.google_my_business.topic_types.offer_type",
      "defaultMessage": "Offer"
    },
    "restaurantsSettingsGoogleMyBusinessTopicTypesStandardType": {
      "id": "restaurants.settings.google_my_business.topic_types.standard_type",
      "defaultMessage": "What's New"
    },
    "restaurantsSettingsGoogleMyBusinessTopicTypesGoogleAlertType": {
      "id": "restaurants.settings.google_my_business.topic_types.google_alert_type",
      "defaultMessage": "ALERT"
    },
    "restaurantsSettingsGoogleMyBusinessTopicTypesGoogleEventType": {
      "id": "restaurants.settings.google_my_business.topic_types.google_event_type",
      "defaultMessage": "EVENT"
    },
    "restaurantsSettingsGoogleMyBusinessTopicTypesGoogleOfferType": {
      "id": "restaurants.settings.google_my_business.topic_types.google_offer_type",
      "defaultMessage": "OFFER"
    },
    "restaurantsSettingsGoogleMyBusinessTopicTypesGoogleStandardType": {
      "id": "restaurants.settings.google_my_business.topic_types.google_standard_type",
      "defaultMessage": "STANDARD"
    },
    "restaurantsSettingsGoogleMyBusinessVerificationOptionsAuto": {
      "id": "restaurants.settings.google_my_business.verification_options.auto",
      "defaultMessage": "Auto"
    },
    "restaurantsSettingsGoogleMyBusinessVerificationOptionsAddress": {
      "id": "restaurants.settings.google_my_business.verification_options.address",
      "defaultMessage": "Mail"
    },
    "restaurantsSettingsGoogleMyBusinessVerificationOptionsEmail": {
      "id": "restaurants.settings.google_my_business.verification_options.email",
      "defaultMessage": "Email"
    },
    "restaurantsSettingsGoogleMyBusinessVerificationOptionsPhoneCall": {
      "id": "restaurants.settings.google_my_business.verification_options.phone_call",
      "defaultMessage": "Call"
    },
    "restaurantsSettingsGoogleMyBusinessVerificationOptionsSms": {
      "id": "restaurants.settings.google_my_business.verification_options.sms",
      "defaultMessage": "Text"
    },
    "restaurantsSettingsGoogleMyBusinessAlertTypesCovid19Type": {
      "id": "restaurants.settings.google_my_business.alert_types.covid_19_type",
      "defaultMessage": "COVID-19"
    },
    "restaurantsSettingsGoogleMyBusinessAlertTypesGoogleCovid19Type": {
      "id": "restaurants.settings.google_my_business.alert_types.google_covid_19_type",
      "defaultMessage": "COVID_19"
    },
    "restaurantsSettingsGoogleMyBusinessPublishingCategories": {
      "id": "restaurants.settings.google_my_business.publishing.categories",
      "defaultMessage": "Categories"
    },
    "restaurantsSettingsGoogleMyBusinessPublishingWebsiteUri": {
      "id": "restaurants.settings.google_my_business.publishing.website_uri",
      "defaultMessage": "Website URI"
    },
    "restaurantsSettingsGoogleMyBusinessPublishingPhoneNumbers": {
      "id": "restaurants.settings.google_my_business.publishing.phone_numbers",
      "defaultMessage": "Phone Numbers"
    },
    "restaurantsSettingsGoogleMyBusinessPublishingStorefrontAddress": {
      "id": "restaurants.settings.google_my_business.publishing.storefront_address",
      "defaultMessage": "Storefront Address"
    },
    "restaurantsSettingsGoogleMyBusinessPublishingMoreHours": {
      "id": "restaurants.settings.google_my_business.publishing.more_hours",
      "defaultMessage": "More Hours"
    },
    "restaurantsSettingsGoogleMyBusinessPublishingTitle": {
      "id": "restaurants.settings.google_my_business.publishing.title",
      "defaultMessage": "Title"
    },
    "restaurantsSettingsGoogleMyBusinessPublishingLating": {
      "id": "restaurants.settings.google_my_business.publishing.lating",
      "defaultMessage": "Lating"
    },
    "restaurantsSettingsGoogleMyBusinessUrlsOrderAhead": {
      "id": "restaurants.settings.google_my_business.urls.url_order_ahead",
      "defaultMessage": "Order ahead link"
    },
    "restaurantsSettingsGoogleMyBusinessUrlsMenu": {
      "id": "restaurants.settings.google_my_business.urls.url_menu",
      "defaultMessage": "Menu link"
    },
    "restaurantsSettingsGoogleMyBusinessUrlsReservations": {
      "id": "restaurants.settings.google_my_business.urls.url_reservations",
      "defaultMessage": "Reservations link"
    },
    "restaurantsProfileSettingsHeading": {
      "id": "restaurants.profile_settings.heading",
      "defaultMessage": "Let's get to know you"
    },
    "restaurantsProfileSettingsSubheading": {
      "id": "restaurants.profile_settings.subheading",
      "defaultMessage": "These questions will help us get to know your business better and give us ideas about future products!"
    },
    "restaurantsProfileSettingsRestaurantGoals": {
      "id": "restaurants.profile_settings.restaurant_goals",
      "defaultMessage": "What does your business need help with? (Check all that apply)"
    },
    "restaurantsProfileSettingsRestaurantMarket": {
      "id": "restaurants.profile_settings.restaurant_market",
      "defaultMessage": "How do you market to your guests? (Check all that apply)"
    },
    "restaurantsProfileSettingsOnlineOrdersRevenue": {
      "id": "estaurants.profile_settings.online_orders_revenue",
      "defaultMessage": "How much do you make in online orders in a week?"
    },
    "restaurantsProfileSettingsOnlineOrdersRevenueGoal": {
      "id": "restaurants.profile_settings.online_orders_revenue_goal",
      "defaultMessage": "How much do you like to make in online orders in a week?"
    },
    "restaurantsProfileSettingsAcceptReservation": {
      "id": "restaurants.profile_settings.accept_reservation",
      "defaultMessage": "Do you accept reservations?"
    },
    "restaurantsProfileSettingsHostTicketedEvents": {
      "id": "restaurants.profile_settings.host_ticketed_events",
      "defaultMessage": "Do you host ticketed events?"
    },
    "restaurantsProfileSettingsPosProvider": {
      "id": "restaurants.profile_settings.pos_provider",
      "defaultMessage": "Who is your POS provider?"
    },
    "restaurantsProfileSettingsSelectAmount": {
      "id": "restaurants.profile_settings.select_amount",
      "defaultMessage": "Select Amount"
    },
    "restaurantsProfileSettingsChooseOne": {
      "id": "restaurants.profile_settings.choose_one",
      "defaultMessage": "Choose One"
    },
    "restaurantsProfileRevenueOptionsOpt1": {
      "id": "restaurants.profile_settings.revenue_options_opt_1",
      "defaultMessage": "I don't know"
    },
    "restaurantsProfileRevenueOptionsOpt2": {
      "id": "restaurants.profile_settings.revenue_options_opt_2",
      "defaultMessage": "$0 - $1k"
    },
    "restaurantsProfileRevenueOptionsOpt3": {
      "id": "restaurants.profile_settings.revenue_options_opt_3",
      "defaultMessage": "$1k - $3k"
    },
    "restaurantsProfileRevenueOptionsOpt4": {
      "id": "restaurants.profile_settings.revenue_options_opt_4",
      "defaultMessage": "$3k - $5k"
    },
    "restaurantsProfileRevenueOptionsOpt5": {
      "id": "restaurants.profile_settings.revenue_options_opt_5",
      "defaultMessage": "$5k - $10k"
    },
    "restaurantsProfileRevenueOptionsOpt6": {
      "id": "restaurants.profile_settings.revenue_options_opt_6",
      "defaultMessage": "$10k+"
    },
    "restaurantsProfileYesNoOptionsOpt1": {
      "id": "restaurants.profile_settings.yesno_options_opt_1",
      "defaultMessage": "Yes"
    },
    "restaurantsProfileYesNoOptionsOpt2": {
      "id": "restaurants.profile_settings.yesno_options_opt_2",
      "defaultMessage": "No"
    },
    "restaurantsProfileYesNoOptionsOpt3": {
      "id": "restaurants.profile_settings.yesno_options_opt_3",
      "defaultMessage": "I would like in the future"
    },
    "restaurantsProfileSettingsMarketOptionsOpt1": {
      "id": "restaurants.profile_settings.market_options_opt_1",
      "defaultMessage": "Email"
    },
    "restaurantsProfileSettingsMarketOptionsOpt2": {
      "id": "restaurants.profile_settings.market_options_opt_2",
      "defaultMessage": "Text Message"
    },
    "restaurantsProfileSettingsMarketOptionsOpt3": {
      "id": "restaurants.profile_settings.market_options_opt_3",
      "defaultMessage": "Social Media"
    },
    "restaurantsProfileSettingsMarketOptionsOpt4": {
      "id": "restaurants.profile_settings.market_options_opt_4",
      "defaultMessage": "Facebook Ads"
    },
    "restaurantsProfileSettingsMarketOptionsOpt5": {
      "id": "restaurants.profile_settings.market_options_opt_5",
      "defaultMessage": "Google Ads"
    },
    "restaurantsProfileSettingsMarketOptionsOpt6": {
      "id": "restaurants.profile_settings.market_options_opt_6",
      "defaultMessage": "Physical Mail"
    },
    "restaurantsProfileSettingsMarketOptionsOpt7": {
      "id": "restaurants.profile_settings.market_options_opt_7",
      "defaultMessage": "Other"
    },
    "restaurantsProfileSettings": {
      "id": "restaurants.profile_settings",
      "defaultMessage": "Restaurant Settings"
    },
    "restaurantsProfileSettingsSaved": {
      "id": "restaurants.profile_settings.saved",
      "defaultMessage": "Profile Saved"
    },
    "restaurantsThemesTabsCustom": {
      "id": "restaurants.themes.tabs.custom",
      "defaultMessage": "Custom"
    },
    "restaurantsThemesTabsDefault": {
      "id": "restaurants.themes.tabs.default",
      "defaultMessage": "Theme"
    },
    "restaurantsThemesTabsEmbeds": {
      "id": "restaurants.themes.tabs.embeds",
      "defaultMessage": "Embeds"
    },
    "restaurantsThemesTabsBasic": {
      "id": "restaurants.themes.tabs.basic",
      "defaultMessage": "Basic"
    },
    "restaurantsThemesTabsMenus": {
      "id": "restaurants.themes.tabs.menus",
      "defaultMessage": "Menus"
    },
    "restaurantsThemesTabsPages": {
      "id": "restaurants.themes.tabs.pages",
      "defaultMessage": "Pages"
    },
    "restaurantsIndex": {
      "id": "restaurants.index",
      "defaultMessage": "Manage Restaurants"
    },
    "restaurantsInformation": {
      "id": "restaurants.information",
      "defaultMessage": "Restaurant Information"
    },
    "restaurantsNewDeal": {
      "id": "restaurants.new_deal",
      "defaultMessage": "Create Restaurant with Deal"
    },
    "restaurantsEdit": {
      "id": "restaurants.edit",
      "defaultMessage": "General Settings"
    },
    "restaurantsUpdated": {
      "id": "restaurants.updated",
      "defaultMessage": "Restaurant successfully updated!"
    },
    "restaurantsUpdatedError": {
      "id": "restaurants.updated_error",
      "defaultMessage": "There was an error updating the restaurant."
    },
    "restaurantsProductsPro": {
      "id": "restaurants.products.pro",
      "defaultMessage": "A beautifully designed, mobile-responsive website with full-site hosting\n Real-time menu management tool with unlimited users & multi-location flexibility\n Interactive, engaging menus\n Item-specific reviews that you get to control\n Google Business Profile integration\n Facebook and Twitter integrations with scheduled posting functionality\n Unlimited mass email messages to guests\n Follower capturing with file uploading to keep everything in one place\n Automated smart email messages to your followers\n Quick and easy announcements\n Built-in event calendar management\n Print center for designing and printing in-house menus\n Full analytics and reporting suite\n Built-in offer and discounts system\n Secret menu features\n Popmenu for Owners iOS and Android App"
    },
    "restaurantsProductsBoost": {
      "id": "restaurants.products.boost",
      "defaultMessage": "Weekly mass emails hand-crafted and sent by our dedicated Boost team\n Content creation for 8 monthly social posts across Facebook, Instagram, and Twitter\n Monthly results recap on email and social content\n Bi-annual photography sessions (min. four months apart)\n Google Adwords & remarketing management (min. 100 visitors per month)\n Search Engine Optimization with ongoing maintenance and progress reporting\n Kick-off Package: in-house collateral, email, and social media content and copy"
    },
    "restaurantsMembersInvite": {
      "id": "restaurants.members.invite",
      "defaultMessage": "Invite Team Member"
    },
    "restaurantsMembersInviteEdit": {
      "id": "restaurants.members.invite_edit",
      "defaultMessage": "Edit Team Member Invite"
    },
    "restaurantsMembersEdit": {
      "id": "restaurants.members.edit",
      "defaultMessage": "Edit Member"
    },
    "restaurantsMembersIndex": {
      "id": "restaurants.members.index",
      "defaultMessage": "Team"
    }
  }
);
export default defaultMessages;
