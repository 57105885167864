
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "blogPostTitle": {
      "id": "blog_post.title",
      "defaultMessage": "Post Title"
    },
    "blogPostContent": {
      "id": "blog_post.content",
      "defaultMessage": "Post Content"
    },
    "blogPostIsDraft": {
      "id": "blog_post.is_draft",
      "defaultMessage": "Is this post a draft?"
    },
    "blogPostPostedAt": {
      "id": "blog_post.posted_at",
      "defaultMessage": "Publish Date"
    },
    "blogPostHeroImage": {
      "id": "blog_post.hero_image",
      "defaultMessage": "Post Image"
    },
    "blogPostHeroImageDescription": {
      "id": "blog_post.hero_image.title",
      "defaultMessage": "Hero Image Title"
    },
    "blogPostPhotoAltText": {
      "id": "blog_post.alt_photo_text",
      "defaultMessage": "Photo Alt Text"
    },
    "blogPostPhotoAltTextHelper": {
      "id": "blog_post.alt_photo_text.helper_text",
      "defaultMessage": "This text will help with SEO, but won't appear on your post"
    },
    "blogPostPublishToSite": {
      "id": "blog_post.publish_to_site",
      "defaultMessage": "Publish Blog to Site"
    },
    "blogPostScheduledAt": {
      "id": "blog_post.scheduled_at",
      "defaultMessage": "Schedule Post"
    }
  }
);
export default defaultMessages;

