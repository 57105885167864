import React from 'react';
import PropTypes from 'prop-types';

import { compose, mapProps } from '@shakacode/recompose';
import { closestElement } from '../../utils/dom';
import { withWindowSizeContext } from '../../shared/WindowSizeProvider';

class MessagePreview extends React.PureComponent {
  constructor(props) {
    super(props);
    this.frameContainerRef = React.createRef();
  }

  componentDidMount() {
    this.initFrame();
  }

  componentDidUpdate() {
    this.initFrame();
  }

  initFrame() {
    if (!this.frameContainerRef.current) {
      return;
    }

    // Replace container contents with new iframe
    while (this.frameContainerRef.current.firstChild) {
      this.frameContainerRef.current.removeChild(this.frameContainerRef.current.firstChild);
    }
    const iframe = document.createElement('iframe');
    iframe.style.width = '100%';
    iframe.style.display = 'block';
    if (!this.props.autoContentHeight) {
      iframe.style.height = `${this.props.windowHeight * this.props.windowHeightPercent}px`;
      iframe.height = `${this.props.windowHeight * this.props.windowHeightPercent}`;
    }

    if (this.props.hideBorders) {
      iframe.style.border = '0';
    }

    iframe.addEventListener('load', () => {
      if (this.props.autoContentHeight) {
        const contentHeight = iframe.contentWindow.document.body.scrollHeight;
        iframe.style.height = `${contentHeight}px`;
        iframe.height = `${contentHeight}`;
      }

      iframe.contentWindow.document.body.addEventListener('click', (e) => {
        const link = closestElement(e.target, 'a');
        if (link && link.href) {
          e.preventDefault();
          e.stopPropagation();
          window.open(link.href, '_blank');
          window.focus();
          return false;
        }
        return true;
      });
    });

    if (this.props.messageHtmlUrl) {
      iframe.src = this.props.messageHtmlUrl;
    } else {
      this.frameContainerRef.current.appendChild(iframe);
      iframe.contentWindow.document.open();
      iframe.contentWindow.document.write(this.props.messageContentHtml);
      iframe.contentWindow.document.close();
    }

    if (this.props.messageHtmlUrl) {
      this.frameContainerRef.current.appendChild(iframe);
    }
  }

  render() {
    return (
      <div ref={this.frameContainerRef} style={{ width: '100%' }} />
    );
  }
}

MessagePreview.defaultProps = {
  autoContentHeight: false,
  hideBorders: false,
  messageContentHtml: null,
  messageHtmlUrl: null,
  windowHeightPercent: 0.5,
};

MessagePreview.propTypes = {
  autoContentHeight: PropTypes.bool,
  hideBorders: PropTypes.bool,
  messageContentHtml: PropTypes.string,
  messageHtmlUrl: PropTypes.string,
  windowHeight: PropTypes.number.isRequired,
  windowHeightPercent: PropTypes.number,
};

export default compose(
  withWindowSizeContext,
  mapProps(({ windowSize, ...props }) => ({
    ...props,
    windowHeight: windowSize.innerHeight,
  })),
)(MessagePreview);
