import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "answeringCallsTitle": {
      "id": "answering.calls.title",
      "defaultMessage": "AI Answering"
    },
    "answeringCallLockedDescription": {
      "id": "answering.call.locked_description",
      "defaultMessage": "Reduce stress on your team and reclaim the power of your phone. With AI Answering powered by artificial intelligence, the simple questions that keep your phone line tied up can now be handled without interrupting your in-person hospitality."
    },
    "answeringCallLockedDescriptionAlt": {
      "id": "answering.call.locked_description_alt",
      "defaultMessage": "Popmenu AI Answering works 24/7, 365 days a year for the introductory price of $349/month, per location."
    },
    "answeringCallLockedTitle": {
      "id": "answering.call.locked_description_alt",
      "defaultMessage": "Turn every phone call into an opportunity with Popmenu AI Answering"
    },
    "answeringCallsLog": {
      "id": "answering.calls.log",
      "defaultMessage": "Call Log"
    },
    "answeringCallsFilterAll": {
      "id": "answering.calls.filter.all",
      "defaultMessage": "All Calls"
    },
    "answeringCallsFilterVoicemail": {
      "id": "answering.calls.filter.voicemail",
      "defaultMessage": "Voicemails Only"
    },
    "answeringSettingsGenericError": {
      "id": "answering.generic_error",
      "defaultMessage": "Something went wrong with your request."
    },
    "answeringCallsTableNA": {
      "id": "answering.calls.n_a",
      "defaultMessage": "N/A"
    },
    "answeringCallsDetailOrderTitle": {
      "id": "answering.calls.detail.order.title",
      "defaultMessage": "Order"
    },
    "answeringCallsDetailOrderDescription": {
      "id": "answering.calls.detail.order.description",
      "defaultMessage": "This call generated Order #"
    },
    "answeringCallsDetailOrderAmount": {
      "id": "answering.calls.detail.order.amount",
      "defaultMessage": "in the amount of"
    },
    "answeringCallsDestroy": {
      "id": "answering.calls.destroy",
      "defaultMessage": "Remove Call"
    },
    "answeringCallsDestroyConfirmTitle": {
      "id": "answering.calls.destroy_confirm_title",
      "defaultMessage": "Confirm Delete"
    },
    "answeringCallsDestroyConfirm": {
      "id": "answering.calls.destroy_confirm",
      "defaultMessage": "Are you sure you want to delete this call record?"
    },
    "answeringCallsNoTranscript": {
      "id": "answering.calls.no_transcript",
      "defaultMessage": "Transcript Unavailable"
    },
    "answeringCallsCallBack": {
      "id": "answering.calls.call_back",
      "defaultMessage": "Call Back"
    },
    "answeringCallsTableFrom": {
      "id": "answering.calls.caller",
      "defaultMessage": "Caller"
    },
    "answeringCallsTableCreatedAt": {
      "id": "answering.calls.created_at",
      "defaultMessage": "Date & Time"
    },
    "answeringCallsTableTopic": {
      "id": "answering.calls.topic",
      "defaultMessage": "Topic"
    },
    "answeringCallsVoicemail": {
      "id": "answering.calls.voicemail",
      "defaultMessage": "Voicemail"
    },
    "answeringCallsTableHeading": {
      "id": "answering.calls.no_calls",
      "defaultMessage": "There are no calls."
    },
    "answeringCallsNoVoicemails": {
      "id": "answering.calls.no_voicemails",
      "defaultMessage": "There are no voicemails."
    },
    "answeringSecondaryPhone": {
      "id": "answering.settings.secondary_phone",
      "defaultMessage": "Secondary Phone Number"
    },
    "answeringSettingsTitle": {
      "id": "answering.settings.title",
      "defaultMessage": "AI Answering Settings"
    },
    "answeringSettingsUpdated": {
      "id": "answering.settings.updated",
      "defaultMessage": "Updated settings for"
    },
    "answeringSettingsDuplicatePhoneNumberError": {
      "id": "answering.settings.error.duplicate_phone_number",
      "defaultMessage": "Sorry, you can't have duplicate Fallback Phone Numbers"
    },
    "answeringSettingsDuplicateFallbackTypeError": {
      "id": "answering.settings.error.duplicate_fallback_type",
      "defaultMessage": "Sorry, you can't have duplicate Fallback Types"
    },
    "answeringSettingsAnsweringOn": {
      "id": "answering.settings.on",
      "defaultMessage": "ON"
    },
    "answeringSettingsAnsweringOnMessage": {
      "id": "answering.settings.on_message",
      "defaultMessage": "Popmenu AI Answering is on and taking calls."
    },
    "answeringSettingsVoicemailOnMessage": {
      "id": "answering.settings.voicemail.on_message",
      "defaultMessage": "We'll send customers to voicemail if your team can't answer"
    },
    "answeringSettingsAnsweringOff": {
      "id": "answering.settings.off",
      "defaultMessage": "OFF"
    },
    "answeringSettingsAnsweringOffMessage": {
      "id": "answering.settings.off_message",
      "defaultMessage": "Popmenu AI Answering is not taking calls."
    },
    "answeringSettingsVoicemailOffMessage": {
      "id": "answering.settings.voicemail.off_message",
      "defaultMessage": "Voicemail is disabled."
    },
    "answeringSettingsAnsweringTitle": {
      "id": "answering.settings.title",
      "defaultMessage": "Answer calls"
    },
    "answeringSettingsAnsweringOffHoursEnabled": {
      "id": "answering.settings.off_hours_enabled",
      "defaultMessage": "24 Hours a day"
    },
    "answeringSettingsAnsweringOffHoursDisabled": {
      "id": "answering.settings.off_hours_disabled",
      "defaultMessage": "Only during non-business hours"
    },
    "answeringSettingsSpamBlocker": {
      "id": "answering.settings.spam_blocker",
      "defaultMessage": "Spam Blocker"
    },
    "answeringSettingsAnsweringSpamBlockerLabel": {
      "id": "answering.settings.spam_blocker_label",
      "defaultMessage": "Block calls that are flagged as 'Spam' by phone carriers?"
    },
    "answeringSettingsAnsweringTurnOffButton": {
      "id": "answering.settings.turn.off.button",
      "defaultMessage": "Turn AI Answering Off"
    },
    "answeringSettingsAnsweringTurnOnButton": {
      "id": "answering.settings.turn.on.button",
      "defaultMessage": "Turn AI Answering On"
    },
    "answeringSettingsAnsweringTurnOff": {
      "id": "answering.settings.turn.off",
      "defaultMessage": "Turn Popmenu AI Answering Off"
    },
    "answeringSettingsAnsweringTurnOn": {
      "id": "answering.settings.turn.on",
      "defaultMessage": "Turn Popmenu AI Answering On"
    },
    "answeringSettingsAnsweringOffChange": {
      "id": "answering.settings.off.change",
      "defaultMessage": "Popmenu AI Answering will be turned off and will no longer answer incoming calls"
    },
    "answeringSettingsAnsweringOnChange": {
      "id": "answering.settings.on.change",
      "defaultMessage": "Popmenu AI Answering will be turned on and will answer all incoming calls"
    },
    "answeringSettingsAnsweringPublishPhoneNumberPublished": {
      "id": "answering.settings.publish_phone_number.published",
      "defaultMessage": "Your Popmenu AI Answering Number has been published."
    },
    "answeringSettingsAnsweringPublishPhoneNumberUnpublished": {
      "id": "answering.settings.publish_phone_number.unpublished",
      "defaultMessage": "Your Popmenu AI Answering Number has been unpublished."
    },
    "answeringSettingsPublishPhoneTitle": {
      "id": "answering.settings.publish_phone.title",
      "defaultMessage": "Number Publishing"
    },
    "answeringSettingsDisplayNumber": {
      "id": "answering.settings.display_answering_number",
      "defaultMessage": "Display Popmenu AI Answering Number"
    },
    "answeringSettingsAnsweringDisplayPhoneNumberLabel": {
      "id": "answering.settings.display_phone_number_label",
      "defaultMessage": "Your Popmenu AI Answering number {phone} is displayed on your website, Google Business Profile and outbound Popmenu generated marketing materials."
    },
    "answeringSettingsFallbackNumber": {
      "id": "answering.settings.fallback_number",
      "defaultMessage": "Fallback Number"
    },
    "answeringSettingsFallbackPhoneTitle": {
      "id": "answering.settings.fallback_phone.title",
      "defaultMessage": "If a customer requests to speak to someone , their call will be forwarded to this number. "
    },
    "answeringSettingsFallbackPhone": {
      "id": "answering.settings.fallback_phone",
      "defaultMessage": "Fallback Phone Number"
    },
    "answeringSettingsVoicemailFallback": {
      "id": "answering.settings.voicemail.fallback.title",
      "defaultMessage": "Voicemail Fallback"
    },
    "answeringSettingsVoicemailFallbackSubtitle": {
      "id": "answering.settings.voicemail.fallback.subtitle",
      "defaultMessage": "If we try to connect to your fallback number and the line is busy or your team can't answer, then we send these callers to voicemail."
    },
    "answeringSettingsPublishPhoneNumberUnpublishButton": {
      "id": "answering.settings.publish_phone_number.unpublish_button",
      "defaultMessage": "Unpublish AI Answering Number"
    },
    "answeringSettingsPublishPhoneNumberPublishButton": {
      "id": "answering.settings.publish_phone_number.publish_button",
      "defaultMessage": "Publish AI Answering Number"
    },
    "answeringSettingsPublishPhoneNumberTurnOffTitle": {
      "id": "answering.settings.publish_phone_number.turn_off.title",
      "defaultMessage": "Unpublish Your AI Answering Number"
    },
    "answeringSettingsPublishPhoneNumberTurnOnTitle": {
      "id": "answering.settings.publish_phone_number.turn_on.title",
      "defaultMessage": "Publish Your AI Answering Number"
    },
    "answeringSettingsPublishPhoneNumberTurnOffLine1": {
      "id": "answering.settings.publish_phone_number.turn_off.line_1",
      "defaultMessage": "Your Popmenu AI Answering Number {answeringNumber} will be replaced with your original business number on:"
    },
    "answeringSettingsPublishPhoneNumberChangesOn1": {
      "id": "answering.settings.publish_phone_number.changes_on_1",
      "defaultMessage": "Your Popmenu hosted website"
    },
    "answeringSettingsPublishPhoneNumberChangesOn2": {
      "id": "answering.settings.publish_phone_number.changes_on_2",
      "defaultMessage": "Google Business Profile"
    },
    "answeringSettingsPublishPhoneNumberChangesOn3": {
      "id": "answering.settings.publish_phone_number.changes_on_3",
      "defaultMessage": "Popmenu generated emails and SMS."
    },
    "answeringSettingsPublishPhoneNumberTurnOffLine2": {
      "id": "answering.settings.publish_phone_number.turn_off.line_2",
      "defaultMessage": "Google Business Profile usually updates within 3 days. Calls made to the unpublished AI Answering number will still be answered by Popmenu AI Answering."
    },
    "answeringSettingsPublishPhoneNumberTurnOnLine1": {
      "id": "answering.settings.publish_phone_number.turn_on.line_1",
      "defaultMessage": "Your original business number {businessNumber} will be replaced with your Popmenu AI Answering Number {answeringNumber} on the following:"
    },
    "answeringSettingsPublishPhoneVoicemailFallbackEnabled": {
      "id": "answering.settings.publish_phone.voicemail_fallback.enabled",
      "defaultMessage": "Yes"
    },
    "answeringSettingsPublishPhoneVoicemailFallbackDisabled": {
      "id": "answering.settings.publish_phone.voicemail_fallback.disabled",
      "defaultMessage": "No"
    },
    "answeringeSettingsPublishPhoneNumberTurnOnLine2": {
      "id": "answering.settings.publish_phone_number.turn_on.line_2",
      "defaultMessage": "Google Business Profile usually updates within 3 days."
    },
    "answeringSettingsVoicemailNotificationsUpdated" : {
      "id": "answering.settings.voicemail.notifications.updated",
      "defaultMessage": "Voicemail notifications settings have been updated."
    },
    "answeringSettingsVoicemailNotifications" : {
      "id": "answering.settings.voicemail.notifications",
      "defaultMessage": "Voicemail Notifications"
    },
    "answeringSettingsVoicemailNotificationsDescription" : {
      "id": "answering.settings.voicemail.notifications.description",
      "defaultMessage": "Notify me when a customer leaves a voicemail by"
    },
    "answeringSettingVoicemailNotificationsSmsNotification" : {
      "id": "answering.settings.voicemail.notifications.sms_notification",
      "defaultMessage": "SMS"
    },
    "answeringSettingsVoicemailNotificationsNotificationEnabled" : {
      "id": "answering.settings.voicemail.notifications.notification_enabled",
      "defaultMessage": "On"
    },
    "answeringSettingsVoicemailNotificationsNotificationDisabled" : {
      "id": "answering.settings.voicemail.notifications.notification_disabled",
      "defaultMessage": "Off"
    },
    "answeringSettingsVoicemailNotificationsEmailNotification" : {
      "id": "answering.settings.voicemail.notifications.email_notification",
      "defaultMessage": "Email"
    },
    "answeringSettingsVoicemailNotificationsPushNotification": {
      "id": "answering.settings.voicemail.notifications.push_notification",
      "defaultMessage": "Push Notification"
    },
    "answeringSettingsVoicemailEnabledPlaceholder": {
      "id": "answering.settings.voicemail.enabled.placeholder",
      "defaultMessage": "Leave a message after the beep! You can press the star key or hang up when finished."
    },
    "answeringSettingsVoicemailDisabledPlaceholder": {
      "id": "answering.settings.voicemail.disabled.placeholder",
      "defaultMessage": "Sorry, voicemail is not available."
    },
    "answeringSettingsSpeechSynthesizeError": {
      "id": "answering.settings.speech_synthesize_error",
      "defaultMessage": "There was an error synthesizing the custom message"
    },
    "answeringSettingsVoiceSettingsTitle": {
      "id": "answering.settings.voice_settings.title",
      "defaultMessage": "Voice Settings"
    },
    "answeringSettingsVoiceSettingsChooseVoice": {
      "id": "answering.settings.voice_settings.choose_voice",
      "defaultMessage": "Choose a Voice"
    },
    "answeringSettingsVoiceSettingsVoiceSpeed": {
      "id": "answering.settings.voice_settings.voice_speed",
      "defaultMessage": "Voice Speed"
    },
    "answeringSettingsVoiceSpeedSlow": {
      "id": "answering.settings.voice_speed.slow",
      "defaultMessage": "Slow"
    },
    "answeringSettingsVoiceSpeedMedium": {
      "id": "answering.settings.voice_speed.medium",
      "defaultMessage": "Normal"
    },
    "answeringSettingsVoiceSpeedFast": {
      "id": "answering.settings.voice_speed.fast",
      "defaultMessage": "Fast"
    },
    "answeringSettingsVoiceSettingsSamplePhrase": {
      "id": "answering.settings.voice_settings.sample_phrase",
      "defaultMessage": "Sample Phrases"
    },
    "answeringSettingsCustomGreetingMessage": {
      "id": "answering.settings.custom_greeting_message",
      "defaultMessage": "Hello, welcome to {restaurant_name}!{notice_phrase} How may I help?"
    },
    "answeringSettingsAfterHoursRouting": {
      "id": "answering.settings.after_hours_routing",
      "defaultMessage": "After Hours Call Routing"
    },
    "answeringSettingsAfterHoursRoutingSubtitle": {
      "id": "answering.settings.after_hours_routing.subtitle",
      "defaultMessage": "If a customer calls after hours and asks for a person, send them to voicemail instead of forwarding to the fallback number. This prevents customers from being caught in a loop."
    },
    "answeringSettingsAfterHoursCallBlocking": {
      "id": "answering.settings.after_hours_call_blocking",
      "defaultMessage": "After Hours Call Blocking"
    },
    "answeringSettingsAnsweringAfterHoursCallBlockingSubtitle": {
      "id": "answering.settings.after_hours_call_blocking.subtitle",
      "defaultMessage": "If a customer calls after hours and asks for a person, do not forward the call. Instead, notify the customer that the location is closed."
    },
    "answeringSettingsCustomClosingMessage": {
      "id": "answering.settings.custom_closing_message",
      "defaultMessage": "Thanks for calling. Goodbye."
    },
    "answeringSettingsUpdatedCustomClosingMessage": {
      "id": "answering.settings.updated_custom_closing_message",
      "defaultMessage": "Good bye and thanks for calling!"
    },
    "answeringSettingsCustomResponseCategoryTitle": {
      "id": "answering.settings.custom_responses_category.title",
      "defaultMessage": "Custom Responses by Category"
    },
    "answeringSettingsCustomResponsesCategoryLine": {
      "id": "answering.settings.custom_responses_category.line",
      "defaultMessage": "Tailor responses per topic so the most accurate response is spoken to callers. Customize to include links, take messages or forward calls to a secondary number."
    },
    "answeringSettingsCustomResponsesCopy": {
      "id": "answering.settings.custom_responses.copy",
      "defaultMessage": "Copy Responses"
    },
    "answeringSettingsCustomResponsesCategoryNewBadge": {
      "id": "answering.settings.custom_responses_category.new_badge",
      "defaultMessage": "✨ New"
    },
    "answeringSettingsCustomResponsesCopyCategorySuccess": {
      "id": "answering.settings.custom_responses.copy_category.success",
      "defaultMessage": "Custom Responses for {category} copied"
    },
    "answeringSettingsCustomResponsesCopyCategoryError": {
      "id": "answering.settings.custom_responses.copy_category.error",
      "defaultMessage": "Something went wrong while copying the responses"
    },
    "answeringSettingsCustomResponsesCopyCategoryModalTitle": {
      "id": "answering.settings.custom_responses.copy_category_modal.title",
      "defaultMessage": "Copy all {category} responses from another location"
    },
    "answeringSettingsCustomResponsesCopyModalConfirmTitle": {
      "id": "answering.settings.custom_responses.copy_modal.confirm.title",
      "defaultMessage": "Copy the Custom Responses?"
    },
    "answeringSettingsCustomResponsesCopyModalConfirmSubtitle": {
      "id": "answering.settings.custom_responses.copy_modal.confirm.subtitle",
      "defaultMessage": "This change is permanent."
    },
    "answeringSettingsCustomResponsesCopyAllSuccess": {
      "id": "answering.settings.custom_responses.copy_all.success",
      "defaultMessage": "Custom Responses copied!"
    },
    "answeringSettingsCustomResponsesCopyAllError": {
      "id": "answering.settings.custom_responses.copy_all.error",
      "defaultMessage": "Something went wrong while copying the responses"
    },
    "answeringSettingsCustomResponsesCopyModalTitle": {
      "id": "answering.settings.custom_responses.copy_modal.title",
      "defaultMessage": "Copy all categories from another location"
    },
    "answeringSettingsIntentCategoriesBackToSettings": {
      "id": "answering.settings.intent.categories.back_to_settings",
      "defaultMessage": "Back to All Topics"
    },
    "answeringSettingsTopics": {
      "id": "answering.settings.topics",
      "defaultMessage": "Topics"
    },
    "answeringSettingsCustomResponsesAction": {
      "id": "answering.settings.custom_responses.action",
      "defaultMessage": "Action:"
    },
    "answeringSettingsCustomResponsesActionsNone": {
      "id": "answering.settings.custom_responses.actions.none",
      "defaultMessage": "None"
    },
    "answeringSettingsCustomResponsesActionsCustomLink": {
      "id": "answering.settings.custom_responses.actions.custom_link",
      "defaultMessage": "Send caller a text message"
    },
    "answeringSettingsCustomResponsesActionsVoicemail": {
      "id": "answering.settings.custom_responses.actions.voicemail",
      "defaultMessage": "Send call to voicemail"
    },
    "answeringSettingsCustomResponsesActionsFallbackPhone": {
      "id": "answering.settings.custom_responses.actions.fallback_phone",
      "defaultMessage": "Forward call to fallback number {fallbackPhone}"
    },
    "answeringSettingsCustomResponsesVerifyUrl": {
      "id": "answering.settings.custom_responses.verify_url",
      "defaultMessage": "Verify URL"
    },
    "answeringSettingsCustomResponsesCustomResponseUpdated": {
      "id": "answering.settings.custom_responses.custom_response.updated",
      "defaultMessage": "Updated custom response successfully"
    },
    "answeringSettingsCustomResponsesFirstLine": {
      "id": "answering.settings.custom_responses.first_line",
      "defaultMessage": "When customers call with questions about"
    },
    "answeringSettingsCustomResponsesSecondLine": {
      "id": "answering.settings.custom_responses.second_line",
      "defaultMessage": "Questions like"
    },
    "answeringSettingsCustomResponsesQuestion": {
      "id": "answering.settings.custom_responses.question",
      "defaultMessage": "{question}?"
    },
    "answeringSettingsCustomResponsesThirdLine": {
      "id": "answering.settings.custom_responses.third_line",
      "defaultMessage": "Then Popmenu AI Answering responds with"
    },
    "answeringSettingsCustomResponsesCustomResponse": {
      "id": "answering.settings.custom_responses.custom_response",
      "defaultMessage": "Custom Response"
    },
    "answeringSettingsCustomResponsesCustomResponseActionsNone": {
      "id": "answering.settings.custom_responses.actions.none",
      "defaultMessage": "None"
    },
    "answeringSettingsCustomResponsesCustomResponseActionsFallbackNumber": {
      "id": "answering.settings.custom_responses.actions.fallback_number",
      "defaultMessage": "Forward call to fallback number "
    },
    "answeringSettingsCustomResponsesActionsUpdatedFallbackPhone": {
      "id": "answering.settings.custom_responses.actions.updated_fallback_phone",
      "defaultMessage": "Forward call to {fallbackType} {fallbackPhone}"
    },
    "answeringSettingsCustomResponsesCustomResponseActionsCustomLink": {
      "id": "answering.settings.custom_responses.actions.custom_link",
      "defaultMessage": "Send caller a text message"
    },
    "answeringSettingsCustomResponsesCustomResponseActionsVoicemail": {
      "id": "answering.settings.custom_responses.actions.voicemail",
      "defaultMessage": "Send call to voicemail"
    },
    "answeringSettingsCustomResponsesCustomResponseActions": {
      "id": "answering.settings.custom_responses.actions",
      "defaultMessage": "Action"
    },
    "answeringSettingsCustomResponsesCustomResponseFallbackNumerLabel": {
      "id": "answering.settings.custom_responses.fallback_number.label",
      "defaultMessage": "Fallback Number"
    },
    "answeringSettingsCustomResponsesUrl": {
      "id": "answering.settings.custom_responses.url",
      "defaultMessage": "Add Custom URL"
    },
    "answeringSettingsListen": {
      "id": "answering.settings.listen",
      "defaultMessage": "Preview Response"
    },
    "answeringSettingsCustomResponsesUpdate": {
      "id": "answering.settings.custom_responses.update",
      "defaultMessage": "Update Response"
    },
    "answeringSettingsEnableFallbackDialogLabel": {
      "id": "answering.enable_fallback.dialog.label_text",
      "defaultMessage": "Enable Fallback Number"
    },
    "answeringSettingsDisableFallbackDialogLabel": {
      "id": "answering.disable_fallback.dialog.label_text",
      "defaultMessage": "Disable Fallback Number"
    },
    "answeringSettingsEnableFallbackDialogBody": {
      "id": "answering.settings.enable_fallback.dialog.body",
      "defaultMessage": "Callers will now be able to connect to a team member via your primary fallback number."
    },
    "answeringSettingsDisableFallbackDialogBody": {
      "id": "answering.settings.disable_fallback.dialog.body",
      "defaultMessage": "Callers will no longer be able to connect to a team member. Any topics with a custom action set to forward to the fallback will be cleared out and reset to the default."
    },
    "answeringSettingsToggleFallbackEnableButton": {
      "id": "answering.settings.toggle_fallback.enable_button",
      "defaultMessage": "Enable Fallback Number"
    },
    "answeringSettingsToggleFallbackDisableButton": {
      "id": "answering.settings.toggle_fallback.disable_button",
      "defaultMessage": "Disable Fallback Number"
    },
    "answeringSettingsDeleteNumberDialogLabelText": {
      "id": "answering.delete_number.dialog.label_text",
      "defaultMessage": "Delete Fallback Number"
    },
    "answeringSettingsDeleteNumberDialogBody": {
      "id": "answering.settings.delete_number.dialog.body",
      "defaultMessage": "At least one topic is currently customized to forward callers to this fallback number. When this change is saved, these topics will revert to the default."
    },
    "answeringSettingsDeleteNumberDialogDisableButton": {
      "id": "answering.settings.delete_number.disable_button",
      "defaultMessage": "Ok"
    },
    "answeringSettingsVoicemailOffButton": {
      "id": "answering.settings.voicemail.off.button",
      "defaultMessage": "Turn Off Voicemail"
    },
    "answeringSettingsVoicemailOnButton": {
      "id": "answering.settings.voicemail.on.button",
      "defaultMessage": "Turn On Voicemail"
    },
    "answeringSettingsVoicemailOff": {
      "id": "answering.settings.voicemail.off",
      "defaultMessage": "Turn Off Voicemail"
    },
    "answeringSettingsVoicemailOn": {
      "id": "answering.settings.voicemail.on",
      "defaultMessage": "Turn On Voicemail"
    },
    "answeringSettingsVoicemailOffBody": {
      "id": "answering.settings.voicemail.off.body",
      "defaultMessage": "AI Answering voicemail will be turned off. Any topics with a custom action to send to voicemail will be reset to the default configuration."
    },
    "answeringSettingsVoicemailOnBody": {
      "id": "answering.settings.voicemail.on.body",
      "defaultMessage": "AI Answering voicemail will now be turned on. Callers will be able to leave voicemail messages."
    },
  }
);
export default defaultMessages;
