
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "reviewsReviewReply": {
      "id": "reviews.review_reply",
      "defaultMessage": "Reply to review"
    },
    "reviewsReview": {
      "id": "reviews.review",
      "defaultMessage": "Review"
    },
    "reviewsReviews": {
      "id": "reviews.reviews",
      "defaultMessage": "Reviews"
    },
    "reviewsDestroyConfirm": {
      "id": "reviews.destroy_confirm",
      "defaultMessage": "Are you sure you want to remove this review?"
    },
    "reviewsReply": {
      "id": "reviews.reply",
      "defaultMessage": "Reply to review"
    },
    "reviewsMore": {
      "id": "reviews.more",
      "defaultMessage": "Read More Reviews"
    },
    "reviewsEmpty": {
      "id": "reviews.empty",
      "defaultMessage": "No reviews!"
    },
    "reviewsPending": {
      "id": "reviews.pending",
      "defaultMessage": "Pending"
    },
    "reviewsNew": {
      "id": "reviews.new",
      "defaultMessage": "Write Your Review"
    },
    "reviewsEdit": {
      "id": "reviews.edit",
      "defaultMessage": "Edit Your Review"
    },
    "reviewsSubmittedBy": {
      "id": "reviews.submitted_by",
      "defaultMessage": "Submitted by {name} on {date}"
    },
    "reviewsSubmitted": {
      "id": "reviews.submitted",
      "defaultMessage": "Review submitted for approval!"
    },
    "reviewsSubmittedError": {
      "id": "reviews.submitted_error",
      "defaultMessage": "There was an error submitting the review."
    },
    "reviewsResubmitted": {
      "id": "reviews.resubmitted",
      "defaultMessage": "Review resubmitted for approval!"
    },
    "reviewsResubmittedError": {
      "id": "reviews.resubmitted_error",
      "defaultMessage": "There was an error updating the review."
    },
    "reviewsUpdated": {
      "id": "reviews.updated",
      "defaultMessage": "Review successfully updated!"
    },
    "reviewsUpdatedError": {
      "id": "reviews.updated_error",
      "defaultMessage": "There was an error updating the review."
    },
    "reviewsDestroyed": {
      "id": "reviews.destroyed",
      "defaultMessage": "Review successfully removed."
    },
    "reviewsDestroyedError": {
      "id": "reviews.destroyed_error",
      "defaultMessage": "There was an error removing the review."
    }
  }
);
export default defaultMessages;

