
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "customTaxRatesDestroyConfirm": {
      "id": "custom_tax_rates.destroy_confirm",
      "defaultMessage": "Are you sure you want to delete this tax rate?"
    },
    "customTaxRatesDestroyConfirmTitle": {
      "id": "custom_tax_rates.destroy_confirm_title",
      "defaultMessage": "Confirm Delete"
    },
    "customTaxRatesDestroy": {
      "id": "custom_tax_rates.destroy",
      "defaultMessage": "Delete Tax Rate"
    },
    "customTaxRatesDestroyed": {
      "id": "custom_tax_rates.destroyed",
      "defaultMessage": "Tax rate successfully deleted."
    },
    "customTaxRatesEdit": {
      "id": "custom_tax_rates.edit",
      "defaultMessage": "Edit Tax Rate"
    },
    "customTaxRatesIndex": {
      "id": "custom_tax_rates.index",
      "defaultMessage": "Tax Rates"
    },
    "customTaxRatesNew": {
      "id": "custom_tax_rates.new",
      "defaultMessage": "Add Tax Rate"
    }
  }
);
export default defaultMessages;

