
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "rootAnnouncementsDestroyConfirm": {
      "id": "root_announcements.destroy_confirm",
      "defaultMessage": "Are you sure you want to delete this root announcement?"
    },
    "rootAnnouncementsDestroyConfirmTitle": {
      "id": "root_announcements.destroy_confirm_title",
      "defaultMessage": "Confirm Delete"
    },
    "rootAnnouncementsIndex": {
      "id": "root_announcements.index",
      "defaultMessage": "Root Announcements"
    },
    "rootAnnouncementsNew": {
      "id": "root_announcements.new",
      "defaultMessage": "Add Root Announcement"
    },
    "rootAnnouncementsEdit": {
      "id": "root_announcements.edit",
      "defaultMessage": "Edit Root Announcement"
    },
    "rootAnnouncementsDestroy": {
      "id": "root_announcements.destroy",
      "defaultMessage": "Delete Root Announcement"
    }
  }
);
export default defaultMessages;

