
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "webBuilderBack": {
      "id": "web_builder.back",
      "defaultMessage": "Back"
    },
    "webBuilderConfirm": {
      "id": "web_builder.confirm",
      "defaultMessage": "Confirm"
    },
    "webBuilderCancel": {
      "id": "web_builder.cancel",
      "defaultMessage": "Cancel"
    },
    "webBuilderHidden": {
      "id": "web_builder.hidden",
      "defaultMessage": "Hidden"
    },
    "webBuilderHiddenDisclaimer": {
      "id": "web_builder.hiddenDisclaimer",
      "defaultMessage": "Making this page hidden will hide it from the public."
    },
    "webBuilderLegacyActivation1": {
      "id": "web_builder.legacy_activation_1",
      "defaultMessage": "Looking for the old page editor? ",
    },
    "webBuilderLegacyActivation2": {
      "id": "web_builder.legacy_activation_2",
      "defaultMessage": "click here",
    },
    "webBuilderLegacyActivation3": {
      "id": "web_builder.legacy_activation_3",
      "defaultMessage": " to go back",
    },
    "webBuilderLegacyActivationConfirmMessage": {
      "id": "web_builder.legacy_activation_confirm_message",
      "defaultMessage": 'Are you sure you want to switch back to the old page editor?'
    },
    "webBuilderVisible": {
      "id": "web_builder.visible",
      "defaultMessage": "Visible"
    },
    "webBuilderHomePage": {
      "id": "web_builder.homePage",
      "defaultMessage": "Home Page"
    },
    "webBuilderIndex": {
      "id": "web_builder.index",
      "defaultMessage": "Web Builder"
    },
    "webBuilderPageTitleHelperText": {
      "id": "web_builder.pageTitleHelperText",
      "defaultMessage": "This is what shows in the browser tab"
    },
    "webBuilderAddLinks": {
      "id": "web_builder.add_links",
      "defaultMessage": "Add Links"
    },
    "webBuilderNoLink": {
      "id": "web_builder.no_links",
      "defaultMessage": "You don't have any links"
    },
    "webBuilderSave": {
      "id": "web_builder.save",
      "defaultMessage": "Save"
    },
    "webBuilderPageSettings": {
      "id": "web_builder.pageSettings",
      "defaultMessage": "Page Settings"
    },
    "webBuilderLinkSettings": {
      "id": "web_builder.linkSettings",
      "defaultMessage": "Link Settings"
    },
    "webBuilderDelete": {
      "id": "web_builder.delete",
      "defaultMessage": "Delete"
    },
    "webBuilderAddColumnContent": {
      "id": "web_builder.addColumnContent",
      "defaultMessage": "Add Column Content"
    },
    "webBuilderAddPage": {
      "id": "web_builder.addPage",
      "defaultMessage": "Add Page"
    },
    "webBuilderDeletePage": {
      "id": "web_builder.deletePage",
      "defaultMessage": "Delete Page"
    },
    "webBuilderDeletePageConfirm": {
      "id": "web_builder.deletePageConfirm",
      "defaultMessage": "Are you sure you want to delete this page? {sectionsLength} <b>The delete is a permanent change and CANNOT be undone.</b>"
    },
    "webBuilderEditColumnContent": {
      "id": "web_builder.editColumnContent",
      "defaultMessage": "Edit Column Content"
    },
    "webBuilderQrCard": {
      "id": "web_builder.qrCard",
      "defaultMessage": "QR Card"
    },
    "webBuilderQrCardHelperText": {
      "id": "web_builder.qrCardHelperText",
      "defaultMessage": "QR cards are not available until after publishing"
    },
    "webBuilderQrCardInactiveHelperText": {
      "id": "web_builder.qrCardInactiveHelperText",
      "defaultMessage": "QR cards are not available for hidden pages"
    },
    "webBuilderBlankPage": {
      "id": "web_builder.blankPage",
      "defaultMessage": "Blank Page"
    },
    "webBuilderPageTemplate": {
      "id": "web_builder.pageTemplate",
      "defaultMessage": "Page Template"
    },
    "webBuilderMainNavOtherPages": {
      "id": "web_builder.mainNavOtherPages",
      "defaultMessage": "Other Pages"
    },
    "webBuilderMainNavOtherPagesHelperText": {
      "id": "web_builder.mainNavOtherPagesHelperText",
      "defaultMessage": "These are pages that don\'t appear in your navigation."
    },
    "webBuilderClose": {
      "id": "web_builder.close",
      "defaultMessage": "CLOSE"
    },
    "webBuilderPreview": {
      "id": "web_builder.preview",
      "defaultMessage": "PREVIEW"
    },
    "webBuilderAdvancedFormsSettings": {
      "id": "web_builder.advanced_forms.settings",
      "defaultMessage": "Form Settings"
    },
    "webBuilderAdvancedFormsSettingsEmailResponsesTo": {
      "id": "web_builder.advanced_forms.settings.email_responses_to",
      "defaultMessage": "Email responses to"
    },
    "webBuilderAdvancedFormsSettingsEmailResponsesToTooltip": {
      "id": "web_builder.advanced_forms.settings.email_responses_to.tooltip",
      "defaultMessage": "Separate multiple email addresses with a semi-colon (;)"
    },
    "webBuilderAdvancedFormsSettingsSubjectLine": {
      "id": "web_builder.advanced_forms.settings.email_subject_line",
      "defaultMessage": "Subject line"
    },
    "webBuilderAdvancedFormsSettingsEmailThankYouContent": {
      "id": "web_builder.advanced_forms.settings.email_thank_you_content",
      "defaultMessage": "Thank you email content"
    },
    "webBuilderSectionDuplicateSection": {
      "id": "web_builder.sectionDuplicateSection",
      "defaultMessage": "Duplicate Section"
    },
    "webBuilderSectionDeleteSection": {
      "id": "web_builder.sectionDeleteSection",
      "defaultMessage": "Delete Section"
    },
    "sectionRemovalWarning": {
      "id": "web_builder.removeSectionWarning",
      "defaultMessage": "Are you sure you want to remove this section? <b>The delete is a permanent change and CANNOT be undone.</b>"
    },
    "giftCardUniquenessError": {
      "id": "web_builder.gift_card_uniqueness_error",
      "defaultMessage": "Gift card images must be unique"
    },
    "locationUniquenessError": {
      "id": "web_builder.location_uniqueness_error",
      "defaultMessage": "Locations must be unique"
    },
    "sectionDuplicateWarning": {
      "id": "web_builder.duplicateSectionWarning",
      "defaultMessage": "Only one catering or online ordering sections is allowed per page"
    },
    "pageDropdownSettings": {
      "id": "web_builder.pageDropdownSettingsButton",
      "defaultMessage": "Settings"
    },
    "pageDropdownDelete": {
      "id": "web_builder.pageDropdownDeleteButton",
      "defaultMessage": "Delete Page"
    },
    "multiColumnDelete": {
      "id": "web_builder.multiColumnDeleteButton",
      "defaultMessage": "Delete Column"
    },
    "advancedFormQuestionAdd": {
      "id": "web_builder.advancedFormQuestionAdd",
      "defaultMessage": "Add Question"
    },
    "advancedFormQuestionDelete": {
      "id": "web_builder.advancedFormQuestionDeleteButton",
      "defaultMessage": "Delete Question"
    },
    "advancedFormConfirmQuestionDeleteTitle": {
      "id": "web_builder.advancedFormConfirmQuestionDeleteTitle",
      "defaultMessage": "Confirm Delete Question"
    },
    "advancedFormConfirmQuestionDeleteMessage": {
      "id": "web_builder.advancedFormConfirmQuestionDeleteMessage",
      "defaultMessage": "Are you sure you want to delete this question?"
    },
    "advancedFormEmptyState": {
      "id": "web_builder.advancedFormEmptyState",
      "defaultMessage": "You don’t have any questions yet."
    },
    "articleDelete": {
      "id": "web_builder.articleDeleteButton",
      "defaultMessage": "Delete Article"
    },
    "multiColumnHeader": {
      "id": "web_builder.multiColumnHeader",
      "defaultMessage": "Columns"
    },
    "headerBackgroundAccordion": {
      "id": "web_builder.headerBackgroundAccordion",
      "defaultMessage": "Background Image"
    },
    "headerLogoImage": {
      "id": "web_builder.headerLogoImage",
      "defaultMessage": "Header Logo Image"
    },
    "headerSectionHeading": {
      "id": "web_builder.headerSectionHeading",
      "defaultMessage": "Header"
    },
    "sectionTemplatesSubheading": {
      "id": "web_builder.sectionTemplatesSubheading",
      "defaultMessage": "Choose a section below to add to the page"
    },
    "giftCardSectionEmptyState": {
      "id": "web_builder.emptyGiftCard",
      "defaultMessage": 'You currently don’t have Gift Card purchasing on. Set up Digital Gift Cards by clicking the button below.'
    },
    "giftCardSectionEmptyStateButton": {
      "id": "web_builder.emptyGiftCardButton",
      "defaultMessage": 'Gift Card Settings'
    },
    "locationSectionEmptyState": {
      "id": "web_builder.emptyLocation",
      "defaultMessage": 'Oops! Looks like you don’t have a location set.'
    },
    "allLocationsSectionWithTotal": {
      "id": "web_builder.locations_with_total",
      "defaultMessage": 'Locations ({total})'
    },
    "allLocationsSectionButtonTooltip": {
      "id": "web_builder.locations_button_tootip",
      "defaultMessage": 'You’ve added all available locations to this section'
    },
    "locationsSectionMissing": {
      "id": "web_builder.locations_section_missing",
      "defaultMessage": 'You have {total} location(s) that’s not on this list. Add Location'
    },
    "seeAllLocations": {
      "id": "web_builder.see_all_locations",
      "defaultMessage": 'See All {total} Locations'
    },
    "manageLocationSettings": {
      "id": "web_builder.manage_location_settings",
      "defaultMessage": 'Manage Location Settings'
    },
    "locationSectionEmptyStateButton": {
      "id": "web_builder.emptyLocationButton",
      "defaultMessage": 'Add Location'
    },
    "mainNavigation": {
      "id": "web_builder.mainNavigation",
      "defaultMessage": 'Main Navigation'
    },
    "mainNavigationHelperText": {
      "id": "web_builder.mainNavigationHelperText",
      "defaultMessage": 'This will be your navigation at the top of your website.'
    },
    "menuSectionEmptyState": {
      "id": "web_builder.emptyMenuSection",
      "defaultMessage": 'Oops! Looks like you haven’t built any menus yet. You can either build them out yourself using our menu builder or we can build them for you.'
    },
    "menuSectionEmptyStateButton": {
      "id": "web_builder.emptyMenuSectionButton",
      "defaultMessage": 'Build Menu Myself'
    },
    "menuSectionEmptyStateImportButton": {
      "id": "web_builder.emptyMenuSectionImportButton",
      "defaultMessage": 'Import Menu'
    },
    "onlineOrderingEmptyState": {
      "id": "web_builder.emptyOnlineOrderingSection",
      "defaultMessage": 'You currently don’t have online ordering turned on.'
    },
    "onlineOrderingEmptyStateButton": {
      "id": "web_builder.emptyOnlineOrderingSectionButton",
      "defaultMessage": 'Turn on Online Ordering'
    },
    "pagesEmptyState": {
      "id": "web_builder.emptyPages",
      "defaultMessage": "You currently don't have any pages on your site."
    },
    "pagesEmptyStateButton": {
      "id": "web_builder.emptyPagesButton",
      "defaultMessage": 'Add Page'
    },
    "calendarEventEmptyState": {
      "id": "web_builder.emptyCalendarEventSection",
      "defaultMessage": 'Looks like you haven’t added any events yet.'
    },
    "calendarEventEmptyStateButton": {
      "id": "web_builder.emptyCalendarEventSectionButton",
      "defaultMessage": 'Add events'
    },
    "webBuilderAddNestedMenuSection": {
      "id": "web_builder.add_menu",
      "defaultMessage": 'Add Menu'
    },
    "webBuilderDuplicatedNestedMenuSection": {
      "id": "web_builder.duplicated.nested_menu",
      "defaultMessage": 'Please, remove the identical menus'
    },
    "webBuilderMenuSectionConfirmChangeLocationTitle": {
      "id": "web_builder.menu.confirm_change_location_title",
      "defaultMessage": 'Confirm Change Location'
    },
    "webBuilderMenuSectionConfirmChangeLocation": {
      "id": "web_builder.menu.confirm_change_location",
      "defaultMessage": 'Are you sure you want to change the location and remove the menus?'
    },
    "webBuilderCustomCssParagraph": {
      "id": "web_builder.customCssParagraph",
      "defaultMessage": 'Add custom CSS to your website'
    },
    "webBuilderActionBarBgColor": {
      "id": "web_builder.action_bar.bg_color",
      "defaultMessage": 'Background Color'
    },
    "webBuilderActionBarIsEnabled": {
      "id": "web_builder.action_bar.is_enabled",
      "defaultMessage": 'Enabled'
    },
    "webBuilderActionBarLinks": {
      "id": "web_builder.action_bar.links",
      "defaultMessage": 'Links'
    },
    "webBuilderActionBarLinksDisplayText": {
      "id": "web_builder.action_bar.display_text",
      "defaultMessage": 'Link Display Text'
    },
    "webBuilderActionBarLinksTargetType": {
      "id": "web_builder.action_bar.target_type",
      "defaultMessage": 'Link Target Type'
    },
    "webBuilderActionBarLinksTargetTypeCustom": {
      "id": "web_builder.action_bar.target_type.custom_type",
      "defaultMessage": 'Custom URL'
    },
    "webBuilderActionBarLinksTargetTypeCustomPage": {
      "id": "web_builder.action_bar.target_type.custom_page_type",
      "defaultMessage": 'Page'
    },
    "webBuilderActionBarLinksTargetTypeReviews": {
      "id": "web_builder.action_bar.target_type.reviews_type",
      "defaultMessage": 'Reviews'
    },
    "webBuilderActionBarLinksTargetTypeBlog": {
      "id": "web_builder.action_bar.target_type.blog_type",
      "defaultMessage": 'Blog'
    },
    "webBuilderActionBarLinksTargetTypeSignUp": {
      "id": "web_builder.action_bar.target_type.sign_up_type",
      "defaultMessage": 'Sign Up'
    },
    "webBuilderActionBarLinksTargetTypeMenu": {
      "id": "web_builder.action_bar.target_type.menu_type",
      "defaultMessage": 'Menus'
    },
    "webBuilderActionBarLinksTargetTypeSignIn": {
      "id": "web_builder.action_bar.target_type.sign_in_type",
      "defaultMessage": 'Sign In'
    },
    "webBuilderActionBarLinksTargetTypeWaitList": {
      "id": "web_builder.action_bar.target_type.waitlist_type",
      "defaultMessage": 'WaitList'
    },
    "webBuilderActionBarLinksTargetTypeReservations": {
      "id": "web_builder.action_bar.target_type.reservations_type",
      "defaultMessage": 'Reservations'
    },
    "webBuilderActionBarLinksTargetTypeOrdering": {
      "id": "web_builder.action_bar.target_type.ordering_type",
      "defaultMessage": 'Ordering'
    },
    "webBuilderActionBarLinksTarget": {
      "id": "web_builder.action_bar.target",
      "defaultMessage": 'Link Target'
    },
    "webBuilderActionBarLinksTargetExternal": {
      "id": "web_builder.action_bar.target.external",
      "defaultMessage": 'External'
    },
    "webBuilderActionBarLinksTargetInternal": {
      "id": "web_builder.action_bar.target.internal",
      "defaultMessage": 'Internal'
    },
    "webBuilderActionBarLinksConfirmDelete": {
      "id": "web_builder.action_bar.confirm_removal",
      "defaultMessage": 'Are you sure you want to remove this link?'
    },
    "webBuilderActionBarLinksConfirmDeleteTitle": {
      "id": "web_builder.action_bar.confirm_removal_title",
      "defaultMessage": 'Confirm Removal'
    },
    "webBuilderAdvancedFormFieldRequireResponse": {
      "id": "web_builder.advanced_form.require_response",
      "defaultMessage": 'Require Response'
    },
    "webBuilderAdvancedFormFieldTitle": {
      "id": "web_builder.advanced_form.field_types.title",
      "defaultMessage": 'Form Data'
    },
    "webBuilderAdvancedFormFieldShortQuestion": {
      "id": "web_builder.advanced_form.field_types.short_question",
      "defaultMessage": 'Short Text'
    },
    "webBuilderAdvancedFormFieldLongQuestion": {
      "id": "web_builder.advanced_form.field_types.long_question",
      "defaultMessage": 'Long Text'
    },
    "webBuilderAdvancedFormFieldFileUpload": {
      "id": "web_builder.advanced_form.field_types.file_upload",
      "defaultMessage": 'File Upload'
    },
    "webBuilderAdvancedFormFieldDate": {
      "id": "web_builder.advanced_form.field_types.date",
      "defaultMessage": 'Date'
    },
    "webBuilderAdvancedFormFieldCheckbox": {
      "id": "web_builder.advanced_form.field_types.check_box",
      "defaultMessage": 'Checkbox'
    },
    "webBuilderAdvancedFormFieldAnswers": {
      "id": "web_builder.advanced_form.field_types.answers",
      "defaultMessage": 'Answers'
    },
    "webBuilderAdvancedFormFieldMultipleChoice": {
      "id": "web_builder.advanced_form.field_types.multiple_choice",
      "defaultMessage": 'Multiple Choice'
    },
    "webBuilderAdvancedFormFieldFirstName": {
      "id": "web_builder.advanced_form.field_types.first_name",
      "defaultMessage": 'First Name'
    },
    "webBuilderAdvancedFormFieldLastName": {
      "id": "web_builder.advanced_form.field_types.last_name",
      "defaultMessage": 'Last Name'
    },
    "webBuilderAdvancedFormFieldPhoneNumber": {
      "id": "web_builder.advanced_form.field_types.phone_number",
      "defaultMessage": 'Phone Number'
    },
    "webBuilderAdvancedFormFieldEmailAddress": {
      "id": "web_builder.advanced_form.field_types.email_address",
      "defaultMessage": 'Email Address'
    },
    "webBuilderAdvancedFormFieldAddress": {
      "id": "web_builder.advanced_form.field_types.address",
      "defaultMessage": 'Address'
    },
    "webBuilderAdvancedFormFieldDropdown": {
      "id": "web_builder.advanced_form.field_types.dropdown",
      "defaultMessage": 'Dropdown'
    },
    "webBuilderAdvancedFormFieldLocationPicker": {
      "id": "web_builder.advanced_form.field_types.location_picker",
      "defaultMessage": 'Location'
    },
    "webBuilderAdvancedFormDeleteButtonTitle": {
      "id": "web_builder.advanced_form.delete_button.title",
      "defaultMessage": 'Delete Form Data'
    },
    "webBuilderAdvancedFormDeleteButtonShortQuestion": {
      "id": "web_builder.advanced_form.delete_button.short_question",
      "defaultMessage": 'Delete Short Text'
    },
    "webBuilderAdvancedFormDeleteButtonLongQuestion": {
      "id": "web_builder.advanced_form.delete_button.long_question",
      "defaultMessage": 'Delete Long Text'
    },
    "webBuilderAdvancedFormDeleteButtonFileUpload": {
      "id": "web_builder.advanced_form.delete_button.file_upload",
      "defaultMessage": 'Delete File Upload'
    },
    "webBuilderAdvancedFormDeleteButtonDate": {
      "id": "web_builder.advanced_form.delete_button.date",
      "defaultMessage": 'Delete Date'
    },
    "webBuilderAdvancedFormDeleteButtonCheckBox": {
      "id": "web_builder.advanced_form.delete_button.check_box",
      "defaultMessage": 'Delete Checkbox'
    },
    "webBuilderAdvancedFormDeleteButtonAnswers": {
      "id": "web_builder.advanced_form.delete_button.answers",
      "defaultMessage": 'Delete Answers'
    },
    "webBuilderAdvancedFormDeleteButtonMultipleChoice": {
      "id": "web_builder.advanced_form.delete_button.multiple_choice",
      "defaultMessage": 'Delete Multiple Choice'
    },
    "webBuilderAdvancedFormFieldFirstNameFirstName": {
      "id": "web_builder.advanced_form.delete_button.first_name",
      "defaultMessage": 'Delete First Name'
    },
    "webBuilderAdvancedFormDeleteButtonLastName": {
      "id": "web_builder.advanced_form.delete_button.last_name",
      "defaultMessage": 'Delete Last Name'
    },
    "webBuilderAdvancedFormDeleteButtonPhoneNumber": {
      "id": "web_builder.advanced_form.delete_button.phone_number",
      "defaultMessage": 'Delete Phone Number'
    },
    "webBuilderAdvancedFormDeleteButtonEmailAddress": {
      "id": "web_builder.advanced_form.delete_button.email_address",
      "defaultMessage": 'Delete Email Address'
    },
    "webBuilderAdvancedFormDeleteButtonAddress": {
      "id": "web_builder.advanced_form.delete_button.address",
      "defaultMessage": 'Delete Address'
    },
    "webBuilderAdvancedFormDeleteButtonLocationPicker": {
      "id": "web_builder.advanced_form.delete_button.location_picker",
      "defaultMessage": 'Delete Location'
    },
    "webBuilderAdvancedFormDeleteButtonDescription": {
      "id": "web_builder.advanced_form.delete_button.description",
      "defaultMessage": 'Delete Description'
    },
    "webBuilderAdvancedFormDeleteButtonHeader": {
      "id": "web_builder.advanced_form.delete_button.header",
      "defaultMessage": 'Delete Header'
    },
    "webBuilderAdvancedFormDeleteButtonSubheader": {
      "id": "web_builder.advanced_form.delete_button.subheader",
      "defaultMessage": 'Delete Subheader'
    },
    "webBuilderAdvancedFormDeleteButtonSpacer": {
      "id": "web_builder.advanced_form.delete_button.spacer",
      "defaultMessage": 'Delete Spacer'
    },
    "webBuilderAdvancedFormDeleteButtonDivider": {
      "id": "web_builder.advanced_form.delete_button.divider",
      "defaultMessage": 'Delete Divider'
    },
    "webBuilderAdvancedFormDeleteButtonDropdown": {
      "id": "web_builder.advanced_form.delete_button.dropdown",
      "defaultMessage": 'Delete Dropdown'
    },
    "webBuilderAdvancedDesignElementTitle": {
      "id": "web_builder.advanced_form.design_elements.title",
      "defaultMessage": 'Form Design'
    },
    "webBuilderAdvancedDesignElementDescription": {
      "id": "web_builder.advanced_form.design_elements.description",
      "defaultMessage": 'Description'
    },
    "webBuilderAdvancedDesignElementDivider": {
      "id": "web_builder.advanced_form.design_elements.divider",
      "defaultMessage": 'Divider Line'
    },
    "webBuilderAdvancedDesignElementSpacer": {
      "id": "web_builder.advanced_form.design_elements.spacer",
      "defaultMessage": 'Spacer'
    },
    "webBuilderAdvancedDesignElementHeader": {
      "id": "web_builder.advanced_form.design_elements.header",
      "defaultMessage": 'Header'
    },
    "webBuilderAdvancedDesignElementSubheader": {
      "id": "web_builder.advanced_form.design_elements.subheader",
      "defaultMessage": 'Subheader'
    },
    "webBuilderFeaturedItemsStepperChooseReviews": {
      "id": "web_builder.featured_items.choose_reviews",
      "defaultMessage": 'Choose Reviews'
    },
    "webBuilderFeaturedItemsStepperOneTitle": {
      "id": "web_builder.featured_items.choose_featured_items",
      "defaultMessage": 'Choose Featured Items'
    },
    "webBuilderFeaturedItemsStepperTwoTitle": {
      "id": "web_builder.featured_items.choose_featured_reviews",
      "defaultMessage": 'Choose Featured Reviews'
    },
    "webBuilderFeaturedItemsSortByName": {
      "id": "web_builder.featured_items.sort_by.name",
      "defaultMessage": 'Name'
    },
    "webBuilderFeaturedItemsSortByLikesReviews": {
      "id": "web_builder.featured_items.sort_by.likes_reviews",
      "defaultMessage": 'Likes & Reviews'
    },
    "webBuilderFeaturedItemsSortByOrderVolume": {
      "id": "web_builder.featured_items.sort_by.order_volume",
      "defaultMessage": 'Order Volume'
    },
    "webBuilderFeaturedItemsSearchItems": {
      "id": "web_builder.featured_items.search_items",
      "defaultMessage": 'Search items'
    },
    "webBuilderFeaturedItemsUp10ItemsDesc": {
      "id": "web_builder.featured_items.up_desc",
      "defaultMessage": 'You can add up to {current}/{limit} items to your featured item section'
    },
    "webBuilderFeaturedItemsTotalLikes": {
      "id": "web_builder.featured_items.total_likes",
      "defaultMessage": 'Total Likes'
    },
    "webBuilderFeaturedItemsReviewTitle": {
      "id": "web_builder.featured_items.review_title",
      "defaultMessage": 'Choose a review to feature on the selected item(s) (optional)'
    },
    "webBuilderFeaturedItemsReview": {
      "id": "web_builder.featured_items.review",
      "defaultMessage": 'Featured Review'
    },
    "webBuilderFeaturedItemsDeleteMessage": {
      "id": "web_builder.featured_items.delete.message",
      "defaultMessage": 'Are you sure you want to delete this item from the section?'
    },
    "webBuilderFeaturedItemsDeleteTitle": {
      "id": "web_builder.featured_items.delete.title",
      "defaultMessage": 'Confirm Delete'
    },
    "webBuilderFeaturedItemsDeleteButtonTitle": {
      "id": "web_builder.featured_items.delete_button.title",
      "defaultMessage": 'Delete Item'
    },
    "webBuilderFeaturedItemsLimit": {
      "id": "web_builder.featured_items.limit",
      "defaultMessage": "You've reached the 10 item limit for this section"
    },
    "webBuilderFeaturedItemsAdd": {
      "id": "web_builder.featured_items.add",
      "defaultMessage": "Add"
    },
    "webBuilderFeaturedItemsAdded": {
      "id": "web_builder.featured_items.added",
      "defaultMessage": "Added"
    },
    "webBuilderImageTextBlankValidation": {
      "id": "web_builder.image_text_is_blank",
      "defaultMessage": "Image text description cannot be blank"
    },
    "webBuilderArticleTitleBlankValidation": {
      "id": "web_builder.article_title_is_blank",
      "defaultMessage": "Article title cannot be blank"
    },
    "webBuilderArticleImageTextBlankValidation": {
      "id": "web_builder.article_image_text_is_blank",
      "defaultMessage": "Article {article} is missing the Logo Description required for ADA compliance. Please correct"
    },
    "webBuilderColumnImageTextBlankValidation": {
      "id": "web_builder.column_image_text_is_blank",
      "defaultMessage": "Column {column} is missing the Photo Alt Text required for ADA compliance. Please correct"
    },
  }
);
export default defaultMessages;
