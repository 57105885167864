export const SET_HAS_NEW_RELEASE = 'SET_HAS_NEW_RELEASE';
export const SET_RELEASE_FETCH_AT = 'SET_RELEASE_FETCH_AT';
export const SET_SHOULD_FETCH_RELEASE = 'SET_SHOULD_FETCH_RELEASE';

export function setHasNewRelease(hasNewRelease) {
  return (dispatch) => {
    dispatch({
      hasNewRelease,
      type: SET_HAS_NEW_RELEASE,
    });
  };
}

export function setReleaseFetchAt(releaseFetchAt) {
  return (dispatch) => {
    dispatch({
      releaseFetchAt,
      type: SET_RELEASE_FETCH_AT,
    });
  };
}

export function setShouldFetchRelease(shouldFetchRelease) {
  return (dispatch) => {
    dispatch({
      shouldFetchRelease,
      type: SET_SHOULD_FETCH_RELEASE,
    });
  };
}
