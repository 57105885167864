
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "cuisineTypesAdd": {
      "id": "cuisine_types.add",
      "defaultMessage": "Add Category"
    },
    "cuisineTypesNoOptions": {
      "id": "cuisine_types.no_options",
      "defaultMessage": "Search for a category"
    },
    "cuisineTypesPlaceholder": {
      "id": "cuisine_types.placeholder",
      "defaultMessage": "Select a category..."
    }
  }
);
export default defaultMessages;

