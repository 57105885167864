import { useEffect, useRef } from 'react';
import branch from 'branch-sdk';
import { captureError } from './branchErrors';

interface useBranchIoI {
  branchIoKey: string | null
  isMobile: boolean
  pageName: string | null
}

const allowedScreensRegex = new RegExp(/orders\/overview|catering-orders|standard-orders/);

const isAllowedScreen = (pageNameArg: string | null) => {
  if (!pageNameArg) {
    return false;
  }

  return allowedScreensRegex.test(pageNameArg);
};

export const useBranchIo = ({ branchIoKey, isMobile, pageName }:useBranchIoI) => {
  const isInitializedRef = useRef(false);
  const isBranchErrorRef = useRef(false);
  const skipBranchCall = !branchIoKey || !isMobile || !isAllowedScreen(pageName) || isBranchErrorRef.current;

  useEffect(() => {
    if (skipBranchCall || isInitializedRef.current) {
      return;
    }

    isInitializedRef.current = true;

    branch.init(branchIoKey, undefined, (err) => {
      isBranchErrorRef.current = true;
      captureError(err);
    });
  }, [branchIoKey, skipBranchCall]);

  useEffect(() => {
    if (skipBranchCall) {
      return;
    }

    branch.track('pageview', undefined, (err) => {
      isBranchErrorRef.current = true;
      captureError(err);
    });
  }, [skipBranchCall, pageName]);
};
