// See docs/loadable-components.md for details regarding **.imports-X.** file extension & duplicate file structure.
import React from 'react';
import loadable from '@loadable/component';
import Loading from '../../../shared/Loading';
import retry from '../../../utils/retry';

const SignInSlider = loadable(() => retry(() => import('./SignInSlider')), {
  fallback: <Loading size="lg" />,
});

export default SignInSlider;
