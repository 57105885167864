
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "eventsEventTypesVisitEvent": {
      "id": "events.event_types.visit_event",
      "defaultMessage": "{user} viewed {eventable}."
    },
    "eventsEventTypesCallAttemptEvent": {
      "id": "events.event_types.call_attempt_event",
      "defaultMessage": "{user} started to call while viewing {eventable}."
    },
    "eventsEventTypesEmailShareEvent": {
      "id": "events.event_types.email_share_event",
      "defaultMessage": "{user} started to share {eventable} via email."
    },
    "eventsEventTypesFacebookShareEvent": {
      "id": "events.event_types.facebook_share_event",
      "defaultMessage": "{user} started to share {eventable} via Facebook."
    },
    "eventsEventTypesGalleryOpenEvent": {
      "id": "events.event_types.gallery_open_event",
      "defaultMessage": "{user} viewed the photo gallery for {eventable}."
    },
    "eventsEventTypesGoogleAddToCalendarEvent": {
      "id": "events.event_types.google_add_to_calendar_event",
      "defaultMessage": "{user} added {eventable} to their calendar."
    },
    "eventsEventTypesIcalAddToCalendarEvent": {
      "id": "events.event_types.ical_add_to_calendar_event",
      "defaultMessage": "{user} added {eventable} to their calendar."
    },
    "eventsEventTypesLinkedinShareEvent": {
      "id": "events.event_types.linkedin_share_event",
      "defaultMessage": "{user} started to share {eventable} via Linkedin."
    },
    "eventsEventTypesOutlookAddToCalendarEvent": {
      "id": "events.event_types.outlook_add_to_calendar_event",
      "defaultMessage": "{user} added {eventable} to their calendar."
    },
    "eventsEventTypesPhotoAttemptEvent": {
      "id": "events.event_types.photo_attempt_event",
      "defaultMessage": "{user} started to submit a photo for {eventable}."
    },
    "eventsEventTypesPhotoSelectEvent": {
      "id": "events.event_types.photo_select_event",
      "defaultMessage": "{user} started to add a photo for {eventable}."
    },
    "eventsEventTypesPinterestShareEvent": {
      "id": "events.event_types.pinterest_share_event",
      "defaultMessage": "{user} started to share {eventable} via Pinterest."
    },
    "eventsEventTypesPopAttemptEvent": {
      "id": "events.event_types.pop_attempt_event",
      "defaultMessage": "{user} started to pop {eventable}."
    },
    "eventsEventTypesReserveAttemptEvent": {
      "id": "events.event_types.reserve_attempt_event",
      "defaultMessage": "{user} started to make a reservation while viewing {eventable}."
    },
    "eventsEventTypesReserveTabEvent": {
      "id": "events.event_types.reserve_tab_event",
      "defaultMessage": "{user} viewed the reservations tab for {eventable}."
    },
    "eventsEventTypesReviewAttemptEvent": {
      "id": "events.event_types.review_attempt_event",
      "defaultMessage": "{user} started to review {eventable}."
    },
    "eventsEventTypesShareTabEvent": {
      "id": "events.event_types.share_tab_event",
      "defaultMessage": "{user} viewed the share tab for {eventable}."
    },
    "eventsEventTypesSmsShareEvent": {
      "id": "events.event_types.sms_share_event",
      "defaultMessage": "{user} started to share {eventable} via text message."
    },
    "eventsEventTypesTwitterShareEvent": {
      "id": "events.event_types.twitter_share_event",
      "defaultMessage": "{user} started to share {eventable} via Twitter."
    },
    "eventsEventTypesYahooAddToCalendarEvent": {
      "id": "events.event_types.yahoo_add_to_calendar_event",
      "defaultMessage": "{user} added {eventable} to their calendar."
    },
    "eventsCreatedCustomPage": {
      "id": "events.created.custom_page",
      "defaultMessage": "{user} added {eventable}."
    },
    "eventsCreatedDish": {
      "id": "events.created.dish",
      "defaultMessage": "{user} added {eventable}."
    },
    "eventsCreatedFollower": {
      "id": "events.created.follower",
      "defaultMessage": "{user} started following {eventable}."
    },
    "eventsCreatedLocation": {
      "id": "events.created.location",
      "defaultMessage": "{user} viewed {eventable}."
    },
    "eventsCreatedMenu": {
      "id": "events.created.menu",
      "defaultMessage": "{user} created {eventable}."
    },
    "eventsCreatedPhoto": {
      "id": "events.created.photo",
      "defaultMessage": "{user} uploaded a photo for {eventable}."
    },
    "eventsCreatedPop": {
      "id": "events.created.pop",
      "defaultMessage": "{user} popped {eventable}."
    },
    "eventsCreatedRestaurant": {
      "id": "events.created.restaurant",
      "defaultMessage": "{user} created {eventable}."
    },
    "eventsCreatedReview": {
      "id": "events.created.review",
      "defaultMessage": "{user} submitted a review for {eventable}."
    },
    "eventsCreatedUser": {
      "id": "events.created.user",
      "defaultMessage": "{user} signed up for Popmenu."
    },
    "eventsGenericCalendarEvent": {
      "id": "events.generic.calendar_event",
      "defaultMessage": "an event"
    },
    "eventsGenericDish": {
      "id": "events.generic.dish",
      "defaultMessage": "a dish"
    },
    "eventsGenericFollower": {
      "id": "events.generic.follower",
      "defaultMessage": "a restaurant"
    },
    "eventsGenericLocation": {
      "id": "events.generic.location",
      "defaultMessage": "a location"
    },
    "eventsGenericMenu": {
      "id": "events.generic.menu",
      "defaultMessage": "a menu"
    },
    "eventsGenericMenuItem": {
      "id": "events.generic.menu_item",
      "defaultMessage": "a dish"
    },
    "eventsGenericPhoto": {
      "id": "events.generic.photo",
      "defaultMessage": "a dish"
    },
    "eventsGenericPop": {
      "id": "events.generic.pop",
      "defaultMessage": "a dish"
    },
    "eventsGenericRestaurant": {
      "id": "events.generic.restaurant",
      "defaultMessage": "a restaurant"
    },
    "eventsGenericUser": {
      "id": "events.generic.user",
      "defaultMessage": "Guest"
    },
    "eventsEmpty": {
      "id": "events.empty",
      "defaultMessage": "No recent activity!"
    }
  }
);
export default defaultMessages;

