
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "rootSectionTypesDestroyConfirm": {
      "id": "root_section_types.destroy_confirm",
      "defaultMessage": "Are you sure you want to remove this page section?"
    },
    "rootSectionTypesDestroyConfirmTitle": {
      "id": "root_section_types.destroy_confirm_title",
      "defaultMessage": "Confirm Removal"
    },
    "rootSectionTypesIndex": {
      "id": "root_section_types.index",
      "defaultMessage": "Root Page Sections"
    },
    "rootSectionTypesNew": {
      "id": "root_section_types.new",
      "defaultMessage": "Add Page Section"
    },
    "rootSectionTypesEdit": {
      "id": "root_section_types.edit",
      "defaultMessage": "Edit Page Section"
    },
    "rootSectionTypesDestroy": {
      "id": "root_section_types.destroy",
      "defaultMessage": "Remove Page Section"
    }
  }
);
export default defaultMessages;

