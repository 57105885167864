
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "errorsAiMarketingContentInvalidScheduledAt": {
      "id": "errors.ai_marketing_content.invalid.scheduled_at",
      "defaultMessage": "Sorry, the scheduled date is too close to another text message."
    },
    "errorsCountryAlreadyUsed": {
      "id": "errors.root_announcement.invalid.country",
      "defaultMessage": "Sorry, this country already has a root announcement."
    },
    "errorsFailedRetry": {
      "id": "errors.failed_retry",
      "defaultMessage": "Sorry, there was an error submitting the request. Please reload the page and try again."
    },
    "errorsHasImageAltTags": {
      "id": "errors.has_image_alt_tags",
      "defaultMessage": "Sorry, images must have a description."
    },
    "errorsFailed": {
      "id": "errors.failed",
      "defaultMessage": "Sorry, there was an error submitting the request"
    },
    "errorsInvalidInstagramSocialAccount": {
      "id": "errors.custom_page/section.invalid.instagram_feed_social_account_id",
      "defaultMessage": "Sorry, that is not a valid Instagram account"
    },
    "errorsInvalidLiquidSyntax": {
      "id": "errors.invalid_liquid_syntax",
      "defaultMessage": "Save failed. Please check for formatting errors."
    },
    "errorsLocationInvalidOrderingEnabled": {
      "id": "errors.restaurant/location.invalid.is_ordering_enabled",
      "defaultMessage": "At least one pickup type must be enabled."
    },
    "errorsMenuItemInvalidSubsectionId": {
      "id": "errors.menu/item.invalid.subsection_id",
      "defaultMessage": "Item already in menu section."
    },
    "errorsNotAuthorized": {
      "id": "errors.not_authorized",
      "defaultMessage": "You are not authorized to perform that action."
    },
    "errorsUnauthorized": {
      "id": "errors.unauthorized",
      "defaultMessage": "Sorry, you are not authorized to perform that action- try signing back in"
    },
    "errorsUploadFailed": {
      "id": "errors.upload.failed",
      "defaultMessage": "Sorry, there was an error uploading your file. Please try again."
    },
    "errorsUploadInvalidFile": {
      "id": "errors.upload.invalid.file",
      "defaultMessage": "Invalid file type. Please try again with a different file format."
    },
    "errorsUploadInvalidFont": {
      "id": "errors.upload.invalid.font",
      "defaultMessage": "Invalid font type. Please try again with a different font format."
    },
    "errorsUploadInvalidImage": {
      "id": "errors.upload.invalid.image",
      "defaultMessage": "Invalid image type. Please try again with a different image format."
    },
    "errorsUploadInvalidVideo": {
      "id": "errors.upload.invalid.video",
      "defaultMessage": "Invalid video type. Please try again with a different video format."
    },
    "errorsSocialPostInvalidMessageLength": {
      "id": "errors.social_post.invalid.message_length",
      "defaultMessage": "Character limit exceeded. Reduce post content to 2200 characters to save."
    },
    "errorsSocialPostInvalidTwitterMessageLength": {
      "id": "errors.social_post.invalid.twitter_message_length",
      "defaultMessage": "Twitter character limit exceeded. Reduce post content to 280 characters to save."
    },
    "errorsSocialPostProcessedAccountArchived": {
      "id": "errors.social_post.processed.account_archived",
      "defaultMessage": "Post Failed: Integration had been removed."
    },
    "errorsSocialPostProcessedInvalidDate": {
      "id": "errors.social_post.processed.invalid_date",
      "defaultMessage": "Post Failed: Scheduled date was invalid (ensure your business start date is set)"
    },
    "errorsSocialPostProcessedInvalidImage": {
      "id": "errors.social_post.processed.invalid_image",
      "defaultMessage": "Post Failed: Uploaded image was invalid (too large or unsupported file type). Please check here for media limits: https://support.popmenu.com/s/article/How-to-Post-a-Video-Using-Popmenu-s-Social-Media-Tools"
    },
    "errorsSocialPostProcessedMessageLength": {
      "id": "errors.social_post.processed.message_length",
      "defaultMessage": "Post Failed: Message was too long"
    },
    "errorsSocialPostProcessedOauthFailed": {
      "id": "errors.social_post.processed.oauth_failed",
      "defaultMessage": "Post Failed: Try reauthenticating your account"
    },
    "errorsSocialPostProcessedRemoved": {
      "id": "errors.social_post.processed.removed",
      "defaultMessage": "Post Failed: Post has been removed"
    },
    "errorsSocialPostProcessedPagePublishingFailed": {
      "id": "errors.social_post.processed.page_publishing_failed",
      "defaultMessage": "Post Failed: You need to obtain Page Publishing Authorization from Facebook before posting."
    },
    "errorsSocialPostProcessedTooManyTags": {
      "id": "errors.social_post.processed.too_many_tags",
      "defaultMessage": "Post Failed: There is a limit of 30 tags on posts."
    },
    "errorsSocialPostProcessedNotInstagramBusiness": {
      "id": "errors.social_post.processed.not_instagram_business",
      "defaultMessage": "Post Failed: This is not an Instagram Business account."
    },
    "errorsSocialPostProcessedAccountRestricted": {
      "id": "errors.social_post.processed.account_restricted",
      "defaultMessage": "Post Failed: API error - social account restricted. Please ensure social account is set up and enabled. Please check https://www.facebook.com/accountquality to see the status of your account."
    },
    "errorsSocialPostProcessedCaptionTooLong": {
      "id": "errors.social_post.processed.caption_too_long",
      "defaultMessage": "Post Failed: Your caption was too long."
    },
    "errorsSocialPostProcessedImpersonationError": {
      "id": "errors.social_post.processed.impersonation_error",
      "defaultMessage": "Post Failed: The user must be an administrator, editor, or moderator of the page in order to impersonate it. The user also needs to enable Two Factor Authentication."
    },
    "errorsSocialPostProcessedStatusIdentical": {
      "id": "errors.social_post.processed.status_identical",
      "defaultMessage": "Post Failed: It is identical to the past post. It may be a duplicate."
    },
    "errorsSocialPostProcessedTwoFactorAuthNeeded": {
      "id": "errors.social_post.processed.admin_or_two_factor_auth_needed",
      "defaultMessage": "Post Failed: User does not have sufficient administrative permission for this action on this page. The user also needs to enable Two Factor Authentication."
    },
    "errorsSocialPostHTTPUnknown": {
      "id": "errors.social_post.processed.http_unknown",
      "defaultMessage": "Post Failed: An unknown error occurred on the Facebook/Instagram server. Please try again later."
    },
    "errorsSocialPostNetworkUnreachable": {
      "id": "errors.social_post.processed.network_unreachable",
      "defaultMessage": "Post Failed: Network Unreachable. Please try again later."
    },
    "errorsSocialPostReadTimeout": {
      "id": "errors.social_post.processed.read_timeout",
      "defaultMessage": "Post Failed: Server timeout error occurred. Please try again later."
    },
    "errorsSocialPostServiceUnavailable": {
      "id": "errors.social_post.processed.service_unavailable",
      "defaultMessage": "Post Failed: Social service unavailable at the moment. Please try again later."
    },
    "errorsSocialPostTwitterBlocked": {
      "id": "errors.social_post.processed.twitter_blocked",
      "defaultMessage": "Post Failed - Twitter error: To protect our users from spam and other malicious activity, this account is temporarily locked. Please log in to https://twitter.com to unlock your account."
    },
    "errorsSocialPostTwitterMediaError": {
      "id": "errors.social_post.processed.twitter_media_error",
      "defaultMessage": "Post Failed: Tweet with media must have exactly 1 gif or video or up to 4 photos."
    },
    "errorsSocialPostViewerPermission": {
      "id": "errors.social_post.processed.viewer_permission",
      "defaultMessage": "Post Failed: Viewer does not have permission to perform this action."
    },
    "errorsSocialPostUnauthorizedApplication": {
      "id": "errors.social_post.processed.unauthorized_application",
      "defaultMessage": "Post Failed: Error validating access token: The user has not authorized application."
    },
    "errorsSocialPostFacebookAccess": {
      "id": "errors.social_post.processed.facebook_access",
      "defaultMessage": "Post Failed: You cannot access the app until you log in to www.facebook.com and follow the instructions given."
    },
    "errorsSocialPostSessionInvalidated": {
      "id": "errors.social_post.processed.session_invalidated",
      "defaultMessage": "Post Failed: Error validating access token: The session has been invalidated because the user changed their password or Facebook has changed the session for security reasons."
    },
    "errorsSocialPostConfirmedUser": {
      "id": "errors.social_post.processed.confirmed_user",
      "defaultMessage": "Post Failed: Error validating access token: Sessions for the user are not allowed because the user is not a confirmed user."
    },
    "errorsSocialPostPermissionsError": {
      "id": "errors.social_post.processed.permissions_error",
      "defaultMessage": "Post Failed: Permission error. Please make sure Popmenu has full access to your social account."
    },
    "errorsSocialPostVideoPermission": {
      "id": "errors.social_post.processed.video_permissions",
      "defaultMessage": "Post Failed: Subject does not have permission to post videos on this target."
    },
    "errorsSocialPostUnpublishedPostPermission": {
      "id": "errors.social_post.processed.unpublished_post_permissions",
      "defaultMessage": "Post Failed: You do not have permission to create an unpublished post."
    },
    "errorsSocialPostTemporarilyBlocked": {
      "id": "errors.social_post.processed.facebook_temporarily_blocked",
      "defaultMessage": "Post Failed - Facebook Error: You have been temporarily blocked from performing this action by Facebook."
    },
    "errorsSocialPostFacebookPolicies": {
      "id": "errors.social_post.processed.facebook_policies",
      "defaultMessage": "Post Failed - Facebook error: You recently posted something that violates Facebook policies, so you're temporarily blocked from using this feature."
    },
    "errorsSocialPostFacebookLimitedAccess": {
      "id": "errors.social_post.processed.limited_access",
      "defaultMessage": "Post Failed - Facebook error: For security reasons, your account has limited access to the site for a few days."
    },
    "errorsSocialPostMediaCreationError": {
      "id": "errors.social_post.processed.media_creation_error",
      "defaultMessage": "Post Failed: Instagram media container creation failed. Please check here for media limits: https://support.popmenu.com/s/article/How-to-Post-a-Video-Using-Popmenu-s-Social-Media-Tools"
    },
    "errorsSocialPostMediaGraphError": {
      "id": "errors.social_post.processed.graph_error",
      "defaultMessage": "Post Failed: Unsupported request - object does not exist, cannot be loaded due to missing permissions, or does not support this operation. Please check permissions and try again."
    },
    "errorsSocialPostMediaTimeout": {
      "id": "errors.social_post.processed.media_timeout",
      "defaultMessage": "Post Failed: Media timeout. Please try again."
    },
    "errorsSocialPostFacebookInstructions": {
      "id": "errors.social_post.processed.facebook_instructions",
      "defaultMessage": "Post Failed: Facebook error - You cannot access the app until you log in to www.facebook.com and follow the instructions given."
    },
    "errorsSocialPostInvalidAspectRatio": {
      "id": "errors.social_post.processed.invalid_aspect_ratio",
      "defaultMessage": "Post Failed: The submitted image with aspect ratio cannot be submitted. Please submit an image with a valid aspect ratio."
    },
    "errorsSocialPostMaximumPosts": {
      "id": "errors.social_post.processed.maximum_posts",
      "defaultMessage": "Post Failed: You reached maximum number of posts that is allowed to be published by Content Publishing API. Please try again later."
    },
    "errorsSocialSelectFacebookPage": {
      "id": "errors.social_post.processed.facebook_page_not_selected",
      "defaultMessage": "Post Failed: Please select a Facebook page from the integration settings."
    },
    "errorsSocialSelectInstagramPage": {
      "id": "errors.social_post.processed.instagram_page_not_found",
      "defaultMessage": "Post Failed: Please select a Facebook page from the integration settings and ensure that a business Instagram page is connected to it."
    },
    "errorsSocialTwitterAccountSuspended": {
      "id": "errors.social_post.processed.twitter_account_suspended",
      "defaultMessage": "Post Failed: Twitter error - your account is suspended. Please check the Twitter help site or contact their customer service."
    },
  }
);
export default defaultMessages;

