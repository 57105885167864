import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { compose } from '@shakacode/recompose';
import { Button } from '@popmenu/admin-ui';
import { Typography } from '@popmenu/common-ui';

import { withStyles } from '../../../utils/withStyles';
import styles from './styles';

import PopmenuLogoImg from '../../../assets/svg/popmenu_logo_white.svg';
import Grid from '../../../shared/Grid';
import SignUpForm from '../../invites/SignUpForm';
import SignInForm from '../../../shared/sessions/SignInForm';

const UserSignUpForm = ({ classes, email, facebookOauthDisabled, restaurantSlug }) => {
  const [showSignUp, setShowSignUp] = useState(true);
  const searchParams = new URLSearchParams(document.location.search);
  const isOauth = searchParams.get('oauth');

  const afterSignInOrUpRedirect = () => {
    if (!isOauth) {
      window.location.href = restaurantSlug ? `/${restaurantSlug}` : '/';
    }
  };

  return (
    <Grid container justify="center" spacing={0} className={classes.container}>
      <Grid item xs={12} md={5} className={classes.logoContainer}>
        <div>
          <Typography color="white" variant="h5">
            <FormattedMessage
              id="sign_up.form"
              defaultMessage="Welcome to"
            />
          </Typography>
          <PopmenuLogoImg className={classes.logo} />
        </div>
      </Grid>
      <Grid item xs={12} md={7} className={classes.signUpFormContainer}>
        <Grid container justify="center" spacing={0}>
          {showSignUp ? (
            <React.Fragment>
              <Grid item xs={11} md={9} className={classes.signUpForm}>
                <Typography variant="h6">
                  <FormattedMessage
                    id="sign_up.create_account"
                    defaultMessage="Let's Create Your Account"
                  />
                </Typography>
                <br />
                <SignUpForm
                  email={email}
                  showAllFields={false}
                  style={{ backgroundColor: '#fff' }}
                  variant="outlined"
                  onUserExists={() => setShowSignUp(false)}
                  afterSignUp={() => afterSignInOrUpRedirect()}
                />
                <Typography align="center" variant="body1">
                  <FormattedMessage
                    id="sign_up.existing_account"
                    defaultMessage="Already have an account?&nbsp;"
                  />
                </Typography>
                <Typography align="center" variant="body1">
                  <Button
                    autoCapitalize="false"
                    onClick={() => setShowSignUp(false)}
                    variant="text"
                  >
                    <FormattedMessage
                      id="sign_up.existing_account"
                      defaultMessage="Sign-In"
                    />
                  </Button>
                </Typography>
              </Grid>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Grid item xs={11} md={9} className={classes.signUpForm}>
                <br />
                <SignInForm
                  email={email}
                  facebookOauthDisabled={facebookOauthDisabled}
                  style={{ backgroundColor: '#fff' }}
                  variant="outlined"
                  afterSignIn={() => afterSignInOrUpRedirect()}
                />
                <br />
                <Typography align="center" variant="body2">
                  <Button
                    onClick={() => setShowSignUp(true)}
                    variant="text"
                  >
                    <FormattedMessage
                      id="sign_up.existing_account_sign_up"
                      defaultMessage="Don't have an existing account?&nbsp;{signUp}"
                      values={{
                        signUp: (
                          <strong className={classes.red}>
                            <FormattedMessage
                              id="sign_up.self"
                              defaultMessage="Sign up"
                            />
                          </strong>
                        ),
                      }}
                    />
                  </Button>
                </Typography>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

UserSignUpForm.defaultProps = {
  email: null,
  restaurantSlug: null,
};

UserSignUpForm.propTypes = {
  classes: PropTypes.object.isRequired,
  email: PropTypes.string,
  facebookOauthDisabled: PropTypes.bool.isRequired,
  restaurantSlug: PropTypes.string,
};

export default compose(
  withStyles(styles),
)(UserSignUpForm);
