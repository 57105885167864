import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "modelFacebookCampaignClicks": {
      "id": "models.facebook_campaign.clicks",
      "defaultMessage": "Clicks"
    },
    "modelFacebookCampaignFollowers": {
      "id": "models.facebook_campaign.followers",
      "defaultMessage": "+ Followers"
    },
    "modelFacebookCampaignImpressions": {
      "id": "models.facebook_campaign.impressions",
      "defaultMessage": "Impressions"
    },
    "modelFacebookCampaignName": {
      "id": "models.facebook_campaign.name",
      "defaultMessage": "Campaign"
    },
    "modelFacebookCampaignOrders": {
      "id": "models.facebook_campaign.orders",
      "defaultMessage": "Orders"
    },
    "modelFacebookCampaignRoi": {
      "id": "models.facebook_campaign.roi",
      "defaultMessage": "ROI"
    },
    "modelFacebookCampaignSelfs": {
      "id": "models.facebook_campaign.self",
      "defaultMessage": "Facebook Campaign"
    },
  }
);
export default defaultMessages;

