export const SET_LAST_VIEWED_HISTORY_OFFSET = 'SET_LAST_VIEWED_HISTORY_OFFSET';
export const SET_NOTIFYING_CURBSIDE_GUEST = 'SET_NOTIFYING_CURBSIDE_GUEST';
export const SET_READY_FOR_CAPTURE = 'SET_READY_FOR_CAPTURE';
export const SET_SELECTED_CART_BUCKET = 'SET_SELECTED_CART_BUCKET';
export const SET_SELECTED_EVENT_ID = 'SET_SELECTED_EVENT_ID';
export const SET_SELECTED_LOCATION_ID = 'SET_SELECTED_LOCATION_ID';

export function setLastViewedHistoryOffset(historyOffset) {
  return (dispatch) => {
    dispatch({
      historyOffset,
      type: SET_LAST_VIEWED_HISTORY_OFFSET,
    });
  };
}

export function setNotifyingCurbsideGuest(notifyingCurbsideGuest) {
  return (dispatch) => {
    dispatch({
      notifyingCurbsideGuest,
      type: SET_NOTIFYING_CURBSIDE_GUEST,
    });
  };
}

export function setReadyForCapture(readyForCapture) {
  return (dispatch) => {
    dispatch({
      readyForCapture,
      type: SET_READY_FOR_CAPTURE,
    });
  };
}

export function setSelectedCartBucket(selectedCartBucket) {
  return (dispatch) => {
    dispatch({
      selectedCartBucket,
      type: SET_SELECTED_CART_BUCKET,
    });
  };
}

export function setSelectedLocationId(selectedLocationId) {
  return (dispatch) => {
    dispatch({
      selectedLocationId,
      type: SET_SELECTED_LOCATION_ID,
    });
  };
}
