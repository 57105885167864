
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "billingCustomersAttach": {
      "id": "billing_customers.attach",
      "defaultMessage": "Attach to existing Stripe ID"
    },
    "billingCustomersCreated": {
      "id": "billing_customers.created",
      "defaultMessage": "The new customer has been added this restaurant!"
    },
    "billingCustomersStripeId": {
      "id": "billing_customers.stripe_id",
      "defaultMessage": "Stripe ID"
    },
    "billingCustomersInvalid": {
      "id": "billing_customers.invalid",
      "defaultMessage": "Sorry, that Stripe ID is invalid."
    },
    "billingCustomersAddress": {
      "id": "billing_customers.address",
      "defaultMessage": "Billing Address"
    },
    "billingCustomersCompany": {
      "id": "billing_customers.company",
      "defaultMessage": "Company"
    },
    "billingCustomersEdit": {
      "id": "billing_customers.edit",
      "defaultMessage": "Update Billing Info"
    },
    "billingCustomersEmail": {
      "id": "billing_customers.email",
      "defaultMessage": "Email"
    },
    "billingCustomersNone": {
      "id": "billing_customers.none",
      "defaultMessage": "No billing address found."
    },
    "billingCustomersPhone": {
      "id": "billing_customers.phone",
      "defaultMessage": "Billing Phone"
    }
  }
);
export default defaultMessages;

