import React from 'react';

import Divider from '../forms/Divider';
import TermsContainer from '../../../consumer/shared/TermsContainer';
import TermsNav from './TermsNav';

const SharedTermsContainer = () => (
  <React.Fragment>
    <TermsNav />
    <Divider />
    <TermsContainer id="client-terms" termsType="clientTerms" />
  </React.Fragment>
);

export default SharedTermsContainer;
