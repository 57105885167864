
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "printLayoutsIndex": {
      "id": "print_layouts.index",
      "defaultMessage": "Print Center"
    },
    "printLayoutsDescription": {
      "id": "print_layouts.description",
      "defaultMessage": "Print Center leverages your online menus to help you easily create printable menus for your restaurant with a few clicks of a button."
    },
  }
);
export default defaultMessages;
