
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "consumerTitleSuffix": {
      "id": "consumer.title_suffix",
      "defaultMessage": "Popmenu"
    },
    "consumerCancel": {
      "id": "consumer.cancel",
      "defaultMessage": "Cancel"
    },
    "consumerBack": {
      "id": "consumer.back",
      "defaultMessage": "Back"
    },
    "consumerInteractiveHeader": {
      "id": "consumer.interactive_header",
      "defaultMessage": "New Feature"
    },
    "consumerInteractiveHint": {
      "id": "consumer.interactive_hint",
      "defaultMessage": "Tap into any item to check out guest reviews and ratings, or add your own!"
    }
  }
);
export default defaultMessages;

