import React from 'react';
import { Typography } from '@popmenu/common-ui';

import { notBlankValidator } from '../../../utils/forms';
import submitCcpaRequestMutation from '../../../libs/gql/mutations/ccpa/submitCCPARequestMutation.gql';

import BasicForm, { RadioGroup, SubmitGroup, TextFieldGroup } from '../forms/BasicForm';

class DoNotSellMyPersonalInformationForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.defaultOptions = [
      {
        label: 'Yes',
        value: true,
      },
      {
        label: 'No',
        value: false,
      },
    ];
  }

  renderYesNoButtonGroup(field, title, options = this.defaultOptions) {
    return (
      <RadioGroup
        field={field}
        options={options.map(option => ({
          label: option.label,
          value: option.value,
        }))}
        title={title}
      />
    );
  }

  /* eslint-disable react/jsx-no-literals */
  render() {
    return (
      <React.Fragment>
        <Typography>
          {'Popmenu, Inc. collects personal information both as a "service provider" to our clients and as a "business," as those terms are defined under the California Consumer Privacy Act (CCPA). Under the CCPA, California residents can request that Popmenu not sell their personal information to third parties and they can request access to, and deletion of, their personal information collected by Popmenu as a business. Popmenu does not sell your personal information to third parties and you can submit your other CCPA requests with respect to Popmenu as a business by completing the form below.'}
          <br />
          <br />
          {'If you have provided personal information to a business for which Popmenu is a service provider, you must contact that business directly regarding the personal information that Popmenu collects on its behalf.'}
        </Typography>
        <br />
        <br />
        <BasicForm
          mutate={{
            mutation: submitCcpaRequestMutation,
            onCompleted: () => {
              this.setState({ submitted: true });
            },
            toVariables: variables => ({
              ccpaRequestInput: variables,
            }),
          }}
          defaultValues={{
            isCaliforniaResident: false,
            isDeleteDataRequest: false,
            isLearnHowDataIsUsedRequest: false,
            isLearnHowDataWasObtainedRequest: false,
            isLearnIfDataWasSharedRequest: false,
            isLearnWhatCategoriesWeHaveRequest: false,
            isLearnWhatCategoryThirdPartiesRequest: false,
            isLearnWhatDataWeHaveRequest: false,
          }}
        >
          <TextFieldGroup
            field="name"
            type="name"
            title="Name"
            validate={notBlankValidator}
          />
          <TextFieldGroup
            field="email"
            type="email"
            title="Email"
            validate={notBlankValidator}
          />
          {this.renderYesNoButtonGroup('isCaliforniaResident', 'Are you a California resident?')}
          {this.renderYesNoButtonGroup('isDeleteDataRequest', 'Would you like Popmenu to delete your personal information?')}
          {this.renderYesNoButtonGroup('isLearnWhatDataWeHaveRequest', 'Would you like to know what personal information about you Popmenu has collected?')}
          {this.renderYesNoButtonGroup('isLearnWhatCategoriesWeHaveRequest', 'Would you like to know what categories of personal information about you Popmenu has collected?')}
          {this.renderYesNoButtonGroup('isLearnIfDataWasSharedRequest', 'Would you like to know what categories of personal information about you Popmenu has disclosed to third parties?')}
          {this.renderYesNoButtonGroup('isLearnHowDataIsUsedRequest', 'Would you like to know the business purposes for Popmenu\'s collection of your personal information?')}
          {this.renderYesNoButtonGroup('isLearnHowDataWasObtainedRequest', 'Would you like to know how Popmenu obtained your personal information?')}
          {this.renderYesNoButtonGroup('isLearnWhatCategoryThirdPartiesRequest', 'Would you like to learn the categories of third parties with whom your personal information was shared?')}
          <br />
          <Typography>
            {'By submitting this request, you declare under penalty of perjury that you are the consumer, or the Authorized Agent (as defined in the CCPA) of the consumer, whose personal information is the subject of this request.'}
          </Typography>
          <br />
          <SubmitGroup
            block
            color="secondary"
            disabled={this.state.submitted}
            offset={0}
            size="lg"
            title={this.state.submitted ? 'Request Submitted!' : 'Submit Request'}
          />
        </BasicForm>
      </React.Fragment>
    );
  }
  /* eslint-enable react/jsx-no-literals */
}

export default DoNotSellMyPersonalInformationForm;
