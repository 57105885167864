export default theme => ({
  paper: {
    backgroundColor: theme.palette.blue.main,
    margin: theme.spacing(4),
    marginBottom: `${theme.spacing(4)}px !important`,
    padding: theme.spacing(2),
  },
  paperDanger: {
    backgroundColor: theme.palette.primary.main,
  },
  root: {
    bottom: 0,
    maxWidth: '320px',
    position: 'fixed',
    right: 0,
    width: '100%',
    zIndex: 999999999,
  },
  typography: {
    color: 'white',
  },
  typographyDanger: {
    color: 'white',
  },
  webBuilder: {
    right: '340px',
  },
});
