export const SET_BUILDER_CONTENTS = 'SET_BUILDER_CONTENTS';
export const RESET_BUILDER_CONTENTS = 'RESET_BUILDER_CONTENTS';

export const mapBuilderDispatchToProps = dispatch => ({
  resetBuilderContents: () => dispatch(
    {
      type: RESET_BUILDER_CONTENTS,
    },
  ),
  setBuilderContents: contents => dispatch(
    {
      builderContents: { ...contents },
      type: SET_BUILDER_CONTENTS,
    },
  ),
});
