
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "messageCampaignsContent": {
      "id": "message_campaigns.content",
      "defaultMessage": "Content"
    },
    "messageCampaignsContentHelp": {
      "id": "message_campaigns.content_help",
      "defaultMessage": "Popmenu provides effective default content for each smart message, or you can customize the content with your own subject line or message:"
    },
    "messageCampaignsEdit": {
      "id": "message_campaigns.edit",
      "defaultMessage": "Edit Smart Message"
    },
    "messageCampaignsTrigger": {
      "id": "message_campaigns.trigger",
      "defaultMessage": "Trigger"
    },
    "messageCampaignsTriggerNote": {
      "id": "message_campaigns.trigger_note",
      "defaultMessage": "Note:"
    },
    "messageCampaignErrorNumberOfOrdersReachedTrigger": {
      "id": "message_campaign.error.number_of_orders_reached_trigger",
      "defaultMessage": "Please click the \"pencil icon\" to set your Order Quantity trigger and attach a reward offer.",
    },
    "messageCampaignErrorNumberOfDaysPassedWithoutOrderTrigger": {
      "id": "message_campaign.error.number_of_days_passed_without_order_trigger",
      "defaultMessage": "Please click the \"pencil icon\" to attach a reward offer.",
    },
    "messageCampaignErrorAmountSpentReachedTrigger": {
      "id": "message_campaign.error.amount_spent_reached_trigger",
      "defaultMessage": "Please click the \"pencil icon\" to set your Order Value trigger and attach a reward offer.",
    },
    "messageNoOffer": {
      "id": "message_campaigns.no_offer",
      "defaultMessage": "No Offer"
    },
    "messageCampaignOfferTypeOnlineOrdering": {
      "id": "message_campaign.offer_type.online_ordering",
      "defaultMessage": "Online Ordering",
    },
    "messageCampaignOfferTypeDineIn": {
      "id": "message_campaign.offer_type.dine_in",
      "defaultMessage": "Dine-In",
    },
    "messageCampaignsResetContent": {
      "id": "message_campaigns.reset_content",
      "defaultMessage": "Reset to default content"
    },
    "messageCampaignsTextAlignment": {
      "id": "message_campaigns.text_alignment",
      "defaultMessage": "Text Alignment"
    },
    "messageCampaignsTheme": {
      "id": "message_campaigns.theme",
      "defaultMessage": "Theme"
    },
    "messageCampaignsThemesLight": {
      "id": "message_campaigns.themes.light",
      "defaultMessage": "Light"
    },
    "messageCampaignsThemesDark": {
      "id": "message_campaigns.themes.dark",
      "defaultMessage": "Dark"
    },
    "messageCampaignsVariablesHelp": {
      "id": "message_campaigns.variables_help",
      "defaultMessage": "The Message Subject and Message Content can dynamically include relevant info about the user, your restaurant, and other related data by inserting Variables. Variables are included by wrapping double curly-braces around certain keywords:"
    },
    "messageCampaignsVariablesHelpAdvanced": {
      "id": "message_campaigns.variables_help_advanced",
      "defaultMessage": "Want to get even more advanced? Popmenu fully supports Liquid markup with objects, tags, and filters."
    },
    "messageCampaignsVariablesHelpTitle": {
      "id": "message_campaigns.variables_help_title",
      "defaultMessage": "Variables"
    },
    "messageCampaignsDisable": {
      "id": "message_campaigns.disable",
      "defaultMessage": "Disable Smart Message"
    },
    "messageCampaignsEnable": {
      "id": "message_campaigns.enable",
      "defaultMessage": "Enable Smart Message"
    },
    "messageCampaignsSettings": {
      "id": "message_campaigns.settings",
      "defaultMessage": "Smart Message Settings"
    },
    "messageCampaignsToggleConfirm": {
      "id": "message_campaigns.toggle_confirm",
      "defaultMessage": "Are you sure you want to {verb} the {name} smart message?"
    },
    "messageCampaignsTrigger": {
      "id": "message_campaigns.trigger",
      "defaultMessage": "Trigger"
    },
    "messageCampaignsOfferReward": {
      "id": "message_campaigns.offer_reward",
      "defaultMessage": "Offer/Reward"
    },
    "messageCampaignsOfferDescription": {
      "id": "message_campaigns.offer_description",
      "defaultMessage": "Please select an Online Offer to use as an incentive or Loyalty reward. Don't have one?"
    },
    "messageCampaignsOfferDescriptionv2": {
      "id": "message_campaigns.offer_description_V2",
      "defaultMessage": "Select from your custom offers, or one of our premade offers."
    },
    "messageCampaignsOfferDescriptionLink": {
      "id": "message_campaigns.offer_description.link",
      "defaultMessage": "Create one here."
    },
    "messageCampaignsOfferDescriptionLinkV2": {
      "id": "message_campaigns.offer_description.link_v2",
      "defaultMessage": "Create offer from scratch"
    },
    "messageCampaignsOfferNote": {
      "id": "message_campaigns.offer_note",
      "defaultMessage": "Note:"
    },
    "messageCampaignsOfferNoteDescription": {
      "id": "message_campaigns.offer_note.description",
      "defaultMessage": "The trigger will reset every time a guest reaches it. e.g if your trigger is 10 orders or $100 in orders, whenever the guest reaches it and gets their reward, the trigger will reset so the guest can earn more rewards if they place more orders."
    },
    "messageCampaignsOfferNoteNumberOfDaysPassedWithoutOrderTriggerDescription": {
      "id": "message_campaigns.offer_note.number_of_days_passed_without_order_trigger.description",
      "defaultMessage": "The trigger will reset every time a guest places an online order. e.g if a guest places an order, the time-frame that qualifies them to receive this smart message will reset."
    },
    "messageCampaignsOfferNoteOfferReminderTriggerDescription": {
      "id": "message_campaigns.offer_note.offer_reminder_trigger.description",
      "defaultMessage": "The trigger will reset every time the customer receives a new offer."
    },
    "messageCampaignSelectedLocationNoPreferredLocation": {
      "id": "message_campaign.selected_location.no_preferred_location",
      "defaultMessage": "No Preferred Location"
    }
  }
);
export default defaultMessages;

