
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "eventTagsNew": {
      "id": "event_tags.new",
      "defaultMessage": "New Event Tag"
    },
    "eventTagsEdit": {
      "id": "event_tags.edit",
      "defaultMessage": "Edit Event Tag"
    },
    "eventTagsDestroyConfirm": {
      "id": "event_tags.destroy_confirm",
      "defaultMessage": "Are you sure you want to delete this tag?"
    },
    "eventTagsDestroyConfirmTitle": {
      "id": "event_tags.destroy_confirm_title",
      "defaultMessage": "Confirm Delete"
    },
    "eventTagsDestroy": {
      "id": "event_tags.destroy",
      "defaultMessage": "Delete Event Tag"
    },
    "eventTagsDestroyed": {
      "id": "event_tags.destroyed",
      "defaultMessage": "Event Tag successfully deleted."
    },
    "eventTagsIndex": {
      "id": "event_tags.index",
      "defaultMessage": "Event Tags"
    },
    "eventTagsSettings": {
      "id": "event_tags.settings",
      "defaultMessage": "Event Tag Settings"
    }
  }
);
export default defaultMessages;

