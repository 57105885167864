import {
  SET_SIGN_UP_DATA,
} from './AnsweringSignUpActions';

const initialState = {
  signUpData: {
    isAiAnsweringEnabled: true,
  },
};

const answeringSignup = (state = initialState, action) => {
  switch (action.type) {
    case SET_SIGN_UP_DATA:
      return {
        ...state,
        signUpData: {
          ...state.signUpData,
          ...action.signUpData,
        },
      };

    default:
      return state;
  }
};

export default answeringSignup;
