import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "twilioSettingsPageTitle": {
      "id": "twilio.settings.title",
      "defaultMessage": "Twilio Settings"
    },
    "twilioSettingsNoPhoneNumber": {
      "id": "twilio.settings.no_phone_number",
      "defaultMessage": "No Phone Number"
    },
    "twilioSettingsPhoneNumberStatusUnverified": {
      "id": "twilio.settings.phone_number.status.unverified",
      "defaultMessage": "Unverified"
    },
    "twilioSettingsPhoneNumberStatusWarning": {
      "id": "twilio.settings.phone_number.status.warning",
      "defaultMessage": "In Review"
    },
    "twilioSettingsPhoneNumberStatusError": {
      "id": "twilio.settings.phone_number.status.error",
      "defaultMessage": "Twilio Rejected"
    },
    "twilioSettingsPhoneNumberStatusSuccess": {
      "id": "twilio.settings.phone_number.status.success",
      "defaultMessage": "Twilio Approved"
    },
    "twilioSettingsPhoneNumberStatusInfo": {
      "id": "twilio.settings.phone_number.status.info",
      "defaultMessage": "Pending Review"
    },
    "twilioSettingsGetPhoneNumber": {
      "id": "twilio.settings.get_phone_number",
      "defaultMessage": "Get Phone Number"
    },
    "twilioSettingsSMSSentMessages": {
      "id": "twilio.settings.sms.sent_messages",
      "defaultMessage": "Sent Messages"
    },
    "twilioSettingsSMSIncomingMessages": {
      "id": "twilio.settings.sms.incoming_messages",
      "defaultMessage": "Incoming Messages"
    },
    "twilioSettingsAnsweringSettings": {
      "id": "twilio.settings.answering.settings",
      "defaultMessage": "Settings"
    },
    "twilioSettingsAnsweringCalls": {
      "id": "twilio.settings.answering.calls",
      "defaultMessage": "Calls"
    },
    "twilioSettingsPhoneTypeAnswering": {
      "id": "twilio.settings.phone_type.answering",
      "defaultMessage": "AI Answering"
    },
    "twilioSettingsPhoneTypeSMS": {
      "id": "twilio.settings.phone_type.sms",
      "defaultMessage": "SMS"
    },
  }
);
export default defaultMessages;
