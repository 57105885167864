
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "qrCardDownload": {
      "id": "qr_card.download",
      "defaultMessage": "Download"
    },
    "qrCardMessage": {
      "id": "qr_card.message",
      "defaultMessage": "Sign up for our VIP list for updates, offers, and more!"
    },
    "qrCardNew": {
      "id": "qr_card.new",
      "defaultMessage": "Create Qr Card"
    },
    "qrCardUrl": {
      "id": "qr_card.url",
      "defaultMessage": "URL"
    }
  }
);
export default defaultMessages;

