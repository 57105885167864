
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "socialPostsAnalytics": {
      "id": "social_posts.analytics",
      "defaultMessage": "Post Analytics"
    },
    "socialPostsPostAgain": {
      "id": "social_posts.post_again",
      "defaultMessage": "Copy to New Post"
    },
    "socialPostsDestroyConfirm": {
      "id": "social_posts.destroy_confirm",
      "defaultMessage": "Are you sure you want to remove this social post? All associated data will be destroyed."
    },
    "socialPostsDestroyConfirmTitle": {
      "id": "social_posts.destroy_confirm_title",
      "defaultMessage": "Confirm Removal"
    },
    "socialPostsLivePosts": {
      "id": "social_posts.live_posts",
      "defaultMessage": "Published Posts"
    },
    "socialPostsDestroy": {
      "id": "social_posts.destroy",
      "defaultMessage": "Remove Post"
    },
    "socialPostsEdit": {
      "id": "social_posts.edit",
      "defaultMessage": "Edit Post"
    },
    "socialPostsFacebook": {
      "id": "social_posts.facebook",
      "defaultMessage": "Facebook"
    },
    "socialPostsIndex": {
      "id": "social_posts.index",
      "defaultMessage": "Social Posts"
    },
    "socialPostsInstagram": {
      "id": "social_posts.instagram",
      "defaultMessage": "Instagram"
    },
    "socialPostsInstagramDeletionNotSupported": {
      "id": "social_posts.instagram_deletion_not_supported",
      "defaultMessage": "Instagram post deletion is not supported"
    },
    "socialPostsNew": {
      "id": "social_posts.new",
      "defaultMessage": "New Social Post"
    },
    "socialPostsScheduledPosts": {
      "id": "social_posts.scheduled_posts",
      "defaultMessage": "Upcoming Posts"
    },
    "socialPostsShowAllPosts": {
      "id": "social_posts.show_all_posts",
      "defaultMessage": "See All Posts"
    },
    "socialPostsTwitter": {
      "id": "social_posts.twitter",
      "defaultMessage": "Twitter"
    },
    "socialPostsAiError": {
      "id": "social_posts.ai_error",
      "defaultMessage": "Unable to generate AI text. Please try again later."
    },
  }
);
export default defaultMessages;

