import type { BranchError } from 'branch-sdk';

const INIT_FAILED = 'branch sdk initialization failed';
const REQUEST_BLOCKED = 'request blocked by client';
const REQUEST_TIMED_OUT = 'request timed out';

const IGNORE_ERRORS = [INIT_FAILED, REQUEST_BLOCKED, REQUEST_TIMED_OUT] as const;

const isInitializationError = (err: string | object) => {
  const errString = String(err).toLowerCase();

  const shouldIgnore = IGNORE_ERRORS.some(errSubstring => errString.includes(errSubstring));

  return shouldIgnore;
};

const captureError = (err: BranchError) => {
  if (!err || !window.sentryCaptureException || isInitializationError(err)) {
    return;
  }

  window.sentryCaptureException(new Error(err));
};

export { isInitializationError, captureError };
