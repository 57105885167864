import React from 'react';
import PropTypes from 'prop-types';
import { withIntl } from '../../utils/withIntl';

import createRestaurantMutation from '../../libs/gql/mutations/restaurants/createRestaurantMutation.gql';
import { notBlankValidator } from '../../utils/forms';

import BasicForm, { SubmitGroup, TextFieldGroup } from '../shared/forms/BasicForm';

const CreateRestaurantForm = props => (
  <BasicForm
    labelPosition="top"
    mutate={{
      mutation: createRestaurantMutation,
      onCompleted: props.onCompleted,
    }}
  >
    <TextFieldGroup
      autoComplete="off"
      field="name"
      title={props.t('models.restaurant.name')}
      validate={notBlankValidator}
    />
    <SubmitGroup title={props.t('restaurants.new')} />
  </BasicForm>
);

CreateRestaurantForm.propTypes = {
  onCompleted: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withIntl(CreateRestaurantForm);
