export default theme => ({
  container: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
  },
  message: {
    margin: theme.spacing(3),
    textAlign: 'center',
  },
  paper: {
    border: '1px solid #CCC',
    boxShadow: '0 4px 24px rgba(0, 0, 0, .1)',
    margin: `${theme.spacing(1)}px auto !important`,
    maxWidth: '420px',
    minWidth: '300px',
    padding: theme.spacing(4),
  },
  popLogo: {
    display: 'block',
    height: '60px',
    margin: '0 auto',
    maxWidth: '100%',
  },
});
