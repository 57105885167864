
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "restaurantNavLinksSelf": {
      "id": "restaurant_nav_links.self",
      "defaultMessage": "Link"
    },
    "restaurantNavLinksDestroyConfirm": {
      "id": "restaurant_nav_links.destroy_confirm",
      "defaultMessage": "Are you sure you want to remove this link?"
    },
    "restaurantNavLinkDraftsDestroyConfirm": {
      "id": "restaurant_nav_link_drafts.destroy_confirm",
      "defaultMessage": "Are you sure you want to remove this link?"
    },
    "restaurantNavLinksDestroyConfirmTitle": {
      "id": "restaurant_nav_links.destroy_confirm_title",
      "defaultMessage": "Confirm Removal"
    },
    "restaurantNavLinkDraftsDestroyConfirmTitle": {
      "id": "restaurant_nav_link_drafts.destroy_confirm_title",
      "defaultMessage": "Confirm Removal"
    },
    "restaurantNavLinksNew": {
      "id": "restaurant_nav_links.new",
      "defaultMessage": "Add Link"
    },
    "restaurantNavLinkDraftsNew": {
      "id": "restaurant_nav_link_drafts.new",
      "defaultMessage": "Add Link"
    },
    "restaurantNavLinksEdit": {
      "id": "restaurant_nav_links.edit",
      "defaultMessage": "Edit Link"
    },
    "restaurantNavLinkDraftsEdit": {
      "id": "restaurant_nav_link_drafts.edit",
      "defaultMessage": "Edit Link"
    },
    "restaurantNavLinksDestroy": {
      "id": "restaurant_nav_links.destroy",
      "defaultMessage": "Remove Link"
    },
    "restaurantNavLinkDraftsDestroy": {
      "id": "restaurant_nav_link_drafts.destroy",
      "defaultMessage": "Remove Link"
    }
  }
);
export default defaultMessages;

