
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "sharesFacebook": {
      "id": "shares.facebook",
      "defaultMessage": "Share on Facebook"
    },
    "sharesPinterest": {
      "id": "shares.pinterest",
      "defaultMessage": "Save to Pinterest"
    },
    "sharesSms": {
      "id": "shares.sms",
      "defaultMessage": "Share via SMS"
    }
  }
);
export default defaultMessages;

