
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "billingProductsEdit": {
      "id": "billing_products.edit",
      "defaultMessage": "Edit Product"
    },
    "billingProductsNew": {
      "id": "billing_products.new",
      "defaultMessage": "Add One-Time Product"
    },
    "billingProductsPrices": {
      "id": "billing_products.prices",
      "defaultMessage": "Prices"
    },
    "billingProductsIsRestrictedTooltip": {
      "id": "billing_products.is_restricted_tooltip",
      "defaultMessage": "Restricted products cannot be selected by root-sales users"
    },
    "billingProductsStatementDescriptorPlaceholder": {
      "id": "billing_products.statement_descriptor_placeholder",
      "defaultMessage": "Extra information about a charge, displayed on the client's credit card statement"
    },
    "billingProductsPricesNew": {
      "id": "billing_products.prices.new",
      "defaultMessage": "Add a price"
    },
    "billingProductsPricesIsRestrictedTooltip": {
      "id": "billing_products.prices.is_restricted_tooltip",
      "defaultMessage": "Restricted prices cannot be selected by root-sales users"
    },
    "billingProductsPricesEdit": {
      "id": "billing_products.prices.edit",
      "defaultMessage": "Edit price"
    },
    "billingProductsPricesSelf": {
      "id": "billing_products.prices.self",
      "defaultMessage": "Pricing"
    }
  }
);
export default defaultMessages;

