import React from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect, Switch, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Query } from '~/lazy_apollo/client/react/components';
import { compose, withProps } from '@shakacode/recompose';
import { Typography } from '@popmenu/common-ui';

import { initAnalytics } from '~/utils/eventable';
import { classNames, withStyles } from '../../utils/withStyles';
import genericStyles from '../../assets/jss/admin/genericStyles';
import { currentSessionShape, withCurrentSession } from '../../shared/CurrentSessionProvider';
import { openForgotModal } from '../../shared/ModalActions';
import { withPopmenuConfig } from '../../utils/withPopmenuConfig';
import { withWindowSizeContext } from '../../shared/WindowSizeProvider';

import publicLoginCarouselSlidesQuery from '../../libs/gql/queries/login/publicLoginCarouselSlidesQuery.gql';

import AcceptInviteContainer from '../invites/AcceptInviteContainer';
import AccessibilityContainer from '../shared/terms/AccessibilityContainer';
import AuthyForm from '../../shared/sessions/AuthyForm';
import DMCAPolicyContainer from '../shared/terms/DMCAPolicyContainer';
import DoNotSellMyPersonalInformationForm from '../shared/terms/DoNotSellMyPersonalInformationForm';
import ForgotModal from '../../shared/ForgotModal';
import GenericContainerWrapper from './GenericContainerWrapper';
import Grid from '../../shared/Grid';
import Loading from '../../shared/Loading';
import Popmenu2020SVG from '../../assets/svg/popmenu_2020.svg';
import PrivacyPolicyContainer from '../shared/terms/PrivacyPolicyContainer';
import ProfileForm from '../../shared/sessions/ProfileForm';
import ResetForm from '../../shared/sessions/ResetForm';
import RestaurantIndexContainer from '../restaurants/RestaurantIndexContainer';
import Route from './GenericRoute';
import SetupLinkContainer from '../shared/SetupLinkContainer';
import SignInForm from '../../shared/sessions/SignInForm';
import SignInSlider from '../sign-in/SignInSlider/SignInSlider.imports-loadable';
import SharedTermsContainer from '../shared/terms/SharedTermsContainer';
import UnsubscribeContainer from '../unsubscribe/UnsubscribeContainer';
import PostUnsubscribeContainer from '../unsubscribe/PostUnsubscribeContainer';

class GenericContainer extends React.PureComponent {
  componentDidMount() {
    initAnalytics({ adminApp: true, popmenuConfig: this.props.popmenuConfig });
  }

  renderSliderLayout(component, noSlidesComponent = component) {
    const { classes } = this.props;
    return (
      <Query query={publicLoginCarouselSlidesQuery}>
        {({ data, loading }) => {
          if (loading || !data) {
            return <Loading size="lg" />;
          }
          const { count, records: slides } = data.publicLoginCarouselSlides;
          if (count && slides.length) {
            return (
              <div style={{ display: 'flex', maxWidth: '100%' }}>
                <div className={classes.sliderWrapper}>
                  <SignInSlider slides={slides} />
                </div>
                <div className={classNames(classes.panel, classes.sliderFormContainer)}>
                  {component}
                </div>
              </div>
            );
          }
          return (
            <GenericContainerWrapper>
              {noSlidesComponent}
            </GenericContainerWrapper>
          );
        }}
      </Query>
    );
  }

  renderSignIn() {
    return (
      <React.Fragment>
        <SignInForm
          afterSignIn={() => this.props.refetch()}
          facebookOauthDisabled={this.props.facebookOauthDisabled}
          openForgotModal={() => this.props.openForgotModal()}
        />
        <div className={this.props.classes.termsLinks}>
          <Grid container>
            <Grid item xs={6}>
              <Typography align="center" className={this.props.classes.termsLink}>
                <Link to="/terms">
                  <FormattedMessage id="terms_and_conditions" defaultMessage="Terms &amp; Conditions" />
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography align="center" className={this.props.classes.termsLink}>
                <Link to="/privacy">
                  <FormattedMessage id="privacy_policy" defaultMessage="Privacy Policy" />
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </div>
        <ForgotModal isAdmin />
      </React.Fragment>
    );
  }

  // Display restaurant list for authenticated users
  // Otherwise display sign in or Authy form
  renderDefault() {
    const session = this.props.currentSession;
    if (session.user) {
      if (!session.isAuthyVerified && session.isAuthyRequired) {
        return !this.props.isMobile ? (
          this.renderSliderLayout(
            <AuthyForm />,
          )
        ) : (
          <GenericContainerWrapper>
            <AuthyForm />
          </GenericContainerWrapper>
        );
      }

      const params = new URLSearchParams(this.props.location.search);
      const redirect = params.get('redirect');

      if (redirect) {
        return <Redirect to={redirect} />;
      }

      return (
        <GenericContainerWrapper>
          <RestaurantIndexContainer refetchCurrentUser={() => this.props.refetch()} />
        </GenericContainerWrapper>
      );
    }
    return !this.props.isMobile ? (
      this.renderSliderLayout(
        <div>
          <Link to="/" className={this.props.classes.panelLogoLink}>
            <Popmenu2020SVG className={this.props.classes.panelLogo} />
          </Link>
          <Typography align="center" variant="h4" className={this.props.classes.welcomeText}>
            <FormattedMessage id="sign_in_welcome" defaultMessage="Welcome!" />
          </Typography>
          {this.renderSignIn()}
        </div>,
        this.renderSignIn(),
      )
    ) : (
      <GenericContainerWrapper>
        {this.renderSignIn()}
      </GenericContainerWrapper>
    );
  }

  // Render "generic" routes- utility components outside the core admin app
  // Potentially used by consumers or unauthenticated users
  render() {
    return (
      <Switch>
        {/* Signed-in Routes */}
        {this.props.currentSession.user && (
          <Route path="/authenticate">
            <AuthyForm />
          </Route>
        )}
        {this.props.currentSession.user && (
          <Route path="/profile">
            <ProfileForm
              afterSignOut={() => {
                this.props.history.push('/');
              }}
              isAdmin
            />
          </Route>
        )}
        {/* Generic Routes */}
        <Route path="/reset/:token">
          <ResetForm />
        </Route>
        <Route path="/invites/:token">
          <AcceptInviteContainer
            afterSignIn={() => this.props.refetch()}
            openForgotModal={() => this.props.openForgotModal()}
          />
        </Route>
        <Route path="/setup-link">
          <SetupLinkContainer />
        </Route>
        <Route path="/accessibility">
          <AccessibilityContainer />
        </Route>
        <Route path="/dmca-policy">
          <DMCAPolicyContainer />
        </Route>
        <Route path="/do-not-sell-my-personal-info">
          <DoNotSellMyPersonalInformationForm />
        </Route>
        <Route path="/privacy">
          <PrivacyPolicyContainer />
        </Route>
        <Route path="/terms">
          <SharedTermsContainer />
        </Route>
        <Route path="/subscriptions/:unsubscribeToken">
          <UnsubscribeContainer />
        </Route>
        <Route path="/unsubscribed/:unsubscribeToken">
          <PostUnsubscribeContainer />
        </Route>
        <Route path="/" renderContainer={false}>
          {this.renderDefault()}
        </Route>
      </Switch>
    );
  }
}

GenericContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  currentSession: currentSessionShape.isRequired,
  facebookOauthDisabled: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  isMobile: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  openForgotModal: PropTypes.func.isRequired,
  popmenuConfig: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default compose(
  withRouter,
  withCurrentSession,
  withPopmenuConfig,
  withWindowSizeContext,
  connect(() => ({}), { openForgotModal }),
  withStyles(genericStyles),
  withProps(props => ({
    ...props,
    isMobile: props.windowSize.isMobile,
    location: props.location,
  })),
)(GenericContainer);
