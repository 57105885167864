
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "calendarEventsNew": {
      "id": "calendar_events.new",
      "defaultMessage": "New Event"
    },
    "calendarEventsEdit": {
      "id": "calendar_events.edit",
      "defaultMessage": "Edit Event"
    },
    "calendarEventsIndex": {
      "id": "calendar_events.index",
      "defaultMessage": "Events"
    },
    "calendarEventsSettings": {
      "id": "calendar_events.settings",
      "defaultMessage": "Event Settings"
    },
    "calendarEventsTags": {
      "id": "calendar_events.tags",
      "defaultMessage": "Manage Tags"
    },
    "calendarEventsDestroyConfirm": {
      "id": "calendar_events.destroy_confirm",
      "defaultMessage": "Are you sure you want to delete this event?"
    },
    "calendarEventsDestroyConfirmTitle": {
      "id": "calendar_events.destroy_confirm_title",
      "defaultMessage": "Confirm Delete"
    },
    "calendarEventsDestroy": {
      "id": "calendar_events.destroy",
      "defaultMessage": "Delete Event"
    },
    "calendarEventsDestroyed": {
      "id": "calendar_events.destroyed",
      "defaultMessage": "Event successfully deleted."
    }
  }
);
export default defaultMessages;

