
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "billingSubscriptionsSelf": {
      "id": "billing_subscriptions.self",
      "defaultMessage": "Subscription"
    },
    "billingSubscriptionsAddProduct": {
      "id": "billing_subscriptions.add_product",
      "defaultMessage": "Add Product"
    },
    "billingSubscriptionsAmountDue": {
      "id": "billing_subscriptions.amount_due",
      "defaultMessage": "Amount Due"
    },
    "billingSubscriptionsCouponTitle": {
      "id": "billing_subscriptions.coupon_title",
      "defaultMessage": "Coupon"
    },
    "billingSubscriptionsCoupon": {
      "id": "billing_subscriptions.coupon",
      "defaultMessage": "{stripe_id} (-{discount})"
    },
    "billingSubscriptionsNextBillingDate": {
      "id": "billing_subscriptions.next_billing_date",
      "defaultMessage": "Next billing date"
    },
    "billingSubscriptionsEdit": {
      "id": "billing_subscriptions.edit",
      "defaultMessage": "Modify Subscription"
    },
    "billingSubscriptionsParentCustomerDetails": {
      "id": "billing_subscriptions.parent_customer_details",
      "defaultMessage": "Subscriptions for this account are paid by a parent billing account: {email} ({name})"
    },
    "billingSubscriptionsNone": {
      "id": "billing_subscriptions.none",
      "defaultMessage": "Subscription has not been set up."
    },
    "billingSubscriptionsNoItems": {
      "id": "billing_subscriptions.no_items",
      "defaultMessage": "No products selected."
    },
    "billingSubscriptionsNoCoupon": {
      "id": "billing_subscriptions.no_coupon",
      "defaultMessage": "No coupon selected."
    },
    "billingSubscriptionsPublish": {
      "id": "billing_subscriptions.publish",
      "defaultMessage": "Start Subscription"
    },
    "billingSubscriptionsPublishConfirm": {
      "id": "billing_subscriptions.publish_confirm",
      "defaultMessage": "Are you sure you want to start this subscription?"
    },
    "billingSubscriptionsPublishConfirmTitle": {
      "id": "billing_subscriptions.publish_confirm_title",
      "defaultMessage": "Start Subscription"
    },
    "billingSubscriptionsPublishConfirmSuccess": {
      "id": "billing_subscriptions.publish_confirm_success",
      "defaultMessage": "Your subscription has been started!"
    },
    "billingSubscriptionsDestroy": {
      "id": "billing_subscriptions.destroy",
      "defaultMessage": "Cancel Subscription"
    },
    "billingSubscriptionsDestroyConfirm": {
      "id": "billing_subscriptions.destroy_confirm",
      "defaultMessage": "Are you sure you want to cancel the subscription? The current subscription will be canceled and the customer will receive no further invoices."
    },
    "billingSubscriptionsPaymentMethod": {
      "id": "billing_subscriptions.payment_method",
      "defaultMessage": "Payment Method"
    },
    "billingSubscriptionsProductTitle": {
      "id": "billing_subscriptions.product_title",
      "defaultMessage": "Products"
    },
    "billingSubscriptionsProduct": {
      "id": "billing_subscriptions.product",
      "defaultMessage": "{quantity} x {plan} ({price})"
    },
    "billingSubscriptionsStatus": {
      "id": "billing_subscriptions.status",
      "defaultMessage": "Status"
    },
    "billingSubscriptionsStatusInTrial": {
      "id": "billing_subscriptions.in_trial",
      "defaultMessage": "Is in trial"
    },
    "billingSubscriptionsStatusNonRenewing": {
      "id": "billing_subscriptions.non_renewing",
      "defaultMessage": "Non renewing"
    },
    "billingSubscriptionsStatusFuture": {
      "id": "billing_subscriptions.future",
      "defaultMessage": "Future"
    },
    "billingSubscriptionsStatusActive": {
      "id": "billing_subscriptions.active",
      "defaultMessage": "Active"
    },
    "billingSubscriptionsStatusPaused": {
      "id": "billing_subscriptions.paused",
      "defaultMessage": "Paused"
    },
    "billingSubscriptionsStatusCancelled": {
      "id": "billing_subscriptions.cancelled",
      "defaultMessage": "Cancelled"
    },
    "billingSubscriptionsStripeId": {
      "id": "billing_subscriptions.stripe_id",
      "defaultMessage": "Stripe ID"
    },
    "billingSubscriptionsTotal": {
      "id": "billing_subscriptions.total",
      "defaultMessage": "Total"
    },
    "billingSubscriptionsTrialDay": {
      "id": "billing_subscriptions.trial_day",
      "defaultMessage": "{number} day"
    },
    "billingSubscriptionsTrialDays": {
      "id": "billing_subscriptions.trial_days",
      "defaultMessage": "{number} days"
    },
    "billingSubscriptionsTrialTitle": {
      "id": "billing_subscriptions.trial_title",
      "defaultMessage": "Free Trial"
    }
  }
);
export default defaultMessages;

