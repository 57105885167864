import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "answeringOnboardingRoutingButton": {
      "id": "answering_onboarding.routing_button",
      "defaultMessage": "Setup Call Routing"
    },
    "answeringOnboardingFallbackButton": {
      "id": "answering_onboarding.fallback_button",
      "defaultMessage": "Add Fallback Numbers"
    },
    "answeringOnboardingGreetingsButton": {
      "id": "answering_onboarding.greetings_button",
      "defaultMessage": "Greetings & Goodbyes"
    },
    "answeringOnboardingReviewButton": {
      "id": "answering_onboarding.review_button",
      "defaultMessage": "Review Your Settings"
    },
    "answeringOnboardingSetupButton": {
      "id": "answering_onboarding.setup_button",
      "defaultMessage": "Build Your Agent"
    },
    "answeringOnboardingLoadingButton": {
      "id": "answering_onboarding.loading_button",
      "defaultMessage": "Loading..."
    },
    "answeringOnboardingPublishButton": {
      "id": "answering_onboarding.publish_button",
      "defaultMessage": "Publish Your Number"
    },
    "answeringOnboardingFinishButton": {
      "id": "answering_onboarding.finish_button",
      "defaultMessage": "See Your AI Answering Dashboard"
    },
    "answeringOnboardingStepsWelcome": {
      "id": "answering_onboarding.steps.welcome",
      "defaultMessage": "Welcome"
    },
    "answeringOnboardingStepsRouting": {
      "id": "answering_onboarding.steps.routing",
      "defaultMessage": "Call Routing"
    },
    "answeringOnboardingStepsFallback": {
      "id": "answering_onboarding.steps.fallback",
      "defaultMessage": "Add a Fallback Number"
    },
    "answeringOnboardingStepsFallbackPlaceholder": {
      "id": "answering_onboarding.steps.fallback.placeholder",
      "defaultMessage": "(555) 555-5555"
    },
    "answeringOnboardingStepsGreetings": {
      "id": "answering_onboarding.steps.greetings",
      "defaultMessage": "Hi's & Byes"
    },
    "answeringOnboardingStepsGreetingsHi": {
      "id": "answering_onboarding.steps.greetings.hi",
      "defaultMessage": "Answer calls with"
    },
    "answeringOnboardingStepsGreetingsBye": {
      "id": "answering_onboarding.steps.greetings.bye",
      "defaultMessage": "End calls with"
    },
    "answeringOnboardingStepsReview": {
      "id": "answering_onboarding.steps.review",
      "defaultMessage": "Review Settings"
    },
    "answeringOnboardingStepsWelcomeTitle": {
      "id": "answering_onboarding.steps.welcome.title",
      "defaultMessage": "Meet Popmenu AI Answering"
    },
    "answeringOnboardingStepsWelcomeSubtitle1": {
      "id": "answering_onboarding.steps.welcome.subtitle_1",
      "defaultMessage": "Here is what we are going to set up:"
    },
    "answeringOnboardingStepsWelcomeSubtitle2": {
      "id": "answering_onboarding.steps.welcome.subtitle_2",
      "defaultMessage": "Call Routing"
    },
    "answeringOnboardingStepsWelcomeSubtitle3": {
      "id": "answering_onboarding.steps.welcome.subtitle_3",
      "defaultMessage": "Adding a Secondary Number"
    },
    "answeringOnboardingStepsWelcomeSubtitle4": {
      "id": "answering_onboarding.steps.welcome.subtitle_4",
      "defaultMessage": "Customize Greetings and Farewells"
    },
    "answeringOnboardingStepsWelcomeSubtitle5": {
      "id": "answering_onboarding.steps.welcome.subtitle_5",
      "defaultMessage": "Test & Publish your Agent"
    },
    "answeringOnboardingStepsRoutingTitle": {
      "id": "answering_onboarding.steps.routing.title",
      "defaultMessage": "Which number do you want customers to call?"
    },
    "answeringOnboardingStepsRoutingSubtitle": {
      "id": "answering_onboarding.steps.routing.subtitle",
      "defaultMessage": "This number will be shown on your website, popmenu email marketing and Google Business Profile."
    },
    "answeringOnboardingStepsRoutingCurrentNumberTitle": {
      "id": "answering_onboarding.steps.routing.current_number.title",
      "defaultMessage": "My Current Number"
    },
    "answeringOnboardingStepsRoutingCurrentNumberSubtitle1": {
      "id": "answering_onboarding.steps.routing.current_number.subtitle_1",
      "defaultMessage": "Your phone number remains the same on your website, marketing materials and Google Business Profile."
    },
    "answeringOnboardingStepsRoutingCurrentNumberSubtitle2": {
      "id": "answering_onboarding.steps.routing.current_number.subtitle_2",
      "defaultMessage": "You will need to set up call forwarding with your phone provider.  We provide instructions for that later."
    },
    "answeringOnboardingStepsRoutingPopmenuNumberTitle": {
      "id": "answering_onboarding.steps.routing.popmenu_number.title",
      "defaultMessage": "Use my Popmenu AI Answering Number"
    },
    "answeringOnboardingStepsRoutingCurrentNumberSubtitle": {
      "id": "answering_onboarding.steps.routing.popmenu_number.subtitle",
      "defaultMessage": "Your original number will still exist, but your AI Answering number will be displayed on your website, marketing emails and Google Business Profile."
    },
    "answeringOnboardingStepsReviewRoutingCurrent": {
      "id": "answering_onboarding.steps.review.routing.current",
      "defaultMessage": "Your current business phone number"
    },
    "answeringOnboardingStepsReviewRoutingPopmenu": {
      "id": "answering_onboarding.steps.review.routing.popmenu",
      "defaultMessage": "Your Popmenu AI Answering Number"
    },
    "answeringOnboardingStepsFallbackTitleText": {
      "id": "answering_onboarding.steps.fallback.title.text",
      "defaultMessage": "Include a fallback number."
    },
    "answeringOnboardingStepsFallbackPopmenuNumberSubtitle": {
      "id": "answering_onboarding.steps.fallback.popmenu_number.subtitle",
      "defaultMessage": "When a customer requests a human, which number do you want to forward them to?"
    },
    "answeringOnboardingStepsFallbackCurrentNumberSubtitle": {
      "id": "answering_onboarding.steps.fallback.current_number.subtitle",
      "defaultMessage": "When customers request to talk to a human, forward their call to another number."
    },
    "answeringOnboardingStepsGreetingsTitleText": {
      "id": "answering_onboarding.steps.greetings.title.text",
      "defaultMessage": "Greetings and Goodbyes"
    },
    "answeringOnboardingStepsGreetingsSubtitleText": {
      "id": "answering_onboarding.steps.greetings.subtitle.text",
      "defaultMessage": "Customize how you greet or end a call with your customers."
    },
    "answeringOnboardingStepsGreetingsTipsTitle": {
      "id": "answering_onboarding.steps.greetings.tips.title",
      "defaultMessage": "Tips on making a great greeting."
    },
    "answeringOnboardingStepsGreetingsTips1": {
      "id": "answering_onboarding.steps.greetings.tips_1",
      "defaultMessage": "Show some appreciation that your customer called, but overrall keep the message short."
    },
    "answeringOnboardingStepsGreetingsTips2": {
      "id": "answering_onboarding.steps.greetings.tips_2",
      "defaultMessage": "Don’t use more than 2 or 3 sentences to greet your callers. Keep it short & simple."
    },
    "answeringOnboardingStepsGreetingsTips3": {
      "id": "answering_onboarding.steps.greetings.tips_3",
      "defaultMessage": "Try to sound natural, friendly and personal not like you are reading from a script."
    },
    "answeringOnboardingStepsReviewTitleText": {
      "id": "answering_onboarding.steps.review.title.text",
      "defaultMessage": "Review Your Settings"
    },
    "answeringOnboardingStepsReviewRouting": {
      "id": "answering_onboarding.steps.review.routing",
      "defaultMessage": "Customers call"
    },
    "answeringOnboardingStepsReviewPhone": {
      "id": "answering_onboarding.steps.review.phone",
      "defaultMessage": "The number that is displayed on your website, marketing materials and Google Business Profile."
    },
    "answeringOnboardingStepsReviewFallback": {
      "id": "answering_onboarding.steps.review.fallback",
      "defaultMessage": "Add a Secondary Number"
    },
    "answeringOnboardingStepsReviewGreetings": {
      "id": "answering_onboarding.steps.review.greetings",
      "defaultMessage": "Select a Call Greeting and Farewell"
    },
    "answeringOnboardingStepsAgentSubtitle1": {
      "id": "answering_onboarding.steps.agent.subtitle_1",
      "defaultMessage": "One moment..."
    },
    "answeringOnboardingStepsAgentSubtitle2": {
      "id": "answering_onboarding.steps.agent.subtitle_2",
      "defaultMessage": "We are building up your agent"
    },
    "answeringOnboardingStepsSummaryTitleText": {
      "id": "answering_onboarding.steps.summary.title.text",
      "defaultMessage": "Your call agent is activated, and ready to answer forwarded calls."
    },
    "answeringOnboardingStepsSummaryHowToTitle": {
      "id": "answering_onboarding.steps.summary.how_to.title",
      "defaultMessage": "Setup Call Forwarding"
    },
    "answeringOnboardingStepsSummaryHowToSubtitle": {
      "id": "answering_onboarding.steps.summary.how_to.subtitle",
      "defaultMessage": "*72"
    },
    "answeringOnboardingStepsSummaryHowToStep1": {
      "id": "answering_onboarding.steps.summary.how_to.step_1",
      "defaultMessage": "Pick up your phone, listen for a dial tone, and press *72."
    },
    "answeringOnboardingStepsSummaryHowToStep2": {
      "id": "answering_onboarding.steps.summary.how_to.step_2",
      "defaultMessage": "Listen for a stutter dial tone followed by regular dial tone."
    },
    "answeringOnboardingStepsSummaryHowToStep3": {
      "id": "answering_onboarding.steps.summary.how_to.step_3",
      "defaultMessage": "Dial the number where you want your calls forwarded."
    },
    "answeringOnboardingStepsSummaryHowToStep4": {
      "id": "answering_onboarding.steps.summary.how_to.step_4",
      "defaultMessage": "When the phone is answered by either a person or voicemail, hang up."
    },
    "answeringOnboardingStepsSummaryHowToStep5": {
      "id": "answering_onboarding.steps.summary.how_to.step_5",
      "defaultMessage": "Your calls will now be forwarded to the number you dialed."
    },
    "answeringOnboardingStepsSummaryHowToStep6": {
      "id": "answering_onboarding.steps.summary.how_to.step_6",
      "defaultMessage": "To remove call forwarding, listen for a dial tone, and press *73"
    },
    "answeringOnboardingStepsSummaryHowToWeb": {
      "id": "answering_onboarding.steps.summary.how_to.web",
      "defaultMessage": "Via Web"
    },
    "answeringOnboardingStepsSummaryHowToWebSubtitle": {
      "id": "answering_onboarding.steps.summary.how_to.web.subtitle",
      "defaultMessage": "Your phone providers might require you to go online, log in and set up call forwarding on your account."
    },
    "answeringOnboardingStepsSummarySubtitleLine2": {
      "id": "answering_onboarding.steps.summary.subtitle.line_2",
      "defaultMessage": "You must setup call forwarding with your business phone provider."
    },
    "answeringOnboardingStepsSummaryTestTitle": {
      "id": "answering_onboarding.steps.summary.test.title",
      "defaultMessage": "Test your number."
    },
    "answeringOnboardingStepsSummaryTestSubtitle": {
      "id": "answering_onboarding.steps.summary.test.subtitle",
      "defaultMessage": "Once forwarding is set up, you can call your business number and test Popmenu Answering by asking questions regarding location, ordering and business hours."
    },
    "answeringOnboardingStepsSummaryPopmenuTitle": {
      "id": "answering_onboarding.steps.summary.popmenu.title",
      "defaultMessage": "Your call agent is now active & ready to be published.",
    },
    "answeringOnboardingStepsSummaryPopmenuNumberTitle": {
      "id": "answering_onboarding.steps.summary.popmenu.number_title",
      "defaultMessage": "Your new Popmenu AI Answering number is:",
    },
    "answeringOnboardingStepsSummaryPopmenuTestTitle": {
      "id": "answering_onboarding.steps.summary.popmenu.test.title",
      "defaultMessage": "Test your new AI Answering assistant!",
    },
    "answeringOnboardingStepsSummaryPopmenuTestDescription": {
      "id": "answering_onboarding.steps.summary.popmenu.test.description",
      "defaultMessage": "Call your new Popmenu AI Answering number and ask questions about hours, location, reservations and waitlisting.",
    },
    "answeringOnboardingStepsSummaryPopmenuPublishTitle": {
      "id": "answering_onboarding.steps.summary.popmenu.publish.title",
      "defaultMessage": "Ready to publish your number?",
    },
    "answeringOnboardingStepsSummaryPopmenuPublishDescription": {
      "id": "answering_onboarding.steps.summary.popmenu.publish.description",
      "defaultMessage": "Your AI Answering phone number will replace your original phone number on the following",
    },
    "answeringOnboardingStepsSummaryPopmenuPublishBullet1": {
      "id": "answering_onboarding.steps.summary.popmenu.publish.bullet_1",
      "defaultMessage": "The footer on all Popmenu marketing emails",
    },
    "answeringOnboardingStepsSummaryPopmenuPublishBullet2": {
      "id": "answering_onboarding.steps.summary.popmenu.publish.bullet_2",
      "defaultMessage": "Your new AI Answering phone number will replace your original phone number on your website.",
    },
    "answeringOnboardingStepsSummaryPopmenuPublishBullet3": {
      "id": "answering_onboarding.steps.summary.popmenu.publish.bullet_3",
      "defaultMessage": "On your Google Business Profile",
    },
    "answeringOnboardingStepsPublishedHeader": {
      "id": "answering_onboarding.steps.published.header",
      "defaultMessage": "Success",
    },
    "answeringOnboardingStepsPublishedTitle": {
      "id": "answering_onboarding.steps.published.title",
      "defaultMessage": "Your number has been published.",
    },
    "answeringOnboardingStepsPublishedSubtitle": {
      "id": "answering_onboarding.steps.published.subtitle",
      "defaultMessage": "Your new phone number is listed on the following.",
    },
    "answeringOnboardingStepsPublishedDescription1": {
      "id": "answering_onboarding.steps.published.description_1",
      "defaultMessage": "Your website",
    },
    "answeringOnboardingStepsPublishedDescription2MarketingAndSmartMessage": {
      "id": "answering_onboarding.steps.published.description_2.marketing_and_smart_message",
      "defaultMessage": "Outgoing Popmenu Marketing Emails and Smart Messages",
    },
    "answeringOnboardingStepsPublishedDescription2MarketingAndAutomatedEmail": {
      "id": "answering_onboarding.steps.published.description_2.marketing_and_automated_email",
      "defaultMessage": "Outgoing Popmenu Marketing Emails and Automated Emails",
    },
    "answeringOnboardingStepsPublishedDescription3": {
      "id": "answering_onboarding.steps.published.description_3",
      "defaultMessage": "Google Business Profile page",
    },
    "answeringOnboardingStepsPublishedNote": {
      "id": "answering_onboarding.steps.published.note",
      "defaultMessage": "Updates to Google Business Profile can take a few hours.",
    },
  }
);
export default defaultMessages;
