import React from 'react';
import PropTypes from 'prop-types';
import { compose, mapProps } from '@shakacode/recompose';
import { useHistory, withRouter } from 'react-router-dom';
import { Typography } from '@popmenu/common-ui';

import { FormattedMessage } from 'react-intl';
import { toInt } from '../../utils/numbers';
import userByUnsubscribeMessageTokenQuery from '../../libs/gql/queries/users/userByUnsubscribeMessageTokenQuery.gql';
import updateUserSubscriptionsMutation from '../../libs/gql/mutations/users/updateUserSubscriptionsMutation.gql';

import BasicForm, { CheckBoxGroup, SubmitGroup } from '../shared/forms/BasicForm';
import Loading from '../../shared/Loading';
import Query from '../../shared/Query';

const UnsubscribeContainer = (props) => {
  const history = useHistory();
  let messageToken = '';
  if (props.urlSearchParamsString !== '') {
    const params = new URLSearchParams(props.urlSearchParamsString.search);
    messageToken = params.get('message_token');
  }
  const { unsubscribeToken } = props.match.params;
  const validateInput = (values) => {
    if (values.isUnsubscribed || values.unsubscribedRestaurantIds.some(value => value)) {
      return false;
    }

    return true;
  };
  return (
    <Query
      query={userByUnsubscribeMessageTokenQuery}
      variables={{ messageToken, unsubscribeToken }}
    >
      {({ data, loading }) => {
        if (loading || !data?.userByUnsubscribeMessageToken?.user) {
          return <Loading />;
        }
        const { user, restaurantId } = data.userByUnsubscribeMessageToken;
        const { followers } = user;

        const restaurantsToUnsubscribe = restaurantId ? followers.filter(follower => follower.restaurant.id === restaurantId) : followers;
        console.log('debug', followers);
        return (
          <React.Fragment>
            <Typography align="center" variant="h5">
              <FormattedMessage
                id="admin.unsubscribe_container.heading"
                defaultMessage="Subscriptions"
              />
            </Typography>
            <br />
            <br />
            <Typography>
              <FormattedMessage
                id="admin.unsubscribe_container.sorry"
                defaultMessage="We're sorry to see you go!"
              />
            </Typography>
            <br />
            <br />
            <Typography>
              <Typography>
                <FormattedMessage
                  id="admin.unsubscribe_container.specific_restaurants"
                  defaultMessage="Would you prefer to stop receiving marketing messages from specific restaurants?"
                />
              </Typography>
            </Typography>
            <BasicForm
              defaultValues={{
                isUnsubscribed: user.isUnsubscribed ? 'true' : null,
                unsubscribedRestaurantIds: restaurantsToUnsubscribe.map(follower => (
                  follower.isUnsubscribed || follower.restaurant.id === restaurantId ? follower.restaurant.id : null
                )),
              }}
              mutate={{
                mutation: updateUserSubscriptionsMutation,
                onCompleted: () => {
                  history.push(`/unsubscribed/${unsubscribeToken}`);
                },
                toVariables: ({ isUnsubscribed, unsubscribedRestaurantIds, ...variables }) => ({
                  ...variables,
                  isUnsubscribed: !!isUnsubscribed,
                  messageGroupMessageToken: messageToken,
                  unsubscribedRestaurantIds: (unsubscribedRestaurantIds || []).map(id => toInt(id)),
                  unsubscribeToken,
                }),
              }}
            >
              {({ values }) => (
                <React.Fragment>
                  {restaurantsToUnsubscribe.map((follower, i) => (
                    <CheckBoxGroup
                      data-cy="unsubscribe_restaurant"
                      field={`unsubscribedRestaurantIds[${i}]`}
                      inline
                      key={follower.id}
                      title={`Unsubscribe from ${follower.restaurant.name}`}
                      value={follower.restaurant.id}
                    />
                  ))}
                  <br />
                  <br />
                  {!restaurantId && (
                    <React.Fragment>
                      <Typography>
                        <FormattedMessage
                          id="admin.unsubscribe_container.all_restaurants"
                          defaultMessage="Do you want to stop receiving marketing messages from all your current restaurants?"
                        />
                      </Typography>
                      <CheckBoxGroup
                        data-cy="unsubscribe_all_checkbox"
                        field="isUnsubscribed"
                        inline
                        title="Unsubscribe from all restaurants"
                      />
                      <br />
                      <br />
                    </React.Fragment>
                  )}
                  <Typography>
                    <FormattedMessage
                      id="admin.unsubscribe_container.order_email_note"
                      defaultMessage="Note: You will still receive emails about any online orders you place at these restaurants."
                    />
                  </Typography>

                  <SubmitGroup
                    color="secondary"
                    data-tour-id="user-update-subscription-preferences-submit-button"
                    disabled={validateInput(values)}
                    justify="center"
                    offset={0}
                    title={<FormattedMessage id="unsubscribe.submit_button" defaultMessage="Update Preferences" />}
                  />
                </React.Fragment>
              )}
            </BasicForm>
          </React.Fragment>
        );
      }}
    </Query>
  );
};

UnsubscribeContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      unsubscribeToken: PropTypes.string,
    }),
  }).isRequired,
};

export default compose(
  withRouter,
  mapProps(({ location, ...props }) => ({
    ...props,
    urlSearchParamsString: location,
  })),
)(UnsubscribeContainer);
