import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { compose } from '@shakacode/recompose';
import { Link } from 'react-router-dom';
import { Typography } from '@popmenu/common-ui';

import { withStyles } from '../../../utils/withStyles';
import { withIntl } from '../../../utils/withIntl';
import { notBlankValidator, usStatesList } from '../../../utils/forms';
import styles from './styles';

import upsertLeadMutation from '../../../libs/gql/mutations/salesforce/upsertLeadMutation.gql';
import PopmenuLogoImg from '../../../assets/svg/popmenu_icon_red.svg';
import BasicForm, { SelectGroup, SubmitGroup, TextFieldGroup } from '../../shared/forms/BasicForm';
import Grid from '../../../shared/Grid';

const LeadForm = ({ classes, t }) => {
  const [showForm, setShowForm] = useState(true);
  const isNcr = window.location.href.includes('ncr');
  const leadSource = isNcr ? 'NCR' : 'Indie';
  return (
    <Grid container justify="center" spacing={0} className={classes.container}>
      <Grid item xs={11}>
        <PopmenuLogoImg className={classes.logo} />
        {showForm ? (
          <React.Fragment>
            <Typography align="center" gutterBottom variant="h5">
              <FormattedMessage
                id="sign_up.welcome"
                defaultMessage="Hi there! We're excited to help you bring your restaurant to life online."
              />
            </Typography>
            <Typography align="center">
              {isNcr ? (
                <FormattedMessage
                  id="sign_up.welcome_description"
                  defaultMessage="Let’s kick off with a few basic details."
                />
              ) : (
                <FormattedMessage
                  id="sign_up.welcome_description"
                  defaultMessage="Let’s kick off your {trial} with a few basic details."
                  values={{
                    trial: <strong><FormattedMessage id="sign_up.free_trial" defaultMessage="Free Trial" /></strong>,
                  }}
                />
              )}
            </Typography>
            <br />
            <br />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Typography align="center" gutterBottom variant="h5">
              <FormattedMessage
                id="sign_up.existing_lead"
                defaultMessage="Looks like you already have an account!"
              />
            </Typography>
            <br />
            <br />
            <Typography align="center">
              <Link to="/">
                <FormattedMessage
                  id="sign_up.existing_lead_description"
                  defaultMessage="Click here to sign in."
                />
              </Link>
            </Typography>
          </React.Fragment>
        )}
      </Grid>
      {showForm && (
        <Grid item xs={11} sm={9} md={6} lg={5}>
          <BasicForm
            mutate={{
              mutation: upsertLeadMutation,
              onCompleted: (e) => {
                // Present sign-in link if
                // 1. Lead email is associated with a user or
                // 2. A user with that email already exists
                if (!e.upsertLead) {
                  setShowForm(false);
                }
                if (typeof window.ga !== 'undefined') {
                  window.ga('send', {
                    eventAction: 'click',
                    eventCategory: 'Trial Button',
                    eventLabel: 'IndieTrial',
                    hitType: 'event',
                  });
                }
              },
              toVariables: variables => ({
                leadInput: {
                  company: variables.company,
                  email: variables.email,
                  fullName: variables.fullName,
                  phone: variables.phone,
                  source: leadSource,
                  state: variables.state,
                },
              }),
            }}
          >
            {({ values }) => (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextFieldGroup
                    field="fullName"
                    title={t('leads.full_name')}
                    validate={notBlankValidator}
                    data-tour-id="lead-form-name"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextFieldGroup
                    field="email"
                    title={t('leads.email')}
                    validate={notBlankValidator}
                    data-tour-id="lead-form-email"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextFieldGroup
                    field="phone"
                    title={t('leads.phone')}
                    type="tel"
                    validate={notBlankValidator}
                    data-tour-id="lead-form-phone"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldGroup
                    field="company"
                    title={t('leads.company')}
                    validate={notBlankValidator}
                    data-tour-id="lead-form-company"
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectGroup
                    field="state"
                    options={usStatesList().map(option => ({
                      label: option,
                      value: option,
                    }))}
                    title={t('leads.state')}
                    validate={notBlankValidator}
                    data-tour-id="lead-form-state"
                  />
                </Grid>
                <Grid item xs={12}>
                  <SubmitGroup
                    ButtonProps={{
                      lowercase: true,
                      padded: true,
                      rounded: true,
                      size: 'lg',
                    }}
                    disabled={!values.fullName || !values.email || !values.phone || !values.company || !values.state}
                    justify="center"
                    title={t('forms.get_started')}
                  />
                </Grid>
              </Grid>
            )}
          </BasicForm>
        </Grid>
      )}
    </Grid>
  );
};

LeadForm.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  withIntl,
  withStyles(styles),
)(LeadForm);
