
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "billingPlansAnsweringTitle": {
      "id": "billing_plans.answering_title",
      "defaultMessage": "Popmenu AI Answering"
    },
    "billingPlansEdit": {
      "id": "billing_plans.edit",
      "defaultMessage": "Edit Product"
    },
    "billingPlansNew": {
      "id": "billing_plans.new",
      "defaultMessage": "Add Recurring Product"
    },
    "billingPlansDescriptionPlaceholder": {
      "id": "billing_plans.description_placeholder",
      "defaultMessage": "Extra information about a product for the client's invoice, displayed within Popmenu"
    },
    "billingPlansIsRestrictedTooltip": {
      "id": "billing_plans.is_restricted_tooltip",
      "defaultMessage": "Restricted products cannot be selected by root-sales users"
    },
    "billingPlansLearnMoreAnswering": {
      "id": "billing_plans.learn_more_answering",
      "defaultMessage": "Start a conversation with us"
    },
    "billingPlansRequestUpgrade": {
      "id": "billing_plans.request_upgrade",
      "defaultMessage": "Request Upgrade"
    },
    "billingPlansRequestUpgradeRestricted": {
      "id": "billing_plans.request_upgrade_restricted",
      "defaultMessage": "Contact your Admin to upgrade"
    },
    "billingPlansRequestUpgradeSubmitted": {
      "id": "billing_plans.request_upgrade_submitted",
      "defaultMessage": "Request Submitted"
    },
    "billingPlansStatementDescriptorPlaceholder": {
      "id": "billing_plans.statement_descriptor_placeholder",
      "defaultMessage": "Extra information about a charge, displayed on the client's credit card statement"
    },
    "billingPlansUpgradePlans": {
      "id": "billing_plans.upgrade_plans",
      "defaultMessage": "Upgrade Plans"
    }
  }
);
export default defaultMessages;
