
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "customFormEntriesIndex": {
      "id": "custom_form_entries.index",
      "defaultMessage": "Form Responses"
    },
    "customFormEntriesTitle": {
      "id": "custom_form_entries.title",
      "defaultMessage": "Form Response"
    }
  }
);
export default defaultMessages;

