
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "adminTitleSuffix": {
      "id": "admin.title_suffix",
      "defaultMessage": "Popmenu"
    },
    "adminWelcome": {
      "id": "admin.welcome",
      "defaultMessage": "Welcome to Popmenu!"
    },
    "adminFooter": {
      "id": "admin.footer",
      "defaultMessage": "Copyright © {year} Popmenu"
    },
    "adminNew": {
      "id": "admin.new",
      "defaultMessage": "New"
    },
    "adminNotAvailable": {
      "id": "admin.not_available",
      "defaultMessage": "n/a"
    },
    "adminErrorContainerHeading": {
      "id": "admin.error_container.heading",
      "defaultMessage": "Sorry."
    },
    "adminErrorContainerBody": {
      "id": "admin.error_container.body",
      "defaultMessage": "Looks like there was an error loading the page."
    },
    "adminErrorContainerLink": {
      "id": "admin.error_container.link",
      "defaultMessage": "Reload"
    },
    "adminDineInCartBucketActiveDineInCartBucket": {
      "id": "admin.dine_in_cart_bucket.active_dine_in_cart_bucket",
      "defaultMessage": "Active"
    },
    "adminDineInCartBucketNewDineCartBucket": {
      "id": "admin.dine_in_cart_bucket.new_dine_in_cart_bucket",
      "defaultMessage": "New"
    },
    "adminDineInCartBucketHistoryDineInCartBucket": {
      "id": "admin.dine_in_cart_bucket.history_dine_in_cart_bucket",
      "defaultMessage": "Complete"
    },
    "adminStatusPromptReconnecting": {
      "id": "admin.status_prompt.reconnecting",
      "defaultMessage": "Reconnecting..."
    },
    "adminStatusPromptChangesConnectionRestored": {
      "id": "admin.status_prompt.changes_connection_restored",
      "defaultMessage": "Changes will not be saved until your connection is restored."
    },
    "adminStatusPromptChangesUpdateReady": {
      "id": "admin.status_prompt.update_ready",
      "defaultMessage": "Update ready!"
    },
    "adminStatusPromptChangesConnectionReloadPage": {
      "id": "admin.status_prompt.reload_page",
      "defaultMessage": "Simply reload the page to get"
    },
    "adminStatusPromptChangesConnectionLatestGoodness": {
      "id": "admin.status_prompt.latest_goodness",
      "defaultMessage": "the latest goodness."
    },
  }
);
export default defaultMessages;

