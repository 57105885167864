export const SET_CARD_TRACKER = 'SET_CARD_TRACKER';
export const SET_CARD_TRACKER_ID = 'SET_CARD_TRACKER_ID';
export const SET_CALENDAR_TYPE_SOURCE = 'SET_CALENDAR_TYPE_SOURCE';

export function setCardTracker(cardTrackerId, cardType) {
  return (dispatch) => {
    dispatch({
      cardTrackerId,
      cardType,
      type: SET_CARD_TRACKER,
    });
  };
}

export function setCardTrackerId(cardTrackerId) {
  return (dispatch) => {
    dispatch({
      cardTrackerId,
      type: SET_CARD_TRACKER_ID,
    });
  };
}

export function setCalendarTypeSource(calendarType) {
  return (dispatch) => {
    dispatch({
      calendarType,
      type: SET_CALENDAR_TYPE_SOURCE,
    });
  };
}
