import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  List as MuiList,
  ListItem as MuiListItem,
  ListItemAvatar as MuiListItemAvatar,
  ListItemSecondaryAction as MuiListItemSecondaryAction,
  ListItemText as MuiListItemText,
} from '@material-ui/core';
import { Button } from '@popmenu/admin-ui';
import { Avatar } from '@popmenu/common-ui';

import { withStyles } from '../../utils/withStyles';
import basicListStyles from '../../assets/jss/shared/basicListStyles';

const BasicListItemAction = props => (
  <MuiListItemSecondaryAction>
    <Button {...props} />
  </MuiListItemSecondaryAction>
);

const BasicListItemAvatar = props => (
  <MuiListItemAvatar>
    <Avatar {...props} />
  </MuiListItemAvatar>
);

const BasicListItemText = props => (
  <MuiListItemText {...props} />
);

BasicListItemText.defaultProps = {
  primary: null,
  secondary: null,
};

BasicListItemText.propTypes = {
  primary: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

const BasicListItem = ({ avatarUrl, children, classes, component, primaryText, secondaryText, ...props }) => (
  <MuiListItem className={classes.listItem} component={component} disableGutters {...props}>
    <React.Fragment>
      {avatarUrl && (
        <BasicListItemAvatar src={avatarUrl} size="large" />
      )}
      {(primaryText || secondaryText) && (
        <BasicListItemText primary={primaryText} secondary={secondaryText} />
      )}
      {children}
    </React.Fragment>
  </MuiListItem>
);

BasicListItem.defaultProps = {
  avatarUrl: null,
  children: undefined,
  component: 'div',
  primaryText: null,
  secondaryText: null,
};

BasicListItem.propTypes = {
  avatarUrl: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
  classes: PropTypes.object.isRequired,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.string]),
  primaryText: PropTypes.oneOfType([PropTypes.array, PropTypes.node, PropTypes.string]),
  secondaryText: PropTypes.oneOfType([PropTypes.array, PropTypes.node, PropTypes.string]),
};

/* eslint-disable react/prop-types */
const BasicListItemLink = ({ href, to, ...props }) => (
  <BasicList.Item button component={to ? Link : 'a'} href={href} to={to} {...props} />
);

const BasicList = props => (
  <MuiList component="div" {...props} />
);
/* eslint-enable react/prop-types */

BasicList.Item = withStyles(basicListStyles)(BasicListItem);
BasicList.ItemAction = BasicListItemAction;
BasicList.ItemLink = BasicListItemLink;
BasicList.ItemAvatar = BasicListItemAvatar;
BasicList.ItemText = BasicListItemText;

export default BasicList;
