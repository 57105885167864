
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "orderingAlmostThere": {
      "id": "ordering.almost_there",
      "defaultMessage": "You’re almost there!"
    },
    "orderingCurbsideDefaultNotification": {
      "id": "ordering.default_reply_text",
      "defaultMessage": "Awesome, We’ll be right out!!"
    },
    "orderingCurbsideDefaultFifteenNotification": {
      "id": "ordering.default_reply_fifteen_text",
      "defaultMessage": "Cool, we will be out in 15 mins!"
    },
    "orderingCurbsideDefaultFibeNotification": {
      "id": "ordering.default_reply_five_text",
      "defaultMessage": "Cool, we will be out in 5 mins!"
    },
    "orderingCurbsideDefaultTenNotification": {
      "id": "ordering.default_reply_ten_text",
      "defaultMessage": "Cool, we will be out in 10 mins!"
    },
    "orderingDeliveryEnableWithoutPaymentTitle": {
      "id": "ordering.delivery.enable_without_payment_title",
      "defaultMessage": "No Payment Method Configured"
    },
    "orderingDeliveryEnableWithoutPaymentDescription": {
      "id": "ordering.delivery.enable_without_payment_description",
      "defaultMessage": "Visit the payment settings for this location and connect an online payment method before enabling Delivery."
    },
   "orderingDeliveryEnableWithoutPaymentConfirm": {
      "id": "ordering.delivery.enable_without_payment_confirm",
      "defaultMessage": "Ok"
    },
    "orderingDineInTitle": {
      "id": "ordering.dine_in.title",
      "defaultMessage": "Contactless Dine-In"
    },
    "orderingDineInEmptyStateP1": {
      "id": "ordering.dine_in.empty_state.p1",
      "defaultMessage": "Popmenu's Contactless Dine-in feature puts the ordering experience in the hands of your guests."
    },
    "orderingDineInEmptyStateP2": {
      "id": "ordering.dine_in.empty_state.p2",
      "defaultMessage": "Guests will be able to scan a QR code, browse your menu, open a tab, fire orders to the kitchen and pay for their meal - all from their phone. Give the gift of speed, ease and convenience to your guests, as well as a more streamlined and efficient process to your staff."
    },
    "orderingDineInEmptyStateNote": {
      "id": "ordering.dine_in.note",
      "defaultMessage": "Note:"
    },
    "orderingDineInEmptyStateNoteDescription": {
      "id": "ordering.dine_in.note_description",
      "defaultMessage": "There is no additional charge to use the Contactless Dine-in feature."
    },
    "orderingDineInEnableWithoutPaymentTitle": {
      "id": "ordering.dine_in.enable_without_payment_title",
      "defaultMessage": "Turn on Dine-In Without Online Payments"
    },
    "orderingDineInEnableWithoutPaymentDescription": {
      "id": "ordering.dine_in.enable_without_payment_description",
      "defaultMessage": "Enabling Dine-In without a payment provider to collect online payments could introduce risks of customers forgetting to pay for their orders after closing their tabs. Do you want to enable without a way to capture online payments?"
    },
   "orderingDineInEnableWithoutPaymentCancel": {
      "id": "ordering.dine_in.enable_without_payment_cancel",
      "defaultMessage": "No"
    },
   "orderingDineInEnableWithoutPaymentConfirm": {
      "id": "ordering.dine_in.enable_without_payment_confirm",
      "defaultMessage": "Yes"
    },
    "orderingDineInGetStarted": {
      "id": "ordering.dine_in.get_started",
      "defaultMessage": "Get started now"
    },
    "orderingNoPaymentMethodModalPaymentLink": {
      "id": "ordering.no_payment_method_modal.payment_link",
      "defaultMessage": "Payment Settings"
    },
    "orderingDineIneWatchDemo": {
      "id": "ordering.dine_in.watch_demo",
      "defaultMessage": "Watch a demo"
    },
    "orderingDescription": {
      "id": "ordering.description",
      "defaultMessage": "Popmenu’s direct ordering solution is included at no extra cost to the restaurant. We think restaurants should own all their revenue, so we don’t take any commissions or percentages on orders, we simply charge a {platformFee} service fee to the guest.* {br}{br} In fact, most consumers (67 percent) said they’re willing to pay at least {platformFee} for an online order. One-third are willing to pay $2 to $4. {br}{br} The best part? That guest service fee makes its way back to you through product improvements; it allows us to continuously innovate around product functionality to make the ordering experience seamless for you and your customers—so you see more orders, more often. {br}{br} *Applicable credit card transaction rates apply",
    },
    "orderingDishSaleReportExportCsv": {
      "id": "ordering.dish_sale_report.export_csv",
      "defaultMessage": ""
    },
    "orderingDishSaleReportEmptyHeading": {
      "id": "ordering.dish_sale_report.empty_heading",
      "defaultMessage": "No dishes reported"
    },
    "orderingDishSaleReportExportPdf": {
      "id": "ordering.dish_sale_report.export_pdf",
      "defaultMessage": ""
    },
    "orderingDishSaleReportTitle": {
      "id": "ordering.dish_sale_report.title",
      "defaultMessage": "Dish Sales Report"
    },
    "orderingDishSaleReportOriginalMenuItemPriceTotal": {
      "id": "ordering.dish_sale_report.original_menu_item_price_total",
      "defaultMessage": "Total Amount"
    },
    "orderingDishSaleReportDishName": {
      "id": "ordering.dish_sale_report.dish_name",
      "defaultMessage": "Item"
    },
    "orderingDishSaleReportSelectedExtrasCount": {
      "id": "ordering.dish_sale_report.selected_extras_count",
      "defaultMessage": "Modifiers"
    },
    "orderingDishSaleReportQuantityTotal": {
      "id": "ordering.dish_sale_report.quantity_total",
      "defaultMessage": "Order Quantity"
    },
    "orderingDishSaleReportSharedExtraGroupName": {
      "id": "ordering.dish_sale_report.shared_extra_group_name",
      "defaultMessage": "Select 1 Side"
    },
    "orderingTopDishReportTitle": {
      "id": "ordering.top_dish_report.title",
      "defaultMessage": "Top-Selling Dishes"
    },
    "orderingTopDishReportExtrasTitle": {
      "id": "ordering.top_dish_report.extras_title",
      "defaultMessage": "Top Extras"
    },
    "orderingEnableDescription": {
      "id": "ordering.enable_description",
      "defaultMessage": "Online Ordering is not yet enabled. Prepare your restaurant for launch with our Online Ordering Launch Guide. When you’re ready to start taking online orders, enable it in Ordering Settings."
    },
    "orderingEventBackToEvents": {
      "id": "ordering.event.back_to_events",
      "defaultMessage": "Back To Events"
    },
    "orderingEventImage": {
      "id": "ordering.event.image",
      "defaultMessage": "Event Image"
    },
    "orderingEventMinOrder": {
      "id": "ordering.event.min_order",
      "defaultMessage": "Minimum Order Amount"
    },
    "orderingEventDate": {
      "id": "ordering.event.event_date",
      "defaultMessage": "Event Date"
    },
    "orderingEventDateAndTime": {
      "id": "ordering.event.event_date_time",
      "defaultMessage": "Date and Time"
    },
    "orderingEventDetails": {
      "id": "ordering.event.details",
      "defaultMessage": "Event Details"
    },
    "orderingEventDescription": {
      "id": "ordering.event.description",
      "defaultMessage": "Event Description"
    },
    "orderingEventLocationHelper": {
      "id": "ordering.event.location_helper_text",
      "defaultMessage": "Locations must accept online payments to host ordering events"
    },
    "orderingEventStatus": {
      "id": "ordering.event.status",
      "defaultMessage": "Status"
    },
    "orderingEventTime": {
      "id": "ordering.event.start_time",
      "defaultMessage": "Time"
    },
    "orderingEventPickupTime": {
      "id": "ordering.event.pickup_time",
      "defaultMessage": "Pickup Time"
    },
    "orderingEventPickupEndTime": {
      "id": "ordering.event.pickup_end_time",
      "defaultMessage": "Pickup End Time"
    },
    "orderingEventPickupStartTime": {
      "id": "ordering.event.pickup_start_time",
      "defaultMessage": "Pickup Start Time"
    },
    "orderingEventLocation": {
      "id": "ordering.event.location",
      "defaultMessage": "Location"
    },
    "orderingEventMenus": {
      "id": "ordering.event.menus",
      "defaultMessage": "Select menus for this event"
    },
    "orderingEventName": {
      "id": "ordering.event.name",
      "defaultMessage": "Event Name"
    },
    "orderingEventContentPlaceholder": {
      "id": "ordering.event.content_placeholder",
      "defaultMessage": "Celebrate St. Patrick’s Day with an order from our special menu!"
    },
    "orderingEventNamePlaceholder": {
      "id": "ordering.event.name_placeholder",
      "defaultMessage": "St. Patrick’s Day Special Event"
    },
    "orderingEventNew": {
      "id": "ordering.event.new",
      "defaultMessage": "Add Ordering Event"
    },
    "orderingEventPlaceOrderBy": {
      "id": "ordering.event.place_order_by",
      "defaultMessage": "Customers must place orders by"
    },
    "orderingEventIsUnlimitedOrders": {
      "id": "ordering.event.is_unlimited_orders",
      "defaultMessage": "How Many Orders Will You Accept?"
    },
    "orderingEventIsLimitedOrders": {
      "id": "ordering.event.limited_orders",
      "defaultMessage": "Limit Orders To"
    },
    "orderingEventIsUnlimitedOrdersTypeUnlimitedOrders": {
      "id": "ordering.event.is_unlimited_orders.unlimited_orders",
      "defaultMessage": "Unlimited Orders"
    },
    "orderingEventIsUnlimitedOrdersTypeLimitedOrders": {
      "id": "ordering.event.is_unlimited_orders.limited_orders",
      "defaultMessage": "Limited Orders"
    },
    "orderingEventDestroyConfirmTitle": {
      "id": "ordering.event.destroy_confirm_title",
      "defaultMessage": "Confirm delete"
    },
    "orderingEventDestroyConfirm": {
      "id": "ordering.event.destroy_confirm",
      "defaultMessage": "Are you sure you want to delete this ordering event?"
    },
    "orderingFaq": {
      "id": "ordering.faq",
      "defaultMessage": "Pop Ordering FAQs"
    },
    "orderingGetStarted": {
      "id": "ordering.get_started",
      "defaultMessage": "Get Started"
    },
    "orderingGoToSettings": {
      "id": "ordering.go_to_settings",
      "defaultMessage": "Go to ordering settings"
    },
    "orderingOrdersNoLocationsButton": {
      "id": "ordering.orders.no_locations_button",
      "defaultMessage": "Ordering Settings"
    },
    "orderingOrdersNoLocationsMessage": {
      "id": "ordering.orders.no_locations_message",
      "defaultMessage": "Enable Online Ordering for your menus to begin receiving orders."
    },
    "orderingOrdersTitle": {
      "id": "ordering.orders.title",
      "defaultMessage": "Orders"
    },
    "orderingPaymentSettingsStripeConnect": {
      "id": "ordering.payment_settings.stripe.connect",
      "defaultMessage": "Connect Stripe Account"
    },
    "orderingPaymentSettingsSquareConnect": {
      "id": "ordering.payment_settings.square.connect",
      "defaultMessage": "Connect Square Account"
    },
    "orderingReportMenuItemsNoItems": {
      "id": "ordering_report.menu_items.no_items",
      "defaultMessage": "No items reported."
    },
    "orderingReportMenuItemsNoModifiers": {
      "id": "ordering_report.menu_items.no_modifiers",
      "defaultMessage": "No modifiers reported."
    },
    "orderingSettingsConfirmButton": {
      "id": "ordering.settings.confirm_button",
      "defaultMessage": "Menu Settings"
    },
    "orderingSettingsConfirmMessage": {
      "id": "ordering.settings.confirm_message",
      "defaultMessage": "To enable this menu for Online Ordering, please enable it in the Menu Settings."
    },
    "orderingSettingsConfirmTitle": {
      "id": "ordering.settings.confirm_title",
      "defaultMessage": "Menu Disabled"
    },
    "orderingSettingsCurbsideGuestCommsHelperText": {
      "id": "ordering.settings.curbside.guest_comms_helper_text",
      "defaultMessage": "This is how the guest will let you know that they have arrived. If you want them to call you, please include a phone number. If not, they can reply to their automated text message."
    },
    "orderingSettingsDineInSkipPaymentAllowed": {
      "id": "ordering.settings.dine_in_skip_payment_allowed",
      "defaultMessage": "Allow customers to skip entering payment info when opening tab"
    },
    "orderingSettingsIsOrderingDineInHidePaymentStepEnabled": {
      "id": "ordering.settings.is_ordering_dine_in_hide_payment_step_enabled",
      "defaultMessage": "Hide \"Payment Info\" Step"
    },
    "orderingSettingsIsOrderingDineInRemoveTableLabelEnabled": {
      "id": "ordering.settings.is_ordering_dine_in_remove_table_label_enabled",
      "defaultMessage": "Hide table number entry"
    },
    "orderingSettingsIsOrderingDineInHideEmailFieldEnabled": {
      "id": "ordering.settings.is_ordering_dine_in_hide_email_field_enabled",
      "defaultMessage": "Hide email address entry"
    },
    "orderingSettingsIsOrderingDineInHideNameFieldEnabled": {
      "id": "ordering.settings.is_ordering_dine_in_hide_name_field_enabled",
      "defaultMessage": "Hide first and last name entry"
    },
    "orderingSettingsIsOrderingDineInHidePhoneNumberFieldEnabled": {
      "id": "ordering.settings.is_ordering_dine_in_hide_phone_number_field_enabled",
      "defaultMessage": "Hide phone number entry"
    },
    "orderingSettingsDineInAutoCloseSectionTitle": {
      "id": "ordering.settings.dine_in.auto_close_section_title",
      "defaultMessage": "Close out all inactive tabs at this time everyday:"
    },
    "orderingSettingsDineInAutoCloseToolTip": {
      "id": "ordering.settings.dine_in.auto_close_tool_tip",
      "defaultMessage": "Closing out inactive tabs will capture payment (if available) and send the tab to history"
    },
    "orderingSettingsDineInAutoCloseFieldTitle": {
      "id": "ordering.settings.dine_in.auto_close_field_title",
      "defaultMessage": "Auto Close Time"
    },
    "orderingSettingsDineInAutoCloseEmptyInput": {
      "id": "ordering.settings.dine_in.auto_close_empty_input",
      "defaultMessage": "No Time Selected"
    },
    "orderingSettingsPaymentAccounts": {
      "id": "ordering.settings.payment_accounts",
      "defaultMessage": "Payment Providers"
    },
    "orderingSettingsDineInTabSettingsDefaultTipNoteMessage": {
      "id": "ordering.settings.dine_in_tab_settings_tip_note",
      "defaultMessage": "This tip percentage will be automatically charged to the guest's tab if they forget to close their tab and you have to close it out for them"
    },
    "orderingNotificationsEmail": {
      "id": "ordering.notifications.email",
      "defaultMessage": "Email"
    },
    "orderingNotificationsTextMessage": {
      "id": "ordering.notifications.text_message",
      "defaultMessage": "Text Message"
    },
    "orderingNotificationsPush": {
      "id": "ordering.notifications.push",
      "defaultMessage": "In-App Notification"
    },
    "orderingSettingsTitle": {
      "id": "ordering.settings.title",
      "defaultMessage": "Ordering Settings"
    },
    "orderingTitle": {
      "id": "ordering.title",
      "defaultMessage": "Ordering"
    },
    "orderingFullTitle": {
      "id": "ordering.full_title",
      "defaultMessage": "Online Orders"
    },
    "orderingPaymentSettingsTitle": {
      "id": "ordering.payment_settings.title",
      "defaultMessage": "Payment Settings"
    },
    "orderingSquareSellerLocationNew": {
      "id": "ordering.square_seller_location_new",
      "defaultMessage": "Create new location within Square"
    },
    "orderingSquareSellerLocationTitle": {
      "id": "ordering.square_seller_location_title",
      "defaultMessage": "Square Locations"
    },
    "orderingSquareSellerLocationReconnect": {
      "id": "ordering.square_seller_location_reconnect",
      "defaultMessage": "Reconnect Square Account"
    },
    "orderingSquareSellerLocationStatusActive": {
      "id": "ordering.square_seller_location_active_status",
      "defaultMessage": "Active"
    },
    "orderingSquareSellerLocationStatusInactive": {
      "id": "ordering.square_seller_location_inactive_status",
      "defaultMessage": "Inactive"
    },
    "orderingNcrSilverStoreTitle": {
      "id": "ordering.ncr_silver_store_title",
      "defaultMessage": "Ncr Silver Stores"
    },
    "orderingSetupFullTitle": {
      "id": "ordering.setup.full_title",
      "defaultMessage": "Online Ordering"
    },
    "orderingSetupDeliveryTitle": {
      "id": "ordering.setup.delivery_title",
      "defaultMessage": "Online Delivery"
    },
    "orderingSetupFeesTaxes": {
      "id": "ordering.setup.fees_taxes",
      "defaultMessage": "Next: Fees & Taxes"
    },
    "orderingSetupOnlinePayment": {
      "id": "ordering.setup.online_payment",
      "defaultMessage": "Next: Online Payment"
    },
    "orderingSetupPickupTitle": {
      "id": "ordering.setup.pickup_title",
      "defaultMessage": "Online Pickup"
    },
    "orderingSetupTitle": {
      "id": "ordering.setup.title",
      "defaultMessage": "Ordering Setup"
    },
    "orderingViewLaunchGuide": {
      "id": "ordering.view_launch_guide",
      "defaultMessage": "View online ordering launch guide"
    },
    "orderingOrderNerdDemo": {
      "id": "ordering.ordernerd.demo",
      "defaultMessage": "Generate OrderNerd Order"
    },
    "orderingPaymentRefreshed": {
      "id": "ordering.payment.refreshed",
      "defaultMessage": "Online payment refreshed!"
    },
    "orderingPaymentCanceled": {
      "id": "ordering.payment.canceled",
      "defaultMessage": "Online payment canceled!"
    },
    "orderingPaymentCaptured": {
      "id": "ordering.payment.captured",
      "defaultMessage": "Online payment captured!"
    },
    "orderingUserContainerTotalOrders": {
      "id": "ordering.container.total_orders",
      "defaultMessage": "Total Orders"
    },
    "orderingUserContainerPopsAndReviews": {
      "id": "ordering.container.pops_and_reviews",
      "defaultMessage": "Pops &amp; Reviews"
    },
    "orderingUserContainerViewFollowerProfile": {
      "id": "ordering.container.view_follower_profile",
      "defaultMessage": "View Follower Profile"
    },
    "orderingUserContainerViewGoToSettings": {
      "id": 'ordering.container.go_to_settings',
      "defaultMessage": 'Go to Ordering Settings'
    },
    "orderingAlertPickupAndDeliveryOff": {
      "id": "ordering.alert.pickup_and_delivery_off",
      "defaultMessage": "Pickup & Delivery are turned off"
    },
    "orderingAlertPickupOff": {
      "id": "ordering.alert.pickup_off",
      "defaultMessage": "Pickup is turned off"
    },
    "orderingAlertDeliveryOff": {
      "id": "ordering.alert.delivery_off",
      "defaultMessage": "Delivery is turned off"
    },
  }
);
export default defaultMessages;
