
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "masterMessageCampaignsDestroyConfirm": {
      "id": "master_message_campaigns.destroy_confirm",
      "defaultMessage": "Are you sure you want to remove this root smart message? It will be removed from ALL restaurants, potentially ruining everything."
    },
    "masterMessageCampaignsDestroyConfirmTitle": {
      "id": "master_message_campaigns.destroy_confirm_title",
      "defaultMessage": "Confirm Removal"
    },
    "masterMessageCampaignsCreate": {
      "id": "master_message_campaigns.create",
      "defaultMessage": "Add Root Smart Message"
    },
    "masterMessageCampaignsIndex": {
      "id": "master_message_campaigns.index",
      "defaultMessage": "Root Smart Messages"
    },
    "masterAutomatedEmailCampaignsIndex": {
      "id": "master_automated_email_campaigns.index",
      "defaultMessage": "Root Automated Emails"
    },
    "masterMessageCampaignsEdit": {
      "id": "master_message_campaigns.edit",
      "defaultMessage": "Edit Root Smart Message"
    },
    "masterMessageCampaignsDestroy": {
      "id": "master_message_campaigns.destroy",
      "defaultMessage": "Remove Smart Message"
    }
  }
);
export default defaultMessages;

