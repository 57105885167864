export default theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.surface.light,
    },
  },
  container: {
    background: 'linear-gradient(135deg, #CE695F 0%, #9F1D42 100%)',
    display: 'flex',
    height: '100vh',
  },
  descriptionContainer: {
    '& p': {
      margin: 'auto',
    },
    '& svg': {
      margin: 'auto',
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(5),
      width: '100%',
    },
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
  line: {
    backgroundColor: 'white',
    height: '1px',
    left: 0,
    position: 'absolute',
    width: '100%',
  },
  logo: {
    color: 'white',
    display: 'block',
    height: '88px',
    marginTop: theme.spacing(2),
    maxWidth: '431px',
    width: '100%',
  },
  logoContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  red: {
    color: theme.palette.primary.main,
  },
  signUpFields: {
    backgroundColor: 'white',
  },
  signUpForm: {
    [theme.breakpoints.up('md')]: {
      minWidth: theme.spacing(77),
    },
    backgroundColor: 'white',
    border: '1px solid #e6e6e6',
    borderRadius: '0.5rem',
    padding: theme.spacing(4),
  },
  signUpFormContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    maxWidth: theme.spacing(108),
    padding: theme.spacing(12, 0, 5),
    position: 'relative',
  },
  signUpImageChildContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-around',
    padding: theme.spacing(0, 5, 5, 3),
    width: '100%',
  },
  signUpImageContainer: {
    '& p': {
      fontWeight: 'bold',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    alignItems: 'center',
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
  },
});
