export const SET_SIGN_UP_DATA = 'SET_SIGN_UP_DATA';

export function setSignUpData(signUpData) {
  return (dispatch) => {
    dispatch({
      signUpData,
      type: SET_SIGN_UP_DATA,
    });
  };
}
