import React from 'react';

import Divider from '../forms/Divider';
import TermsContainer from '../../../consumer/shared/TermsContainer';
import TermsNav from './TermsNav';

const DMCAPolicyContainer = () => (
  <React.Fragment>
    <TermsNav />
    <Divider />
    <TermsContainer termsType="dmcaPolicy" />
  </React.Fragment>
);

export default DMCAPolicyContainer;
