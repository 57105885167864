import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "modifiersAdd": {
      "id": "modifier_groups.add",
      "defaultMessage": "Add Modifier Group"
    },
    "modifiersCreate": {
      "id": "modifier_groups.create",
      "defaultMessage": "Create Modifier Group"
    },
    "modifiersDestroy": {
      "id": "modifier_groups.destroy",
      "defaultMessage": "Remove Modifier Group"
    },
    "modifiersDestroyConfirm": {
      "id": "modifier_groups.destroy_confirm",
      "defaultMessage": "Are you sure want to remove this modifier group?"
    },
    "modifiersDestroyConfirmTitle": {
      "id": "modifier_groups.destroy_confirm_title",
      "defaultMessage": "Confirm Removal"
    },
    "modifiersNew": {
      "id": "modifier_groups.new",
      "defaultMessage": "New Modifier Group"
    },
    "modifiersCopy": {
      "id": "modifier_groups.copy",
      "defaultMessage": "Copy to new modifier group"
    },
    "modifiersEdit": {
      "id": "modifier_groups.edit",
      "defaultMessage": "Edit Modifier Group"
    },
    "modifiersEmpty": {
      "id": "modifier_groups.empty",
      "defaultMessage": "Create modifiers group to customize items and provide additional choices for online ordering.",
    },
    "modifiersIndex": {
      "id": "modifier_groups.index",
      "defaultMessage": "Modifiers"
    },
    "modifiersManageOnSyncing": {
      "id": "modifier_groups.manage_on_syncing",
      "defaultMessage": "Manage OrderNerd Sync"
    },
    "modifiersOutOfStock": {
      "id": "modifier_groups.out_of_stock",
      "defaultMessage": "Modifiers out of stock"
    },
    "modifiersOpenRootGroup": {
      "id": "modifier_groups.open_root_group",
      "defaultMessage": "Open Root Group"
    },
    "modifiersRemoveGroup": {
      "id": "modifier_groups.remove_group",
      "defaultMessage": "Remove Group"
    },
    "modifiersModalButton": {
      "id": "modifier_groups.modal_button_title",
      "defaultMessage": "Item & Modifier Group Symbols"
    },
     "modifiersDatatableTitle": {
      "id": "modifier_groups.datatable_title",
      "defaultMessage": "Modifier Groups"
    },
    "modifiersModalQuickOverview": {
      "id": "modifier_groups.modal.quick_overview",
      "defaultMessage": "A quick overview"
    },
    "modifiersModalNavigatingSymbols": {
      "id": "modifier_groups.modal.navigating_symbols",
      "defaultMessage": "Navigating through our menu system you may start to see these symbols throughout. These icons are indicators that convey the relationships between items, modifiers, and menu level interactions."
    },
    "modifiersModalIfYouSee": {
      "id": "modifier_groups.modal.if_you_see",
      "defaultMessage": "If you see..."
    },
    "modifiersModalRootLevelItems": {
      "id": "modifier_groups.modal.root_level_items",
      "defaultMessage": "These indicate a root level Item and Modifier Group respectively. When changes are made to an Item or Modifier Group at this level, it affect all items and modifier group for all menus and locations (unless otherwise noted)."
    },
    "modifiersModalItems": {
      "id": "modifier_groups.modal.items",
      "defaultMessage": "These indicate an instance of an Item and Modifier Group respectively. When changes are made to an Item or Modifier Group, it will only affect the current menu and/or location you’re in. The impact is contextual to where you are editing."
    },
    "modifiersModalIconRootItem": {
      "id": "modifier_groups.modal.icon.root_item",
      "defaultMessage": "= Root Item"
    },
    "modifiersModalIconRootModifierGroup": {
      "id": "modifier_groups.modal.icon.root_modifier_group",
      "defaultMessage": "= Root Modifier Group"
    },
    "modifiersModalIconItem": {
      "id": "modifier_groups.modal.icon.item",
      "defaultMessage": "= Item (instance)"
    },
    "modifiersModalIconModifierGroup": {
      "id": "modifier_groups.modal.icon.modifier_group",
      "defaultMessage": "= Modifier Group (instance)"
    },
    "buildingModifierGroupsModalTitle": {
      "id":"modifier_groups.build.title",
      "defaultMessage": "Building Modifier Groups"
    },
    "buildingModifierGroupsModalLearnMore": {
      "id":"modifier_groups.build.learn_more_link",
      "defaultMessage": "Learn More"
    },
    "modifiers.connectModifierGroupHeader": {
      "id": "modifier_groups.connect_modifier_group_header",
      "defaultMessage": "Select a Modifier Group"
    },
    "modifiers.connectModifierGroupTitle": {
      "id": "modifier_groups.connect_modifier_group_title",
      "defaultMessage": "Select to add modifier groups to &ldquo;{item_name}&rdquo;"
    },
    "modifiers.connectModifierGroupSelectedCount": {
      "id": "modifier_groups.connect_modifier_group_selected_count",
      "defaultMessage": "{selected_modifier_group_count}/{modifier_group_count} modifier groups selected"
    },
  }
);
export default defaultMessages;
