
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "usersFullName": {
      "id": "users.full_name",
      "defaultMessage": "Name"
    },
    "usersGuest": {
      "id": "users.guest",
      "defaultMessage": "Guest"
    },
    "usersBirthday": {
      "id": "users.birthday",
      "defaultMessage": "Birthday"
    },
    "usersCreatedAt": {
      "id": "users.created_at",
      "defaultMessage": "Signed Up"
    },
    "usersExistsError": {
      "id": "users.exists_error",
      "defaultMessage": "Email has already been taken"
    },
    "usersGeneralError": {
      "id": "users.general_error",
      "defaultMessage": "An error occurred while signing up"
    },
    "usersIsPendingDestroy": {
      "id": "users.is_pending_destroy",
      "defaultMessage": "User marked for deletion"
    },
    "usersLastSignInIpAddress": {
      "id": "users.last_sign_in_ip_address",
      "defaultMessage": "Last IP Address"
    },
    "usersLastSignInUserAgent": {
      "id": "users.last_sign_in_user_agent",
      "defaultMessage": "Last User Agent"
    },
    "usersPasswordLength": {
      "id": "users.password_length",
      "defaultMessage": "Password ({length} characters min)"
    },
    "usersPasswordConfirm": {
      "id": "users.password_confirm",
      "defaultMessage": "Password confirmation"
    },
    "usersPasswordCurrent": {
      "id": "users.password_current",
      "defaultMessage": "Current password"
    },
    "usersPasswordCurrentNote": {
      "id": "users.password_current_note",
      "defaultMessage": "(required)"
    }
  }
);
export default defaultMessages;

