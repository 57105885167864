const mergePaginatedHandlerRecords = (existing = { count: 0, records: [] }, incoming, { readField }) => {
  const existingRecords = existing.records.slice(0);
  const existingRecordsDictionary = existingRecords.reduce((dictionary, record) => ({ ...dictionary, [readField('id', record)]: true }), {});
  const incomingRecords = incoming.records.filter(record => !existingRecordsDictionary[readField('id', record)]);
  const mergedRecords = [...existingRecords, ...incomingRecords];
  return {
    ...incoming,
    records: mergedRecords,
  };
};

export const mergeMenuItemCartsByLocationIdRecords = (existing, incoming, options) => {
  if (options?.args?.cartBucket === 'history') {
    return incoming;
  }

  return mergePaginatedHandlerRecords(existing, incoming, options);
};
