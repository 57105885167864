import {
  SET_ORDERING_OFFER_ID,
} from './TextMessageGroupsActions';

const initialState = {
  orderingOfferId: null,
};

const textMessageGroups = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORDERING_OFFER_ID:
      return {
        ...state,
        orderingOfferId: action.orderingOfferId,
      };

    default:
      return state;
  }
};

export default textMessageGroups;
