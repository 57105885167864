
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "restaurantMemberInvitesDestroyConfirm": {
      "id": "restaurant_member_invites.destroy_confirm",
      "defaultMessage": "Are you sure you want to revoke this invite?"
    },
    "restaurantMemberInvitesDestroyConfirmTitle": {
      "id": "restaurant_member_invites.destroy_confirm_title",
      "defaultMessage": "Confirm Revocation"
    },
    "restaurantMemberInvitesDestroy": {
      "id": "restaurant_member_invites.destroy",
      "defaultMessage": "Revoke Invite"
    }
  }
);
export default defaultMessages;

