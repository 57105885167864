export const TOGGLE_IFRAME_RENDER = 'TOGGLE_IFRAME_RENDER';
export const SET_LOADING_PREVIEW = 'SET_LOADING_PREVIEW';
export const SET_SELECTED_NAV_LINK = 'SET_SELECTED_NAV_LINK';
export const SET_SELECTED_PAGE = 'SET_SELECTED_PAGE';
export const SET_PREVIEW_SELECTED_SECTION = 'SET_PREVIEW_SELECTED_SECTION';
export const SET_ACTIVE_SECTION = 'SET_ACTIVE_SECTION';
export const SET_ADD_SECTION_OPENED = 'SET_ADD_SECTION_OPENED';
export const SET_FORM_ERROR = 'SET_FORM_ERROR';

// This function forces the iframe inside the webbuilder to re-render
export function toggleIframeRender() {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_IFRAME_RENDER,
    });
  };
}

export function setLoadingPreview(loadingPreview) {
  return (dispatch) => {
    dispatch({
      loadingPreview,
      type: SET_LOADING_PREVIEW,
    });
  };
}
export function setSelectedNavLink(selectedNavLink) {
  return (dispatch) => {
    dispatch({
      selectedNavLink,
      type: SET_SELECTED_NAV_LINK,
    });
  };
}
export function setSelectedPage(selectedPageId, pageUrl = null) {
  return (dispatch) => {
    dispatch({
      pageUrl,
      selectedPageId,
      type: SET_SELECTED_PAGE,
    });
  };
}
export function setPreviewSelectedSection(data) {
  return (dispatch) => {
    dispatch({
      data,
      type: SET_PREVIEW_SELECTED_SECTION,
    });
  };
}
export function setActiveSection(section) {
  return (dispatch) => {
    dispatch({
      section,
      type: SET_ACTIVE_SECTION,
    });
  };
}
export function setAddSectionOpened(payload) {
  return (dispatch) => {
    dispatch({
      payload,
      type: SET_ADD_SECTION_OPENED,
    });
  };
}
export function setFormError(formError) {
  return (dispatch) => {
    dispatch({
      formError,
      type: SET_FORM_ERROR,
    });
  };
}
