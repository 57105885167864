
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "announcementsNew": {
      "id": "announcements.new",
      "defaultMessage": "Add Announcement"
    },
    "announcementsFeatured": {
      "id": "announcements.featured",
      "defaultMessage": "Enabled"
    },
    "announcementsFeatured?": {
      "id": "announcements.featured?",
      "defaultMessage": "Enabled?"
    },
    "announcementsSendAsSmartMessage?": {
      "id": "announcements.send_as_smart_message?",
      "defaultMessage": "Send as smart message?"
    },
    "announcementsSendAsAutomatedEmail?": {
      "id": "announcements.send_as_automated_email?",
      "defaultMessage": "Send as automated email?"
    },
    "announcementsIndex": {
      "id": "announcements.index",
      "defaultMessage": "Announcements"
    },
    "announcementsEdit": {
      "id": "announcements.edit",
      "defaultMessage": "Edit Announcement"
    },
    "announcementsDestroyConfirm": {
      "id": "announcements.destroy_confirm",
      "defaultMessage": "Are you sure you want to remove this announcement?"
    },
    "announcementsDestroyConfirmTitle": {
      "id": "announcements.destroy_confirm_title",
      "defaultMessage": "Confirm Removal"
    },
    "announcementsCreated": {
      "id": "announcements.created",
      "defaultMessage": "Announcement successfully created!"
    },
    "announcementsCreatedError": {
      "id": "announcements.created_error",
      "defaultMessage": "There was an error creating the announcement."
    },
    "announcementsUpdated": {
      "id": "announcements.updated",
      "defaultMessage": "Announcement successfully updated!"
    },
    "announcementsUpdatedError": {
      "id": "announcements.updated_error",
      "defaultMessage": "There was an error updating the announcement."
    },
    "announcementsDestroy": {
      "id": "announcements.destroy",
      "defaultMessage": "Remove Announcement"
    },
    "announcementsDestroyed": {
      "id": "announcements.destroyed",
      "defaultMessage": "Announcement successfully removed."
    },
    "announcementsErrorFetchingData": {
      "id": "announcements.error_fetching_data",
      "defaultMessage": "Sorry, there was an error fetching the data."
    },
    "announcementsShowMore": {
      "id": "announcements.show_details",
      "defaultMessage": "Show details"
    },
    "announcementsLoadMore": {
      "id": "announcements.load_more",
      "defaultMessage": "Load More"
    },
    "announcementsCategories": {
      "id": "announcements.categories",
      "defaultMessage": "Categories"
    },
    "announcementsProductUpdates": {
      "id": "announcements.product_updates",
      "defaultMessage": "Product updates"
    },
    "announcementsSeeLatestFeatures": {
      "id": "announcements.see_latest_features",
      "defaultMessage": "See the latest feature releases, product improvements and bug fixes."
    },
    "announcementsStepperCreateAnnouncement": {
      "id": "announcements.stepper.create_announcement",
      "defaultMessage": "Create Announcement"
    },
    "announcementsStepperCreateAnnouncementPreview": {
      "id": "announcements.stepper.create_announcement.preview",
      "defaultMessage": "Preview"
    },
    "announcementsStepperCreateAnnouncementBackToDates": {
      "id": "announcements.stepper.create_announcement.back_to_dates",
      "defaultMessage": "Back to Dates"
    },
    "announcementsStepperCreateAnnouncementStyle": {
      "id": "announcements.stepper.create_announcement.style",
      "defaultMessage": "Style"
    },
    "announcementsStepperCreateAnnouncementStyleSubDescription": {
      "id": "announcements.stepper.create_announcement.style_sub_description",
      "defaultMessage": "Choose how you'd like the announcement displayed"
    },
    "announcementsStepperCreateAnnouncementStyleDisplay": {
      "id": "announcements.stepper.create_announcement.display",
      "defaultMessage": "Display"
    },
    "announcementsStepperCreateAnnouncementStyleBanner": {
      "id": "announcements.stepper.create_announcement.banner",
      "defaultMessage": "Banner"
    },
    "announcementsStepperCreateAnnouncementStyleBannerSubText": {
      "id": "announcements.stepper.create_announcement.banner_sub_text",
      "defaultMessage": "This will be a banner on the top of your home page"
    },
    "announcementsStepperCreateAnnouncementStylePopup": {
      "id": "announcements.stepper.create_announcement.popup",
      "defaultMessage": "Pop Up"
    },
    "announcementsStepperCreateAnnouncementStylePopupSubText": {
      "id": "announcements.stepper.create_announcement.popup_sub_text",
      "defaultMessage": "This will be a pop up on your home page"
    },
    "announcementsStepperCreateAnnouncementStylePageSection": {
      "id": "announcements.stepper.create_announcement.page_section",
      "defaultMessage": "Page Section"
    },
    "announcementsStepperCreateAnnouncementStylePageSectionSubText": {
      "id": "announcements.stepper.create_announcement.page_section_sub_text",
      "defaultMessage": "This will appear when you add an Announcement section to your page"
    },
    "announcementsStepperCreateAnnouncementStyleAboveMenu": {
      "id": "announcements.stepper.create_announcement.above_menu",
      "defaultMessage": "Above Menu"
    },
    "announcementsStepperCreateAnnouncementStyleAboveMenuSubText": {
      "id": "announcements.stepper.create_announcement.above_menu_sub_text",
      "defaultMessage": "This will appear above your menu "
    },
    "announcementsStepperCreateAnnouncementStyleSideBySide": {
      "id": "announcements.stepper.create_announcement.side_by_side",
      "defaultMessage": "Side By Side"
    },
    "announcementsStepperCreateAnnouncementStyleSideBySideSubText": {
      "id": "announcements.stepper.create_announcement.side_by_side_sub_text",
      "defaultMessage": "The content will be shown side by side horizontally"
    },
    "announcementsStepperCreateAnnouncementStyleStacked": {
      "id": "announcements.stepper.create_announcement.stacked",
      "defaultMessage": "Stacked"
    },
    "announcementsStepperCreateAnnouncementStyleStackedSubText": {
      "id": "announcements.stepper.create_announcement.stacked_sub_text",
      "defaultMessage": "The content will be vertically stacked"
    },
    "announcementsStepperChooseStyle": {
      "id": "announcements.stepper.choose_style",
      "defaultMessage": "Choose Style"
    },
    "announcementsStepperBackContent": {
      "id": "announcements.stepper.back_content",
      "defaultMessage": "Back to Content"
    },
    "announcementsStepperDates": {
      "id": "announcements.stepper.dates",
      "defaultMessage": "Dates"
    },
    "announcementsStepperChooseDates": {
      "id": "announcements.stepper.choose_template.choose_dates",
      "defaultMessage": "Choose Dates"
    },
    "announcementsStepperChooseLocations": {
      "id": "announcements.stepper.choose_template.choose_locations",
      "defaultMessage": "Choose Locations"
    },
    "announcementsStepperDatesChooseDatesWantShow": {
      "id": "announcements.stepper.choose_dates_want_show",
      "defaultMessage": "Choose the dates when you want the announcement to show."
    },
    "announcementsStepperPreviewMenuBasic": {
      "id": "announcements.stepper.preview.menu_basic",
      "defaultMessage": "Menu - Basic"
    },
    "announcementsStepperCreateAnnouncementReview": {
      "id": "announcements.stepper.create_announcement.review",
      "defaultMessage": "Review"
    },
    "announcementsStepperCreateAnnouncementBackToStyle": {
      "id": "announcements.stepper.create_announcement.back_to_style",
      "defaultMessage": "Back to Style"
    },
    "announcementsStepperCreateAnnouncementBackToPreview": {
      "id": "announcements.stepper.create_announcement.back_to_preview",
      "defaultMessage": "Back to Preview"
    },
    "announcementsStepperCreateAnnouncementBackToLocations": {
      "id": "announcements.stepper.create_announcement.back_to_locations",
      "defaultMessage": "Back to Locations"
    },
    "announcementsStepperLocations": {
      "id": "announcements.stepper.locations",
      "defaultMessage": "Locations"
    },
    "announcementsStepperLocationsSubTitle": {
      "id": "announcements.stepper.locations_sub_title",
      "defaultMessage": "Choose what location menu you'd like the announcement to appear on"
    },
    "announcementsStepperSelectAll": {
      "id": "announcements.stepper.select_all",
      "defaultMessage": "Select All"
    },
    "announcementsStepperCreateAnnouncementContentStyle": {
      "id": "announcements.stepper.create_announcement.content_style",
      "defaultMessage": "Cotent Style"
    },
    "announcementsStepperCreateAnnouncementStartDate": {
      "id": "announcements.stepper.start_date",
      "defaultMessage": "Start Date"
    },
    "announcementsStepperCreateAnnouncementEndDate": {
      "id": "announcements.stepper.end_date",
      "defaultMessage": "End Date"
    },
    "announcementsStepperCreateAnnouncementWeeklyRecurring": {
      "id": "announcements.stepper.weekly_recurring",
      "defaultMessage": "Weekly Recurring"
    },
    "announcementsStepperPreviewOrderOnline": {
      "id": "announcements.stepper.preview.order_online",
      "defaultMessage": "ORDER ONLINE"
    },
    "announcementsStepperPreviewMenu": {
      "id": "announcements.stepper.preview.menu",
      "defaultMessage": "MENU"
    },
    "announcementsStepperPreviewBecomeVip": {
      "id": "announcements.stepper.preview.become_vip",
      "defaultMessage": "BECOME VIP"
    },
    "announcementsStepperPreviewSignIn": {
      "id": "announcements.stepper.preview.sign_in",
      "defaultMessage": "SIGN IN"
    },
    "announcementsStepperSuccess": {
      "id": "announcements.stepper.create_announcement.success",
      "defaultMessage": "Announcement successfully created!"
    },
    "announcementsStepperEditSuccess": {
      "id": "announcements.stepper.edit_announcement.success",
      "defaultMessage": "Announcement successfully edited!"
    },
  }
);
export default defaultMessages;
