import type { StyleRules, Theme } from '@material-ui/core/styles';

const basicListStyles = (theme: Theme) => ({
  listItem: {
    borderRadius: '4px',
    marginLeft: theme.spacing(-1.5),
    marginRight: theme.spacing(-1.5),
    padding: theme.spacing(1.5),
    width: 'auto',
  },
} as const satisfies StyleRules);

export default basicListStyles;
