
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "sessionsSignUpTerms": {
      "id": "sessions.sign_up_terms",
      "defaultMessage": "Terms"
    },
    "sessionsSignUpPrivacy": {
      "id": "sessions.sign_up_privacy",
      "defaultMessage": "Privacy Policy"
    },
    "sessionsSignOut": {
      "id": "sessions.sign_out",
      "defaultMessage": "Sign Out"
    },
    "sessionsSeparator": {
      "id": "sessions.separator",
      "defaultMessage": "Or"
    },
    "sessionsReset": {
      "id": "sessions.reset",
      "defaultMessage": "Reset password"
    },
    "sessionsResetConfirm": {
      "id": "sessions.reset_confirm",
      "defaultMessage": "Are you sure you want to reset this user's password?"
    },
    "sessionsResetSend": {
      "id": "sessions.reset_send",
      "defaultMessage": "Send Password Reset"
    },
    "sessionsResetSent": {
      "id": "sessions.reset_sent",
      "defaultMessage": "The user will receive an email with the reset link below."
    },
    "sessionsSignInMagicLink": {
      "id": "sessions.sign_in_magic_link",
      "defaultMessage": "Email me a Magic Link"
    },
    "sessionsSignInAccessCode": {
      "id": "sessions.sign_in_access_code",
      "defaultMessage": "Text me an Acces Code"
    }
  }
);
export default defaultMessages;

