
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "helpIndex": {
      "id": "help.index",
      "defaultMessage": "Help"
    }
  }
);
export default defaultMessages;

