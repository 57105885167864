
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "restaurantNavDropdownsSelf": {
      "id": "restaurant_nav_dropdowns.self",
      "defaultMessage": "Dropdown"
    },
    "restaurantNavDropdownsNew": {
      "id": "restaurant_nav_dropdowns.new",
      "defaultMessage": "Add Dropdown"
    },
    "restaurantNavDropdownDraftsNew": {
      "id": "restaurant_nav_dropdown_drafts.new",
      "defaultMessage": "Add Dropdown"
    },
    "restaurantNavDropdownsNewLink": {
      "id": "restaurant_nav_dropdowns.new_link",
      "defaultMessage": "+ Add Dropdown Link"
    },
    "restaurantNavDropdownsEdit": {
      "id": "restaurant_nav_dropdowns.edit",
      "defaultMessage": "Edit Dropdown"
    },
    "restaurantNavDropdownDraftsEdit": {
      "id": "restaurant_nav_dropdown_drafts.edit",
      "defaultMessage": "Edit Dropdown"
    },
    "restaurantNavDropdownsDestroyConfirm": {
      "id": "restaurant_nav_dropdowns.destroy_confirm",
      "defaultMessage": "Are you sure you want to remove this dropdown?"
    },
    "restaurantNavDropdownDraftsDestroyConfirm": {
      "id": "restaurant_nav_dropdown_drafts.destroy_confirm",
      "defaultMessage": "Are you sure you want to remove this dropdown?"
    },
    "restaurantNavDropdownsDestroyConfirmTitle": {
      "id": "restaurant_nav_dropdowns.destroy_confirm_title",
      "defaultMessage": "Confirm Removal"
    },
    "restaurantNavDropdownDraftsDestroyConfirmTitle": {
      "id": "restaurant_nav_dropdown_drafts.destroy_confirm_title",
      "defaultMessage": "Confirm Removal"
    },
    "restaurantNavDropdownsDestroy": {
      "id": "restaurant_nav_dropdowns.destroy",
      "defaultMessage": "Remove Dropdown"
    },
    "restaurantNavDropdownDraftsDestroy": {
      "id": "restaurant_nav_dropdown_drafts.destroy",
      "defaultMessage": "Remove Dropdown"
    }
  }
);
export default defaultMessages;
