import { defineMessages } from 'react-intl';
export default defineMessages({
  "requestUpgrade": {
    "id": "upgrade_request.request_upgrade",
    "defaultMessage": "Request upgrade"
  },
  "requestUpgradeRestricted": {
    "id": "upgrade_request.upgrade_restricted",
    "defaultMessage": "Contact your Admin to upgrade"
  },
  "requestUpgradeRequestSubmitted": {
    "id": "upgrade_request.request_submitted",
    "defaultMessage": "Request Submitted"
  },
});
