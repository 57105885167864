import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "modelsSharedExtraGroup::ExtraSelf": {
      "id": "models.shared_extra_group/extra.self",
      "defaultMessage": "Option"
    },
    "modelsSharedExtraGroup::ExtraName": {
      "id": "models.shared_extra_group/extra.name",
      "defaultMessage": "Name"
    },
    "modelsSharedExtraGroup::ExtraPrice": {
      "id": "models.shared_extra_group/extra.price",
      "defaultMessage": "Price"
    },
    "sharedExtraGroupsDestroyConfirm": {
      "id": "shared_extra_groups.destroy_confirm",
      "defaultMessage": "Are you sure want to remove this modifier group?"
    },
    "sharedExtraGroupsDestroyConfirmTitle": {
      "id": "shared_extra_groups.destroy_confirm_title",
      "defaultMessage": "Confirm Removal"
    },
    "sharedExtraGroupsNew": {
      "id": "shared_extra_groups.new",
      "defaultMessage": "New Modifier Group"
    },
    "sharedExtraGroupsCopy": {
      "id": "shared_extra_groups.copy",
      "defaultMessage": "Copy to new shared modifier"
    },
    "sharedExtraGroupsEdit": {
      "id": "shared_extra_groups.edit",
      "defaultMessage": "Edit Modifier Group"
    },
    "sharedExtraGroupsIndex": {
      "id": "shared_extra_groups.index",
      "defaultMessage": "Shared Modifiers"
    },
    "sharedExtraGroupsDestroy": {
      "id": "shared_extra_groups.destroy",
      "defaultMessage": "Remove"
    },
    "sharedExtraGroupsPreview": {
      "id": "shared_extra_groups.preview",
      "defaultMessage": "Preview"
    },
      "dishSharedModifierGroupSearchForModifierGroups": {
        "id": "dish_shared_modifier_group.search_for_modifier_groups",
        "defaultMessage": "Search for modifier groups"
    },
  }
);
export default defaultMessages;
