import { SWITCH_SNACKBAR } from './SnackbarActions';

const initialState = {
  openedSnackbars: [],
};

const snackbars = (state = initialState, action) => {
  const { type, snackbarKey } = action;
  const { openedSnackbars: oS } = state;
  switch (type) {
    case SWITCH_SNACKBAR:
      return {
        ...state,
        openedSnackbars: oS.includes(snackbarKey) ? oS.filter(el => el !== snackbarKey) : oS.concat(snackbarKey),
      };
    default:
      return state;
  }
};

export default snackbars;
