import {
  SET_CALENDAR_TYPE_SOURCE,
  SET_CARD_TRACKER,
  SET_CARD_TRACKER_ID,
} from './AdvisorActions';

const initialState = {
  calendarTypeSource: null,
  cardTrackerId: null,
  cardType: null,
};

const AdvisorBoardReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CALENDAR_TYPE_SOURCE:
      return {
        ...state,
        calendarTypeSource: action.calendarType,
      };
    case SET_CARD_TRACKER:
      return {
        ...state,
        cardTrackerId: action.cardTrackerId,
        cardType: action.cardType,
      };
    case SET_CARD_TRACKER_ID:
      return {
        ...state,
        cardTrackerId: action.cardTrackerId,
        cardType: null,
      };
    default:
      return state;
  }
};

export default AdvisorBoardReducer;
